/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingCurrentRatios: false,
	fetchingEquityClassification: false,
	data: {},
	equityClassification: {
		sectorName: null,
		industryName: null
	},
	eps: {
		earningsPerShareMRQ: null,
		earningsPerShareTTM: null,
		earningsPerShareMRQ1Yr: null,
		earningsPerShareTTM1Yr: null,
		currency: null
	},
	revenue: {
		revenuePerShareMRQ: null,
		revenuePerShareTTM: null,
		revenueGrowthMRQ: null,
		revenueGrowthTTM: null,
		currency: null
	},
	profitability: {
		grossMarginTTM: null,
		preTaxMarginTTM: null,
		fiveYrPreTaxMarginTTM: null,
		operatingMarginTTM: null,
		fiveYrOperatingMarginTTM: null,
		profitMarginTTM: null,
		fiveYearAvgNetProfitMarginTTM: null,
		fiveYearAvgNetProfitMarginGrowthTTM: null,
		effectiveTaxRateTTM: null,
		fiveYearAvgEffectiveTaxRateTTM: null,
		grossMarginPtile: null,
		preTaxMarginPtile: null,
		operatingMarginPtile: null,
		operatingMargin5YPtile: null,
		profitMarginPtile: null,
		profitMargin5YPtile: null,
		netProfitMarginGrowth5YPtile: null,
		effectiveTaxRatePtile: null,
		effectiveTaxRate5YPtile: null

	},
	valuation: {
		priceEarningsTTM: null,
		priceCashFlowTTM: null,
		priceFreeCashFlowTTM: null,
		priceTangibleBookMRQ: null,
		priceSalesTTM: null,
		priceEarningsPtile: null,
		priceCashFlowPtile: null,
		priceFreeCashFlowPtile: null,
		priceTangibleBookPtile: null,
		priceSalesPtile: null
	},
	financialStrength: {
		longTermDebtEquityMRQ: null,
		totalDebtEquityMRQ: null,
		interestCoverageMRQ: null,
		quickRatioMRQ: null,
		currentRatioMRQ: null,
		longTermDebtEquityPtile: null,
		totalDebtEquityPtile: null,
		interestCoveragePtile: null,
		quickRatioPtile: null,
		currentRatioPtile: null

	},
	shareRelated: {
		marketCapitalization: null,
		sharesOutstanding: null,
		float: null,
		marketCapitalizationPtile: null,
		shareOutstandingPtile: null,
		floatPtile: null,
		currency: null
	},
	managementEffectiveness: {
		returnOnAssets: null,
		returnOnInvestments: null,
		returnOnEquity: null,
		returnOnAssetsPtile: null,
		returnOnInvestmentsPtile: null,
		returnOnEquityPtile: null
	},
	additionalPerShare: {
		bookValueMRQ: null,
		cashFlowMRQ: null,
		cashMRQ: null,
		currency: null
	},
	dividend: {
		payoutRatio: null,
		fiveYearYieldAvg: null,
		fiveYearYieldGrowthRate: null,
		payoutRatioPtile: null,
		fiveYearYieldAvgPtile: null,
		fiveYieldGrowthRatePtile: null
	}
};

const requestCurrentRatios = state => {
	return Object.assign({}, state, {
		...INITIAL_STATE,
		fetchingCurrentRatios: true
	});
};

const requestEquityClassification = state => {
	return Object.assign({}, state, {
		fetchingEquityClassification: true,
		equityClassification: {
			sectorName: null,
			industryName: null
		}
	});
};

const receiveCurrentRatios = (state, action) => {
	const { data } = action.response;
	const { ratios } = data;
	const {
		perShare, growthRates, margins, valuation,
		financialStrength, priceVolume, managementEffectiveness
	} = ratios || {};
	const {
		epsExcludingExtroaordinaryItemsTtm,
		revenuePerShareTtm,
		bookValuePerShareTangibleTtm,
		cashPerShareMrq,
		epsBasicExcludingExtraordinaryItemsMRQ,
		revenuePerShareMRQ,
		cashFlowPerShareAnnMRQ,
		currency
	} = perShare || {};
	const {
		epsPercentChangeMrq1YearAgo,
		epsPercentChangeTtmOverTtm,
		revenuePercentChangeTtmOverTtm,
		netProfitMargin5YearGrowth,
		revenuePercentChangePriorQMRQ,
		dividendGrowth5Year
	} = growthRates || {};
	const {
		grossMarginTtm,
		marginBeforeTaxTtm,
		marginBeforeTax5YearAverage,
		operatingMarginTtm,
		operatingMargin5YearAverage,
		netProfitPercentTtm,
		netMargin5YearAverage,
		taxRateTtm,
		taxRate5YearAverage
	} = margins || {};

	const {
		peExcludingExtraordinaryItemsTtm,
		priceToCashFlowTtm,
		priceToFreeCashFlowPerShareTtm,
		priceToTangibleBookTtm,
		priceToSalesTtm,
		dividendYield5YearAverage
	} = valuation || {};

	const {
		longTermDebtToEquityMrfy,
		totalDebtToTotalEquityMrq,
		quickRatioMrfy,
		currentRatioMrfy,
		payoutRatioTtm,
		netInterestCoverageMRQ
	} = financialStrength || {};

	const {
		marketCap,
		sharesOutstandingMillions,
		sharesTotalFloatMillions
	} = priceVolume || {};

	const {
		returnOnAverageAssetsTtm,
		returnOnInvestmentTtm,
		returnOnAverageEquityTtm
	} = managementEffectiveness || {};

	return Object.assign({}, state, {
		fetchingCurrentRatios: false,
		data: Object.assign({}, action.response.data),
		eps: Object.assign({}, {
			earningsPerShareMRQ: epsBasicExcludingExtraordinaryItemsMRQ,
			earningsPerShareTTM: epsExcludingExtroaordinaryItemsTtm,
			earningsPerShareMRQ1Yr: epsPercentChangeMrq1YearAgo,
			earningsPerShareTTM1Yr: epsPercentChangeTtmOverTtm,
			currency
		}),
		revenue: Object.assign({}, {
			revenuePerShareMRQ,
			revenuePerShareTTM: revenuePerShareTtm,
			revenueGrowthMRQ: revenuePercentChangePriorQMRQ,
			revenueGrowthTTM: revenuePercentChangeTtmOverTtm,
			currency
		}),
		profitability: Object.assign({}, state.profitability, {
			grossMarginTTM: grossMarginTtm,
			preTaxMarginTTM: marginBeforeTaxTtm,
			fiveYrPreTaxMarginTTM: marginBeforeTax5YearAverage,
			operatingMarginTTM: operatingMarginTtm,
			fiveYrOperatingMarginTTM: operatingMargin5YearAverage,
			profitMarginTTM: netProfitPercentTtm,
			fiveYearAvgNetProfitMarginTTM: netMargin5YearAverage,
			fiveYearAvgNetProfitMarginGrowthTTM: netProfitMargin5YearGrowth,
			effectiveTaxRateTTM: taxRateTtm,
			fiveYearAvgEffectiveTaxRateTTM: taxRate5YearAverage
		}),
		valuation: Object.assign({}, state.valuation, {
			priceEarningsTTM: peExcludingExtraordinaryItemsTtm,
			priceCashFlowTTM: priceToCashFlowTtm,
			priceFreeCashFlowTTM: priceToFreeCashFlowPerShareTtm,
			priceTangibleBookMRQ: priceToTangibleBookTtm,
			priceSalesTTM: priceToSalesTtm
		}),
		financialStrength: Object.assign({}, state.financialStrength, {
			longTermDebtEquityMRQ: longTermDebtToEquityMrfy,
			totalDebtEquityMRQ: totalDebtToTotalEquityMrq,
			interestCoverageMRQ: netInterestCoverageMRQ,
			quickRatioMRQ: quickRatioMrfy,
			currentRatioMRQ: currentRatioMrfy
		}),
		shareRelated: Object.assign({}, state.shareRelated, {
			marketCapitalization: marketCap,
			sharesOutstanding: sharesOutstandingMillions,
			float: sharesTotalFloatMillions,
			currency
		}),
		managementEffectiveness: Object.assign({}, state.managementEffectiveness, {
			returnOnAssets: returnOnAverageAssetsTtm,
			returnOnInvestments: returnOnInvestmentTtm,
			returnOnEquity: returnOnAverageEquityTtm
		}),
		additionalPerShare: Object.assign({}, {
			bookValueMRQ: bookValuePerShareTangibleTtm,
			cashFlowMRQ: cashFlowPerShareAnnMRQ,
			cashMRQ: cashPerShareMrq,
			currency
		}),
		dividend: Object.assign({}, state.dividend, {
			payoutRatio: payoutRatioTtm,
			fiveYearYieldAvg: dividendYield5YearAverage,
			fiveYearYieldGrowthRate: dividendGrowth5Year
		})
	});
};

const clearCurrentRatios = state => {
	return Object.assign({}, state, {
		...INITIAL_STATE,
		fetchingCurrentRatios: false
	});
};

const receiveCurrentRatiosPercentiles = (state, action) => {
	const { data } = action.response;
	const { listingPercentiles } = data;

	const {
		margins,
		priceVolume,
		growthRates,
		financialStrength,
		valuation,
		managementEffectiveness
	} = listingPercentiles;

	const {
		taxRateTtmPtile,
		taxRate5YPtile,
		pretaxMarginTtmPtile,
		grossMarginPtile,
		operatingMarginPtile,
		operMargin5YPtile,
		profitMarginPtile,
		profitMargin5YPtile
	} = margins;

	const {
		sharesTotalFloatPtile,
		sharesOutPtile,
		marketCapPtile
	} = priceVolume;

	const {
		netProfitMarginGrowth5YPtile,
		dividendGrowth5YrPtile
	} = growthRates;

	const {
		quickRatioMrqPtile,
		currentRatioMrqPtile,
		payOutRatioTtmPtile,
		cebtLt2EquityMrqPtile,
		cebtTotal2EquityTotalMrqPtile
	} = financialStrength;

	const {
		divYield5YrAvgPtile,
		pEexclXItemsTtmPtile,
		price2BookvTangMrqPtile,
		price2CashFlowTtmPtile,
		price2CashFlowFreeTtmPtile,
		price2SalesTtmPtile
	} = valuation;

	const {
		roAssetsTtmPtile,
		roEquityTtmPtile,
		roInvestmentTtmPtile
	} = managementEffectiveness;

	const {
		profitability, shareRelated, dividend
	} = state;

	return Object.assign({}, state, {
		profitability: Object.assign({}, profitability, {
			grossMarginPtile,
			preTaxMarginPtile: pretaxMarginTtmPtile,
			operatingMarginPtile,
			operatingMargin5YPtile: operMargin5YPtile,
			profitMarginPtile,
			profitMargin5YPtile,
			netProfitMarginGrowth5YPtile,
			effectiveTaxRatePtile: taxRateTtmPtile,
			effectiveTaxRate5YPtile: taxRate5YPtile

		}),
		managementEffectiveness: Object.assign({}, state.managementEffectiveness, {
			returnOnAssetsPtile: roAssetsTtmPtile,
			returnOnInvestmentsPtile: roInvestmentTtmPtile,
			returnOnEquityPtile: roEquityTtmPtile
		}),
		valuation: Object.assign({}, state.valuation, {
			priceEarningsPtile: pEexclXItemsTtmPtile,
			priceTangibleBookPtile: price2BookvTangMrqPtile,
			priceCashFlowPtile: price2CashFlowTtmPtile,
			priceFreeCashFlowPtile: price2CashFlowFreeTtmPtile,
			priceSalesPtile: price2SalesTtmPtile

		}),
		financialStrength: Object.assign({}, state.financialStrength, {
			longTermDebtEquityPtile: cebtLt2EquityMrqPtile,
			totalDebtEquityPtile: cebtTotal2EquityTotalMrqPtile,
			interestCoveragePtile: null,
			quickRatioPtile: quickRatioMrqPtile,
			currentRatioPtile: currentRatioMrqPtile
		}),
		shareRelated: Object.assign({}, shareRelated, {
			marketCapitalizationPtile: marketCapPtile,
			shareOutstandingPtile: sharesOutPtile,
			floatPtile: sharesTotalFloatPtile
		}),
		dividend: Object.assign({}, dividend, {
			payoutRatioPtile: payOutRatioTtmPtile,
			fiveYearYieldAvgPtile: divYield5YrAvgPtile,
			fiveYieldGrowthRatePtile: dividendGrowth5YrPtile
		})
	});
};

const receiveEquityClassification = (state, action) => {
	const { equityClassification } = state;
	const { data } = action.response;
	const { equities } = data || null;
	return Object.assign({}, state, {
		equityClassification: Object.assign({}, equityClassification, {
			sectorName: equities.sectorName,
			industryName: equities.industryName
		})
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_CURRENTRATIOS]: requestCurrentRatios,
	[Types.API_RECEIVE_CURRENTRATIOS]: receiveCurrentRatios,
	[Types.API_CLEAR_CURRENTRATIOS]: clearCurrentRatios,
	[Types.API_HANDLE_CURRENTRATIOS_LOADER]: handleLoader,
	[Types.API_RECEIVE_CURRENTRATIOS_PERCENTILE]: receiveCurrentRatiosPercentiles,
	[Types.API_RECEIVE_EQUITY_CLASSIFICATION]: receiveEquityClassification,
	[Types.API_REQUEST_EQUITY_CLASSIFICATION]: requestEquityClassification
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
