/* eslint-disable arrow-body-style */
/* eslint-disable no-use-before-define */
const marketsCentreOverview = {
	key: 'marketsCentreOverview',
	group: 'marketsCentre',
	route: '/markets',
	name: 'Overview',
	isExact: true,
	componentPath: 'MarketsCentre/MarketsOverview',
	heirarchy: ['quotes-research', 'market-centre'],
	navItems: () => navOrder.marketsCentre
};

const marketsAtAGlance = {
	key: 'marketsAtAGlance',
	route: '/markets/glance',
	name: 'Markets at a glance',
	componentPath: 'MarketsCentre/MarketsGlance',
	heirarchy: ['quotes-research', 'market-centre'],
	navItems: () => navOrder.marketsCentre
};

const marketMovers = {
	key: 'marketMovers',
	route: '/markets/movers',
	name: 'Market movers',
	componentPath: 'MarketsCentre/MarketMovers',
	heirarchy: ['quotes-research', 'market-centre'],
	navItems: () => navOrder.marketsCentre
};

const marketsNews = {
	key: 'marketsNews',
	route: '/markets/news',
	name: 'News',
	componentPath: 'MarketsCentre/MarketsNews',
	heirarchy: ['quotes-research', 'market-centre'],
	navItems: () => navOrder.marketsCentre
};

const marketsTechnicalAnalysisNews = {
	key: 'marketsTechnicalAnalysisNews',
	route: '/markets/technicalanalysis/:page',
	name: 'Technical analysis',
	checkTechAnalysis: true,
	redirectPath: '/markets',
	hideFromNav: true,
	componentPath: 'MarketsCentre/MarketTechnicalAnalysis',
	heirarchy: ['quotes-research', 'market-centre'],
	navItems: () => navOrder.marketsCentre
};

const stockCentreCustomizeScreeners = {
	key: 'stockCentreCustomizeScreeners',
	route: '/stocks/customizescreener',
	name: 'customizeScreener',
	componentPath: 'StockCentre/CustomizeScreener',
	isExact: true,
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stockCentre
};

const stockCentreOverview = {
	key: 'stockCentreOverview',
	route: '/stocks',
	isExact: true,
	name: 'Overview',
	componentPath: 'StockCentre/StockCentreOverview',
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stockCentre
};

const stocksUpgradesAndDowngrades = {
	key: 'stocksUpgradesAndDowngrades',
	route: '/stocks/upgradesdowngrades',
	name: 'upgradesAndDowngrades',
	componentPath: 'StockCentre/StocksUpgradesDowngrades',
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stockCentre
};

const stockScreener = {
	key: 'stockScreener',
	route: '/stocks/screener',
	name: 'stockScreener',
	componentPath: 'StockCentre/Screener',
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stockCentre
};

// const stockCentreScreenerTest = {
// key: 'stockCentreScreenerTest',
// group: 'stockCentre',
// route: '/stocks/screenertest',
// name: 'ScreenerTest',
// isExact: true,
// componentPath: 'StockCentre/StockCentreScreenerTest',
// navItems: () => areas.stockCentre
// };

const stocksSnapshot = {
	key: 'stocksSnapshot',
	route: '/stocks/:country/:symbol/snapshot',
	name: 'snapshot',
	subComponentPath: 'Stocks/Snapshot',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksNews = {
	key: 'stocksNews',
	route: '/stocks/:country/:symbol/news',
	name: 'news',
	subComponentPath: 'Stocks/News',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksCharts = {
	key: 'stocksChart',
	route: '/stocks/:country/:symbol/charts',
	name: 'charts',
	subComponentPath: 'Stocks/Charts',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksReports = {
	key: 'stocksReports',
	route: '/stocks/:country/:symbol/reports',
	name: 'reports',
	subComponentPath: 'Stocks/Reports',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksFundamentals = {
	key: 'stocksFundamentals',
	route: '/stocks/:country/:symbol/fundamentals',
	name: 'fundamentals',
	subComponentPath: 'Stocks/Fundamentals',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksEarnings = {
	key: 'stocksEarnings',
	route: '/stocks/:country/:symbol/earnings',
	name: 'earnings',
	subComponentPath: 'Stocks/Earnings',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksFinancials = {
	key: 'stocksFinancials',
	route: '/stocks/:country/:symbol/financials',
	name: 'financials',
	subComponentPath: 'Stocks/Financials',
	componentPath: 'Stocks/Stocks',
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stocks,
	isExact: true
};

const stocksOptions = {
	key: 'stocksOptions',
	route: '/stocks/:country/:symbol/options',
	name: 'options',
	checkOptions: true,
	subComponentPath: 'Stocks/Options',
	componentPath: 'Stocks/Stocks',
	navItems: () => navOrder.stocks,
	heirarchy: ['quotes-research', 'stock-centre'],
	isExact: true
};

const stocksTechnicalAnalysis = {
	key: 'stocksTechnicalAnalysis',
	route: '/stocks/:country/:symbol/technicalanalysis',
	name: 'Technical analysis',
	subComponentPath: 'Stocks/TechnicalAnalysis',
	checkTechAnalysis: true,
	redirectPath: '/stocks',
	componentPath: 'Stocks/Stocks',
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stocks
};

const stocksAnalysts = {
	key: 'stocksAnalysts',
	route: '/stocks/:country/:symbol/analysts',
	name: 'analysts',
	subComponentPath: 'Stocks/Analysts',
	checkAnalysts: true,
	redirectPath: '/stocks',
	componentPath: 'Stocks/Stocks',
	heirarchy: ['quotes-research', 'stock-centre'],
	navItems: () => navOrder.stocks
};

const quotelistsWatchlists = {
	key: 'quotelistsWatchlists',
	group: 'quotelists',
	route: '/quotelists/watchlist',
	matchPaths: ['/quotelists/watchlist/edit/:portfolioId/',
		'/quotelists/watchlist/create/',
		'/quotelists/watchlist/create/:xids',
		'/quotelists/watchlist/edit/:portfolioId/:xids',
		'/quotelists/watchlist/:tab/:portfolioId?'],
	name: 'Watch lists',
	isExact: true,
	heirarchy: ['quotes-research', 'my-watch-lists', 'watch-lists'],
	componentPath: 'Quotelists/Watchlist',
	navItems: () => areas.quotelists
};

const quotelistsWatchlistTabs = {
	key: 'quotelistsWatchlistQuoteDetail',
	group: 'quotelists',
	hideFromNav: true,
	route: '/quotelists/watchlist/:tab/:portfolioId?',
	name: 'Watch Lists',
	strict: true,
	isExact: true,
	componentPath: 'Quotelists/Watchlist',
	heirarchy: ['quotes-research', 'my-watch-lists', 'watch-lists'],
	tabNameMapping: {
		quotedetail: 'quote-detail', portfolio: 'portfolio', performance: 'performance', fundamentals: 'fundamentals', estimates: 'estimates'
	},
	addParams: true,
	navItems: () => areas.quotelists
};

const quotelistsWatchlistsEdit = {
	key: 'quotelistsWatchlistsEdit',
	group: 'quotelists',
	hideFromNav: true,
	route: '/quotelists/watchlist/edit/:portfolioId/',
	name: 'Watch Lists',
	strict: true,
	isExact: true,
	componentPath: 'Quotelists/CreateEditWatchlist',
	heirarchy: ['quotes-research', 'my-watch-lists', 'watch-lists'],
	navItems: () => areas.quotelists
};

const quotelistsWatchlistsAdd = {
	key: 'quotelistsWatchlistsAdd',
	group: 'quotelists',
	hideFromNav: true,
	route: '/quotelists/watchlist/edit/:portfolioId/:xids',
	name: 'Watch Lists',
	isExact: true,
	componentPath: 'Quotelists/CreateEditWatchlist',
	heirarchy: ['quotes-research', 'my-watchl-ists', 'watch-lists'],
	navItems: () => areas.quotelists
};

const quotelistsWatchlistsCreate = {
	key: 'quotelistsWatchlistsCreate',
	group: 'quotelists',
	hideFromNav: true,
	strict: true,
	isExact: true,
	route: '/quotelists/watchlist/create/',
	name: 'Watch lists',
	componentPath: 'Quotelists/CreateEditWatchlist',
	heirarchy: ['quotes-research', 'my-watch-lists', 'watch-list'],
	navItems: () => areas.quotelists
};

const quotelistsWatchlistsCreateWithXids = {
	key: 'quotelistsWatchlistsCreate',
	group: 'quotelists',
	hideFromNav: true,
	strict: true,
	isExact: false,
	route: '/quotelists/watchlist/create/:xids',
	name: 'Watch lists',
	componentPath: 'Quotelists/CreateEditWatchlist',
	heirarchy: ['quotes-research', 'my-watch-lists', 'watch-list'],
	navItems: () => areas.quotelists
};

const quotelistsQuotelists = {
	key: 'quotelistsQuotelists',
	group: 'quotelists',
	route: '/quotelists/quotelist',
	matchPaths: ['/quotelists/quotelist',
		'/quotelists/quotelist/:tab'],
	name: 'Quote lists',
	isExact: true,
	componentPath: 'Quotelists/Quotelist',
	heirarchy: ['quotes-research', 'my-watch-lists', 'quote-lists'],
	navItems: () => areas.quotelists
};

const quotelistsQuotelistsTabs = {
	key: 'quotelistsQuotelistsTabs',
	group: 'quotelists',
	route: '/quotelists/quotelist/:tab',
	hideFromNav: true,
	name: 'Quote lists',
	isExact: true,
	componentPath: 'Quotelists/Quotelist',
	addParams: true,
	heirarchy: ['quotes-research', 'my-watch-lists', 'quote-lists'],
	tabNameMapping: { stocks: 'stocks-etfs-indices', mutualfunds: 'mutual-funds', options: 'options' },
	navItems: () => areas.quotelists
};

const notImplemented = {
	key: 'notImplemented',
	group: 'notImplemented',
	route: '/notimplemented/:from',
	name: 'Not Implemented',
	isExact: true,
	componentPath: 'Shared/PageNotFound/PageNotFound',
	navItems: () => areas.pageNotFound
};

const etfCentreOverview = {
	key: 'etfCentreOverview',
	group: 'etfCentre',
	route: '/etfcentre/overview',
	matchPaths: ['/etfcentre/overview/:tab'],
	name: 'Overview',
	strict: true,
	isExact: true,
	heirarchy: ['quotes-research', 'etf-centre'],
	componentPath: 'EtfCentre/EtfCentre',
	navItems: () => navOrder.etfCentre
};

const etfCentreOverviewTabs = {
	key: 'etfCentreScreenerTabs',
	group: 'etfCentre',
	hideFromNav: true,
	route: '/etfcentre/overview/:tab',
	name: 'Overview',
	strict: true,
	isExact: true,
	componentPath: 'EtfCentre/EtfCentre',
	heirarchy: ['quotes-research', 'etf-centre', 'overview'],
	navItems: () => navOrder.etfCentre
};

const etfCentreScreener = {
	key: 'etfCentreScreener',
	group: 'etfCentre',
	route: '/etfcentre/screener',
	matchPaths: ['/etfcentre/screener/:tab'],
	name: 'Screener',
	strict: true,
	isExact: true,
	// CIBC-5171 componentPath: 'EtfCentre/EtfCentreScreener'
	componentPath: 'EtfCentre/EtfCentreScreener',
	heirarchy: ['quotes-research', 'etf-centre', 'screener'],
	navItems: () => navOrder.etfCentre
};

const etfCentreScreenerTabs = {
	key: 'etfCentreScreenerTabs',
	group: 'etfCentre',
	hideFromNav: true,
	route: '/etfcentre/screener/:tab',
	name: 'Screener',
	isExact: true,
	// CIBC-5171 componentPath: 'EtfCentre/EtfCentreScreener'
	heirarchy: ['quotes-research', 'etf-centre', 'screener'],
	tabNameMapping: {
		savedscreens: 'saved-screens', quickscreener: 'quick-screener', featuredscreens: 'featured-screens', customscreener: 'custom-screener'
	},
	addParams: true,
	componentPath: 'EtfCentre/EtfCentreScreener',
	navItems: () => navOrder.etfCentre
};

const etfCentreCompare = {
	key: 'etfCentreCompare',
	group: 'etfCentre',
	route: '/etfcentre/compare',
	name: 'Compare',
	isExact: true,
	componentPath: 'EtfCentre/Compare',
	heirarchy: ['quotes-research', 'etf-centre'],
	navItems: () => navOrder.etfCentre
};

const etfCentreCommentary = {
	key: 'etfCentreCommentary',
	group: 'etfCentre',
	route: '/etfcentre/commentary',
	name: 'Commentary',
	isExact: true,
	componentPath: 'EtfCentre/Commentary',
	heirarchy: ['quotes-research', 'etf-centre'],
	navItems: () => navOrder.etfCentre
};

const etfCentreHolding = {
	key: 'etfCentreHolding',
	group: 'etfCentre',
	route: '/etfcentre/:country/:symbol/',
	matchPaths: [
		'/etfcentre/:country/:symbol',
		'/etfcentre/:country/:symbol/snapshot',
		'/etfcentre/:country/:symbol/performance',
		'/etfcentre/:country/:symbol/portfolio',
		'/etfcentre/:country/:symbol/charts',
		'/etfcentre/:country/:symbol/reports',
		'/etfcentre/:country/:symbol/options',
		'/etfcentre/:country/:symbol/technicalanalysis'
	],
	name: 'EtfHolding',
	navComponentPath: 'Shared/Navigation/SymbolTab',
	strict: true,
	isExact: true,
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	navItems: () => areas.etfCentre
};

const etfCentreSnapshot = {
	key: 'etfCentreSnapshot',
	route: '/etfcentre/:country/:symbol/snapshot',
	name: 'snapshot',
	hideFromNav: true,
	subComponentPath: 'EtfCentre/Snapshot',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const etfCentrePerformance = {
	key: 'etfCentrePerformance',
	route: '/etfcentre/:country/:symbol/performance',
	name: 'performance',
	hideFromNav: true,
	subComponentPath: 'EtfCentre/Performance',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const etfCentrePortfolio = {
	key: 'etfCentrePortfolio',
	route: '/etfcentre/:country/:symbol/portfolio',
	name: 'portfolio',
	hideFromNav: true,
	subComponentPath: 'EtfCentre/Portfolio',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const etfCentreCharts = {
	key: 'etfCentreCharts',
	route: '/etfcentre/:country/:symbol/charts',
	name: 'charts',
	hideFromNav: true,
	subComponentPath: 'EtfCentre/Charts',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const etfCentreReports = {
	key: 'etfCentreReports',
	route: '/etfcentre/:country/:symbol/reports',
	name: 'reports',
	hideFromNav: true,
	subComponentPath: 'EtfCentre/Reports',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const etfCentreOptions = {
	key: 'etfCentreOptions',
	route: '/etfcentre/:country/:symbol/options',
	name: 'options',
	checkOptions: true,
	hideFromNav: true,
	subComponentPath: 'EtfCentre/Options',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const etfCentreTechnicalAnalysis = {
	key: 'etfCentreTechnicalAnalysis',
	route: '/etfcentre/:country/:symbol/technicalanalysis',
	name: 'technicalAnalysis',
	checkTechAnalysis: true,
	redirectPath: '/etfcentre/overview',
	hideFromNav: true,
	subComponentPath: 'EtfCentre/TechnicalAnalysis',
	componentPath: 'EtfCentre/EtfHolding',
	heirarchy: ['quotes-research', 'etf-centre'],
	isExact: true
};

const pdfDownload = {
	key: 'pdf-download',
	route: '/getpdf/:id/:docId',
	name: 'PDF download',
	componentPath: 'Shared/PdfDownload/PdfDownload',
	isExact: true,
	strict: true
};

const fundCentreSnapShot = {
	key: 'fundCentreSnapShot',
	route: '/fundcentre/:country/:symbol/snapshot',
	name: 'snapshot',
	hideFromNav: true,
	checkLipperLeaders: false,
	checkIndividualSecurityView: true,
	redirectPath: '/fundcentre/overview',
	subComponentPath: 'FundCentre/Snapshot',
	componentPath: 'FundCentre/FundCentreHolding',
	heirarchy: ['quotes-research', 'fund-centre'],
	isExact: true
};

const fundCentreCharts = {
	key: 'fundCentreCharts',
	route: '/fundcentre/:country/:symbol/charts',
	name: 'charts',
	hideFromNav: true,
	checkLipperLeaders: false,
	checkIndividualSecurityView: true,
	redirectPath: '/fundcentre/overview',
	subComponentPath: 'FundCentre/Charts',
	componentPath: 'FundCentre/FundCentreHolding',
	heirarchy: ['quotes-research', 'fund-centre'],
	isExact: true
};

const fundCentreHoldingPerformance = {
	key: 'fundCentreHoldingPerformance',
	route: '/fundcentre/:country/:symbol/performance',
	name: 'performance',
	hideFromNav: true,
	checkLipperLeaders: false,
	checkIndividualSecurityView: true,
	redirectPath: '/fundcentre/overview',
	subComponentPath: 'FundCentre/HoldingPerformance',
	componentPath: 'FundCentre/FundCentreHolding',
	heirarchy: ['quotes-research', 'fund-centre'],
	isExact: true
};

const fundCentrePortfolio = {
	key: 'fundCentrePortfolio',
	route: '/fundcentre/:country/:symbol/portfolio',
	name: 'portfolio',
	hideFromNav: true,
	checkLipperLeaders: false,
	checkIndividualSecurityView: true,
	redirectPath: '/fundcentre/overview',
	subComponentPath: 'FundCentre/Portfolio',
	componentPath: 'FundCentre/FundCentreHolding',
	heirarchy: ['quotes-research', 'fund-centre'],
	isExact: true
};

const fundCentreLipperLeaders = {
	key: 'fundCentreLipperLeaders',
	route: '/fundcentre/:country/:symbol/lipperleaders',
	name: 'lipperLeaders',
	hideFromNav: true,
	checkLipperLeaders: true,
	checkIndividualSecurityView: true,
	redirectPath: '/fundcentre/overview',
	subComponentPath: 'FundCentre/LipperLeaders',
	componentPath: 'FundCentre/FundCentreHolding',
	heirarchy: ['quotes-research', 'fund-centre'],
	isExact: true
};

const fundCentreOptions = {
	key: 'fundCentreOptions',
	route: '/fundcentre/:country/:symbol/options',
	name: 'options',
	checkOptions: true,
	hideFromNav: true,
	subComponentPath: 'FundCentre/Options',
	componentPath: 'FundCentre/FundCentreHolding',
	heirarchy: ['quotes-research', 'fund-centre'],
	isExact: true
};

const fundCentreOverview = {
	key: 'fundCentreOverview',
	route: '/fundcentre/overview',
	name: 'overview',
	componentPath: 'FundCentre/FundCentreOverview',
	isExact: true,
	navItems: () => areas.fundCentre,
	checkFundCentre: true,
	heirarchy: ['quotes-research', 'fund-centre'],
	redirectPath: '/markets'
};

const fundCentrePerformance = {
	key: 'fundCentreOverview',
	route: '/fundcentre/performance',
	name: 'Performance',
	componentPath: 'FundCentre/FundCentrePerformance',
	matchPaths: ['/fundcentre/performance/:tab'],
	isExact: true,
	navItems: () => areas.fundCentre,
	checkFundCentre: true,
	heirarchy: ['quotes-research', 'fund-centre', 'performance'],
	redirectPath: '/markets'
};

const fundCentrePerformanceTabs = {
	key: 'fundCentrePerformanceTabs',
	group: 'fundCentre',
	hideFromNav: true,
	route: '/fundcentre/performance/:tab',
	name: 'Performance',
	strict: true,
	isExact: true,
	componentPath: 'FundCentre/FundCentrePerformance',
	navItems: () => areas.fundCentre,
	checkFundCentre: true,
	addParams: true,
	heirarchy: ['quotes-research', 'fund-centre', 'performance'],
	tabNameMapping: { mutualfunds: 'mutual-funds', totalnetassets: 'total-net-assets', lipperleaders: 'lipper-leaders' },
	redirectPath: '/markets'
};

const fundCentreScreeners = {
	key: 'fundCentreScreener',
	route: '/fundcentre/screener',
	name: 'Screener',
	componentPath: 'FundCentre/FundCentreScreeners',
	isExact: true,
	navItems: () => areas.fundCentre,
	checkFundCentre: true,
	heirarchy: ['quotes-research', 'fund-centre'],
	redirectPath: '/markets'
};

const fundCentreCustomizeScreeners = {
	key: 'fundCentreCustomizeScreeners',
	route: '/fundcentre/customizescreener',
	name: 'customizeScreener',
	componentPath: 'FundCentre/CustomizeScreener',
	isExact: true,
	navItems: () => areas.fundCentre,
	checkFundCentre: true,
	heirarchy: ['quotes-research', 'fund-centre'],
	redirectPath: '/markets'
};

const fundCentreEtfs = {
	key: 'fundCentreEtfs',
	route: '/fundcentre/etfs',
	name: 'etfs',
	wgeOnly: true, // only show this tab if the user is Wood Gundy
	componentPath: 'FundCentre/FundCentreEtfs',
	redirectPath: '/fundcentre/overview',
	checkFundCentreETFs: true,
	isExact: true,
	heirarchy: ['quotes-research', 'fund-centre'],
	navItems: () => areas.fundCentre
};

const alertsOverview = {
	key: 'alertsOverview',
	route: '/alerts/overview',
	name: 'overview',
	componentPath: 'Alerts/Overview',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsMarkets = {
	key: 'alertsMarkets',
	route: '/alerts/markets',
	name: 'markets',
	matchPaths: [
		'/alerts/markets',
		'/alerts/markets/:tab'
	],
	componentPath: 'Alerts/Markets',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsMarketsEdit = {
	key: 'alertsMarketsEdit',
	route: '/alerts/markets/:tab',
	name: 'markets',
	hideFromNav: true,
	componentPath: 'Alerts/Markets',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	addParams: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsStocks = {
	key: 'alertsStocks',
	route: '/alerts/stocks',
	matchPaths: [
		'/alerts/stocks',
		'/alerts/stocks/:country/:symbol'
	],
	name: 'stocks',
	componentPath: 'Alerts/Stocks',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsIndividualStock = {
	key: 'alertsIndividualStock',
	route: '/alerts/stocks/:country/:symbol',
	name: 'stocks',
	hideFromNav: true,
	componentPath: 'Alerts/Stocks',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsWatchlist = {
	key: 'alertsIndividualStock',
	route: '/alerts/watchlist',
	name: 'Watch list',
	componentPath: 'Alerts/Watchlist',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsTechnicalAnalysis = {
	key: 'alertsTechnicalAnalysis',
	route: '/alerts/technicalanalysis',
	name: 'Technical analysis',
	componentPath: 'Alerts/TechnicalAnalysisAlerts',
	redirectPath: '/alerts/overview',
	checkAlerts: true,
	checkTechAnalysis: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsAnalystReports = {
	key: 'alertsAnalystReports',
	route: '/alerts/analystreports',
	name: 'analystReports',
	// CIBC-5171 componentPath: 'Alerts/AnalystReports'
	componentPath: 'Alerts/AnalystReports',
	// componentPath: 'Shared/ComingSoon/ComingSoon',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const alertsPreferences = {
	key: 'alertsPreferences',
	route: '/alerts/preferences',
	name: 'preferences',
	componentPath: 'Alerts/Preferences',
	redirectPath: '/markets',
	checkAlerts: true,
	isExact: true,
	heirarchy: ['quotes-research', 'my-alerts'],
	navItems: () => areas.alerts
};

const analystReportsOverview = {
	key: 'analystReportsOverview',
	route: '/analystreports/overview',
	name: 'overview',
	componentPath: 'AnalystReports/Overview',
	isExact: true,
	heirarchy: ['quotes-research', 'analyst-reports'],
	navItems: () => areas.analystReports
};

const analystReportsEquities = {
	key: 'analystReportsEquities',
	route: '/analystreports/equities',
	name: 'equities',
	componentPath: 'AnalystReports/Equities',
	isExact: true,
	heirarchy: ['quotes-research', 'analyst-reports'],
	navItems: () => areas.analystReports
};

const analystReportsEconomics = {
	key: 'analystReportsEconomics',
	route: '/analystreports/economics',
	name: 'economics',
	componentPath: 'AnalystReports/Economics',
	isExact: true,
	heirarchy: ['quotes-research', 'analyst-reports'],
	navItems: () => areas.analystReports
};

const analystReportsCustomSearch = {
	key: 'analystReportsCustomSearch',
	route: '/analystreports/customsearch',
	matchPaths: ['/analystreports/customsearch/:itemId',
		'/analystreports/customsearch'],
	name: 'customSearch',
	componentPath: 'AnalystReports/CustomSearch',
	isExact: true,
	heirarchy: ['quotes-research', 'analyst-reports'],
	navItems: () => areas.analystReports
};

const analystReportsCustomSearchEdit = {
	key: 'analystReportsCustomSearchEdit',
	hideFromNav: true,
	route: '/analystreports/customsearch/:itemId',
	name: 'customSearchEdit',
	componentPath: 'AnalystReports/CustomSearch',
	isExact: true,
	heirarchy: ['quotes-research', 'analyst-reports'],
	navItems: () => areas.analystReports
};

const analystReportsSavedSearches = {
	key: 'analystReportsSavedSearches',
	route: '/analystreports/savedsearches',
	matchPaths: ['/analystreports/savedsearches/:itemId',
		'/analystreports/savedsearches'],
	name: 'savedSearches',
	componentPath: 'AnalystReports/SavedSearches',
	navComponentPath: 'AnalystReports/SavedSearchesTab',
	heirarchy: ['quotes-research', 'analyst-reports'],
	isExact: true,
	navItems: () => areas.analystReports
};

const analystReportsSavedSearchesView = {
	key: 'analystReportsSavedSearchesView',
	hideFromNav: true,
	route: '/analystreports/savedsearches/:itemId',
	name: 'savedSearchesView',
	componentPath: 'AnalystReports/SavedSearches',
	navComponentPath: 'AnalystReports/SavedSearchesTab',
	isExact: true,
	heirarchy: ['quotes-research', 'analyst-reports'],
	navItems: () => areas.analystReports
};

const pages = {
	marketsCentreOverview,
	marketsAtAGlance,
	marketMovers,
	marketsNews,
	stocksUpgradesAndDowngrades,
	stockScreener,
	stockCentreCustomizeScreeners,
	stocksSnapshot,
	stocksNews,
	stocksCharts,
	stocksReports,
	stocksFundamentals,
	stocksEarnings,
	stocksFinancials,
	stocksOptions,
	stocksTechnicalAnalysis,
	stocksAnalysts,
	stockCentreOverview,
	// stockCentreScreenerTest,
	quotelistsWatchlistsEdit,
	quotelistsWatchlistsCreate,
	quotelistsWatchlistsCreateWithXids,
	quotelistsWatchlistTabs,
	quotelistsQuotelistsTabs,
	quotelistsWatchlists,
	quotelistsQuotelists,
	etfCentreOverview,
	etfCentreOverviewTabs,
	etfCentreScreenerTabs,
	etfCentreScreener,
	// etfCentreScreenerTest,
	etfCentreCompare,
	etfCentreCommentary,
	etfCentreSnapshot,
	etfCentrePerformance,
	etfCentrePortfolio,
	etfCentreCharts,
	etfCentreReports,
	etfCentreOptions,
	etfCentreTechnicalAnalysis,
	fundCentreSnapShot,
	fundCentreCharts,
	fundCentreHoldingPerformance,
	fundCentrePortfolio,
	fundCentreLipperLeaders,
	fundCentreOptions,
	fundCentrePerformanceTabs,
	fundCentrePerformance,
	fundCentreEtfs,
	fundCentreScreeners,
	fundCentreCustomizeScreeners,
	fundCentreOverview,
	alertsOverview,
	alertsMarkets,
	alertsStocks,
	alertsIndividualStock,
	alertsWatchlist,
	alertsMarketsEdit,
	alertsTechnicalAnalysis,
	alertsAnalystReports,
	alertsPreferences,
	analystReportsOverview,
	analystReportsEquities,
	analystReportsEconomics,
	analystReportsCustomSearch,
	analystReportsSavedSearches,
	notImplemented,
	pdfDownload
};

const navOrder = {
	marketsCentre: [
		marketsCentreOverview,
		marketsAtAGlance,
		marketMovers,
		marketsNews,
		marketsTechnicalAnalysisNews
	],
	stockCentre: [
		stockCentreOverview,
		stocksUpgradesAndDowngrades,
		stockScreener,
		stockCentreCustomizeScreeners
	],
	etfCentre: [
		etfCentreOverviewTabs,
		etfCentreOverview,
		// etfCentreScreenerTest,
		etfCentreScreenerTabs,
		etfCentreScreener,
		etfCentreCompare,
		etfCentreCommentary,
		etfCentreHolding
	],
	stocks: [
		stocksSnapshot,
		stocksNews,
		stocksCharts,
		stocksAnalysts,
		stocksReports,
		stocksFundamentals,
		stocksEarnings,
		stocksFinancials,
		stocksOptions,
		stocksTechnicalAnalysis
	]
};

const areas = {
	marketsCentre: [
		marketsAtAGlance,
		marketMovers,
		marketsNews,
		marketsTechnicalAnalysisNews,
		marketsCentreOverview
	],
	stockCentre: [
		stocksUpgradesAndDowngrades,
		stockScreener,
		// stockCentreScreenerTest,
		stockCentreCustomizeScreeners
	],
	stocks: [
		stocksSnapshot,
		stocksNews,
		stocksCharts,
		stocksAnalysts,
		stocksReports,
		stocksFundamentals,
		stocksEarnings,
		stocksFinancials,
		stocksOptions,
		stocksTechnicalAnalysis,
		stockCentreOverview
	],
	quotelists: [
		quotelistsWatchlistsEdit,
		quotelistsWatchlistsCreate,
		quotelistsWatchlistsCreateWithXids,
		quotelistsWatchlistsAdd,
		quotelistsWatchlistTabs,
		quotelistsQuotelistsTabs,
		quotelistsWatchlists,
		quotelistsQuotelists
	],
	etfCentre: [
		etfCentreOverviewTabs,
		etfCentreOverview,
		// etfCentreScreenerTest,
		etfCentreScreenerTabs,
		etfCentreScreener,
		etfCentreCompare,
		etfCentreCommentary
	],
	etfCentreIndividualHolding: [
		etfCentreSnapshot,
		etfCentrePerformance,
		etfCentrePortfolio,
		etfCentreCharts,
		etfCentreReports,
		etfCentreOptions,
		etfCentreTechnicalAnalysis
	],
	fundCentreIndividualHolding: [
		fundCentreSnapShot,
		fundCentreCharts,
		fundCentreHoldingPerformance,
		fundCentrePortfolio,
		fundCentreLipperLeaders,
		fundCentreOptions
	],
	fundCentre: [
		fundCentreOverview,
		fundCentrePerformanceTabs,
		fundCentrePerformance,
		fundCentreEtfs,
		fundCentreScreeners,
		fundCentreCustomizeScreeners
	],
	alerts: [
		alertsOverview,
		alertsMarkets,
		alertsMarketsEdit,
		alertsStocks,
		alertsIndividualStock,
		alertsWatchlist,
		alertsTechnicalAnalysis,
		alertsAnalystReports,
		alertsPreferences
	],
	analystReports: [
		analystReportsOverview,
		analystReportsEquities,
		analystReportsEconomics,
		analystReportsCustomSearch,
		analystReportsCustomSearchEdit,
		analystReportsSavedSearches,
		analystReportsSavedSearchesView
	],
	pageNotFound: [
		notImplemented
	],
	pdfDownload: [
		pdfDownload
	]
};

const checkAllPages = (pagesToCheck, location) => {
	let name = null;
	const keys = Object.keys(pagesToCheck);

	for (let index = 0; index < keys.length; index += 1) {
		const page = pagesToCheck[keys[index]];
		if (page.route === location) {
			name = page.name;
			break;
		}
	}

	return name;
};

const getDisplayNameByLocation = location => {
	return checkAllPages(pages, location);
};

// Using this pattern here som it can be easily imported by the node js script
module.exports = {
	pages,
	areas,
	getDisplayNameByLocation,
	navOrder
};
