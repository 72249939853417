/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingSymbolSearch: false,
	data: {},
	classificationIds: [
		{
			id: 1,
			name: 'Debt'
		},
		{
			id: 2,
			name: 'Derivative'
		},
		{
			id: 3,
			name: 'Equity'
		},
		{
			id: 5,
			name: 'Fund'
		},
		{
			id: 18,
			name: 'ETF'
		},
		{
			id: 84,
			name: 'Closed End Funds'
		}
	]
};

const requestSymbolSearch = state => {
	return Object.assign({}, state, {
		fetchingSymbolSearch: true,
		data: {}
	});
};

const receiveSymbolSearch = (state, action) => {
	return Object.assign({}, state, {
		fetchingSymbolSearch: false,
		data: action.response.data
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_SINGLE_REQUEST_SYMBOL_SEARCH]: requestSymbolSearch,
	[Types.API_RECEIVE_SYMBOL_SEARCH]: receiveSymbolSearch,
	[Types.API_HANDLE_SYMBOL_SEARCH_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
