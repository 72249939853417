/* global CSS, document */
const cssDetect = {};

cssDetect.supportsFlex = () => {
	return typeof CSS !== 'undefined' && CSS && CSS.supports && CSS.supports('display', 'flex');
};

cssDetect.isIe = () => {
	return document && !!document.documentMode;
};

export default cssDetect;
