/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingResearchHistoryData: false,
	fetchingResearchHistoryLink: false,
	researchHistoryData: {},
	researchHistoryMetaData: {},
	fileSize: null
};

const requestResearchHistory = (state, action) => {
	return Object.assign({}, state, {
		fetchingResearchHistoryData: true,
		researchHistoryData: {},
		customSearchInputs: action.params
	});
};

const receiveFileSize = (state, action) => {
	return Object.assign({}, state, {
		fileSize: action.fileSize
	});
};

const receiveResearchHistory = (state, action) => {
	return Object.assign({}, state, {
		fetchingResearchHistoryData: false,
		researchHistoryData: { ...action.response.data }
	});
};

const requestResearchHistoryWithMetaData = state => {
	return Object.assign({}, state, {
		fetchingResearchHistoryDocumentMetaData: true,
		researchHistoryMetaData: {}
	});
};

const receiveResearchHistoryWithMetaData = (state, action) => {
	const { researchHistoryMetaData } = state;
	const { documentId } = action;

	researchHistoryMetaData[documentId] = action.response.data;

	return Object.assign({}, state, {
		fetchingResearchHistoryDocumentMetaData: false,
		researchHistoryMetaData: { ...researchHistoryMetaData }
	});
};

const setCustomSearchInputs = (state, action) => {
	return Object.assign({}, state, {
		customSearchInputs: action.customSearchInputs
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_RESEARCH_HISTORY]: requestResearchHistory,
	[Types.API_RECEIVE_RESEARCH_HISTORY]: receiveResearchHistory,
	[Types.API_REQUEST_RESEARCH_HISTORY_META_DATA]: requestResearchHistoryWithMetaData,
	[Types.API_RECEIVE_RESEARCH_HISTORY_META_DATA]: receiveResearchHistoryWithMetaData,
	[Types.API_HANDLE_RESEARCH_HISTORY_LOADER]: handleLoader,
	[Types.SET_CUSTOM_SEARCH_INPUTS]: setCustomSearchInputs,
	[Types.API_RECEIVE_RESEARCH_FILE_SIZE]: receiveFileSize
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
