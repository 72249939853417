// eslint-disable-next-line no-undef
const { navigator } = window;
const { msSaveOrOpenBlob } = navigator || {};
const isIe = typeof msSaveOrOpenBlob !== 'undefined';
// eslint-disable-next-line no-confusing-arrow
const cacheBust = () => isIe ? Date.now() : false;

export default (parameters, options) => {
	return cacheBust() && !(options && options.skipCacheBust)
		? { ...parameters, cacheBust: cacheBust() }
		: parameters;
};
