import { createReducer } from 'reduxsauce';
import Types from 'Actions/Types';

export const INITIAL_STATE = {
	ratingsSummary: {
		strongBuyRecomendations: null,
		buyRecomendations: null,
		holdRecomendations: null,
		underperformRecomendations: null,
		sellRecomendations: null
	},
	analystOpinions: {
		currentConsensusRec: null,
		previousConsensusRec: null,
		currentRating: null,
		ratingDate: null,
		totalOpinions: null,
		totalAnalysts: null
	},
	announcements: {},
	fetchingStockEarnings: false,
	fetchingStockEarningsAnnouncements: false
};

const requestStockEarnings = state => {
	return { ...state, fetchingStockEarnings: true };
};

const receiveStockEarnings = (state, action) => {
	const { ratingsSummary, analystOpinions } = action.response.data;
	return {
		...state,
		fetchingStockEarnings: false,
		ratingsSummary,
		analystOpinions
	};
};

const requestStockEarningsAnnouncements = state => {
	return {
		...state,
		announcements: {},
		fetchingStockEarningsAnnouncements: true
	};
};

const receiveStockEarningsAnnouncements = (state, action) => {
	return {
		...state,
		fetchingStockEarnings: false,
		announcements: action.response.data
	};
};

const handleLoader = (state, action) => {
	return { ...state, [action.fetchName]: false };
};

const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_STOCK_EARNINGS]: requestStockEarnings,
	[Types.API_CIBC_RECEIVE_STOCK_EARNINGS]: receiveStockEarnings,
	[Types.API_CIBC_REQUEST_STOCK_EARNINGS_ANNOUNCEMENTS]: requestStockEarningsAnnouncements,
	[Types.API_CIBC_RECEIVE_STOCK_EARNINGS_ANNOUNCEMENTS]: receiveStockEarningsAnnouncements,
	[Types.API_CIBC_HANDLE_STOCK_EARNINGS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
