/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingNewsData: false,
	newsData: {},
	etfNewsData: {},
	fetchingNewsArticle: false,
	fetchingNewsLink: false,
	newsArticleData: {},
	fetchingNewsInsights: false,
	insightsData: {},
	fetchingPdf: false,
	fetchingEtfNewsData: false
};

const requestMarketNews = state => {
	return Object.assign({}, state, {
		fetchingNewsData: true
	});
};

const receiveMarketNews = (state, action) => {
	return Object.assign({}, state, {
		fetchingNewsData: false,
		newsData: Object.assign({}, action.response)
	});
};

const requestEtfNews = state => {
	return Object.assign({}, state, {
		fetchingEtfNewsData: true
	});
};

const receiveEtfNews = (state, action) => {
	return Object.assign({}, state, {
		fetchingEtfNewsData: false,
		etfNewsData: Object.assign({}, action.response)
	});
};

const requestNewsLink = state => {
	return Object.assign({}, state, {
		fetchingNewsLink: true,
		newsArticleData: {}
	});
};

const requestNewsArticle = state => {
	return Object.assign({}, state, {
		fetchingNewsArticle: true,
		newsArticleData: {}
	});
};

const receiveNewsArticle = (state, action) => {
	const data = action.response;
	return Object.assign({}, state, {
		fetchingNewsArticle: false,
		newsArticleData: data
	});
};

const requestInsights = state => {
	return Object.assign({}, state, {
		fetchingNewsInsights: true
	});
};

const receiveInsights = (state, action) => {
	return Object.assign({}, state, {
		fetchingNewsArticle: false,
		insightsData: Object.assign({}, state.insightsData, {
			[action.newsId]: action.response.data
		})
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_MARKET_NEWS]: requestMarketNews,
	[Types.API_RECEIVE_MARKET_NEWS]: receiveMarketNews,
	[Types.API_REQUEST_ETF_NEWS]: requestEtfNews,
	[Types.API_RECEIVE_ETF_NEWS]: receiveEtfNews,
	[Types.API_REQUEST_NEWS_LINK]: requestNewsLink,
	[Types.API_REQUEST_NEWS_ARTICLE]: requestNewsArticle,
	[Types.API_RECEIVE_NEWS_ARTICLE]: receiveNewsArticle,
	[Types.API_REQUEST_NEWS_INSIGHTS]: requestInsights,
	[Types.API_RECEIVE_NEWS_INSIGHTS]: receiveInsights,
	[Types.API_HANDLE_NEWS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
