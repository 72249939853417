import Types from './Types';
import { requestXrefByVenueXids } from './xrefActions';
import { getRealtimeByTier } from './QuotesActions';

const holdingsGroups = {
	MUTUALFUNDS: 'mutualFunds',
	OPTIONS: 'options',
	STOCKS_ETFS_INDICES: 'stocksETFsIndices'
};

const handleLoader = fetchName => {
	return ({
		type: Types.API_HANDLE_QUOTELIST_LOADER,
		fetchName
	});
};

const receiveQuotelistFundBasics = (response, xid) => {
	return ({
		type: Types.API_RECEIVE_QUOTELIST_FUND_BASICS,
		response,
		xid
	});
};

const requestQuotelistFundBasics = (xid, lang) => {
	const result = {
		type: Types.API_REQUEST_QUOTELIST_FUND_BASICS,
		endPoint: `managedfunds-lippercanada-fundbasics/v1/fundBasics/${xid}`,
		params: {
			venueXids: xid,
			lang
		},
		ajaxType: 'GET',
		onSuccess: response => { return receiveQuotelistFundBasics(response, xid); },
		loader: handleLoader,
		fetchName: 'fetchingQuotelistFundBasicsData'
	};

	return result;
};

const receiveQuotelistQuotes = (response, xids) => {
	return ({
		type: Types.API_RECEIVE_QUOTELIST_QUOTE,
		response,
		xids
	});
};

const requestQuotelistQuotes = (xids, tier) => {
	const result = {
		type: Types.API_REQUEST_QUOTELIST_QUOTE,
		endPoint: '/quote/v1/details',
		params: {
			venueXids: xids.join(','),
			marketHoursTradeType: 'normal'
		},
		ajaxType: 'GET',
		headers: {
			realtime: getRealtimeByTier(tier)
		},
		onSuccess: response => { return receiveQuotelistQuotes(response, xids); },
		loader: handleLoader,
		fetchName: 'fetchingQuotelistQuoteData'
	};

	return result;
};

const addToQuotelist = (symbol, lang, tier) => {
	const actions = [
		{
			type: Types.ADD_TO_QUOTELIST,
			symbol
		}
	];
	const holdingType = symbol.classification.name.toLowerCase();
	const xid = symbol.xids.venue;

	if (xid) {
		actions.push(requestXrefByVenueXids([xid]));
		if (holdingType === 'fund') {
			actions.push(requestQuotelistFundBasics(xid, lang));
		} else {
			actions.push(requestQuotelistQuotes([xid], tier));
		}
	}

	return actions;
};

const removeFromQuotelist = (symbolsToKeep, quotelistSection) => {
	return {
		type: Types.REMOVE_FROM_QUOTELIST,
		symbolsToKeep,
		quotelistSection
	};
};

const sortHoldingsGroup = (holdingGroup, field, direction, quoteData) => {
	return ({
		type: Types.SORT_QUOTELIST,
		field,
		direction,
		holdingGroup,
		quoteData
	});
};

const setQuotelistTableSortIndicators = (holdingGroup, field, direction) => {
	return ({
		type: Types.SORT_QUOTELIST_SETINDICATORS,
		field,
		direction,
		holdingGroup
	});
};

export {
	addToQuotelist,
	removeFromQuotelist,
	requestQuotelistFundBasics,
	requestQuotelistQuotes,
	holdingsGroups,
	setQuotelistTableSortIndicators,
	sortHoldingsGroup
};
