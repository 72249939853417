/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingCorporateDividends: false,
	data: {}
};

const requestCorporateDividends = state => {
	return Object.assign({}, state, {
		fetchingCorporateDividends: true,
		data: Object.assign({}, {})
	});
};

const receiveCorporateDividends = (state, action) => {
	return Object.assign({}, state, {
		fetchingCorporateDividends: false,
		data: Object.assign({}, action.response.data)
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_SINGLE_REQUEST_CORPORATE_DIVIDENDS]: requestCorporateDividends,
	[Types.API_RECEIVE_CORPORATE_DIVIDENDS]: receiveCorporateDividends,
	[Types.API_HANDLE_CORPORATE_DIVIDENDS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
