import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingFundBasicsLIpper: false,
	fetchingFundYieldLipper: false,
	fetchingFundLipperLeaders: false,
	fetchingFundLipperAssetAllocations: false,
	fetchingFundPerformanceLIpper: false,
	venueXid: null,
	fundBasics: {},
	fundYield: {},
	fundLipperLeaders: {},
	fundLipperAssetAllocations: {},
	performance: {}
};

const requestFundBasics = state => {
	return {
		...state,
		fundBasics: {},
		venueXid: null,
		fetchingFundBasicsLIpper: true
	};
};

const requestFundYield = state => {
	return {
		...state,
		fundYield: {},
		fetchingFundYieldLipper: true
	};
};

const requestFundLipperLeaders = state => {
	return {
		...state,
		fundLipperLeaders: {},
		fetchingFundLipperLeaders: true
	};
};

const requestFundLipperAssetAllocations = state => {
	return {
		...state,
		fundLipperAssetAllocations: {},
		fetchingFundLipperAssetAllocations: true
	};
};

const receiveFundBasics = (state, action) => {
	let data = {};
	if (action.response.data) {
		data = action.response.data;
	}

	return {
		...state,
		fetchingFundBasicsLIpper: false,
		venueXid: action.xid,
		fundBasics: data
	};
};

const requestFundPerformance = state => {
	return {
		...state,
		performance: {},
		venueXid: null,
		fetchingFundPerformanceLIpper: true
	};
};

const receiveFundPerformance = (state, action) => {
	let data = {};
	if (action.response.data) {
		data = action.response.data;
		if (action.response.data.performance) {
			data = action.response.data.performance;
		}
	}

	return {
		...state,
		fetchingFundPerformanceLIpper: false,
		venueXid: action.xid,
		performance: data
	};
};

const clearPerformanceData = state => {
	return {
		...state,
		performance: {}
	};
};

const receiveFundYield = (state, action) => {
	return {
		...state,
		fetchingFundYieldLipper: false,
		fundYield: action.response.data
	};
};

const receiveFundLipperLeaders = (state, action) => {
	return {
		...state,
		fetchingFundLipperLeaders: false,
		fundLipperLeaders: action.response.data
	};
};

const clearLipperLeaders = state => {
	return {
		...state,
		fundLipperLeaders: {}
	};
};

const receiveFundLipperAssetAllocations = (state, action) => {
	return {
		...state,
		fetchingFundLipperAssetAllocations: false,
		fundLipperAssetAllocations: action.response.data
	};
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return { ...state, [action.fetchName]: false };
};

const ACTION_HANDLERS = {
	[Types.API_REQUEST_FUND_BASIC_LIPPER]: requestFundBasics,
	[Types.API_RECEIVE_FUND_BASIC_LIPPER]: receiveFundBasics,
	[Types.API_REQUEST_FUND_YIELD_LIPPER]: requestFundYield,
	[Types.API_RECEIVE_FUND_YIELD_LIPPER]: receiveFundYield,
	[Types.API_REQUEST_FUND_LIPPER_LEADERS]: requestFundLipperLeaders,
	[Types.API_RECEIVE_FUND_LIPPER_LEADERS]: receiveFundLipperLeaders,
	[Types.CLEAR_FUND_LIPPER_LEADERS]: clearLipperLeaders,
	[Types.API_REQUEST_FUND_LIPPER_ASSET_ALLOCATIONS]: requestFundLipperAssetAllocations,
	[Types.API_RECEIVE_FUND_LIPPER_ASSET_ALLOCATIONS]: receiveFundLipperAssetAllocations,
	[Types.API_REQUEST_FUND_PERFORMANCE_LIPPER]: requestFundPerformance,
	[Types.API_RECEIVE_FUND_PERFORMANCE_LIPPER]: receiveFundPerformance,
	[Types.CLEAR_FUND_PERFORMANCE_LIPPER]: clearPerformanceData,
	[Types.API_HANDLE_LIPPER_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
