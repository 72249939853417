/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from 'Actions/Types';

export const INITIAL_STATE = {
	fetchingUpgradesDowngrades: false,
	data: {
		downgrades: [],
		upgrades: []
	}
};

const requestUpgradesDowngrades = state => {
	return Object.assign({}, state, {
		fetchingUpgradesDowngrades: true
	});
};

const receiveUpradesDowngrades = (state, action) => {
	if (action.response && action.response.data) {
		return Object.assign({}, state, {
			fetchingUpgradesDowngrades: false,
			data: action.response.data
		});
	}

	return Object.assign({}, state, {
		fetchingUpgradesDowngrades: false
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_UPGRADES_DOWNGRADES]: requestUpgradesDowngrades,
	[Types.API_CIBC_RECEIVE_UPGRADES_DOWNGRADES]: receiveUpradesDowngrades,
	[Types.API_CIBC_HANDLE_UPGRADES_DOWNGRADES_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
