/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable indent */
/* eslint-disable prefer-object-spread */

import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingEquitiesGeneralInfo: false,
	fetchingEquitiesOfficerInfo: false,
	generalInfo: {},
	officerInfo: []
};

const requestEquityGeneralInfo = state => {
	return Object.assign({}, state, {
		generalInfo: {},
		fetchingEquitiesGeneralInfo: true
	});
};

const clearEquityGeneralInfo = state => {
	return Object.assign({}, state, {
		generalInfo: {},
		fetchingEquitiesGeneralInfo: false
	});
};

const receiveEquityGeneralInfo = (state, action) => {
	const { response } = action;
	const { data } = response;
	let companyOverviewFull;
	let truncTextOverview;
	if (data && data.companyText && data.companyText.length) {
		const companyText = Object.values(data.companyText);
		companyText.forEach(descriptions => {
			if (descriptions.type === 'Business Summary') {
				companyOverviewFull = descriptions.textBody;
				if (descriptions.textBody && descriptions.textBody.length > 470) {
					truncTextOverview = `${descriptions.textBody.slice(0, 470)} ...`;
				}
			}
		});
	}
	data.companyOverviewFull = companyOverviewFull;
	data.truncTextOverview = truncTextOverview;
	return Object.assign({}, state, {
		fetchingEquitiesGeneralInfo: false,
		generalInfo: Object.assign({}, action.response.data)
	});
};

const requestOfficerInfo = state => {
	return Object.assign({}, state, {
		officerInfo: {},
		fetchingEquitiesOfficerInfo: true
	});
};

const receiveOfficerInfo = (state, action) => {
	const { response } = action;
	const { data } = response;
	const info = [];
	if (action.response.data) {
		if (data.items && data.items.length) {
			let officers = data.items;
			if (data.items && data.items.length > 5) {
				officers = data.items.slice(0, 5);
			}
			// eslint-disable-next-line no-restricted-syntax
			for (const officer of officers) {
				const { age, name } = officer || {};
				const { first, middle, last } = name || {};
				const obj = {};
				let title = null;
				const nameFormatted = `${first} ${middle} ${last}`;
				const since = officer.dateStartOfficer.year || null;
				if (officer.titles.length) {
					title = officer.titles[0].long;
				}
				obj.title = title;
				obj.name = nameFormatted;
				obj.since = since;
				obj.age = age;
				info.push(obj);
			}
		}
	}

	return Object.assign({}, state, {
		fetchingEquitiesOfficerInfo: false,
		officerInfo: info
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_REUTERS_EQUITIES_INFO]: requestEquityGeneralInfo,
	[Types.API_CLEAR_REUTERS_EQUITIES_INFO]: clearEquityGeneralInfo,
	[Types.API_RECEIVE_REUTERS_EQUITIES_INFO]: receiveEquityGeneralInfo,
	[Types.API_RECEIVE_OFFICER_INFO]: receiveOfficerInfo,
	[Types.API_REQUEST_OFFICER_INFO]: requestOfficerInfo,
	[Types.API_HANDLE_REUTERS_EQUITIES_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
