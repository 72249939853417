/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import lodash from 'lodash';
import Types from '../Actions/Types';
import { holdingsGroups } from '../Actions/QuotelistActions';

export const INITIAL_STATE = {
	quotelistByIssueType: {
		stocksETFsIndices: [],
		mutualFunds: [],
		options: []
	},
	stocksETFsIndicesSortDirection: 'asc',
	stocksETFsIndicesSortField: 'symbol',
	mutualFundsSortDirection: 'asc',
	mutualFundsSortField: 'symbol'
};

const requestQuotelistFundBasics = state => {
	return Object.assign({}, state, {
		fetchingQuotelistFundBasicsData: true
	});
};

const receiveQuotelistFundBasics = (state, action) => {
	// eslint-disable-next-line prefer-destructuring
	const currentMutualFunds = state.quotelistByIssueType.mutualFunds;

	const fundBasicsData = action.response.data;
	const { error } = action.response;

	if (!error && fundBasicsData) {
		const fundBasicsDataXid = action.xid;
		const newMutualFunds = [];
		currentMutualFunds.forEach(symbol => {
			const xid = symbol.xids.venue;

			if (fundBasicsDataXid === xid) {
				const mergedData = lodash.merge(symbol, fundBasicsData);
				newMutualFunds.push(mergedData);
			} else {
				newMutualFunds.push(symbol);
			}
		});
		return Object.assign({}, state, {
			quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
				mutualFunds: newMutualFunds
			})
		});
	}

	return state;
};

const requestQuotelistQuotes = state => {
	return Object.assign({}, state, {
		fetchingQuotelistQuoteData: true
	});
};

const receiveQuotelistQuotes = (state, action) => {
	const {
		stocksETFsIndices
	} = state.quotelistByIssueType;
	const { data, error } = action.response;
	const { quotes } = data || {};
	const quoteMap = {};

	if (!error && quotes && quotes.length) {
		quotes.forEach(quoteData => {
			const { data: quote } = quoteData;
			const { venueXid } = quote || {};
			quoteMap[venueXid] = {
				last: quote.lastTrade.last,
				date: quote.lastTrade.date,
				high: quote.lastTrade.high,
				low: quote.lastTrade.low,
				open: quote.lastTrade.open === 0 ? null : quote.lastTrade.open,
				change: quote.lastTrade.change,
				chgPercentPrev: quote.changePercent.today,
				currency: quote.currency.isoCode,
				bid: quote.bid.price,
				bidSize: quote.bid.size,
				ask: quote.ask.price,
				askSize: quote.ask.size,
				price52WeekHigh: quote.price52Week.high,
				price52WeekLow: quote.price52Week.low,
				volume: quote.volume.last,
				realTimeQuoteIndicator: quote.realTimeQuoteIndicator,
				financialStatusIndicator: quote.financialStatusIndicator
			};
		});

		const newStocksETFsIndices = [];

		stocksETFsIndices.forEach(symbol => {
			const xid = symbol.xids.venue;
			const quoteDataToMerge = quoteMap[xid];
			if (quoteDataToMerge) {
				newStocksETFsIndices.push(lodash.merge(symbol, quoteDataToMerge));
			}
		});
	}

	return Object.assign({}, state, {
		fetchingQuotelistQuoteData: false,
		quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
			stocksETFsIndices: Object.assign([], newStocksETFsIndices)
		})
	});
};

const addToQuotelist = (state, action) => {
	const { quotelistByIssueType } = state;
	const { stocksETFsIndices, mutualFunds } = quotelistByIssueType;
	const holdingType = action.symbol.classification.name.toLowerCase();
	let quotelistCurrentTypeTotal = 0;

	// get current number of symbols in QL, based on type
	if (holdingType === 'fund') {
		quotelistCurrentTypeTotal = Object.keys(mutualFunds).length;
	} else if (holdingType !== 'option') {
		quotelistCurrentTypeTotal = Object.keys(stocksETFsIndices).length;
	}

	if (quotelistCurrentTypeTotal < 20) {
		let symbolAlreadyExists = false;

		// dupe check
		if (holdingType === 'fund') {
			mutualFunds.forEach(listing => {
				if (!symbolAlreadyExists) {
					symbolAlreadyExists = listing.xids.venue === action.symbol.xids.venue;
				}
			});
		} else if (holdingType !== 'option') {
			stocksETFsIndices.forEach(listing => {
				if (!symbolAlreadyExists) {
					symbolAlreadyExists = listing.xids.venue === action.symbol.xids.venue;
				}
			});
		}

		if (!symbolAlreadyExists) {
			let newSymbols;
			action.symbol.checked = false;

			switch (holdingType) {
				case 'option':
					newSymbols = state.quotelistByIssueType.options;
					newSymbols.push(action.symbol);
					return Object.assign({}, state, {
						quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
							options: Object.assign([], state.quotelistByIssueType.options,
								newSymbols)
						})
					});

				case 'fund':
					newSymbols = state.quotelistByIssueType.mutualFunds;
					newSymbols.push(action.symbol);
					return Object.assign({}, state, {
						quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
							mutualFunds: Object.assign([], state.quotelistByIssueType.mutualFunds,
								newSymbols)
						})
					});

				case 'equity':
				case 'etf':
				default:
					newSymbols = state.quotelistByIssueType.stocksETFsIndices;
					newSymbols.push(action.symbol);
					return Object.assign({}, state, {
						quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
							stocksETFsIndices: Object.assign([], state.quotelistByIssueType.stocksETFsIndices,
								newSymbols)
						})
					});
			}
		}
	}

	return state;
};

const removeFromQuotelist = (state, action) => {
	const { symbolsToKeep } = action;

	switch (action.quotelistSection) {
		case 'mutualFunds':
			return Object.assign({}, state, {
				quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
					mutualFunds: Object.assign([], symbolsToKeep)
				})
			});
		case 'stocksETFsIndices':
		default:
			return Object.assign({}, state, {
				quotelistByIssueType: Object.assign({}, state.quotelistByIssueType, {
					stocksETFsIndices: Object.assign([], symbolsToKeep)
				})
			});
	}
};

const sortHoldingsGroup = (state, action) => {
	const { quotelistByIssueType } = state;
	const { field, direction } = action;
	let sortDirection = direction;

	let groupSortDirection = direction;
	let groupSortField = null;
	let sortData = null;
	let fieldName = null;
	let directionName = null;

	switch (action.holdingGroup) {
		case holdingsGroups.MUTUALFUNDS:
			{
				const { mutualFundsSortDirection, mutualFundsSortField } = state;
				const { mutualFunds } = quotelistByIssueType;

				groupSortDirection = mutualFundsSortDirection;
				groupSortField = mutualFundsSortField;
				sortData = mutualFunds;
				fieldName = 'mutualFundsSortField';
				directionName = 'mutualFundsSortDirection';
			} break;
		case holdingsGroups.OPTIONS:
			{
				const { optionsSortDirection, optionsSortField } = state;
				const { options } = quotelistByIssueType;

				groupSortDirection = optionsSortDirection;
				groupSortField = optionsSortField;
				sortData = options;
				fieldName = 'optionsSortField';
				directionName = 'optionsSortDirection';
			} break;
		case holdingsGroups.STOCKS_ETFS_INDICES:
			{
				const { stocksETFsIndicesSortDirection, stocksETFsIndicesSortField } = state;
				const { stocksETFsIndices } = quotelistByIssueType;

				groupSortDirection = stocksETFsIndicesSortDirection;
				groupSortField = stocksETFsIndicesSortField;
				sortData = stocksETFsIndices;
				fieldName = 'stocksETFsIndicesSortField';
				directionName = 'stocksETFsIndicesSortDirection';
			} break;
		default:
			// NOTE: Intentionally left empty
			break;
	}

	if (sortData) {
		if ((groupSortDirection && groupSortField === field)
			|| (groupSortDirection === direction && groupSortField === field)) {
			sortDirection = groupSortDirection === 'asc' ? 'desc' : 'asc';
		}

		const dataToSortByField = [];
		const dataToSortBySymbol = [];
		sortData.forEach(item => {
			if (item[field] === 'symbol') {
				dataToSortBySymbol.push(item);
			} else {
				dataToSortByField.push(item);
			}
		});

		const dataSortedByField = lodash.orderBy(dataToSortByField, field, [sortDirection]);
		const dataSortedBySymbol = lodash.orderBy(dataToSortBySymbol, 'symbol', ['asc']);
		const newData = [...dataSortedByField, ...dataSortedBySymbol];
		const newQuotelistByIssueType = {};
		newQuotelistByIssueType[action.holdingGroup] = [...newData];

		const newStateObject = {};
		newStateObject[fieldName] = field;
		newStateObject[directionName] = sortDirection;
		newStateObject.quotelistByIssueType = Object.assign({},
			state.quotelistByIssueType, newQuotelistByIssueType);

		return Object.assign({}, state, newStateObject);
	}

	return state;
};

const setQuotelistTableSortIndicators = (state, action) => {
	const { field, direction } = action;
	let fieldName = null;
	let directionName = null;
	switch (action.holdingGroup) {
		case holdingsGroups.OPTIONS:
			fieldName = 'optionsSortField';
			directionName = 'optionsSortDirection';
			break;
		case holdingsGroups.STOCKS_ETFS_INDICES:
			fieldName = 'stocksETFsIndicesSortField';
			directionName = 'stocksETFsIndicesSortDirection';
			break;
		case holdingsGroups.MUTUALFUNDS:
			fieldName = 'mutualFundsSortField';
			directionName = 'mutualFundsSortDirection';
			break;
		default:
			// NOTE: Intentionally left empty
			break;
	}

	if (fieldName && directionName) {
		const newStateObject = {};
		newStateObject[fieldName] = field;
		newStateObject[directionName] = direction;

		return Object.assign({}, state, newStateObject);
	}

	return state;
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_RECEIVE_QUOTELIST_QUOTE]: receiveQuotelistQuotes,
	[Types.API_REQUEST_QUOTELIST_QUOTE]: requestQuotelistQuotes,
	[Types.API_RECEIVE_QUOTELIST_FUND_BASICS]: receiveQuotelistFundBasics,
	[Types.API_REQUEST_QUOTELIST_FUND_BASICS]: requestQuotelistFundBasics,
	[Types.ADD_TO_QUOTELIST]: addToQuotelist,
	[Types.REMOVE_FROM_QUOTELIST]: removeFromQuotelist,
	[Types.SORT_QUOTELIST]: sortHoldingsGroup,
	[Types.SORT_QUOTELIST_SETINDICATORS]: setQuotelistTableSortIndicators,
	[Types.API_HANDLE_QUOTELIST_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
