/* global localStorage */
import { getLangFromQueryString } from '../Utils/pathUtils';

const validateLang = lang => {
	return lang && (lang === 'en' || lang === 'fr');
};

const getLanguage = defaultLanguage => {
	// Look in the query string if we have one save it for later and return it
	const languageFromQueryString = getLangFromQueryString();
	if (languageFromQueryString && validateLang(languageFromQueryString)) {
		localStorage.setItem('language', languageFromQueryString);
		return languageFromQueryString;
	}

	// Check local storage if we have a language set return it
	const languageFromLocalStorage = localStorage.getItem('language');
	if (languageFromLocalStorage) {
		return languageFromLocalStorage;
	}

	// if a default was passed in return that else return english
	return defaultLanguage || 'en';
};

export default getLanguage;
