import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
// import reducers
import { IntlReducer } from 'react-redux-multilingual';
import NewsReducer from './NewsReducer';
import PriceChartReducer from './PriceChartReducer';
import QuotesReducer from './QuotesReducer';
import UserReducer from './UserReducer';
import UtilityReducer from './UtilityReducer';
import commoditiesReducer from './CommoditiesReducer';
import commoditiesChartReducer from './CommoditiesChartReducer';
import currencyReducer from './CurrencyChartReducer';
import indexReducer from './IndexReducer';
import rankingsReducer from './rankingsReducer';
import symbolSearchReducer from './symbolSearchReducer';
import technicalAnalysisReducer from './technicalAnalysisReducer';
import xrefReducer from './xrefReducer';
import OptionsReducer from './OptionsReducer';
import OptionsQuoteListReducer from './OptionsQuoteListReducer';
import watchlistReducer from './watchlistReducer';
import QuotelistReducer from './QuotelistReducer';
import MorningstarReducer from './MorningstarReducer';
import PremiumDiscountChartReducer from './PremiumDiscountChartReducer';
import ResearchReducer from './ResearchReducer';
import CorporateDividendsReducer from './CorporateDividendsReducer';
import DividendsPerShareReducer from './dividendsPerShareChartReducer';
import Growth10KChartReducer from './Growth10KChartRedurcer';
import AnnualPerformanceChartReducer from './AnnualPerformanceChartReducer';
import GlobalExposureChartReducer from './GlobalExposureChartReducer';
import TrailingReturnsChartReducer from './TrailingReturnsChartReducer';
import CurrentRatiosReducer from './CurrentRatiosReducer';
import StockEarningsReducer from './StockEarningsReducer';
import EquitiesGeneralTrkdReducer from './EquitiesGeneralTrkdReducer';
import StockEarningsChartReducer from './StockEarningsChartReducer';
import FinancialsReducer from './FinancialsReducer';
import FundDataReducer from './FundDataReducer';
import LipperReducer from './LipperReducer';
import AddEtfReducer from './AddEtfReducer';
import SimilarEtfs from './SimilarEtfsReducer';
import AlertsReducer from './AlertsReducer';
import ScreenerUtilsReducer from './screenerUtilsReducer';
import TodaysTopSectorsReducer from './TodaysTopSectorsReducer';
import FundIndexReducer from './FundIndexReducer';
import UpgradesDowngradesReducer from './UpgradesDowngradesReducer';
import BuzzNewsReducer from './BuzzNewsReducer';
import MarketsOverviewReducer from './MarketsOverviewReducer';

// create app reducer / combine reducers
const appReducer = combineReducers({
	alertsData: AlertsReducer,
	annualPerformanceChartData: AnnualPerformanceChartReducer,
	buzzNewsData: BuzzNewsReducer,
	commoditiesChartData: commoditiesChartReducer,
	commoditiesData: commoditiesReducer,
	compareEtfList: AddEtfReducer,
	compareSimilarEtfs: SimilarEtfs,
	corporateDividendData: CorporateDividendsReducer,
	currencyChartData: currencyReducer,
	currentRatiosData: CurrentRatiosReducer,
	dividendsPerShareChartData: DividendsPerShareReducer,
	equitiesTrkd: EquitiesGeneralTrkdReducer,
	financialsData: FinancialsReducer,
	fundData: FundDataReducer,
	fundIndexData: FundIndexReducer,
	globalExposureChartData: GlobalExposureChartReducer,
	growth10kChartData: Growth10KChartReducer,
	indexData: indexReducer,
	Intl: IntlReducer, // Must be named this due to how the intl provider trys to access it.
	lipperData: LipperReducer,
	marketRankingsData: rankingsReducer,
	morningstarData: MorningstarReducer,
	newsData: NewsReducer,
	optionData: OptionsReducer,
	optionQuoteListData: OptionsQuoteListReducer,
	premiumDiscountChartData: PremiumDiscountChartReducer,
	priceChartData: PriceChartReducer,
	quotelistData: QuotelistReducer,
	quotesData: QuotesReducer,
	marketsOverviewChartData: MarketsOverviewReducer,
	researchData: ResearchReducer,
	routing: routerReducer,
	screenerUtils: ScreenerUtilsReducer,
	stockEarnings: StockEarningsReducer,
	stockEarningsChartData: StockEarningsChartReducer,
	symbolSearchData: symbolSearchReducer,
	technicalAnalysisData: technicalAnalysisReducer,
	todaysTopSectors: TodaysTopSectorsReducer,
	trailingReturnsChartData: TrailingReturnsChartReducer,
	userData: UserReducer,
	utility: UtilityReducer,
	watchlistData: watchlistReducer,
	xrefData: xrefReducer,
	stockUpgradesDowngrades: UpgradesDowngradesReducer
});

// create root reducer that can destroy state if necessary
const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export default rootReducer;
