/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import lodash from 'lodash';
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

const INITIAL_STATE = {
	compareEtfSymbols: [],
	compareQuoteList: [],
	morningstarProspectus: [],
	corporateDividends: [],
	morningstarRatings: [],
	morningstarBenchmarks: [],
	morningstarBenchmarkCategory: [],
	morningstarFundAttributes: [],
	shareClassData: [],
	fundDetails: [],
	fundStrategy: [],
	portfolioStatisticsData: [],
	performance: [],
	annualPerformance: [],
	riskMeasures: [],
	premiumDiscount: [],
	dividendInformation: [],
	assetAllocationData: [],
	assetsFormatted: [],
	topHoldingsData: [],
	geographicAllocationData: [],
	sectorAllocationData: [],
	limit: 4
};

const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

const requestComparelistQuotes = state => {
	return Object.assign({}, state, {
		fetchingComparelistQuoteData: true
	});
};

const requestMorningstarProspectusFees = state => {
	return Object.assign({}, state, {
		fetchingCompareMorningstarProspectusFees: true
	});
};

const receiveMorningstarProspectusFees = (state, action) => {
	return Object.assign({}, state, {
		fetchingCompareMorningstarProspectusFees: false,
		morningstarProspectus: [...state.morningstarProspectus, action.response.data]
	});
};

const requestCorporateDividends = state => {
	return Object.assign({}, state, {
		fetchingCompareCorporateDividends: true
	});
};

const receiveCorporateDividends = (state, action) => {
	return Object.assign({}, state, {
		fetchingCompareCorporateDividends: false,
		corporateDividends: [...state.corporateDividends, action.response.data]
	});
};

const requestMorningstarRatings = state => {
	return Object.assign({}, state, {
		fetchingCompareMorningstarRatings: true
	});
};

const receiveMorningstarRatings = (state, action) => {
	const { data } = action.response;
	return Object.assign({}, state, {
		fetchingCompareMorningstarRatings: false,
		morningstarRatings: data ? [...state.morningstarRatings, data] : state.morningstarRatings
	});
};

const requestMorningstarBenchmarks = state => {
	return Object.assign({}, state, {
		fetchingMorningstarBenchmark: true
	});
};

const receiveMorningstarBenchmarks = (state, action) => {
	return Object.assign({}, state, {
		fetchingMorningstarBenchmark: false,
		morningstarBenchmarks: [...state.morningstarBenchmarks, action.response.data.items]
	});
};

const requestMorningstarBenchmarkCategory = state => {
	return Object.assign({}, state, {
		fetchingManagedFundsBenchmarkCategory: true
	});
};

const receiveMorningstarBenchmarkCategory = (state, action) => {
	return Object.assign({}, state, {
		fetchingManagedFundsBenchmarkCategory: false,
		morningstarBenchmarkCategory: [
			...state.morningstarBenchmarkCategory,
			action.response.data.items
		]
	});
};

const requestCompareAttributes = state => {
	return Object.assign({}, state, {
		fetchingCompareFundsAttributes: true
	});
};

const receiveCompareAttributes = (state, action) => {
	return Object.assign({}, state, {
		fetchingCompareFundsAttributes: false,
		morningstarFundAttributes: [
			...state.morningstarFundAttributes,
			action.response.data
		]
	});
};

const requestShareClass = state => {
	return Object.assign({}, state, {
		fetchingCompareShareclassData: true
	});
};

const receiveShareClass = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingCompareShareclassData: false,
		shareClassData: [...state.shareClassData, premData]
	});
};

const requestCompareFundDetails = state => {
	return Object.assign({}, state, {
		fetchingCompareFundsDetails: true
	});
};

const receiveCompareFundDetails = (state, action) => {
	let data = [];
	if (action.response.data) {
		// eslint-disable-next-line prefer-destructuring
		data = action.response.data[0];
	}
	return Object.assign({}, state, {
		fetchingCompareFundsDetails: false,
		fundDetails: [
			...state.fundDetails,
			data
		]
	});
};

const requestCompareFundStrategy = state => {
	return Object.assign({}, state, {
		fetchingCompareFundsStrategy: true
	});
};

const receiveCompareFundStrategy = (state, action) => {
	let data = [];
	if (action.response.data) {
		data = action.response.data;
	}
	return Object.assign({}, state, {
		fetchingCompareFundsStrategy: false,
		fundStrategy: [
			...state.fundStrategy,
			data
		]
	});
};

const requestComparePerformance = state => {
	return Object.assign({}, state, {
		fetchingComparePerformance: true
	});
};

const receiveComparePerformance = (state, action) => {
	let data = [];
	if (action.response.data) {
		data = action.response.data;
	}
	return Object.assign({}, state, {
		fetchingComparePerformance: false,
		performance: [
			...state.performance,
			data
		]
	});
};

const requestCompareAnnualPerformance = state => {
	return Object.assign({}, state, {
		fetchingCompareAnnualPerformance: true
	});
};

const receiveCompareAnnualPerformance = (state, action) => {
	let data = [];
	if (action.response.data) {
		data = action.response.data;
	}
	return Object.assign({}, state, {
		fetchingCompareAnnualPerformance: false,
		annualPerformance: [
			...state.annualPerformance,
			data
		]
	});
};

const requestPortfolioStatistics = state => {
	return Object.assign({}, state, {
		fetchingComparePortfolioStatistics: true
	});
};

const receivePortfolioStatistics = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingComparePortfolioStatistics: false,
		portfolioStatisticsData: [...state.portfolioStatisticsData, premData]
	});
};

const requestComparePremiumDiscount = state => {
	return Object.assign({}, state, {
		fetchingcomparePremiumDiscount: true
	});
};

const receiveComparePremiumDiscount = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingcomparePremiumDiscount: false,
		premiumDiscount: [...state.premiumDiscount, premData]
	});
};

const requestRiskMeasures = state => {
	return Object.assign({}, state, {
		fetchingRiskMeasures: true,
		beta: null,
		volitility: Object.assign({})
	});
};

const receiveRiskMeasures = (state, action) => {
	let premData = {};
	const volitility = {};
	// eslint-disable-next-line no-unused-vars
	let beta = null;
	if (action.response && action.response.data) {
		premData = action.response.data;
		const {
			fund
		} = premData || {};
		const {
			nav: fundNav,
			asOfDate: asOfDateFund
		} = fund || {};
		const betaValue = fundNav
			&& fundNav.beta
			&& fundNav.beta.primaryIndex
			&& fundNav.beta.primaryIndex[0].value;

		if (betaValue) {
			beta = betaValue;
		}

		volitility.asOfDate = asOfDateFund || asOfDateCategory;

		const {
			rSquared: fundRsquared,
			alpha: fundAlpha,
			beta: fundBeta
		} = fundNav || {};

		const mapDataForName = (data, name, saveName, type) => {
			if (data && data[name] && data[name].length) {
				data[name].forEach(item => {
					if (!volitility[saveName]) {
						volitility[saveName] = {};
					}

					if (!volitility[saveName][item.temporalPeriod]) {
						volitility[saveName][item.temporalPeriod] = {};
					}

					volitility[saveName][item.temporalPeriod][type] = item.value;
				});
			}
		};

		mapDataForName(fundNav, 'standardDeviation', 'standardDeviation', 'fund');
		mapDataForName(fundNav, 'sharpeRatio', 'sharpeRatio', 'fund');
		mapDataForName(fundRsquared, 'primaryIndex', 'rSquared', 'fund');
		mapDataForName(fundAlpha, 'primaryIndex', 'alpha', 'fund');
		mapDataForName(fundBeta, 'primaryIndex', 'beta', 'fund');
	}

	return Object.assign({}, state, {
		fetchingRiskMeasures: false,
		riskMeasures: [...state.riskMeasures, volitility]
		// beta
	});
};

const requestCompareCorporateDividends = state => {
	return Object.assign({}, state, {
		fetchingCompareCorporateDividends: true
	});
};

const receiveCompareCorporateDividends = (state, action) => {
	return Object.assign({}, state, {
		fetchingCompareCorporateDividends: false,
		dividendInformation: [...state.dividendInformation, action.response.data]
	});
};

const requestCompareAssetAllocationData = state => {
	return Object.assign({}, state, {
		fetchingCompareAssetAllocationData: true
	});
};

const receiveCompareAssetAllocationData = (state, action) => {
	let premData = {};
	let formatted = [];

	if (action.response.data) {
		premData = action.response.data;
		const { items, categoryAllocation } = premData || {};
		const assets = {
			equity: {
				name: 'equity',
				value: 0,
				categoryValue: null
			},
			bonds: {
				name: 'bonds',
				value: 0,
				categoryValue: null
			}
		};

		if (items && items.length > 0) {
			items.forEach(item => {
				if (item.value.net) {
					if (item.type === 'usstock' || item.type === 'nonusstock') {
						assets.equity.value += item.value.net;
					} else if (item.type === 'usbond' || item.type === 'nonusbond') {
						assets.bonds.value += item.value.net;
					} else if (assets[item.type]) {
						assets[item.type].value += item.value.net;
					} else {
						assets[item.type] = {
							name: item.type,
							value: item.value.net
						};
					}
				}
			});
		}

		if (categoryAllocation && categoryAllocation.items && categoryAllocation.items.length > 0) {
			const catItems = categoryAllocation.items;
			catItems.forEach(catItem => {
				if (catItem.value.net) {
					if (catItem.type === 'usstock' || catItem.type === 'nonusstock') {
						assets.equity.categoryValue += catItem.value.net;
					} else if (catItem.type === 'usbond' || catItem.type === 'nonusbond') {
						assets.bonds.categoryValue += catItem.value.net;
					} else if (assets[catItem.type] && assets[catItem.type].categoryValue) {
						assets[catItem.type].categoryValue += catItem.value.net;
					} else if (assets[catItem.type] && !assets[catItem.type].categoryValue) {
						const newData = {
							name: assets[catItem.type].name,
							value: assets[catItem.type].value,
							categoryValue: catItem.value.net
						};
						assets[catItem.type] = newData;
					}
				}
			});
		}

		Object.keys(assets).forEach(key => {
			const item = assets[key];
			if (item.value !== 0) {
				formatted.push(item);
			}
		});

		if (formatted.length > 0) {
			formatted = lodash.orderBy(formatted, ['value'], ['desc']);

			formatted = formatted.map(asset => {
				asset.isShort = asset.value < 0;
				asset.isLeveraged = asset.value > 100;
				return asset;
			});
		}
	}

	return Object.assign({}, state, {
		fetchingCompareAssetAllocationData: false,
		assetAllocationData: [...state.assetAllocationData, premData],
		assetsFormatted: [...state.assetsFormatted, formatted]
	});
};

const receiveTopHoldings = (state, action) => {
	let premData = {};
	const holdings = [];

	if (action.response.data) {
		premData = action.response.data;
		const { items } = premData || {};

		if (items && items.length > 0) {
			items.forEach(symbol => {
				const {
					securityIdentifiers,
					shareDetails
				} = symbol || {};
				const { name } = securityIdentifiers || {};
				const { weighting } = shareDetails || {};

				holdings.push({
					name,
					value: weighting
				});
			});
		}
	}

	return Object.assign({}, state, {
		fetchingCompareTopHoldings: false,
		topHoldingsData: [...state.topHoldingsData, Object.assign([], holdings)]
	});
};

const requestTopHoldings = state => {
	return Object.assign({}, state, {
		fetchingCompareTopHoldings: true
	});
};

const requestCompareGeographicAllocation = state => {
	return Object.assign({}, state, {
		fetchingcompareGeographicAllocation: true
	});
};

const receiveCompareGeographicAllocation = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingcompareGeographicAllocation: false,
		geographicAllocationData: [...state.geographicAllocationData, premData]
	});
};

const requestCompareSectorAllocation = state => {
	return Object.assign({}, state, {
		fetchingcompareSectorAllocation: true
	});
};

const receiveCompareSectorAllocation = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingcompareSectorAllocation: false,
		sectorAllocationData: [...state.sectorAllocationData, premData]
	});
};

const receiveComparelistQuotes = (state, action) => {
	const quotesData = action.response.data;
	const { data, error } = action.response.data.quotes[0];

	if (!error && quotesData && quotesData.quotes && quotesData.quotes.length > 0) {
		const quoteDataToMerge = {
			last: data.lastTrade.last,
			close: data.lastTrade.close,
			date: data.lastTrade.date,
			high: data.lastTrade.high,
			low: data.lastTrade.low,
			open: data.lastTrade.open === 0 ? null : data.lastTrade.open,
			change: data.lastTrade.change,
			chgPercentPrev: data.changePercent.today,
			currency: data.currency.isoCode,
			bid: data.bid.price,
			bidSize: data.bid.size,
			ask: data.ask.price,
			askSize: data.ask.size,
			price52WeekHigh: data.price52Week.high,
			price52WeekLow: data.price52Week.low,
			volume: data.volume.last,
			avg10Day: data.volume.average10Day,
			financialStatusIndicator: data.financialStatusIndicator
		};

		return Object.assign({}, state, {
			compareQuoteList: [...state.compareQuoteList, quoteDataToMerge]
		});
	}

	return state;
};

const addSymbolToCompareList = (state, action) => {
	return Object.assign({}, state, {
		compareEtfSymbols: [...state.compareEtfSymbols, action.symbol]
	});
};

const removeItemByIndex = (data, index) => {
	data.splice(index, 1);
	return data;
};

const deleteSymbolFromCompareList = (state, action) => {
	const { index } = action || -1;
	return Object.assign({}, state, {
		compareEtfSymbols: removeItemByIndex(state.compareEtfSymbols, index),
		compareQuoteList: removeItemByIndex(state.compareQuoteList, index),
		morningstarProspectus: removeItemByIndex(state.morningstarProspectus, index),
		corporateDividends: removeItemByIndex(state.corporateDividends, index),
		morningstarRatings: removeItemByIndex(state.morningstarRatings, index),
		morningstarBenchmarks: removeItemByIndex(state.morningstarBenchmarks, index),
		morningstarBenchmarkCategory: removeItemByIndex(state.morningstarBenchmarkCategory, index),
		morningstarFundAttributes: removeItemByIndex(state.morningstarFundAttributes, index),
		shareClassData: removeItemByIndex(state.shareClassData, index),
		fundDetails: removeItemByIndex(state.fundDetails, index),
		fundStrategy: removeItemByIndex(state.fundStrategy, index),
		portfolioStatisticsData: removeItemByIndex(state.portfolioStatisticsData, index),
		performance: removeItemByIndex(state.performance, index),
		annualPerformance: removeItemByIndex(state.annualPerformance, index),
		riskMeasures: removeItemByIndex(state.riskMeasures, index),
		premiumDiscount: removeItemByIndex(state.premiumDiscount, index),
		dividendInformation: removeItemByIndex(state.dividendInformation, index),
		assetAllocationData: removeItemByIndex(state.assetAllocationData, index),
		assetsFormatted: removeItemByIndex(state.assetsFormatted, index),
		topHoldingsData: removeItemByIndex(state.topHoldingsData, index),
		geographicAllocationData: removeItemByIndex(state.geographicAllocationData, index),
		sectorAllocationData: removeItemByIndex(state.sectorAllocationData, index)
	});
};

const resetCompareList = () => {
	return Object.assign({}, INITIAL_STATE);
};

const ACTION_HANDLERS = {
	[Types.API_ADD_SYMBOLS_TO_COMPARE_LIST]: addSymbolToCompareList,
	[Types.API_HANDLE_COMPARELIST_LOADER]: handleLoader,
	[Types.API_RECEIVE_COMPARE_LIST_QUOTE]: receiveComparelistQuotes,
	[Types.API_REQUEST_COMPARE_LIST_QUOTE]: requestComparelistQuotes,
	[Types.API_REQUEST_COMPARE_MORNINGSTAR_PROSPECTUS]: requestMorningstarProspectusFees,
	[Types.API_RECEIVE_COMPARE_MORNINGSTAR_PROSPECTUS]: receiveMorningstarProspectusFees,
	[Types.API_REQUEST_COMPARE_CORPORATE_DIVIDEND]: requestCorporateDividends,
	[Types.API_RECEIVE_COMPARE_CORPORATE_DIVIDEND]: receiveCorporateDividends,
	[Types.API_REQUEST_COMPARE_MORNINGSTAR_RATINGS]: requestMorningstarRatings,
	[Types.API_RECEIVE_COMPARE_MORNINGSTAR_RATINGS]: receiveMorningstarRatings,
	[Types.API_REQUEST_COMPARE_MORNINGSTAR_BENCHMARKS]: requestMorningstarBenchmarks,
	[Types.API_RECEIVE_COMPARE_MORNINGSTAR_BENCHMARKS]: receiveMorningstarBenchmarks,
	[Types.API_REQUEST_COMPARE_MORNINGSTAR_BENCHMARK_CATEGORY]: requestMorningstarBenchmarkCategory,
	[Types.API_RECEIVE_COMPARE_MORNINGSTAR_BENCHMARK_CATEGORY]: receiveMorningstarBenchmarkCategory,
	[Types.API_REQUEST_COMPARE_ATTRIBUTES]: requestCompareAttributes,
	[Types.API_RECEIVE_COMPARE_ATTRIBUTES]: receiveCompareAttributes,
	[Types.API_REQUEST_COMPARE_SHARECLASS_DATA]: requestShareClass,
	[Types.API_RECEIVE_COMPARE_SHARECLASS_DATA]: receiveShareClass,
	[Types.API_CIBC_REQUEST_COMPARE_FUND_DETAILS]: requestCompareFundDetails,
	[Types.API_CIBC_RECEIVE_COMPARE_FUND_DETAILS]: receiveCompareFundDetails,
	[Types.API_REQUEST_COMPARE_FUND_STRATEGY]: requestCompareFundStrategy,
	[Types.API_RECEIVE_COMPARE_FUND_STRATEGY]: receiveCompareFundStrategy,
	[Types.API_REQUEST_COMPARE_PORTFOLIO_STATS]: requestPortfolioStatistics,
	[Types.API_RECEIVE_COMPARE_PORTFOLIO_STATS]: receivePortfolioStatistics,
	[Types.API_REQUEST_COMPARE_PERFORMANCE]: requestComparePerformance,
	[Types.API_RECEIVE_COMPARE_PERFORMANCE]: receiveComparePerformance,
	[Types.API_REQUEST_COMPARE_ANNUAL_PERFORMANCE]: requestCompareAnnualPerformance,
	[Types.API_RECEIVE_COMPARE_ANNUAL_PERFORMANCE]: receiveCompareAnnualPerformance,
	[Types.API_REQUEST_COMPARE_RISK_MEASURES]: requestRiskMeasures,
	[Types.API_RECEIVE_COMPARE_RISK_MEASURES]: receiveRiskMeasures,
	[Types.API_REQUEST_COMPARE_PREMIUM_DISCOUNT]: requestComparePremiumDiscount,
	[Types.API_RECEIVE_COMPARE_PREMIUM_DISCOUNT]: receiveComparePremiumDiscount,
	[Types.API_REQUEST_COMPARE_DIVIDEND_INFORMATION]: requestCompareCorporateDividends,
	[Types.API_RECEIVE_COMPARE_DIVIDEND_INFORMATION]: receiveCompareCorporateDividends,
	[Types.API_REQUEST_COMPARE_ASSET_ALLOCATION]: requestCompareAssetAllocationData,
	[Types.API_RECEIVE_COMPARE_ASSET_ALLOCATION]: receiveCompareAssetAllocationData,
	[Types.API_CIBC_REQUEST_COMPARE_TOP_HOLDINGS]: requestTopHoldings,
	[Types.API_CIBC_RECEIVE_COMPARE_TOP_HOLDINGS]: receiveTopHoldings,
	[Types.API_REQUEST_COMPARE_GEOGRAPHIC_ALLOCATION]: requestCompareGeographicAllocation,
	[Types.API_RECEIVE_COMPARE_GEOGRAPHIC_ALLOCATION]: receiveCompareGeographicAllocation,
	[Types.API_REQUEST_COMPARE_SECTOR_ALLOCATION]: requestCompareSectorAllocation,
	[Types.API_RECEIVE_COMPARE_SECTOR_ALLOCATION]: receiveCompareSectorAllocation,
	[Types.DELETE_SYMBOL_FROM_COMPARE_LIST]: deleteSymbolFromCompareList,
	[Types.RESET_COMPARE_LIST]: resetCompareList
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
