/* global window, localStorage */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reduxMulti from 'redux-multi';
import reduxReset from 'redux-reset';
import createCompressor from 'redux-persist-transform-compress';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import accessTokenService from '../services/accessTokenService';
import rootReducer from '../Reducers';
import rootSaga from '../Sagas/index';

export default function configureStore() {
	const compressor = createCompressor();

	const persistConfig = {
		key: 'root',
		storage: storageSession,
		transforms: [compressor],
		blacklist: ['userData', 'Intl', 'quotesData', 'optionData', 'researchData', 'alertsData']
	};

	const persistedReducer = persistReducer(persistConfig, rootReducer);

	let myState;
	const sagaMiddleware = createSagaMiddleware();
	const middleware = applyMiddleware(sagaMiddleware, reduxMulti);
	const enhancer = compose(
		middleware,
		reduxReset()
	);
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(persistedReducer, myState, composeEnhancers(enhancer));
	const persistor = persistStore(store);

	const previousToken = accessTokenService.getPreviousToken();
	const token = accessTokenService.retrieve(null);

	if ((previousToken !== token) || (!previousToken && !token)) {
		/* When we get a new token we will flush and purge and reset the redux store
		There is a case that 2 diff users could login on the same machine using the same browser
		This will prevent the 2nd user from seeing any content belonging to the 1st user
		*/
		persistor.flush();
		persistor.purge();
		store.dispatch({
			type: 'RESET'
		});
	}

	if (token) {
		localStorage.setItem('access_token', token);
	}

	// run sagas
	sagaMiddleware.run(rootSaga(token));

	return {
		store,
		persistor
	};
}
