/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import { isDebugInfoOn } from 'Utils/pathUtils';
import writeToDebug from 'Utils/writeToDebug';
import showDebugRequestTransform from './showDebugTransforms/showDebugRequestTransform';
import buildParams from '../Utils/apiParametersBuilderHelper';

const create = (baseURL, authToken) => {
	const api = apisauce.create({
		// base URL is read from the "constructor"
		baseURL,
		// here are some default headers
		headers: {
			Accept: '*/*',
			'Cache-Control': 'no-store',
			Authorization: `Bearer ${authToken}`
		},
		// 20 second timeout...
		timeout: 20000
	});

	const getData = (endPoint, parameters, options) => api.get(endPoint, buildParams(parameters, options));
	const getPdfData = (endPoint, parameters, options) => api.get(endPoint, buildParams(parameters, options), { responseType: 'arraybuffer', withCredentials: false });
	const postData = (endPoint, parameters) => api.post(endPoint, parameters);
	const putData = (endPoint, parameters) => api.put(endPoint, parameters);
	// This is a hack so the parameters of a DELETE method are sent as the body in the request
	const deleteData = (endPoint, parameters) => api.post(endPoint, parameters, { method: 'delete' });
	const getDataDownload = (endPoint, parameters) => api.post(endPoint, parameters, { responseType: 'blob', withCredentials: false });
	api.addRequestTransform(showDebugRequestTransform);
	api.addResponseTransform(response => {
		if (isDebugInfoOn()) {
			writeToDebug(response);
		}
	});
	return {
		// a list of the API functions from step 2
		api,
		getData,
		getPdfData,
		postData,
		putData,
		getDataDownload,
		deleteData
	};
};

// let's return back our create method as the default.
export default {
	create
};
