/* global document, localStorage */
import { getTokenFromQueryString } from '../Utils/pathUtils';

const retrieve = localAccessToken => {
	// token from querystring > token from meta > token from local storage > local access token
	const accessTokenFromQueryString = getTokenFromQueryString();
	if (accessTokenFromQueryString) {
		return accessTokenFromQueryString;
	}

	const accessTokenMeta = document.querySelector("meta[name='access_token']");
	const accessTokenFromMeta = accessTokenMeta ? accessTokenMeta.getAttribute('value') : null;
	if (accessTokenFromMeta) {
		return accessTokenFromMeta;
	}

	const accessTokenFromLocalStorage = localStorage.getItem('access_token');
	if (accessTokenFromLocalStorage) {
		return accessTokenFromLocalStorage;
	}

	return localAccessToken || '';
};

const getPreviousToken = () => {
	return localStorage.getItem('access_token');
};

export default {
	retrieve,
	getPreviousToken
};
