const chartColorHelper = {};

chartColorHelper.colors = [
	'#0065AA',
	'#1B7D5A',
	'#B73917',
	'#333479',
	'#8A1C41',
	'#995002',
	'#024423',
	'#D03E5E',
	'#7B6E74',
	'#101316'
];

chartColorHelper.getColorByIndex = index => {
	const num = `${index}`;
	return chartColorHelper.colors[num.slice(-1)];
};

export default chartColorHelper;
