import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingStockEarningsChart: false,
	chartData: {}
};

const requestStockEarningsChart = state => {
	return { ...state, fetchingStockEarningsChart: true };
};

const receiveStockEarningsChart = (state, action) => {
	return {
		...state,
		fetchingStockEarningsChart: false,
		chartData: action.response.data
	};
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return { ...state, [action.fetchName]: false };
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_RECEIVE_STOCK_EARNINGS_CHART]: receiveStockEarningsChart,
	[Types.API_CIBC_REQUEST_STOCK_EARNINGS_CHART]: requestStockEarningsChart,
	[Types.API_CIBC_HANDLE_TRAILING_RETURNS_CHART_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
