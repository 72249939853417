import { createTypes } from 'reduxsauce';

export default createTypes(`
	KILL_ACTIVE_SAGA_WORKERS

	API_CIBC_RECEIVE_USER_INFO
	API_CIBC_RECEIVE_USER_INFO_FAIL
	API_CIBC_REQUEST_USER_INFO
	API_CIBC_SINGLE_REQUEST_USER_INFO
	API_CIBC_HANDLE_USER_INFO

	API_REQUEST_INDEX_QUOTES
	API_RECIEVE_INDEX_QUOTES

	API_REQUEST_MARKET_NEWS
	API_RECEIVE_MARKET_NEWS
	API_REQUEST_ETF_NEWS
	API_RECEIVE_ETF_NEWS
	API_HANDLE_NEWS_LOADER
	API_REQUEST_COMMODITIES_QUOTES
	API_RECEIVE_COMMODITIES_QUOTES

	API_REQUEST_NEWS_LINK
	API_REQUEST_NEWS_ARTICLE
	API_RECEIVE_NEWS_ARTICLE

	API_HANDLE_INDEX_LOADER
	API_HANDLE_NEWS_LOADER

	API_REQUEST_QUOTE
	API_RECEIVE_QUOTE
	API_HANDLE_QUOTE_LOADER

	API_CIBC_REQUEST_PRICECHART
	API_CIBC_SINGLE_REQUEST_PRICECHART
	API_CIBC_RECEIVE_PRICECHART
	API_CIBC_RECEIVE_MULTIPLE_PRICECHART
	API_CIBC_HANDLE_PRICECHART_LOADER

	API_REQUEST_MARKETRANKINGS
	API_RECEIVE_MARKETRANKINGS
	API_HANDLE_MARKETRANKINGS_LOADER

	API_REQUEST_SYMBOL_SEARCH
	API_RECEIVE_SYMBOL_SEARCH
	API_HANDLE_SYMBOL_SEARCH_LOADER
	API_HANDLE_COMMODITIES_LOADER
	API_SINGLE_REQUEST_SYMBOL_SEARCH

	API_RECEIVE_MARKET_RANKINGS
	API_REQUEST_MARKET_RANKINGS
	API_HANDLE_MARKET_RANKINGS_LOADER

	API_RECEIVE_XREF_BY_VENUE_XIDS
	API_HANDLE_XREF_BY_VENUE_XIDS_LOADER
	API_CIBC_REQUEST_XIDS_BY_LEGACYSYMBOL
	API_REQUEST_XREF_BY_VENUE_XIDS
	API_REQUEST_SYMBOL_IN_CONTEXT
	API_RECEIVE_SYMBOL_IN_CONTEXT
	API_REQUEST_EXCHANGES
	SET_SYMBOL_IN_CONTEXT
	API_CIBC_REQUEST_UNDERLAYING_BY_XIDS
	API_CIBC_RECEIVE_UNDERLAYING_BY_XIDS
	SET_SYMBOL_BY_XID

	API_CIBC_REQUEST_XIDS_BY_COMPANYID
	API_CIBC_RECEIVE_XIDS_BY_COMPANYID
	API_CIBC_HANDLE_XIDS_BY_COMPANYID_LOADER

	API_CIBC_REQUEST_CURRENCYCHART
	API_CIBC_RECEIVE_CURRENCYCHART
	API_CIBC_HANDLE_CURRENCYCHART_LOADER

	API_CIBC_RECEIVE_TECHNICAL_ANALYSIS
	API_CIBC_REQUEST_TECHNICAL_ANALYSIS
	API_CIBC_HANDLE_TECHNICAL_ANALYSIS_LOADER

	API_CIBC_REQUEST_WATCHLIST_NAMES
	API_CIBC_RECEIVE_WATCHLIST_NAMES
	API_CIBC_RECEIVE_WATCHLIST
	API_CIBC_REQUEST_WATCHLIST
	API_REQUEST_WATCHLIST_QUOTE
	API_RECEIVE_WATCHLIST_QUOTE
	SORT_WATCHLIST
	SORT_WATCHLIST_SETINDICATORS
	API_CIBC_REQUEST_WATCHLIST_UPDATE_TRANSACTION
	API_CIBC_RECEIVE_WATCHLIST_UPDATE_TRANSACTION
	API_CIBC_REQUEST_WATCHLIST_CREATE_WATCHLIST
	API_CIBC_RECEIVE_WATCHLIST_CREATE_WATCHLIST
	API_CIBC_REQUEST_WATCHLIST_ADD_HOLDINGS
	API_CIBC_RECEIVE_WATCHLIST_ADD_HOLDINGS
	API_CIBC_RECEIVE_WATCHLIST_DELETE_HOLDINGS
	API_CIBC_REQUEST_WATCHLIST_DELETE_HOLDINGS
	API_CIBC_RECEIVE_WATCHLIST_RENAME
	API_CIBC_SET_WATCHLIST_RENAME_ERROR
	API_CIBC_REQUEST_WATCHLIST_RENAME
	API_CIBC_RECEIVE_WATCHLIST_DELETE
	API_CIBC_REQUEST_WATCHLIST_DELETE
	API_CIBC_REQUEST_ADD_TO_RECENT_QUOTES
	API_CIBC_RECEIVE_ADD_TO_RECENT_QUOTES
	SET_ACTIVE_WATCHLIST_ID
	API_HANDLE_WATCHLIST_LOADER
	API_HANDLE_TIPRANKS_LOADER

	API_CIBC_REQUEST_GLOBAL_EXPOSURE_CHART
	API_CIBC_RECEIVE_GLOBAL_EXPOSURE_CHART
	API_CIBC_HANDLE_GLOBAL_EXPOSURE_CHART_LOADER

	ADD_TO_QUOTELIST
	REMOVE_FROM_QUOTELIST
	SORT_QUOTELIST
	SORT_QUOTELIST_SETINDICATORS
	API_HANDLE_QUOTELIST_LOADER
	API_RECEIVE_QUOTELIST_QUOTE
	API_REQUEST_QUOTELIST_QUOTE
	API_RECEIVE_QUOTELIST_FUND_BASICS
	API_REQUEST_QUOTELIST_FUND_BASICS

	API_REQUEST_NEWS_INSIGHTS
	API_RECEIVE_NEWS_INSIGHTS
	API_REQUEST_PDF
	API_RECEIVE_PDF

	API_CIBC_REQUEST_COMMODITIESCHART
	API_CIBC_HANDLE_COMMODITIESCHART_LOADER
	API_CIBC_RECEIVE_COMMODITIESCHART

	API_CIBC_REQUEST_TOP_HOLDINGS
	API_CIBC_RECEIVE_TOP_HOLDINGS
	API_CLEAR_TOP_HOLDINGS

	API_RECEIVE_MORNINGSTAR_ANNUAL_FEES
	API_REQUEST_MORNINGSTAR_ANNUAL_FEES

	API_REQUEST_KEYRATES_QUOTE
	API_RECEIVE_KEYRATES_QUOTE
	API_HANDLE_KEYRATES_LOADER

	API_REQUEST_US_KEYRATES_QUOTE
	API_RECEIVE_US_KEYRATES_QUOTE
	API_HANDLE_US_KEYRATES_LOADER

	API_RECEIVE_OPTION_CHAIN
	API_HANDLE_OPTION_LOADER
	API_REQUEST_OPTION_CHAIN
	API_REQUEST_OPTION_CHAIN_WITH_QUOTES
	API_RECEIVE_OPTION_EXPIRATION_DATES
	API_REQUEST_OPTION_EXPIRATION_DATES
	API_RECEIVE_OPTION_CHAIN_BY_STRIKE
	API_CIBC_REQUEST_STRATEGY_CHART
	API_CIBC_RECEIVE_STRATEGY_CHART

	ADD_OPTION_TO_QUOTE_LIST
	REMOVE_OPTIONS_FROM_QUOTE_LIST
	CLEAR_OPTIONS_QUOTELIST
	SET_OPTION_CHAIN_STRIKE_COUNT

	API_REQUEST_MORNINGSTAR_RATINGS
	API_HANDLE_MORNINGSTAR_RATINGS_LOADER
	API_RECEIVE_MORNINGSTAR_RATINGS
	API_REQUEST_MORNINGSTAR_PREMIUM_DISCOUNT_DAILY
	API_RECEIVE_MORNINGSTAR_PREMIUM_DISCOUNT_DAILY
	API_RECEIVE_MORNINGSTAR_SHARE_CLASS
	API_REQUEST_MORNINGSTAR_SHARE_CLASS
	API_RECEIVE_MORNINGSTAR_PORTFOLIO_STATISTICS
	API_REQUEST_MORNINGSTAR_PORTFOLIO_STATISTICS
	API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_STRATEGY
	API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_STRATEGY
	API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK
	API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK
	API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK_CATEGORY
	API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK_CATEGORY
	API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_PORTFOLIO_STATS
	API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_PORTFOLIO_STATS
	API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_ATTRIBUTES
	API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_ATTRIBUTES
	API_REQUEST_MORNINGSTAR_GROWTH_10K
	API_RECEIVE_MORNINGSTAR_GROWTH_10K
	API_RECEIVE_MORNINGSTAR_RISK_MEASURES
	API_REQUEST_MORNINGSTAR_RISK_MEASURES
	API_RECEIVE_MORNINGSTAR_PEERS
	API_REQUEST_MORNINGSTAR_PEERS
	API_RECEIVE_MORNINGSTAR_ANNUAL_PERFORMANCE
	API_REQUEST_MORNINGSTAR_ANNUAL_PERFORMANCE
	API_RECEIVE_MORNINGSTAR_PERFORMANCE
	API_REQUEST_MORNINGSTAR_PERFORMANCE
	API_REQUEST_MORNINGSTAR_ASSET_ALLOCATION
	API_RECEIVE_MORNINGSTAR_ASSET_ALLOCATION
	API_RECEIVE_MORNINGSTAR_SECTOR_ALLOCATION
	API_REQUEST_MORNINGSTAR_SECTOR_ALLOCATION
	API_RECEIVE_MORNINGSTAR_TOP_HOLDINGS
	API_REQUEST_MORNINGSTAR_TOP_HOLDINGS
	API_REQUEST_MORNINGSTAR_COMPANY_INFORMATION
	API_RECEIVE_MORNINGSTAR_COMPANY_INFORMATION
	API_CIBC_REQUEST_MORNINGSTAR_FUND_DETAILS
	API_CIBC_RECEIVE_MORNINGSTAR_FUND_DETAILS
	API_CIBC_REQUEST_MORNINGSTAR_TOP_HOLDINGS
	API_REQUEST_MORNINGSTAR_ANNUAL_RETURN_DATA
	API_RECIEVE_MORNINGSTAR_ANNUAL_RETURN_DATA
	API_REQUEST_MORNINGSTAR_PROSPECTUS_FEES
	API_RECEIVE_MORNINGSTAR_PROSPECTUS_FEES

	API_CIBC_RECEIVE_TRAILING_RETURNS_CHART
	API_CIBC_REQUEST_TRAILING_RETURNS_CHART
	API_CIBC_HANDLE_TRAILING_RETURNS_CHART_LOADER
	API_CIBC_RECEIVE_PREMIUM_DISCOUNT_CHART
	API_CIBC_HANDLE_PREMIUM_DISCOUNT_CHART_LOADER
	API_CIBC_REQUEST_PREMIUM_DISCOUNT_CHART
	API_CIBC_REQUEST_GROWTH_10K_CHART
	API_CIBC_RECEIVE_GROWTH_10K_CHART
	API_CIBC_HANDLE_GROWTH_10K_CHART_LOADER
	API_CIBC_REQUEST_ANNUAL_PERFORMANCE_CHART
	API_CIBC_RECEIVE_ANNUAL_PERFORMANCE_CHART
	API_CIBC_HANDLE_ANNUAL_PERFORMANCE_CHART_LOADER


	API_REQUEST_RESEARCH_PDF
	API_REQUEST_RESEARCH_HISTORY_DOCUMENT
	API_REQUEST_RESEARCH_HISTORY_LINK
	API_REQUEST_RESEARCH_HISTORY
	API_HANDLE_RESEARCH_HISTORY_LOADER
	API_RECEIVE_RESEARCH_HISTORY
	API_REQUEST_RESEARCH_HISTORY_META_DATA
	API_RECEIVE_RESEARCH_HISTORY_META_DATA

	API_RECEIVE_CORPORATE_DIVIDENDS
	API_HANDLE_CORPORATE_DIVIDENDS_LOADER
	API_SINGLE_REQUEST_CORPORATE_DIVIDENDS

	API_CIBC_RECEIVE_DIVIDENDS_PER_SHARE_CHART
	API_CIBC_HANDLE_DIVIDENDS_PER_SHARE_CHART_LOADER
	API_CIBC_REQUEST_DIVIDENDS_PER_SHARE_CHART

	API_REQUEST_CURRENTRATIOS
	API_RECEIVE_CURRENTRATIOS
	API_CLEAR_CURRENTRATIOS
	API_HANDLE_CURRENTRATIOS_LOADER

	API_REQUEST_CURRENTRATIOS_PERCENTILE
	API_RECEIVE_CURRENTRATIOS_PERCENTILE
	API_CIBC_HANDLE_CURRENTRATIOS_PERCENTILE_LOADER

	API_CIBC_REQUEST_STOCK_EARNINGS
	API_CIBC_RECEIVE_STOCK_EARNINGS
	API_CIBC_HANDLE_STOCK_EARNINGS_LOADER
	API_CIBC_RECEIVE_STOCK_EARNINGS_ANNOUNCEMENTS
	API_CIBC_REQUEST_STOCK_EARNINGS_ANNOUNCEMENTS

	API_REQUEST_EQUITY_CLASSIFICATION
	API_RECEIVE_EQUITY_CLASSIFICATION

	API_REQUEST_REUTERS_EQUITIES_INFO
	API_CLEAR_REUTERS_EQUITIES_INFO
	API_RECEIVE_REUTERS_EQUITIES_INFO
	API_REQUEST_OFFICER_INFO
	API_RECEIVE_OFFICER_INFO
	API_HANDLE_REUTERS_EQUITIES_LOADER

	API_CIBC_REQUEST_STOCK_EARNINGS_CHART
	API_CIBC_RECEIVE_STOCK_EARNINGS_CHART
	API_CIBC_HANDLE_STOCK_EARNINGS_CHART_LOADER

	API_CIBC_REQUEST_FINANCIALS
	API_CIBC_RECEIVE_FINANCIALS
	API_HANDLE_FINANCIALS_LOADER

	API_CIBC_HANDLE_FUND_DATA_LOADER
	API_CIBC_RECEIVE_FUND_SNAPSHOT_DATA
	API_CIBC_RECEIVE_FUND_SNAPSHOT_DATA_FAIL
	API_CIBC_REQUEST_FUND_SNAPSHOT_DATA
	CLEAR_FUND_SNAPSHOT_DATA
	API_CIBC_RECEIVE_FUND_PERFORMANCE_DATA
	API_CIBC_RECEIVE_FUND_PERFORMANCE_DATA_FAIL
	API_CIBC_REQUEST_FUND_PERFORMANCE_DATA
	CLEAR_FUND_PERFORMANCE_DATA
	API_CIBC_RECEIVE_FUND_PORTFOLIO_DATA
	API_CIBC_RECEIVE_FUND_PORTFOLIO_DATA_FAIL
	API_CIBC_REQUEST_FUND_PORTFOLIO_DATA
	CLEAR_FUND_PORTFOLIO_DATA
	API_CIBC_RECEIVE_FUND_LIPPER_LEADER_DATA
	API_CIBC_RECEIVE_FUND_LIPPER_LEADER_DATA_FAIL
	API_CIBC_REQUEST_FUND_LIPPER_LEADER_DATA
	CLEAR_FUND_LIPPER_LEADER_DATA

	API_CIBC_REQUEST_FUND_INDEX_OVERVIEW_FUND
	API_CIBC_RECEIVE_FUND_INDEX_OVERVIEW_FUND
	API_CIBC_REQUEST_FUND_INDEX_OVERVIEW_INDEX
	API_CIBC_RECEIVE_FUND_INDEX_OVERVIEW_INDEX
	API_CIBC_HANDLE_FUND_INDEX_LOADER

	API_REQUEST_FUND_BASIC_LIPPER
	API_RECEIVE_FUND_BASIC_LIPPER
	API_REQUEST_FUND_PERFORMANCE_LIPPER
	CLEAR_FUND_PERFORMANCE_LIPPER
	API_RECEIVE_FUND_PERFORMANCE_LIPPER
	API_HANDLE_LIPPER_LOADER

	API_REQUEST_FUND_YIELD_LIPPER
	API_RECEIVE_FUND_YIELD_LIPPER

	API_REQUEST_FUND_LIPPER_LEADERS
	API_RECEIVE_FUND_LIPPER_LEADERS
	CLEAR_FUND_LIPPER_LEADERS

	API_REQUEST_FUND_LIPPER_ASSET_ALLOCATIONS
	API_RECEIVE_FUND_LIPPER_ASSET_ALLOCATIONS

	API_ADD_SYMBOLS_TO_COMPARE_LIST
	API_REQUEST_COMPARE_LIST_QUOTE
	API_RECEIVE_COMPARE_LIST_QUOTE
	API_REQUEST_COMPARE_MORNINGSTAR_PROSPECTUS
	API_RECEIVE_COMPARE_MORNINGSTAR_PROSPECTUS
	API_REQUEST_COMPARE_CORPORATE_DIVIDEND
	API_RECEIVE_COMPARE_CORPORATE_DIVIDEND
	API_REQUEST_COMPARE_MORNINGSTAR_RATINGS
	API_RECEIVE_COMPARE_MORNINGSTAR_RATINGS
	API_REQUEST_COMPARE_MORNINGSTAR_BENCHMARKS
	API_RECEIVE_COMPARE_MORNINGSTAR_BENCHMARKS
	API_REQUEST_COMPARE_MORNINGSTAR_BENCHMARK_CATEGORY
	API_RECEIVE_COMPARE_MORNINGSTAR_BENCHMARK_CATEGORY
	API_REQUEST_COMPARE_ATTRIBUTES
	API_RECEIVE_COMPARE_ATTRIBUTES
	API_REQUEST_COMPARE_SHARECLASS_DATA
	API_RECEIVE_COMPARE_SHARECLASS_DATA
	API_CIBC_REQUEST_COMPARE_FUND_DETAILS
	API_CIBC_RECEIVE_COMPARE_FUND_DETAILS
	API_REQUEST_COMPARE_FUND_STRATEGY
	API_RECEIVE_COMPARE_FUND_STRATEGY
	API_REQUEST_COMPARE_PORTFOLIO_STATS
	API_RECEIVE_COMPARE_PORTFOLIO_STATS
	API_REQUEST_COMPARE_PERFORMANCE
	API_RECEIVE_COMPARE_PERFORMANCE
	API_REQUEST_COMPARE_ANNUAL_PERFORMANCE
	API_RECEIVE_COMPARE_ANNUAL_PERFORMANCE
	API_REQUEST_COMPARE_RISK_MEASURES
	API_RECEIVE_COMPARE_RISK_MEASURES
	API_REQUEST_SIMILAR_ETFS
	API_RECEIVE_SIMILAR_ETFS
	API_REQUEST_COMPARE_PREMIUM_DISCOUNT
	API_RECEIVE_COMPARE_PREMIUM_DISCOUNT
	API_REQUEST_COMPARE_DIVIDEND_INFORMATION
	API_RECEIVE_COMPARE_DIVIDEND_INFORMATION
	API_REQUEST_COMPARE_ASSET_ALLOCATION
	API_RECEIVE_COMPARE_ASSET_ALLOCATION
	API_CIBC_REQUEST_COMPARE_TOP_HOLDINGS
	API_CIBC_RECEIVE_COMPARE_TOP_HOLDINGS
	API_HANDLE_COMPARELIST_LOADER
	API_REQUEST_COMPARE_GEOGRAPHIC_ALLOCATION
	API_RECEIVE_COMPARE_GEOGRAPHIC_ALLOCATION
	API_REQUEST_COMPARE_SECTOR_ALLOCATION
	API_RECEIVE_COMPARE_SECTOR_ALLOCATION
	DELETE_SYMBOL_FROM_COMPARE_LIST

	API_HANDLE_ALERTS_LOADER
	API_RECEIVE_ALERTS_PROFILE
	API_REQUEST_ALERTS_PROFILE
	API_RECEIVE_ALERTS_PROFILE_DESTINATIONS
	API_REQUEST_ALERTS_PROFILE_DESTINATIONS
	API_RECEIVE_ALERTS_SUBSCRIPTIONS
	API_REQUEST_ALERTS_SUBSCRIPTIONS
	API_RECEIVE_ALERTS_DELETE_DESTINATION
	API_REQUEST_ALERTS_DELETE_DESTINATION
	API_REQUEST_ALERTS_PUT_DESTINATION
	API_RECEIVE_ALERTS_PUT_DESTINATION
	API_RECEIVE_ALERTS_POST_PROFILE
	API_REQUEST_ALERTS_POST_PROFILE
	API_CIBC_REQUEST_SCREENER_COLUMNS_DISTINCT_LIST
	API_CIBC_RECEIVE_SCREENER_COLUMN_DISTINCT_LIST
	SET_SAVED_SCREEN_COUNT
	API_HANDLE_SCREENER_UTILS_LOADER
	API_CIBC_HANDLE_TODAYS_TOP_SECTORS_LOADER
	API_CIBC_REQUEST_TODAYS_TOP_SECTORS
	API_CIBC_RECEIVE_TODAYS_TOP_SECTORS
	API_RECEIVE_ALERTS_DELETE
	API_REQUEST_ALERTS_DELETE
	API_RECEIVE_ALERT_UPDATE
	API_REQUEST_ALERT_UPDATE
	API_CIBC_REQUEST_ALERT_UPDATE
	API_RECEIVE_ALERTS_ADD
	API_REQUEST_ALERTS_ADD

	API_CIBC_RECEIVE_LEGACY_QUOTE
	API_CIBC_REQUEST_LEGACY_QUOTE

	API_CIBC_HANDLE_UPGRADES_DOWNGRADES_LOADER
	API_CIBC_REQUEST_UPGRADES_DOWNGRADES
	API_CIBC_RECEIVE_UPGRADES_DOWNGRADES

	API_CIBC_REQUEST_FUND_PROSPECTUS_DATA
	API_CIBC_RECEIVE_FUND_PROSPECTUS_DATA

	RESET_COMPARE_LIST

	SET_CUSTOM_SEARCH_INPUTS
	API_FAILURE
	API_REQUEST

	API_REQUEST_RESEARCH_FILE_SIZE
	API_RECEIVE_RESEARCH_FILE_SIZE
	ADD_SELECTED_OPTION

	API_CIBC_REQUEST_BUZZNEWS
	API_CIBC_RECEIVE_BUZZNEWS
	API_CIBC_HANDLE_BUZZNEWS_LOADER

	API_REQUEST_MARKETS_OVERVIEW_CHART
	API_RECEIVE_MARKETS_OVERVIEW_CHART
	API_HANDLE_MARKETS_OVERVIEW_CHART_LOADER
`);
