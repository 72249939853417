/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingGrowth10kChart: false,
	chartData: {}
};

const requestGrowth10KChart = state => {
	return Object.assign({}, state, {
		fetchingGrowth10kChart: true
	});
};

const receiveGrowth10KChart = (state, action) => {
	return Object.assign({}, state, {
		fetchingGrowth10kChart: false,
		chartData: action.response.data
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_RECEIVE_GROWTH_10K_CHART]: receiveGrowth10KChart,
	[Types.API_CIBC_REQUEST_GROWTH_10K_CHART]: requestGrowth10KChart,
	[Types.API_CIBC_HANDLE_GROWTH_10K_CHART_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
