/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingDividendsPerShareChart: false,
	chartData: {}
};

const requestDividendsPerShareChart = state => {
	return Object.assign({}, state, {
		fetchingDividendsPerShareChart: true
	});
};

const receiveDividendsPerShareChart = (state, action) => {
	return Object.assign({}, state, {
		fetchingDividendsPerShareChart: false,
		chartData: action.response.data
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_RECEIVE_DIVIDENDS_PER_SHARE_CHART]: receiveDividendsPerShareChart,
	[Types.API_CIBC_REQUEST_DIVIDENDS_PER_SHARE_CHART]: requestDividendsPerShareChart,
	[Types.API_CIBC_HANDLE_DIVIDENDS_PER_SHARE_CHART_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
