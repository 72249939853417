/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingAlertsProfile: false,
	fetchingAlertsDestination: false,
	fetchingAlertsSubscriptions: false,
	fetchingDeleteDestination: false,
	fetchingPutDestination: false,
	fetchingPostProfile: false,
	fetchingDeleteAlerts: false,
	fetchingUpdateAlert: false,
	fetchingAddAlerts: false,
	hasDestinations: false,
	subscriptions: {},
	destinations: {},
	profile: {},
	market: {},
	stock: [],
	watchlist: [],
	analystReport: []
};

const requestAlertsProfile = state => {
	return Object.assign({}, state, {
		fetchingAlertsProfile: true
	});
};

const receiveAlertsProfile = (state, action) => {
	return Object.assign({}, state, {
		fetchingAlertsProfile: false,
		profile: action.response.data
	});
};

const requestAlertsDestinations = state => {
	return Object.assign({}, state, {
		fetchingAlertsDestination: true
	});
};

const receiveAlertsDestinations = (state, action) => {
	let hasDestinations = false;
	const { data } = action.response;
	const { items } = data || {};
	if (items && items.length) {
		items.forEach(destination => {
			if (!hasDestinations) {
				hasDestinations = destination.address && destination.address !== '';
			}
		});
	}

	return Object.assign({}, state, {
		fetchingAlertsDestination: false,
		destinations: action.response.data,
		hasDestinations
	});
};

const requestAlertsSubscriptions = state => {
	return Object.assign({}, state, {
		fetchingAlertsSubscriptions: true,
		subscriptions: {},
		market: {},
		stock: [],
		watchlist: [],
		analystReport: [],
		alertsByXid: {}
	});
};

const receiveAlertsSubscriptions = (state, action) => {
	const { data } = action.response;
	const { items } = data || {};
	const market = {};
	const alertsByXid = {};
	const stock = [];
	const watchlist = [];
	let analystReport = [];
	const uniqueItemIds = [];

	if (items && items.length) {
		items.forEach(item => {
			const { alertCategory, criteria, itemId } = item || {};
			const { exchangeCode, venueXid } = criteria || {};

			// Each destination has its own item but shared itemId so we will filter out the duplicates
			if (uniqueItemIds.indexOf(itemId) > -1) {
				return;
			}

			uniqueItemIds.push(itemId);

			switch (alertCategory) {
				case 'Price':
				case 'Volume':
				case 'Earnings':
				case 'News':
					if (!alertsByXid[venueXid]) {
						alertsByXid[venueXid] = [item];
					} else {
						alertsByXid[venueXid].push(item);
					}
					stock.push(item);
					break;
				case 'Market':
					if (market[exchangeCode]) {
						market[exchangeCode].push(item);
					} else {
						market[exchangeCode] = [item];
					}
					break;
				case 'Portfolio':
					watchlist.push(item);
					break;
				case 'Research':
					analystReport.push(item);
					break;
				default:
					break;
			}
		});
	}

	// mapping saved search name/values and sorting by most recent.
	if (analystReport.length) {
		analystReport = analystReport.map(alert => {
			const { attributes } = alert || {};
			const attributesMap = {};
			if (attributes && attributes.length) {
				attributes.forEach(att => {
					const { name, value } = att || {};
					attributesMap[name] = JSON.parse(value);
				});
			}
			return { ...alert, attributesMap };
		}).sort((a, b) => {
			return ((a.insertDate < b.insertDate) ? 1 : -1);
		});
	}

	return Object.assign({}, state, {
		fetchingAlertsSubscriptions: false,
		subscriptions: data,
		market,
		stock,
		watchlist,
		analystReport,
		alertsByXid
	});
};

const receiveDeleteDestination = state => {
	return Object.assign({}, state, {
		fetchingDeleteDestination: false
	});
};

const requestDeleteDestination = state => {
	return Object.assign({}, state, {
		fetchingDeleteDestination: true
	});
};

const receivePutDestination = state => {
	return Object.assign({}, state, {
		fetchingPutDestination: false
	});
};

const requestPutDestination = state => {
	return Object.assign({}, state, {
		fetchingPutDestination: true
	});
};

const receivePostProfile = state => {
	return Object.assign({}, state, {
		fetchingPostProfile: true
	});
};

const requestPostProfile = state => {
	return Object.assign({}, state, {
		fetchingPostProfile: false
	});
};

const receiveDeleteAlerts = state => {
	return Object.assign({}, state, {
		fetchingDeleteAlerts: false
	});
};

const requestDeleteAlerts = state => {
	return Object.assign({}, state, {
		fetchingDeleteAlerts: true
	});
};

const receiveUpdateAlert = state => {
	return Object.assign({}, state, {
		fetchingUpdateAlert: false
	});
};

const requestUpdateAlert = state => {
	return Object.assign({}, state, {
		fetchingUpdateAlert: true
	});
};

const requestUpdateAnalystResearchAlert = state => {
	return Object.assign({}, state, {
		fetchingUpdateAlert: true
	});
};

const receiveAddAlerts = state => {
	return Object.assign({}, state, {
		fetchingAddAlerts: false
	});
};

const requestAddAlerts = state => {
	return Object.assign({}, state, {
		fetchingAddAlerts: true
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_ALERTS_PROFILE]: requestAlertsProfile,
	[Types.API_RECEIVE_ALERTS_PROFILE]: receiveAlertsProfile,
	[Types.API_REQUEST_ALERTS_PROFILE_DESTINATIONS]: requestAlertsDestinations,
	[Types.API_RECEIVE_ALERTS_PROFILE_DESTINATIONS]: receiveAlertsDestinations,
	[Types.API_REQUEST_ALERTS_SUBSCRIPTIONS]: requestAlertsSubscriptions,
	[Types.API_RECEIVE_ALERTS_SUBSCRIPTIONS]: receiveAlertsSubscriptions,
	[Types.API_REQUEST_ALERTS_DELETE_DESTINATION]: requestDeleteDestination,
	[Types.API_RECEIVE_ALERTS_DELETE_DESTINATION]: receiveDeleteDestination,
	[Types.API_REQUEST_ALERTS_PUT_DESTINATION]: requestPutDestination,
	[Types.API_RECEIVE_ALERTS_PUT_DESTINATION]: receivePutDestination,
	[Types.API_RECEIVE_ALERTS_POST_PROFILE]: receivePostProfile,
	[Types.API_REQUEST_ALERTS_POST_PROFILE]: requestPostProfile,
	[Types.API_RECEIVE_ALERTS_DELETE]: receiveDeleteAlerts,
	[Types.API_REQUEST_ALERTS_DELETE]: requestDeleteAlerts,
	[Types.API_RECEIVE_ALERT_UPDATE]: receiveUpdateAlert,
	[Types.API_REQUEST_ALERT_UPDATE]: requestUpdateAlert,
	[Types.API_CIBC_REQUEST_ALERT_UPDATE]: requestUpdateAnalystResearchAlert,
	[Types.API_REQUEST_ALERTS_ADD]: requestAddAlerts,
	[Types.API_RECEIVE_ALERTS_ADD]: receiveAddAlerts,
	[Types.API_HANDLE_ALERTS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
