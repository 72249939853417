/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* global window, MD, document */
import ResizeObserver from 'resize-observer-polyfill';

class IframeCommunication {
	constructor() {
		this.init();
	}

	init() {
		// bind listeners
		this.bindListeners();
		this.actions = {
			advancedChart: 'advancedChart',
			keepAlive: 'keepAlive',
			tokenExpired: 'tokenExpired',
			trade: 'trade',
			iframeSize: 'iframeSize',
			navigation: 'navigation',
			modal: 'modal',
			scrollTo: 'scrollTo',
			checkParentFrame: 'checkParentFrame',
			parentFrameValid: 'parentFrameValid'
		};
		this.minListHeight = 0;
		this.startKeepAliveTimer();
	}

	bindListeners() {
		const self = this;
		// messages from parent
		window.addEventListener('message', event => self.receiveMessage(event, self), false);
		// listen for body resize events
		const ro = new ResizeObserver(
			entries => this.handleResize(entries, this)
		);
		ro.observe(document.body);
	}

	bindIframeListener(iframe) {
		this.tcFrame = iframe;
	}

	bindTRIframeListener(iframe) {
		this.tipranksFrame = iframe;
	}

	sendMessage(message) {
		if (MD && MD.environment !== 'production') {
			console.log(message);
		}
		window.parent.postMessage(message, '*');
	}

	receiveMessage(event, self) {
		if (event && event.isTrusted && event.data) {
			if (!event.data.action && event.origin) {
				if (event.origin.indexOf('tipranks') !== -1) {
					if (event.data.height) {
						event.data.action = 'trIframeSize';
					}
				}
			}

			if (event.data.action) {
				if (MD && MD.environment !== 'production') {
					const message = JSON.stringify(event.data, undefined, 4);
					console.log(message);
				}

				self.handleCommand(event.data, event.origin);
			}
		}
	}

	handleCommand(message, origin) {
		switch (message.action) {
			case 'language':
				this.handleLanguage(message);
				break;
			case 'addToWatchlist':
				console.log(' Add to watchlist not implemented');
				break;
			case 'iframeSize':
				this.handleTcFrameSize(message, origin);
				break;
			case 'trIframeSize':
				this.handleTRIFrameSize(message, origin);
				break;
			case 'modalPosition':
				this.handleModalPosition(message);
				break;
			case 'trade':
			case 'keepAlive':
			case 'modal':
				this.handleTcMessages(message, origin);
				break;
			case 'advancedChartClosed':
				this.handleAdvancedChartClose(message);
				break;
			case 'parentFrameValid':
				this.handleParentFrameCheck(message);
				break;
			default:
				console.log('This command does not have a handler');
				break;
		}
	}

	handleTcMessages(message, origin) {
		// passing on messages from the TC iframe to the parent CIBC frame
		if (message && origin && origin.indexOf('recognia') > -1) {
			if (MD && MD.environment !== 'production') {
				console.log('passing message from TC: ', message);
			}
			this.sendMessage(message);
		}
	}

	handleTcFrameSize(message, origin) {
		if (message && origin && origin.indexOf('recognia') > -1) {
			this.tcFrame.setAttribute('height', message.height);
		}
	}

	handleTRIFrameSize(message, origin) {
		if (message && origin && origin.indexOf('tipranks') > -1) {
			if (message.height && this.tipranksFrame) {
				this.tipranksFrame.setAttribute('height', message.height + 20);
			}
		}
	}

	startKeepAliveTimer() {
		this.lastActiveTime = Date.now();
		window.setInterval(() => {
			const elapsed = Date.now() - this.lastActiveTime;
			// if the last activity was less than 5 min ago
			// send keep alive message
			if (elapsed < 300000) {
				this.sendMessage({
					action: this.actions.keepAlive
				});
			}
		}, 300000);
	}

	handleKeepAlive(event) {
		if (event) {
			this.lastActiveTime = Date.now();
		}
	}

	handleLanguage(message) {
		console.log('the language handler');
		console.log(message);
	}

	handleResize(entries, self) {
		window.clearTimeout(this.iframeSizeWait);
		this.resizeWait = window.setTimeout(() => {
			entries.forEach(entry => {
				const {
					height
				} = entry.contentRect;

				if (height !== 0 && self.lastHeight !== height && height > 315) {
					let frameHeight = Math.ceil(height) + 20;
					if (frameHeight < self.minListHeight + 20) {
						frameHeight = Math.ceil(self.minListHeight) + 20;
					}

					self.lastHeight = frameHeight;
					self.sendMessage({
						action: self.actions.iframeSize,
						height: frameHeight
					});
				}
			});
		}, 200);
	}

	handleDropdownIframeSize(height) {
		this.minListHeight = height;
		const { lastHeight, actions, sendMessage } = this;
		const body = document.getElementById('mod-body');
		const bodyHeight = body ? body.offsetHeight : 0;

		if (lastHeight < height + 60 && height > 315) {
			this.lastHeight = Math.ceil(height) + 60;
			sendMessage({
				action: actions.iframeSize,
				height: Math.ceil(height) + 60
			});
		}

		// When the menu is closed setting the height back to the height of the body
		if (height === 0 && (bodyHeight + 20) < lastHeight) {
			this.lastHeight = bodyHeight + 60;
			sendMessage({
				action: actions.iframeSize,
				height: bodyHeight + 60
			});
		}
	}

	handleAdvancedChartClose() {
		if (this.focusRef) {
			this.focusRef.focus();
		}
	}

	openAdvancedChart(xid, country, symbol, symbolDescription, page, focusRef) {
		const {
			actions,
			sendMessage
		} = this;
		this.focusRef = focusRef;
		sendMessage({
			action: actions.advancedChart,
			symbol,
			country,
			xid,
			symbolDescription,
			page
		});
	}

	tradeOption(exchange, symbol, strike, optionType, expYear, expMonth, expDay, page) {
		const {
			actions,
			sendMessage
		} = this;
		sendMessage({
			action: actions.trade,
			page,
			type: 'D',
			symbol,
			exchange,
			strike,
			optionType,
			expYear,
			expMonth,
			expDay
		});
	}

	tradeMutualFund(symbol, page) {
		const {
			actions,
			sendMessage
		} = this;
		sendMessage({
			action: actions.trade,
			symbol,
			type: 'M',
			page
		});
	}

	tradeStockEtf(exchange, symbol, page, type = 'E') {
		const {
			actions,
			sendMessage
		} = this;
		sendMessage({
			action: actions.trade,
			type,
			exchange,
			symbol,
			page
		});
	}

	tokenExpired(goTo) {
		const {
			actions,
			sendMessage
		} = this;
		sendMessage({
			action: actions.tokenExpired,
			goTo
		});
	}

	openModal(isOpen, modalPositionCallBack) {
		const {
			actions,
			sendMessage,
			lastHeight
		} = this;
		this.modalPositionCallBack = modalPositionCallBack;

		const modal = document.getElementById('modal-container') && document.getElementById('modal-container').offsetHeight;
		const body = document.getElementById('mod-body').offsetHeight;

		if (lastHeight < (modal + 60) && isOpen) {
			sendMessage({
				action: actions.iframeSize,
				height: (Math.ceil(modal) + 60)
			});
		}

		if (!isOpen && body > 315) {
			sendMessage({
				action: actions.iframeSize,
				height: Math.ceil(body)
			});
		}

		sendMessage({
			action: actions.modal,
			isOpen
		});
	}

	handleModalPosition(message) {
		const {
			modalPositionCallBack
		} = this;
		const {
			scrollY
		} = message;
		if (modalPositionCallBack && scrollY) {
			modalPositionCallBack(scrollY);
		}
	}

	sendNavigation(location) {
		const {
			actions,
			sendMessage
		} = this;
		this.clearValidateParentTimer();
		sendMessage({
			action: actions.navigation,
			location
		});
	}

	scrollTo(distanceFromTop) {
		const {
			actions,
			sendMessage
		} = this;

		sendMessage({
			action: actions.scrollTo,
			distanceFromTop
		});
	}

	handleParentFrameCheck(message) {
		const {
			valid
		} = message;
		console.log('Parent frame message recieved');

		if (valid) {
			const theValidFrameTime = window.performance.now();
			console.log('parentFrameValid at: ', theValidFrameTime);
			console.log('Time taken to validate parent frame: ', theValidFrameTime - this.sentCheckParentFrame);
			this.waitingForParent = false;
			this.clearValidateParentTimer();
		}
	}

	clearValidateParentTimer() {
		const {
			clearTimeout
		} = window;
		if (this.parentCheckTimer) {
			clearTimeout(this.parentCheckTimer);
		}
	}

	validateParentFrame(cibcRedirectUrl) {
		const {
			actions,
			sendMessage
		} = this;
		const {
			setInterval,
			location,
			MD
		} = window;
		const {
			environment
		} = MD || {};
		this.waitingForParent = true;

		this.sentCheckParentFrame = window.performance.now();
		sendMessage({
			action: actions.checkParentFrame,
			timestamp: this.sentCheckParentFrame
		});
		console.log('Sending checkParentFrame at: ', this.sentCheckParentFrame);

		this.clearValidateParentTimer();

		this.parentCheckTimer = setInterval(() => {
			if (this.waitingForParent && environment !== 'localhost') {
				const { pathname } = location || {};
				window.location.href = `${cibcRedirectUrl}/#/txn/bridge/quotesResearch?mdredirectpath=${pathname}`;
			} else {
				this.clearValidateParentTimer();
			}
		}, 5000);
	}
}

export default IframeCommunication;
