export const translations = {
	en: {
		locale: 'English',
		messages: {
			'': '',
			'1d': '1d',
			'1dHidden': '1 day',
			'1week': '1 week',
			'1m': '1m',
			'1month': '1 month',
			'1monthPct': '1 month %',
			'1monthReturn': '1 month return',
			'1monthReturnDescription': 'The total return of an ETF, including change in price and any re-invested distributions over a one-month period.',
			'1star': '1 Star',
			'2days': '2 days',
			'2months': '2 months',
			'2stars': '2 Stars',
			'3stars': '3 Stars',
			'4stars': '4 Stars',
			'5d': '5d',
			'5days': '5 days',
			'5dHidden': '5 day',
			'5stars': '5 Stars',
			'3m': '3m',
			'3month': '3 month',
			'3monthPct': '3 month %',
			'3monthReturn': '3 month return',
			'3monthReturnDescription': 'The total return of an ETF, including change in price and any re-invested distributions over a three-month period.',
			'3months': '3 months',
			'3mHidden': '3 months',
			'3w': '3w',
			'3wHidden': '3 weeks',
			'1y': '1y',
			'1yHidden': '1 year',
			'1yPct': '1 year %',
			'3yPct': '3 year %',
			'5y': '5y',
			'5yHidden': '5 years',
			'6m': '6m',
			'6month': '6 month',
			'6monthReturn': '6 month return',
			'6monthReturnDescription': 'The total return of an ETF, including change in price and any re-invested distributions over a six-month period.',
			'6months': '6 months',
			'3monthPremiumDiscount': '3 month premium/discount data',
			'1year': '1 Year',
			'1yearReturn': '1 year return',
			'1yearReturnDescription': 'The average total annualized return of an ETF, including change in price and any re-invested distributions over a one-year period.',
			'1yearLower': '1 year',
			'3year': '3 year',
			'3yearRating': '3 year rating',
			'3yearRatingDescription': 'Morningstar\'s rating based on the ETF\'s risk-adjusted return over a three-year period.',
			'3yearReturn': '3 year return',
			'3yearReturnDescription': 'The average total annualized return of an ETF, including change in price and any re-invested distributions over a three-year period.',
			'3yearReturnRatingDescription': 'The ETF\'s return rating over a three-year period.',
			'3yearRisk': '3 year risk',
			'3yearRiskDescription': 'The ETF\'s risk rating over a three-year period.',
			'3yearLower': '3 year',
			'3years': '3 years',
			'5year': '5 year',
			'5yearRating': '5 year rating',
			'5yearRatingDescription': 'Morningstar\'s rating based on the ETF\'s risk-adjusted return over a five-year period.',
			'5yearReturn': '5 year return',
			'5yearReturnDescription': 'The average total annualized return of an ETF, including change in price and any re-invested distributions over a five-year period.',
			'5yearReturnRatingDescription': 'The ETF\'s return rating over a five-year period.',
			'5yearRisk': '5 year risk',
			'5yearRiskDescription': 'The ETF\'s risk rating over a five-year period.',
			'5yearLower': '5 year',
			'5years': '5 years',
			'10year': '10 year',
			'10yearRating': '10 year rating',
			'10yearRatingDescription': 'Morningstar\'s rating based on the ETF\'s risk-adjusted return over a ten-year period.',
			'10yearReturn': '10 year return',
			'10yearReturnDescription': 'The average total annualized return of an ETF, including change in price and any re-invested distributions over a ten-year period.',
			'10yearReturnRatingDescription': 'The ETF\'s return rating over a ten-year period.',
			'10yearRisk': '10 year risk',
			'10yearRiskDescription': 'The ETF\'s risk rating over a ten-year period.',
			'10yearLower': '10 year',
			'10years': '10 years',
			'52WeekRange': '52 Week range',
			'52WeekRangeLowerCase': '52 week range',
			'52WeekRangeSr': '52 week range',
			'52WH': '52 W H',
			'52WL': '52 W L',
			'7days': '7 days',
			'14days': '14 days',
			'30days': '30 days',
			'60days': '60 days',
			'90days': '90 days',
			'1WeekReturn': '1 week return',
			'1MonthReturn': '1 month return ',
			'3MonthReturn': '3 month return',
			'6MonthReturn': '6 month return',
			'1YearReturn': '1 year return',
			'3YearReturnAnn': '3 year return (annualized) ',
			'3YearsReturn': '3 years return',
			'5YearReturnAnn': '5 year return (annualized)',
			'5YearsReturn': '5 years return',
			'10YearReturnAnn': '10 year return (annualized) ',

			'--': '--',
			__RECENT__: 'Recent quotes',
			'5StarEquityETFs': '5-Star Equity ETFs',
			'5StarEquityETFs_desc': 'ETFs with a 5-star rating from Morningstar that invest in Canadian, U.S. and international equity holdings.',
			above: 'above',
			aboveAverage: 'Above average',
			above52WeekHigh: 'Above 52 week high',
			above52WeekHighDescription: 'ETFs that reached a new 52 week high.',
			accountingChange: 'Accounting Change',
			accountsPayable: 'Accounts Payable',
			accountsReceivable: 'Accounts Receivable',
			accptOutstanding: 'Acceptances Outstanding',
			accruedExpenses: 'Accrued Expenses',
			accruedInvestIncome: 'Accrued Investment Income',
			accumGoodwillAmort: 'Accum. Goodwill Amort.',
			accumIntangibleAmort: 'Accum. Intangible Amort.',
			accumulatedDepletion: 'Accumulated Depletion',
			accumulatedDepreciation: 'Accumulated Depreciation',
			additionalPaidInCap: 'Additional Paid-In Capital',
			acqBusiness: 'Acquisition of Business',
			actionCantBeUndone: 'This action can\'t be undone',
			add: 'Add',
			addCriteria: 'Add criteria',
			addCriteriaSRDirections: 'To navigate to criteria categories, use the tab key. To navigate into each category, use the right arrow key. Use the up and down arrow keys to navigate within the criteria list.',
			additionalPerShare: 'Additional per share data',
			addNewAlert: 'Add New Alert (Enter Symbol)',
			addOption: 'Add Option',
			addOptionSymbol: 'Add {symbol} to quote lists',
			addOptionSymbolAndGoTo: 'Add {symbol} and go to quote lists',
			address: 'Address',
			addSelectedToWatchList: 'Add Selected to Watch List',
			addToWatchListAndCloseDialogue: 'Add to watch list and close dialogue',
			addSymbolsUsingTheSearchAbove: 'Add symbols using the search above.',
			addSymbolsUsingTheSearchAboveOrSelectOpton: 'Add symbols using the search above or select an option below.',
			addToQuoteList: 'Add to quote list',
			addToWatchList: 'Add to watch list',
			addToWatchlistSr: 'Add selected to watch list in a new dialogue',
			addToWatchlistSymbolSr: 'Add {symbol} to watch list',
			Advanced: 'Advanced',
			advancedChart: 'Advanced chart',
			advancedChartSr: '{symbol} advanced chart in a new dialogue',
			advancedChartSrOnly: '{symbol} advanced chart in a new dialogue',
			advertExpense: 'Advertising Expense',
			'aerospace&defense': 'Aerospace & Defense',
			age: 'Age',
			alertType: 'Alert type',
			alertTypeSet: 'Alert type set',
			alertsAgreement: 'Alerts Agreement',
			alertsAgreementHidden: 'Alerts agreement pdf in a new browser tab',
			alertsPref: 'Alerts preferences',
			alertsPrefDisclaimer1: 'If you subscribe to alerts by providing your email address and clicking on the save button below, you are agreeing to the terms of our alerts agreement and are bound by its terms and conditions.',
			alertsPrefDisclaimer2: 'Due to text message size limitations, you may receive multiple text messages for each alert. Standard text messaging fees may apply based on your agreement with your provider.',
			alertsPrefEmailNote: 'Email changes for Technical Alerts can only be made in Email address 1.',
			alertsPrefMsg0: 'You can receive alerts by email and SMS text message.',
			alertsPrefMsg1: 'To set up an email alert, you must provide at least one email address. You can select to receive emails as either HTML or text messages.',
			alertsPrefMsg2: 'By providing your email address and clicking on the Save button below, you are agreeing to the Alerts agreement and are bound by its terms and conditions. Refer to the link at the bottom of the page to read this agreement.',
			alertsPrefMsg3: 'If you’d like to receive text message alerts, please provide a mobile phone number in addition to an email address.',
			alertsPrefMsg4: 'You can also use the ‘vacation stop’ option to stop receiving alerts for a specified period of time.',
			alertsPrefMsg5: 'To save your preferences, select the Save button at the bottom of the page.',
			amortAcqCosts: 'Amortiz of Acquist Costs',
			amortDeferPolicyAcqCosts: 'Amort Defer Policy Acq Csts',
			amortIntangibles: 'Amortization of Intangibles',
			amortization: 'Amortization',
			amortOAcqCosts: 'Amortization of Acq Costs',
			amortOfAcqCosts: 'Amortization of Acq Costs',
			amortOfIntangibles: 'Amortization of Intangibles',
			amortPolicyAcquisitionCost: 'Amort. Of Policy Acquisition Costs',
			all: 'All',
			allcanada: 'All',
			allMarketDisclaimer: 'Trade prices and quotes are not sourced from all markets.',
			allunitedstates: 'All',
			allocation: 'Allocation',
			allowFundsDurConst: 'Allow Funds Used Dur Const',
			allSectors: 'All sectors',
			allFundType: 'All fund types',
			allFundPromoters: 'All fund promoters',
			allKeywords: 'All keywords',
			alpha: 'Alpha',
			alphaYear: 'Alpha ({year} year)',
			analystConsensus: 'Analyst consensus',
			analystConsensusTooltip: 'Analyst consensus is based on sell-side analysts’ ratings issued within the past 3 months.',
			MSDPrimaryIndexAlphaY1Ptile: 'Alpha (1 year)',
			MSDPrimaryIndexAlphaY3Ptile: 'Alpha (3 year)',
			MSDPrimaryIndexAlphaY5Ptile: 'Alpha (5 year)',
			MSDPrimaryIndexAlphaY10Ptile: 'Alpha (10 year)',
			MSDPrimaryIndexAlphaY1: 'Alpha (1 year)',
			MSDPrimaryIndexAlphaY3: 'Alpha (3 year)',
			MSDPrimaryIndexAlphaY5: 'Alpha (5 year)',
			MSDPrimaryIndexAlphaY10: 'Alpha (10 year)',
			alphaDescription: 'One-, three-, five- or ten-year alpha represents the excess return of an ETF relative to the risk adjusted return of its benchmark over a specified period. Alpha may be used as a measure to represent the value that a portfolio manager adds.',
			alphaVsCategory: 'Alpha vs. {category}',
			alphaVsCategoryTooltip: 'Alpha represents an ETF’s excess return relative to its benchmark’s risk-adjusted return over a stated period of time. Alpha can be used to measure value added by a portfolio manager.',
			alreadyInWatchList: 'The symbol is already in this list.',
			alreadyInWatchList2: '{symbols} are already in your watch list',
			alternative: 'Alternative',
			alternativeenergy: 'Alternative Energy',
			analysts: 'Analysts',
			analystRating: 'Analyst Ratings',
			analystReportsPreview: 'The Analyst Report custom search section isn’t quite done, but will be soon. We’ll have a new and improved format, where you’ll find all your saved custom search information.',
			analystEstimate: 'Analyst estimates',
			analystReports: 'Analyst Reports',
			analystReportAlerts: 'The Analyst Reports alerts section isn’t quite done, but will be soon. When it’s ready, we’ll have a new and improved format where you’ll find all your saved alerts information. </br><strong>Note:</strong> Existing alerts will be on hold until our new format is up and running.',
			analystReportsDisclaimer1: 'The investment research information provided to you through this Web site is general only, it is not intended as specific investment advice and you should not rely on it as such.',
			analystReportsDisclaimer2: 'Research reports from entities other than CIBC World Markets Inc. are not prepared subject to Canadian disclosure requirements.',
			analystReportsDisclaimer3: 'Written or electronic Research Reports prepared by the CIBC World Markets and CIBC Wood Gundy divisions of CIBC World Markets Inc. will be made available for external distribution contemporaneously to all customers who are entitled to receive these Reports. Material that is not security specific, such as Economics & Strategy or Bond Index material, is generally made available to the public at large. Most material that is industry and/or security specific, such as Fixed Income Research, Equity Research, and Technical & Quantitative Analysis is considered to be proprietary and is only made available to institutional clients, to CIBC World Markets retail brokers for distribution to their clients, and to clients of CIBC affiliates who have been given access to such reports online. The most recent Report will be posted on this website and will be available for access by all customers who are entitled to receive the Reports. These Materials are intended for Canadian residents only.',
			annualEps: 'Annual EPS',
			announcementDate: 'Announcement Date',
			annualizedReturnsTooltip: 'How {symbol} has performed over multiple periods',
			analystOpinions: 'Analyst Opinions',
			annual: 'Annual',
			annualOption: 'Annual',
			semiannual: 'Semi-annual',
			annualIncomeStatementItem: 'Annual income statement item',
			annualBalanceSheetItem: 'Annual balance sheet item',
			annualCashFlowItem: 'Annual cash flow item',
			annualDividend: 'Annual dividend',
			annualIncomeStatement: '',
			annualPerformance: 'Annual performance',
			annualPerformanceChartDataFollows: 'Annual performance chart data follows',
			annualPerformanceData: 'Annual Performance Data',
			annualized: 'annualized',
			annualizedReturns: 'Annualized returns',
			annualizedPerformance: 'Annualized performance',
			anyKeywords: 'Any keywords',
			apply: 'Apply',
			applySrOnly: 'Apply search filters',
			articleByDate: '{article} article by date',
			ascending: 'Ascending',
			ask: 'Ask',
			askLots: 'Ask/Lots',
			askLotsCapitalization: 'Ask/lots',
			askLotsCapitalization2: 'Ask/Lots',
			askSharesCapitalization: 'Ask/shares',
			askSharesCapitalization2: 'Ask/Shares',
			askLotsSrOnly: 'ask lots',
			asOf: 'As of',
			dailyMarketAsOf: 'Daily Markets as of',
			asOfMarketClose: 'As of market close on',
			asOfPreviousClose: 'As of Previous Close',
			asOfPreviousClose2: 'As of previous close',
			asOfPreviousMonthEnd: 'Data as of previous month end',
			assets: 'Assets',
			assetClasses: 'Asset classes',
			assetClass: 'Asset class',
			assetAllocation: 'Asset allocation',
			assetAllocationInfo: ' Asset allocation information as of last closure provided by Morningstar.',
			'automobiles&parts': 'Automobiles & Parts',
			availableWatchlists: 'Available Watchlists',
			average: 'Average',
			averageCost: 'Average Cost',
			averageVolume10Day: 'Average volume (10 day)',
			averageVolume10DayDescription: 'The average number of shares traded over the past 10 trading days.',
			balanced: 'Balanced',
			balanceSheet: 'Balance sheet',
			banks: 'Banks',
			Basic: 'Basic',
			basicRanges: 'Basic ranges: ',
			basicRangesSR: 'Select the basic ranges radio button to choose values, or the specific range radio button to enter a value or values.',
			basicmaterials: 'Basic materials',
			MSDStockSectorBasicMaterials: 'Sector Basic materials',
			basicNormalEPS: 'Basic Normalized EPS',
			basicPrimarEPSExclExtraItems: 'Basic/Primary EPS Excl. Extra. Items',
			basicPrimarEPSInclExtraItems: 'Basic/Primary EPS Incl. Extra. Items',
			basicPrimarWeightAvShares: 'Basic/Primary Weighted Average Shares',
			below: 'below',
			belowAverage: 'Below average',
			belowB: 'Below B',
			below52WeekLow: 'Below 52 week low',
			below52WeekLowDescription: 'ETFs that reached a new 52 week low.',
			bearishMovingAverageCrossover: 'Bearish Moving Average Crossover',
			bearishMovingAverageCrossover_desc: 'A bearish Moving Average Crossover occurs when a faster moving average (15-Day SMA) crosses below the slower moving average (50-Day SMA).',
			bearishRSI: 'Bearish RSI',
			bearishRSI_desc: 'A bearish Relative Strength Index (RSI) signal occurs when RSI (14-Day) crosses below 70.',
			bearishStochastics: 'Bearish Stochastics',
			bearishStochastics_desc: 'A bearish signal is given by the Slow Stochastics when the K% line (14-Day) crosses below the D% line (3-Day).',
			beta: 'Beta',
			betaYear: 'Beta ({year} year)',
			MSDPrimaryIndexBetaY1Ptile: 'Beta (1 year)',
			MSDPrimaryIndexBetaY3Ptile: 'Beta (3 year)',
			MSDPrimaryIndexBetaY5Ptile: 'Beta (5 year)',
			MSDPrimaryIndexBetaY10Ptile: 'Beta (10 year)',
			MSDPrimaryIndexBetaY1: 'Beta (1 year)',
			MSDPrimaryIndexBetaY3: 'Beta (3 year)',
			MSDPrimaryIndexBetaY5: 'Beta (5 year)',
			MSDPrimaryIndexBetaY10: 'Beta (10 year)',
			beta1Year: 'Beta (1 year)',
			betaDescription: 'One-, three-, five- or ten-year beta represents the tendency of an ETF\'s returns to respond to swings in its benchmark over a specified period. A beta of 1 indicates that the ETF may experience the same return as its benchmark while a beta of 1.5 indicates that the ETF may experience returns of 1.5 times its benchmark.',
			betaVsCategory: 'Beta vs. {category}',
			betaVsCategoryTooltip: 'Beta represents an ETF return’s tendency to respond to swings in its benchmark of a stated period of time. A beta of 1 indicates the ETF may experience the same return as its benchmark while a beta of 1.5 indicates the ETF may experience returns of 1.5 times its benchmark.',
			beverages: 'Beverages',
			bid: 'Bid',
			bids: 'Bids',
			bidLots: 'Bids/Lots',
			bidLotsCapitalization: 'Bids/lots',
			bidLotsCapitalization2: 'Bid/Lots',
			bidSharesCapitalization: 'Bids/shares',
			bidSharesCapitalization2: 'Bid/Shares',
			bidLotsSrOnly: 'bid lots',
			billion: 'billion',
			bookRatio: 'Book Ratio',
			bookValue: 'Book value',
			bonds: 'Bonds',
			both: 'Both',
			bottomNMovers: 'Bottom {count} Movers',
			bottomPriceMovers: 'Bottom $ movers',
			bottomPerformers: 'Bottom performers',
			bottomPctMovers: 'Bottom % movers',
			bottomMovers: 'Bottom movers',
			buildings: 'Buildings',
			bullishMovingAverageCrossover: 'Bullish Moving Average Crossover',
			bullishMovingAverageCrossover_desc: 'A bullish Moving Average Crossover occurs when a faster moving average (15-Day Simple Moving Average (SMA)) crosses above the slower moving average (50-Day SMA).',
			bullishRSI: 'Bullish RSI',
			bullishRSI_desc: 'A bullish Relative Strength Index (RSI) signal occurs when RSI (14-Day) crosses above 30.',
			bullishStochastics: 'Bullish Stochastics',
			bullishStochastics_desc: 'A bullish signal is given by the Slow Stochastics when the K% line (14-Day) crosses above the D% line (3-Day).',
			buttonView: 'Button view',
			breakevn: 'Break Even',
			byChain: 'By Chain',
			byCustom: 'By Custom',
			byExpiry: 'By Expiry',
			byStrike: 'By Strike',
			calls: 'Calls',
			call: 'Call',
			callPut: 'Call/Put',
			calendarYearReturns: 'Calendar year returns',
			canadaGrowthAtReasonablePrice: 'Canada - growth at a reasonable price',
			canadaGrowthAtReasonablePrice_desc: 'Canadian stocks with reasonable price growth',
			canadaLowPEStocks: 'Canada - low PE stocks',
			canadaValueStocks: 'Canada - value stocks',
			canadianEquityETFs: 'Canadian Equity ETFs',
			canadianEquityETFs_desc: 'ETFs that invest in Canadian equity holdings.',
			canadianDollars: 'Canadian dollars',
			canadianEquity: 'Canadian equity',
			canadianNews: 'Canadian news',
			canadianPrime: 'Canadian Prime',
			canadianResearchReview: 'Canadian research review',
			canadianResearchReviewDescription: 'An annual review on the Canadian stock universe covered by CIBC World Market analysts, including top picks, as well as Economic commentary.',
			cancel: 'Cancel',
			cancelSr: 'Cancel and close dialogue',
			cancelWatchlist: 'Cancel and go to watch lists',
			capEx: 'Capital Expenditures',
			capitalgoods: 'Capital Goods',
			capitalLeastObligations: 'Capital Lease Obligations',
			capitalPreservation: 'Capital preservation',
			capLabel: 'Cap',
			cashFlowStandAlone: 'Cash flow',
			cash: 'Cash',
			cashDividsPaidCommon: 'Cash Divids Paid - Common',
			cashDividsPaidPreferred: 'Cash Divs Paid - Preferred ',
			cashInterestPdSuppl: 'Cash Interest Pd, Suppl',
			cashPayments: 'Cash Payments',
			cashReceipts: 'Cash Receipts',
			cashTaxesPdSuppl: 'Cash Taxes Pd, Supplemental',
			cashDueBanks: 'Cash & Due from Banks',
			cashEquivalents: 'Cash & Equivalents',
			cashShortTermInv: 'Cash and Short Term Inv',
			categoryAverage: 'Category average',
			category: 'Category',
			categoryRankTooltip: 'The category rank represents the ETF’s placement in a ranking of its peers, with 1 being the highest percentile and 100 the lowest.<br/><br/>For example, an ETF of <span style="font-style:italic;">Category rank/total</span> <span style="font-weight:800;">15/10</span> means it ranks in the category’s top <span style="font-weight:800;">15%</span>, and the category consists of <span style="font-weight:800;">10 ETFs.</span>',
			categoryRank: 'Category rank/total',
			change: 'Change',
			changeContextChangement: 'Change',
			changeContextVariation: 'Change',
			changeDollars: 'Change $',
			changeOf: 'A change of',
			changePercent: 'Change %',
			changesWorkingCapital: 'Changes in Working Capital',
			changeToETFDetails: 'Change to {symbol} details',
			chargesMayNotBeApplicable: 'Charges may not be applicable to all Load types',
			chart: 'Chart',
			charts: 'Charts',
			chartUnavailable: 'No Chart Available',
			checkYourNotifications: 'Check your notifications, downloads or browser to open the file',
			chemicals: 'Chemicals',
			cibcFunds: 'CIBC funds',
			cibcWoodGundy: 'CIBC Wood Gundy',
			cibcWorldMarkets: 'CIBC World Markets',
			cibcWorldMarketsLower: 'CIBC world markets',
			clearList: 'Clear List',
			closeMenu: 'Close menu',
			close: 'Close',
			closeWindow: 'Close window',
			closeDialogueSrOnly: 'Close dialogue',
			closeCopy: 'Close',
			Commentary: 'Commentary',
			commercialPaper: 'Commercial Paper',
			commonStockQCMS: 'Common Stock',
			commonStockSCMS: 'Common Stock',
			commishFeeSecurityAct: 'Commish & Fees/Secur Act',
			commodities: 'Commodities',
			commoditiesInUsDollars: 'Commodities in US dollars',
			commodity: 'Commodity',
			commonStockNet: 'Common Stock, Net',
			communicationservices: 'Communication services',
			MSDStockSectorCommunicationServices: 'Sector Communication services',
			company: 'Company',
			Compare: 'Compare',
			comparedToIndustry: '(compared to industry)',
			compareLimitReached: 'Please do not select more than {count} ETFs.',
			companyOverview: 'Company overview',
			compareToSymbol: 'Compare to {symbol}',
			comingSoon: 'Coming soon',
			'construction&materials': 'Construction & Materials',
			constructionProgress: 'Construction in Progress',
			consumercyclical: 'Consumer cyclical',
			MSDStockSectorConsumerCyclical: 'Sector Consumer cyclical',
			consumercyclicals: 'Consumer Cyclicals',
			consumerdefensive: 'Consumer defensive',
			MSDStockSectorConsumerDefensive: 'Sector Consumer defensive',
			consumerDiscretionary: 'Consumer Discretionary',
			consumergoods: 'Consumer goods',
			'consumernon-cyclicals': 'Consumer/non-cyclicals',
			'consumernon-cyclical': 'Consumer Non-Cyclical',
			consumerservices: 'Consumer services',
			consumerStaples: 'Consumer staples',
			conglomerates: 'Conglomerates',
			consensusEstimate: 'Consensus estimates',
			contactInfo: 'Contact information',
			cost: 'Cost',
			convertible: 'Convertible',
			convPrefStkNonRdmbl: 'Conv. Pref. Stk-Non Rdmbl.',
			costOfRev: 'Cost of Revenue',
			costRevenueTotal: 'Cost of Revenue, Total',
			country: 'Country',
			countryDescription: 'The country in which the ETF trades.',
			createNew: 'Create New',
			createNewScreen: 'Create a new screen',
			createNewWatchlist: 'Create watch list',
			creditCardFee: 'Credit Card Fees',
			creditQuality: 'Credit quality',
			creditDuration: 'Credit duration',
			creditordebit: 'Credit or Debt',
			creditQualityRating: 'Credit quality rating',
			creditQualityRatingDescription: 'The credit quality rating is used to evaluate the default risk of fixed income investments.',
			creditYears: '{years} years',
			crosses: 'Crosses',
			cfra: 'CFRA',
			'cyf/nfy': 'CFY/NFY',
			'C-AM': 'Call',
			currentlyAddedSymbols: 'Currently added symbols',
			currentRatio: 'Current ratio',
			cumTransAdjst: 'Cum. Trans. Adjustment',
			currentFundCurrency: 'Current fund currency',
			currPortLTDebtCapLs: 'Curr. Port. LT Dbt/Cap Ls.',
			customerAcceptances: 'Customer Acceptances',
			customerAdvances: 'Customer Advances',
			customizeScreener: 'Customize screener',
			customizeScreenerResults: 'Customize screener results',
			customPeriodRange: 'Custom period range',
			customizeScreeners: 'Customize Screeners',
			customScreener: 'Custom screener',
			customSearch: 'Custom search',
			cumulativePerformance: 'Cumulative performance',
			data: 'Data',
			dataRetrieved: 'Data retrieved',
			date: 'Date',
			dateCreated: 'Date created',
			day: 'Day',
			dayD: '- day %D',
			dailyChart: 'Daily chart',
			dayKCrosses: '-day %K crosses ',
			days: 'days',
			dayRange: 'Day range',
			dealerTradingAcctProfit: 'Dealer Trading Acct Profit',
			dealerTradingAcctLoss: 'Dealer Trading Account Loss',
			deferredTaxes: 'Deferred Taxes',
			definitions: 'Definitions',
			definitionsSrOnly: 'Definitions in a new dialogue',
			delete: 'Delete',
			deleteSelected: 'Delete selected',
			deletePurchaseDetails: 'Delete Purchase Details',
			deleteSelectedSrOnly: 'Delete selected from list',
			deleteWatchlist: 'Delete watch list',
			deleteWatchlistSr: 'Delete list in a new dialogue',
			deleteWatchlistHeader: 'Delete watch list',
			delta: 'Delta',
			depletion: 'Depletion',
			deposits: 'Deposits',
			depreciation: 'Depreciation',
			depreciationAmortization: 'Depreciation/Amortization',
			depreciationDepletion: 'Depreciation/Depletion',
			depreciationExpense: 'Depreciation Expense',
			depreciationSupp: 'Depreciation, Supplemental',
			depreciationSuppl: 'Depreciation, Supplemental',
			descending: 'Descending',
			description: 'Description',
			detail: 'Detail',
			details: 'Details',
			detailList: 'Detail list ',
			detailedList: 'Detailed list ',
			detailValue: 'Detail value',
			dilutedEPSExclExtraItems: 'Diluted EPS Excl. Extra. Items',
			dilutedEPSInclExtraItems: 'Diluted EPS Incl. Extra. Items',
			dilutedNormalEPS: 'Diluted Normalized EPS',
			dilutedWeightedAveShares: 'Diluted Weighted Average Shares',
			dilutionAdj: 'Dilution Adjustment',
			disclosures: 'Disclosures',
			discontinuedOps: 'Discontinued Operations',
			display: 'Display',
			displayBy: 'Display by',
			displayCurrency: 'Display currency',
			distribution: 'Distribution',
			distributionChart: 'Distribution chart',
			distributionChartUnavailable: 'Distribution information is unavailable for this ETF.',
			noDistributionInformationMF: 'There is no distribution data for this fund.',
			distributionDetail: 'Distribution detail',
			distributionFrequency: 'Distribution frequency',
			distributionFrequencyDescription: 'The frequency at which an ETF pays out a distribution. Distributions can be paid monthly, quarterly, semi-annually or annually.',
			distributionInformation: 'Distribution information',
			distributionInformationAmounts: 'Distribution information amounts',
			distributionsPerYear: 'Distributions per year',
			distributionYield: 'Distribution yield',
			distributionYieldDescription: 'Distribution yield is defined as the annual distribution declared by an ETF divided by its price.',
			distributionsPerShare: 'Distributions per share',
			distributionLabel: 'Distribution',
			dividend: 'Dividend',
			dividendInfo: 'Dividend information',
			dividendYield: 'Dividend Yield',
			dividendGrowthLabel: 'Dividend growth 5 year',
			dividendGrowthLabel2: 'Div growth 5 year',
			domesticbond: 'Domestic bonds',
			down: 'Down',
			dpsCommonStockPrimaryIssue: 'DPS - Common Stock Primary Issue',
			djiaChartHidden: 'DJIA chart',
			duplicateWatchlistNameError: 'Watch List name already in use',
			defIncTaxLTAsst: 'Def. Inc. Tax - LT Asset',
			defPolicyAcqCosts: 'Def. Policy Acq. Costs',
			deferredCharges: 'Deferred Charges',
			deferredGasCost: 'Deferred Gas Cost',
			deferredIncomeTax: 'Deferred Income Tax',
			deferredIncomeTaxLBDT: 'Deferred Income Tax',
			deferredIncomeTaxLDTC: 'Deferred Income Tax',
			deferredIncomeTaxSBDT: 'Deferred Income Tax',
			deferrentInvTaxCredit: 'Deferred Inv. Tax Credit',
			discontOpsCurrentAsst: 'Discont. Ops.-Curr. Assets',
			discontOpsLiabili: 'Discont. Ops. - Lblts',
			discontinuedOperations: 'Discontinued Operations',
			disctOpsLTAsst: 'Disct. Ops. - LT Asset',
			dividendsPayable: 'Dividends Payable',
			domicile: 'Domicile',
			doNotSend: 'Do not send',
			duringThePriorTradingSession: 'during the prior trading session.',
			earnings: 'Earnings',
			earningsPerShare: 'Earnings per share',
			economicInsights: 'Economic insights',
			economicInsightsDescription: 'Browse our updated forecasts of Canadian and U.S. financial markets and our outlook on the North American economy.',
			economics: 'Economics',
			edit: 'Edit',
			editSearch: 'Edit search',
			editWatchlistSrOnly: 'Edit selected watch list',
			editScreenerName: 'Edit screener name',
			editPurchaseDetails: 'Edit Purchase Details',
			educationDisclaimer: 'The analyst commentary, educational content and other investment research information provided to you through this website is general only, it is not intended as specific investment advice and you should not rely on it as such. Research reports from entities other than CIBC World Markets Inc. are not prepared subject to Canadian disclosure requirements.',
			effectiveTaxRate: 'Effective tax rate',
			earningsValue: 'Earnings Value',
			effectSpecialItemOnIncomeTax: 'Effect of Special Items on Income Taxes',
			electricity: 'Electricity',
			'electronic&electricalequipment': 'Electronic & Electrical Equipment',
			electricOps: 'Electric Operations',
			emailAddress: 'Email address',
			energy: 'Energy',
			MSDStockSectorEnergy: 'Sector Energy',
			enterName: 'Enter Name',
			enterNumberToMax: 'Enter a number to a maximum of 6 digits',
			enterNameForWatchlist: 'Please enter a name for this watch list.',
			enterSpecificValues: 'Enter specific values',
			equalToNLessThanM: '= to {min} to < {max}',
			equityInAffiliates: 'Equity In Affiliates',
			equityNetEarnLoss: 'Equity in Net Earnings/Loss',
			equityStylebox: 'Equity stylebox',
			equityStyleboxDescription: 'The equity stylebox is a proprietary Morningstar measure that provides a graphical representation of an ETF\'s investment style. The stylebox is a nine-square grid that classifies equities by capitalization along the vertical axis and by investment style characteristics across the horizontal axis.',
			errorDuringDownload: 'Error during download',
			etfs: 'ETFs',
			etfMenu: 'ETF menu',
			etfPortfolioDisclaimer: 'ETF Portfolio Disclaimer',
			etfHoldingSearch: 'ETF holdings search',
			featuredScreens: 'Featured screens',
			featuredScreens2: 'Featured screens',
			fundClassesDisclaimer: 'A mutual fund may be available in multiple classes. The differences in fees and expenses between classes mean that each class has a different net asset value per unit, and therefore performance may differ between classes.',
			fundClassification: 'Fund classification',
			fundPromoter: 'Fund promoter',
			fundCommissionsDisclaimer: 'Commissions, trailing commissions, management fees and expenses all may be associated with mutual fund investments. Please read the simplified prospectus before investing. The indicated rates of return are the historical annual compounded total returns for the units, including changes in unit value and reinvestment of all distributions, but do not take into account sales, redemption, distribution or optional charges or income taxes payable by any unitholder that would have reduced returns. For money market funds, the performance data provided assumes reinvestment of distributions only but does not take into account sales, redemption, distribution or optional charges or income taxes payable by any unitholder that would have reduced returns. Mutual fund securities are not covered by the Canada Deposit Insurance Corporation or by any other government deposit insurer, nor are they guaranteed. There can be no assurance that a money market fund will be able to maintain its net asset value per unit at a constant amount or that the full amount of your investment will be returned to you. The values of many mutual funds change frequently. Past performance may not be repeated.',
			ema15day: '15-day EMA',
			ema50day: '50-day EMA',
			ema100day: '100-day EMA',
			ema200day: '200-day EMA',
			endDay: 'End day',
			endMonth: 'End month',
			endYear: 'End year',
			enterPurchaseDetails: 'Enter Purchase Details',
			enterPurchaseDetailsSr: 'Enter {symbol} purchase details in a new dialogue',
			estimates: 'Estimates',
			estimatesDisclaimer: 'CFY = Current Fiscal Year, NFY = Next Fiscal Year',
			estimatesStockScreener: 'Estimates',
			editWatchlist: 'Edit Watch List',
			etfsCaption: '{count} ETFs',
			etfCentre: 'ETF Centre',
			etfsAtGlance: 'ETFs at a glance',
			etfsOverviewTodaysMoversTooltip: 'Leveraged ETFs use financial derivatives and debt to amplify the returns of an underlying index on a daily basis. These ETFs should be traded with caution and are intended for advanced traders with an in-depth understanding of the associated risks.',
			equities: 'Equities',
			equity: 'Equity',
			equityFund: 'Equity',
			equityinvestmentinstruments: 'Equity Investment Instruments',
			eps: 'EPS',
			epsGrowth: 'EPS Growth',
			epsGrowthCFY: 'EPS growth CFY',
			epsGrowthNFY: 'EPS growth NFY',
			ESPODebtGuarantee: 'ESOP Debt Guarantee',
			exactPhrase: 'Exact phrase',
			examplePhone: 'Example: 5555551212@domain.com',
			exciseTaxPayment: 'Excise Taxes Payments',
			excisTaxReceipts: 'Excise Taxes Receipts',
			exceededExpectations: 'Exceeded expectations',
			exDivDate: 'Ex Div Date',
			exDividendDate: 'Ex-dividend date',
			expirationDate: 'Expiration Date',
			expiration: 'Expiration',
			expired: 'Expired',
			expiry: 'Expiry',
			expiryLabel: 'expiry',
			explorationProduction: 'Exploration & Production',
			extraItem: 'Extraordinary Item',
			extraordinaryItemun: 'Extraordinary Item',
			family: 'Family',
			fax: 'Fax: ',
			fedFundResale: 'Fed. Fund/Resale',
			fedFundSoldSecSoldUnderRepurch: 'Fed Funds Sold/Secs Sold under Repurch Agrmnt',
			fedFundsREPO: 'Fed. Funds/REPOs',
			feeCommishFromOp: 'Fees & Commish from Operat',
			feeOtherCustServ: 'Fees for Other Cust Serv',
			FHLB: 'FHLB',
			fiftyTwoWeek: '52 Week',
			financeCashFlowItem: 'Financing Cash Flow Items',
			financials: 'Financials',
			financialsFor: 'Financials for {symbol}',
			financialsPeriod: 'Financials period',
			financialservices: 'Financial services',
			MSDStockSectorFinancialServices: 'Sector Financial services',
			financialStrength: 'Financial strength',
			financialsType: 'Financials type',
			finishedAddingCriteria: 'Finished adding criteria',
			finishedAddingCriteriaError: 'Select criteria to continue.',
			filterBy: 'Filter by',
			filterByClassification: 'Filter by classification',
			filterByCountry: 'Filter by country',
			filterByDate: 'Filter by date',
			filterByEtfType: 'Filter by ETF type',
			filterByFundFamily: 'Filter by fund family',
			filterByFundPromoter: 'Filter by fund promoters',
			filterByMovers: 'Filter by movers',
			filterByPerformance: 'Filter by performance',
			filterByPeriod: 'Filter by period',
			filterByPublication: 'Filter by publication',
			filterByScreenType: 'Filter by screen type',
			filterBySector: 'Filter by sector',
			filterByVolume: 'Filter by volume',
			filterMoversBy: 'Filter movers by',
			filterDataTypeBy: 'Filter data type by',
			financialsTableDisclaimer: 'In millions of {currency} (except for per share items)',
			fiveYearBond: '5 Year Bond',
			fiveYrAvgPreTaxMargin: '5 yr. avg. pre-tax margin',
			fiveYrAvgOperatingMargin: '5 yr. avg. operating margin',
			fiveYrNetProfitMargin: '5 yr. avg. net profit margin',
			fiveYrAvgNetProfitMarginGrowth: '5 yr. avg. net profit margin growth',
			fiveYrAvgEffectiveTaxRate: '5 yr. avg. effective tax rate',
			fiveYrYieldGrowthRate: '5 yr. yield growth rate',
			fiveYrYieldAvg: '5 yr. yield average',
			fixedincome: 'Fixed Income',
			fixedIncome: 'Fixed income',
			fixedIncomeStylebox: 'Fixed income stylebox',
			fixedIncomeStyleboxDescription: 'Morningstar\'s proprietary measure for fixed income funds, based on interest rate sensitivity and credit quality. The stylebox is a nine-square grid that measures credit quality along the vertical axis and interest rate sensitivity along the horizontal axis.',
			fixedIncomeStyleboxCol1: 'L',
			fixedIncomeStyleboxCol2: 'M',
			fixedIncomeStyleboxCol3: 'E',
			fixedIncomeStyleboxRow1: 'H',
			fixedIncomeStyleboxRow2: 'M',
			fixedIncomeStyleboxRow3: 'L',
			fixedlinetelecommunications: 'Fixed Line Telecommunications',
			float: 'Float',
			'forestry&paper': 'Forestry & Paper',
			'food&drugretailers': 'Food & Drug Retailers',
			foodproducers: 'Food Producers',
			foreclosedRealEstate: 'Foreclosed Real Estate',
			foreignbond: 'Foreign Bonds',
			forexAdjustment: 'Foreign Currency Adjustment',
			forexAdjustmentNonOp: 'Foreign Currency Adjustment',
			forexEffects: 'Foreign Exchange Effects',
			ForexGains: 'Foreign Currency Gains',
			forexLoss: 'Foreign Currency Losses',
			format: 'Format',
			fsiD: 'Deficient: Issuer Failed to Meet NASDAQ Continued Listing Requirements',
			fsiE: 'Delinquent: Issuer Missed Regulatory Filing Deadline',
			fsiG: 'Deficient and Bankrupt',
			fsiH: 'Deficient and Delinquent',
			fsiJ: 'Delinquent and Bankrupt',
			fsiK: 'Deficient, Delinquent, and Bankrupt',
			fsiN: 'Normal (Default)" Issue is not currently deficient, delinquent, or bankrupt.',
			fsiQ: 'Bankrupt: Issuer Has Filed for Bankruptcy',
			fuelExpense: 'Fuel Expense',
			fuelPurchasedResale: 'Fuel Purchased for Resale',
			fundamentals: 'Fundamentals',
			fundamentalsStockScreener: 'Fundamentals',
			fundCategory: 'Fund category',
			fundCategoryDescription: 'Fund category is a detailed industry standard grouping of ETFs according to similar style, strategies, asset class or other criteria.',
			fundCentre: 'Fund Centre',
			fundCodes: 'Fund codes',
			fundDetails: '{symbol} details',
			fundDetail: 'Fund detail',
			fundFamily: 'Fund family',
			fundFamilyDescription: 'A group of ETFs offered under a fund company or sponsor.',
			fundName: 'Fund name',
			fundNameLower: 'Fund name',
			fundOverview: 'Fund Overview',
			fundObjective: 'Fund objective',
			funds: 'Funds',
			fundsFromOpREIT: 'Funds From Operats - REIT',
			fundSnapshot: '{name} snapshot',
			fundStructure: 'Fund structure',
			fundStructureDescription: `An ETF can be classified as standard, leveraged or inverse.
			\nLeveraged ETFs use financial derivatives to amplify the returns of an underlying index or basket of securities on a daily basis. These ETFs should be traded with caution and are intended for advanced traders with an in-depth understanding of the associated risks.
			\nInverse ETFs are designed to benefit from price movements opposite to that of the underlying index or basket of securities.`,
			fundType: 'Type',
			fundTypeHeader: 'Fund type',
			fundValue: 'Fund value',
			fundMovement: 'Fund movement',
			fedFundsPurch: 'Fed Funds Purch.',
			FHLBAdvances: 'FHLB Advances',
			fhlbStock: 'FHLB Stock',
			fuelInvent: 'Fuel - Inventory',
			getAnalystRatings: 'Get analyst ratings',
			globalExposure: 'Global exposure',
			globalExposureDisclaimer: 'Country breakdown is calculated using only the portfolio’s long stock and bond holdings, by country of domicile.',
			globalExposureError: 'Global holdings are unavailable',
			globalExposureErrorPortfolio: 'Global holdings are unavailable for this ETF',
			greatestPremium: 'Greatest premium',
			greatestDiscount: 'Greatest discount',
			gamma: 'Gamma',
			gain: 'Gain',
			gainSaleofAssets: 'Gain (Loss) on Sale of Assets',
			gainSr: 'gain',
			gainTotalSr: '{gain} gain',
			'gas,water&multiutilities': 'Gas, Water & Multiutilities',
			gasOps: 'Gas Operations',
			generalindustrials: 'General Industrials',
			generalretailers: 'General Retailers',
			genPartnerDistro: 'Gen Partner\'s Distributions',
			getLatestCommentary: 'Get the latest ETF commentary from Morningstar analysts. Learn more about ETFs with Morningstar’s educational content. ',
			gold: 'Gold',
			goldSr: 'Gold chart',
			grossDividendCommStock: 'Gross Dividend - Common Stock',
			grossPremWritten: 'Gross Premiums Written',
			grossRev: 'Gross Revenue',
			groupBy: 'Group By',
			growth5Year: 'Growth 5 Year',
			growth10K: 'Growth of $10,000',
			growth10KText1: '$10,000 invest on ',
			growth10KText2: ' would now be worth ',
			growthNfy: 'Growth NFY',
			grossMargin: 'Gross margin',
			gasStorageInvent: 'Gas in Storage - Inventory',
			generalPartner: 'General Partner',
			goodwillGross: 'Goodwill, Gross',
			goodwillNet: 'Goodwill, Net',
			headlinesOnly: 'Headlines only',
			headlinesAndDocumentBody: 'Headlines & document body',
			healthCare: 'Health care',
			healthcare: 'Healthcare',
			MSDStockSectorHealthcare: 'Health care',
			'healthcareequipment&services': 'Health Care Equipment & Services',
			high: 'High',
			highPriceContext: 'High',
			highLow: 'High/Low',
			highLowLabel: 'High/Low',
			hide: 'Hide',
			hideCallsSrOnly: 'Hide calls for {symbol}',
			hidePutsSrOnly: 'Hide puts for {symbol}',
			hideCriteriaMatches: 'Hide criteria matches',
			holdings: 'Holdings',
			'householdgoods&homeconstruction': 'Household Goods & Home Construction',
			etfHoldingsContent: 'Identify ETFs with top holdings exposure to the selected stocks',
			etfHoldingDisclaimer: 'Information is based on last disclosure by the Fund to Morningstar.',
			holdingsMentioned: 'Holdings mentioned',
			hyphen: '-',
			highEstimate: 'High estimates',
			impAssetsHeldSale: 'Impair-Assets Held for Sale',
			impAssetsHeldUse: 'Impair-Assets Held for Use',
			impliedVolatility: 'Implied volitility',
			inactive: 'Inactive',
			inANewDialogue: '{name} in a new dialogue',
			inANewWindow: 'PDF in a new window',
			industrialtransportation: 'Industrial Transportation',
			inception: 'Inception',
			inceptionDate: 'Inception date',
			includeLeveraged: 'Include leveraged',
			inceptionDateDescription: 'The date on which an ETF first began to offer shares.',
			incomeAvailCommonExclExtraItems: 'Income Available to Common Excl. Extra. Items',
			incomeAvailCommonInclExtraItems: 'Income Available to Common Incl. Extra. Items',
			incomeHandling: 'Income handling',
			incTaxExImpactofSpItem: 'Inc Tax Ex Impact of Sp Items',
			indicationRatesNotActualRates: 'Indication rates, not actual rates',
			industrialengineering: 'Industrial Engineering',
			'industrialmetals&mining': 'Industrial Metals & Mining',
			industrials: 'Industrials',
			MSDStockSectorIndustrials: 'Sector Industrials',
			industry: 'Industry',
			industryDataNotAvailable: 'Industry data not available',
			informationTechnology: 'Information technology',
			initialCharge: 'Initial charge',
			insights: 'Insights',
			insurCommishFeePrem: 'Insur Commish Fees& Premium',
			int: 'Int',
			intangibleNet: 'Intangible, Net',
			interAdjPrimaryEPS: 'Inter Adjust - Primary EPS',
			interCapitalOp: 'Inter Capitalzd, Operating',
			interCapNonOp: 'Inter Capital, Non-Operat',
			interDivsInvestSecurs: 'Inter & Divs/ Invest Securs',
			interesonDeposits: 'Interest on Deposits',
			interest: 'Interest',
			interestCapSuppl: 'Interest Capitalized, Suppl',
			interestCoverage: 'Interest coverage',
			interestExpenseSuppl: 'Interest Expense, Suppl',
			interestFeeLoans: 'Interest & Fees on Loans',
			interestIncomeBank: 'Interest Income, Bank',
			interestIncomeNonBank: 'Interest Income, Non-Bank',
			interestIncomeNetNonOp: 'Interest Income (Exp), Net Non-Operating',
			interestIncomeNonOp: 'Interest Income, Non-Operating',
			interestIncomeOp: 'Interest Income, Operating',
			interestInvestIncomeNonOp: 'Interest/Investment Income, Non-Operating',
			interestOnDeposits: 'Interest on Deposit',
			interExpenseNetNonOp: 'Inter Expse,Net Non-Operat',
			interExpenseNetOp: 'Inter Expense, Net Operatng',
			interExpenseNonOp: 'Inter Expse, Non-Operating',
			interExpenseOp: 'Inter Expense, Operating',
			interInvestOp: 'Inter/Invest Inc, Operating',
			internationalEquity: 'International equity',
			internationalEquityAbbrv: 'Int\'l equity',
			internationalEquityETFs: 'International Equity ETFs',
			internationalEquityETFs_desc: 'ETFs that invest in International equity holdings.',
			interOnOtherBorrow: 'Inter on Other Borrowings',
			inTheMoney: 'In the money',
			indexName: 'Index name',
			indexMovement: 'Indices movement',
			indicatorInput: 'Indicator input',
			invalidCharacterError: 'The information you have entered can\'t be processed as it contains unacceptable symbols or words. Please re-enter your information.',
			inventories: 'Inventories',
			inverseEtf: 'Inverse ETF',
			inverseLeveragedETFs: 'Inverse/Leveraged ETFs',
			inverseLeveragedETFs_desc: 'ETFs that use financial derivatives to take an inverse position in the underlying index/security (Inverse ETFs) or to amplify the returns of an underlying index/security (Leveraged ETFs).',
			inverseDisclaimer: 'Inverse ETfs are designed to benefit from price movements opposite to that of the underlying index or basket of securities.',
			investIncomeOp: 'Invest Income, Operating',
			investIncomeNonOp: 'Investment Income, Non-Operating',
			investmentType: 'Investment type',
			investmentGoal: 'Investment Goal: ',
			investmentNet: 'Investment, Net',
			investmentValuation: 'Investment valuation',
			investmtSecurityLoss: 'Investmt Securities Losses',
			investSecGains: 'Invest Securities Gains',
			issRetirmntDebtNet: 'Iss (Retirmnt) of Debt, Net',
			issRetirmntStockNet: 'Iss (Retirmnt) of Stock,Net',
			incomeTaxPayable: 'Income Taxes Payable',
			insruanceReserve: 'Insurance Reserves',
			insuranceReceivables: 'Insurance Receivables',
			intangibleGross: 'Intangibles, Gross',
			interestBearingDept: 'Interest Bearing Deposits',
			interestEarnDeposits: 'Interest-Earning Deposits',
			interestRateSensitivity: 'Interest rate sensitivity',
			interestRcvbl: 'Interest Receivable',
			inventFinishedGoods: 'Invent. - Finished Goods',
			inventOther: 'Inventories - Other',
			inventRawMaterial: 'Invent. - Raw Materials',
			inventWIP: 'Invent. - Work In Progress',
			keyCanadianRates: 'Key Canadian Rates',
			keyUsRates: 'Key US Rates',
			keyword: 'Keyword',
			keywords: 'Keywords',
			keywordResultsBy: 'Keyword results by',
			laborRelatedExpense: 'Labor & Related Expenses',
			lastTrade: 'Last trade',
			lastTradeHeader: 'Last',
			lastChange: 'Last Change',
			lastUpdatedToday: 'Last updated today',
			lastUpdated: 'Last updated',
			last: 'Last',
			lastLabel: 'Last',
			lastClose: 'Last close',
			lastBidLots: 'Last Bid / Lots',
			lastAskLots: 'Last Ask / Lots',
			lastPrice: 'Last price',
			lastWeek: 'Last week',
			last2Weeks: 'Last 2 weeks',
			last4Weeks: 'Last 4 weeks',
			latestEconomicReports: 'Latest CIBC economics reports',
			latestEquityReports: 'Latest equity reports',
			latestEtfCommentary: 'Latest ETF commentary',
			latestPdfReports: 'Latest PDF Reports',
			latestDividend: 'Latest dividend',
			latestPaymentDate: 'Latest payment date',
			latestExDistributionDate: 'Latest ex-distribution date',
			latestDistributionAmount: 'Latest distribution amount',
			legalStructure: 'Legal structure',
			leisuregoods: 'Leisure Goods',
			less: 'Less',
			leveragedEtf: 'Leveraged ETF',
			leveragedDisclaimer: 'Leveraged ETFs use financial derivatives and debt to amplify the returns of an underlying index on a daily basis. These ETFS should be traded with caution and are intended for advanced traders with an in-depth understanding of the associated risks. For more information on Leveraged ETFS, please read Investing in Leveraged ETFs in the Resources tab. Note: not all ETF types include leveraged funds.',
			lifeinsurance: 'Life Insurance',
			listBy: 'List by',
			litigation: 'Litigation',
			litigationExpense: 'Litigation Expense',
			load: 'Load',
			loadLabelFr: '',
			loanLossProvision: 'Loan Loss Provision',
			loanOriginationInvest: 'Loans Origination - Invest',
			loans: 'Loans',
			longDurationHighQuality: 'Long Duration/High Quality',
			longDurationMediumQuality: 'Long Duration/Medium Quality',
			longDurationLowQuality: 'Long Duration/Low Quality',
			longTermDebtIssued: 'Long Term Debt Issued',
			longTermDebtNet: 'Long Term Debt, Net',
			longTermDebtReduce: 'Long Term Debt Reduction',
			loss: 'Loss',
			lossBeniesAdjust: 'Losses, Benies & Adjusts',
			lossBeniesAdjustTotal: 'Losses, Benies & Adjmts, Total',
			lossSaleAsts: 'Loss(Gain)/Sale Asts (Oper)',
			lossSr: 'Perte de',
			lossTotalSr: '{loss} loss',
			lots: 'Lots',
			lowHigh: 'Low / high',
			low: 'Low',
			lowPriceContext: 'Low',
			lowAndHigh: 'Low and high',
			lowEstimate: 'Low Estimates',
			longStrategy: 'Long strategy',
			longStrategyChart: 'Long Strategy Chart',
			longPosition: 'Long Position %',
			largeCapValue: 'Large Cap Value',
			largeCapBlend: 'Large Cap Blend',
			largeCapGrowth: 'Large Cap Growth',
			longTermDebtEquity: 'Long term debt/equity',
			lipperLeaders: 'Lipper Leaders',
			landImprovements: 'Land/Improvements',
			leases: 'Leases',
			lifoReserve: 'LIFO Reserve',
			limitedPartner: 'Limited Partner',
			loanLossAllowances: 'Loan Loss Allowances',
			loansHeldSale: 'Loans Held for Sale',
			longTermDebt: 'Long Term Debt',
			longTermInvestment: 'Long Term Investments',
			LTInvntOther: 'LT Investments - Other',
			LTInvtAffiliateComp: 'LT Invt. - Affiliate Comp.',
			machineryEquip: 'Machinery/Equipment',
			maCrossover15_50: 'MA Crossover (15-day SMA/50-day SMA)',
			minPensionLiabiliAdj: 'Min. Pension Lblty Adj.',
			midCapValue: 'Mid Cap Value',
			midCapBlend: 'Mid Cap Blend',
			midCapGrowth: 'Mid Cap Growth',
			makeDefaultWatchlist: 'Set as my default list',
			managementEffectiveness: 'Management effectiveness',
			managerDeclared: 'Manager’s declared',
			manager: 'Manager',
			market: 'Market',
			marketCentreDisclaimer: 'Some information on our site (e.g., Quotes and Research) is provided by our vendors and is not available in French.',
			marketValue: 'Market Value',
			marketsOverview: 'Markets overview',
			marketOverview: 'Market overview',
			marketsOverviewBanner: 'Markets overview banner',
			markets: 'Markets',
			'Markets at a glance': 'Markets at a glance',
			'Market movers': 'Market movers',
			marketLabel: 'Market',
			marketClose: 'Market Close on',
			marketCentre: 'Market Centre',
			marketMovers: 'Market movers',
			matches: 'Matches',
			mixedEquity: 'Mixed equity',
			materials: 'Materials',
			moreReports: 'More reports',
			marketCap: 'Market capitalization ',
			marketCapShort: 'Market cap',
			marketCapShort2: 'Market cap',
			marketMoversHeading: '{type} for the {set}',
			marketDataDelayedBy15: 'Market data delayed by at least 15 minutes',
			marketDataIsRealTime: 'Market data is real-time, except for OTC securities',
			marketsOverviewDelayedBy15: 'Delayed by at least 15 min',
			maxHoldingsReached: 'This watch list has reached its symbol limit.',
			maxQLHoldingsReached: 'This list has reached its symbol limit.',
			media: 'Media',
			MER: 'MER',
			MERDescription: 'A management expense ratio (MER) is the expense to operate an investment fund, which is expressed as a percentage of average annual fund assets.',
			mediumDurationHighQuality: 'Medium Duration/High Quality',
			mediumDurationMediumQuality: 'Medium Duration/Medium Quality',
			mediumDurationLowQuality: 'Medium Duration/Low Quality',
			million: 'million',
			minimumAdditionalInvestment: 'Minimum additional investment',
			minimumInitialInvestment: 'Minimum initial investment',
			minimumInvestmentsTooltip: 'CIBC minimum investments may be higher',
			mining: 'Mining',
			minorityInterest: 'Minority Interest',
			minPensionLiabAdj: 'Min Pension Liabil Adjust',
			minPensionLiabGain: 'Min Pension Liability Gain',
			minPensionLiabilLoss: 'Min Pension Liability Loss',
			miscEarnAdjs: 'Misc Earnings Adjustment',
			missedExpectations: 'Missed expectations',
			mobileNumber: 'Mobile number',
			mobiletelecommunications: 'Mobile Telecommunications',
			month: 'Month',
			monthly: 'Monthly',
			monthlyOption: 'Monthly',
			monthlyFxOutlook: 'Monthly FX outlook',
			monthlyFxOutlookDescription: 'A monthly publication that provides both short and long-term forecasts on some key currencies. Also listed are upcoming events that may impact foreign exchange rates.',
			monthlyWorldMarketsReport: 'Monthly world markets report',
			monthlyWorldMarketsReportDescription: 'A monthly publication that highlights current investing ideas for both Canadian and U.S. equities, and provides suggestions for mutual funds.',
			moreNews: 'More news',
			moreMarketMovers: 'More market movers',
			moversMarketFilter: 'Movers market filter',
			more: 'More',
			morningstarAnalyst: 'Morningstar Analyst',
			morningstarEquityStylebox: 'Morningstar equity stylebox',
			morningstarEquityStyleboxSr: 'Select Morningstar equity criteria from the accompanying style boxes.',
			morningstarEquityStyleboxCol1: 'V',
			morningstarEquityStyleboxCol2: 'B',
			morningstarEquityStyleboxCol3: 'G',
			morningstarEquityStyleboxRow1: 'L',
			morningstarEquityStyleboxRow2: 'M',
			morningstarEquityStyleboxRow3: 'S',
			morningstarFixedIncomeStylebox: 'Morningstar fixed income stylebox',
			morningstarFixedIncomeStyleboxSr: 'Select Morningstar fixed income criteria from the accompanying style boxes.',
			morningstarOverallRisk: 'Morningstar overall risk',
			morningstarOverallReturn: 'Morningstar overall return',
			morningstar3YearRisk: 'Morningstar 3 year risk',
			morningstar3YearReturn: 'Morningstar 3 year return',
			morningstar3YearRating: 'Morningstar 3 year rating',
			morningstar5YearRisk: 'Morningstar 5 year risk',
			morningstar5YearReturn: 'Morningstar 5 year return',
			morningstar5YearRating: 'Morningstar 5 year rating',
			morningstar10YearRisk: 'Morningstar 10 year risk',
			morningstar10YearReturn: 'Morningstar 10 year return',
			morningstar10YearRating: 'Morningstar 10 year rating',
			morningstarQuant: 'Morningstar Quant',
			morningNewsCall: 'Canadian Morning News Call',
			morningNewsCallWC: 'Canadian morning news call',
			usMorningNewsCall: 'US Morning News Call',
			usMorningNewsCallWC: 'US morning news call',
			insightDescription: 'Stay on top of the latest news and insights from Reuters and CIBC Capital Markets.',
			morningstarReturn: 'Morningstar return',
			morningstarOverallRating: 'Morningstar overall rating',
			morningstarReturnRating: 'Morningstar return rating',
			morningstarRisk: 'Morningstar risk rating',
			morningstarStylebox: 'Morningstar Style Box',
			morningstarBenchmark: 'Morningstar primary benchmark',
			morningstarStylbox: 'Morningstar stylebox',
			morningstarRating: 'Morningstar rating',
			morningstarRatingButtonSr: 'Expand for by-year range Morningstar ratings',
			morningstarRatings: 'Morningstar ratings',
			morningstarRiskRating: 'Morningstar risk',
			morningstarReports: 'Morningstar reports',
			morningstarAnalystCommentary: 'Morningstar analyst commentary',
			morningstarAnalystReport: 'Morningstar analyst report',
			morningstarTooltip: 'Morningstar’s proprietary rating system ranks ETFs according to their risk-adjusted performance. The overall rating is a weighted average of the ETFs three-, five- and ten-year rating. ETFs are rated on a star system. 5 stars is the highest ranking; 1 star is the lowest.',
			morningstarStockTooltip: 'The Morningstar rating for stocks is calculated by comparing a stock\'s current market price with Morningstar\'s estimate of the stock\'s fair value. The further the market price is below the fair value, the higher the star rating. Stocks trading at a large discount fair value estimate will receive higher (4 or 5) star ratings, and stocks trading at large premiums to their fair value estimates will receive lower (1 or 2) star ratings. ',
			morningstarRiskTooltip: 'Morningstar\'s assessment of variations in the ETF\'s monthly returns in comparison to similar funds. Overall risk rating is a weighted average of the ETF\'s three-, five- and ten-year risk rating.',
			morningstarDisclaimerETF: 'Powered By Morningstar: © Morningstar Research Inc. All Rights Reserved. The information contained herein: (1) is proprietary to Morningstar and/or its content providers; (2) may not be copied or distributed; (3) is not warranted to be accurate, complete or timely. Neither Morningstar nor its content providers are responsible for any damages or losses arising from any use of this information. Past performance is no guarantee of future results.',
			mostActive: 'Most active',
			mostRecentQtr: 'Most recent quarter (MRQ)',
			movingAverageCrossover: 'Moving average crossover',
			movingAvgXOVer: 'Moving avg x-over',
			movingAverageCrossoverDescription: `A bullish moving average crossover occurs when a faster moving average crosses above the slower moving average (i.e. 15-day simple moving average crosses above 50-day simple moving average).
			\nA bearish moving average cross occurs when a faster moving average crosses below the slower moving average (i.e. 15-day simple moving average crosses below 50-day simple moving average).`,
			mrq: 'MRQ',
			moneyMarket: 'Money market',
			mrq1YrAgo: 'MRQ vs. same 1 year ago',
			municipalBond: 'Municipal bond',
			municipalbond: 'Municipal bond',
			mutualFunds: 'Mutual Funds',
			mutualFundsLower: 'Mutual funds',
			refinitivLipperDisclaimerTitle: 'Refinitiv Lipper Leaders',
			refinitivLipperDisclaimerText: 'Ratings for Total Return reflect fund\'s historical total return performance relative to peers. Ratings for Consistent Return reflect funds\' historical risk-adjusted returns relative to peers. Lipper ratings for Preservation are relative, rather than absolute. Lipper Leader ratings Do not take into account the effects of sales charges. Ratings are based on an equal-weighted average of percentile ranks for each measure over 3-, 5-, and 10-year periods (if applicable).',
			RUTRBCIndustry: 'Industry',
			ruMktCapUS: 'Market cap ($ Mill)',
			ruPriceLast: 'Price ($)',
			ruPriceChange: 'Price change (%)',
			RUTRBCSector: 'Sector',
			ruVol10dAvg: 'Volume (mill)',
			mutualFundsLabel: 'Mutual Funds',
			mutualFundCaption: '{count} Mutual Funds',
			myAlerts: 'My Alerts',
			myWatchLists: 'My Watch Lists',
			myWatchListsNewDialog: 'My watch lists in a new dialogue',
			myQuoteLists: 'My Quote Lists',
			myCriteria: 'My criteria',
			mySearches: 'My searches',
			mySearchesPlaceholder: 'The Analyst Report custom search section isn’t quite done, but will be soon. We’ll have a new and improved format, where you’ll find all your saved custom search information.',
			nStars: '{stars} stars',
			NA: 'Not applicable',
			name: 'Name',
			nasdaqChartHidden: 'NASDAQ chart',
			nav: 'NAV',
			NAVShare: 'NAV/Share',
			nearTheMoney: 'Near the money',
			netAssetValue: 'Net asset value',
			netCashBeginBalRsrvFutUse: 'NetCash-BeginBal/RsvdforFutUse',
			netCashEndBalRsrvFutUse: 'NetCash-EndBal/RsrvforFutUse',
			netChangeCash: 'Net Change in Cash',
			netIncome: 'Net Income',
			netIncomeAfterTax: 'Net Income After Taxes',
			netIncomeBeforeExtraItems: 'Net Income Before Extra. Items',
			netIncomeBeforeTax: 'Net Income Before Taxes',
			netInterestInAfterLoanLoss: 'NetInterIn after Loan Loss Provision',
			netInterestIncome: 'Net Interest Income',
			netInvestmentIncome: 'Net Investment Income',
			netPercentAllocation: 'Net percent allocation',
			netPercentOfHoldings: 'Net percent of holdings',
			netPremEarned: 'Net Premiums Earned',
			netSales: 'Net Sales',
			newWatchlist: 'New watch list',
			new: 'New',
			news: 'News',
			News: 'News',
			newAboutThisEtf: 'News about this ETF',
			newsHeadlines: 'News headlines',
			newsTeaserHeadline: 'Brief-{headline}',
			newWatchlistName: 'Watch List {name}',
			nextArticles: 'Update preceding results with next page',
			no: 'No',
			noData: 'No Data',
			noDataAvailable: 'No data available',
			noDistributionsInformation: 'Distribution information is unavailable for this ETF.',
			noETFHoldingsNews: 'There are holdings news reports available for this ETF.',
			noMutualFundsAreInThisQuotelist: 'No Mutual Funds are in this Quote List.',
			nonCashItems: 'Non-Cash Items',
			nonclassifiedequity: 'Non classified equity',
			noNewsAvailable: 'No news available',
			noneDeclared: 'None declared',
			nonequityinvestmentinstruments: 'Nonequity Investment Instruments',
			nonInterestExpenseBank: 'Non-Interest Expense, Bank',
			nonInterestIncomeBank: 'Non-Interest Income, Bank',
			nonlifeinsurance: 'Nonlife Insurance',
			noOptionsAreInThisQuotelist: 'No options are in this Quote List.',
			noRecentSymbols: 'No Recent Symbols',
			noReportsAvailable: 'There are no reports available.',
			noReportsAvailableForThisEtf: 'There is no Morningstar analyst report for this ETF.',
			normalIIncAvailtoCommon: 'Normalized Inc Avail to Common',
			normalIncomeAfterTax: 'Normalized Income After Tax',
			normalizedIncomeBeforeTax: 'Normalized Income Before Taxes',
			northAmericanMarkets: 'North American markets',
			noSavedScreens: 'You do not have any saved screens.',
			noSymbolsInWatchlist: 'You have no symbols in {name}',
			noSymbolsAreInThisQuotelist: 'No symbols are in this Quote List.',
			noSecuritiesAreInThisQuoteList: 'No securities are in this Quote List.',
			noSymbolsAreInThisWatchlist: 'No symbols are in this Watch List.',
			noTopHoldings: 'No top holdings for this fund',
			noTopSectors: 'No top sectors for this fund',
			noWatchLists: 'No Watch Lists',
			noWatchListsLong: 'It looks like you have no watchlists created yet.',
			noWatchListsLongLink: 'Would you like to create a watchlist?',
			numberOf: 'Number of',
			numberOfShares: 'Number of Shares',
			numberOfResults: 'Number of results',
			numberOfUnits: 'Number of Units',
			numberOfContracts: 'Number of Contracts',
			numberOfDaysWithinNav: 'Number of days within +/- 0.5% of NAV',
			numberOfDaysAtPremium: 'Number of days at premium',
			numberOfDaysAtNav: 'Number of days at NAV',
			numberOfDaysAtDiscount: 'Number of days at discount',
			numberOfFundsInCategory: 'Number of funds in category',
			numberOfHoldings: 'Number of holdings',
			numOfAnalysts: 'Number of analysts',
			natResources: 'Natural Resources',
			netLoans: 'Net Loans',
			nonInterestBearingDept: 'Non-Interest Bearing Dep.',
			noteRcvbleLongTerm: 'Note Rcvble - Long Term',
			notesPayableSTDebt: 'Notes Payable/ST Debt',
			notesRcvbleShortTerm: 'Notes Recvble - Short Term',
			off52WeekHigh: '{symbol} is {diff52Week} off its 52-week high of {high} on {highDate}',
			off52WeekHighSr: '{symbol} is {direction} {diff52Week} off its 52-week high of {high} on {highDate}',
			officerNameTitle: 'Officer name/profile',
			ok: 'OK',
			okSr: 'Okay to delete and close dialogue',
			okSr2: 'Okay and close dialogue',
			of: 'of',
			oil: 'Oil',
			'oil&gasproducers': 'Oil & Gas Producers',
			'oilequipment,services&distribution': 'Oil Equipment, Services & Distribution',
			oilSr: 'Oil Chart',
			'oil&gas': 'Oil & Gas',
			onAssets: 'on Assets',
			oneMonth: 'One Month',
			onEquity: 'on Equity',
			oneWeek: 'One Week',
			oneYear: 'One Year',
			operatingMargin: 'Operating margin',
			open: 'Open',
			openAlert: 'Open {name} menu in a new dialogue',
			openMenu: 'Open menu',
			openSymbol: 'Open {symbol} menu in a new dialogue',
			openHelpContent: 'Open help content',
			openInt: 'Open int',
			openInterest: 'Open interest',
			openSymbolMenu: 'Open {symbol} menu in a new dialogue',
			opIncome: 'Operating Income',
			opsMaintenance: 'Operations & Maintenance',
			options: 'Options',
			optionChain: 'Option chain',
			optionsExercised: 'Options Exercised',
			optionsNotFound: '{symbol} does not have options.',
			optionChainFor: 'Option chain for {symbol}',
			openPdf: 'Open PDF',
			openPdfInANewWindow: 'Open {title} PDF in a new window',
			openInANewWindow: 'Open {title} in a new window',
			openPdfSr: 'Open {name} PDF',
			optionsCaption: '{count} Options',
			optionDetails: 'Option details',
			optionDetailsSrOnly: '{symbol} details',
			outOfTheMoney: 'Out of the money',
			other: 'Other',
			MSDRegionOther: 'Region Other',
			others: 'Others',
			otherAssets: 'Other Assets',
			otherExpense: 'Other Expense',
			otherFinancingCashFlow: 'Other Financing Cash Flow',
			otherInsuranceRev: 'Other Insurance Revenue',
			otherInterestIncome: 'Other Interest Income',
			otherInvestCashFlow: 'Other Investing Cash Flow',
			otherInvestCashFlowTot: 'OtherInvestCashFlowItms,Tot',
			otherNet: 'Other, Net',
			otherNonbankIncome: 'Other Non-Bank Income',
			otherNonCashItems: 'Other Non-Cash Items',
			otherNonInsurRev: 'Other Non-Insur Revenue',
			otherNonOpIncomeExpense: 'Other Non-Operating Income (Expenses)',
			otherNonutilRev: 'Other Non-Utility Revenue',
			otherOpExpense: 'Other Operating Expense',
			otherOpExpenseTotal: 'Other Operat Expse, Total',
			otherRev: 'Other Revenue',
			otherRevenue: 'Other Revenue',
			otherRevTotal: 'Other Revenue, Total',
			otherUnusualExpense: 'Other Unusual Expense',
			otherUnusualIncome: 'Other Unusual Income',
			otherUtilRev: 'Other Utility Revenue',
			otherAssetsTotal: 'Other Assets, Total',
			otherBearingLiabili: 'Other Bearing Liabilities ',
			otherBearingLiabiliTotal: 'Other Bearing Lblts, Total',
			otherComprehensiveIncome: 'Other Comprehensive Income',
			otherCurrLiabiliTotal: 'Other Curr. Lblts, Total',
			otherCurrentAsst: 'Other Current Assets',
			otherCurrentAsstTotal: 'Other Curr. Assets, Total',
			otherCurrntLiabili: 'Other Current Liabilities',
			otherDept: 'Other Deposits',
			otherEarningAsst: 'Other Earning Assets',
			otherEquity: 'Other Equity',
			otherEquityTotal: 'Other Equity, Total',
			otherErngAsstTotal: 'Other Erng. Assets, Total',
			otherLTLiabilities: 'Other LT Liabilities',
			otherLibailities: 'Other Liabilities',
			otherLibailitiesTotal: 'Other Liabilities, Total',
			otherLongTermAsst: 'Other Long Term Assets',
			otherPayables: 'Other Payables',
			otherPolicyholdFunds: 'Other Policyholders&#39; Funds',
			otherPropPlantEquip: 'Other Prop./Plant/Equip.',
			otherRcvlble: 'Other Receivables',
			otherRealEstateOwned: 'Other Real Estate Owned',
			otherSTBorrow: 'Other ST Borrowings',
			otherShortTrmInvest: 'Other Short Trm Invest.',
			overall: 'Overall',
			Overall: 'Overall',
			overallReturn: 'Overall return',
			overallReturnDescription: 'Morningstar\'s assessment of the ETF\'s excess return over a risk-free rate in comparison to similar funds. Overall return rating is a weighted average of the ETF\'s three-, five- and ten-year return ratings.',
			overallRisk: 'Overall risk',
			overallRiskDescription: 'Morningstar\'s assessment of variations in the ETF\'s monthly returns in comparison to similar funds. Overall risk rating is a weighted average of the ETF\'s three-, five- and ten-year risk rating.',
			overallTimeframe: 'Overall timeframe',
			overThe: 'over the',
			overview: 'Overview',
			Overview: 'Overview',
			'P-AM': 'Put',
			page: 'Page',
			pageCount: 'Page {count}',
			payableAccrued: 'Payable/Accrued',
			payoutRatio: 'Payout ratio',
			pctChange: 'Percentage Change',
			pdfReports: 'PDF Reports',
			pE: 'P/E',
			peRatio: 'P/E Ratio',
			peRatioTTM: 'P/E ratio (TTM)',
			percentChange: '% Change',
			performers: 'Performers',
			period: 'Period',
			'pharmaceuticals&biotechnology': 'Pharmaceuticals & Biotechnology',
			pleaseSelectAListToAddTo: 'Please select a watch list to add symbols to.',
			policyLoans: 'Policy Loans',
			preferrredStockNet: 'Preferred Stock, Net',
			prepaidExpense: 'Prepaid Expenses',
			primary: 'Primary',
			primaryEmail: 'Primary email',
			principPaymntSecurit: 'PrincipPaymnts fromSecurits',
			primarySr: 'Primary report results only if checked',
			priceAtLaunch: 'Price at launch',
			priceEarnings: 'Price/earnings',
			priceSales: 'Price/sales',
			priceSalesRatio: 'Price/sales ratio',
			priceCashFlow: 'Price/cash flow',
			priceCashFlowRatio: 'Price/cash flow',
			priceTangibleBook: 'Price/tangible book',
			priceFreeCashFlow: 'Price/free cash flow',
			pricing: 'Pricing',
			pricingAndVolume: 'Pricing & volume',
			pricingVolume: 'Pricing & volume',
			percentOfHolding: 'Percent of holding',
			percentOfHoldings: 'Percent of holdings',
			percentOfNetAssets: '% net assets',
			percentOfDaysWithinNav: 'Percent of days within +/- 0.5% of NAV',
			periodRangeError: 'Please select a valid end date that is later than the beginning date.',
			performance: 'Performance',
			performanceReview: 'Review performance',
			personalgoods: 'Personal Goods',
			phone: 'Phone: ',
			pleaseNameYourWatchList: 'Please name your new watch list',
			pleaseNote: 'Please note:',
			pleaseSelectSymbols: 'Please select symbol(s) to delete.',
			pleaseTryAgain: 'Please try again',
			portfolio: 'Portfolio',
			portfolioReview: 'Review portfolio',
			portfolioAllocation: 'Portfolio allocation',
			portfolioTurnover: 'Portfolio turnover',
			portfolioTurnoverDescription: 'Portfolio turnover is the measure of how frequently assets within an ETF are bought and sold.',
			preferences: 'Preferences',
			preferred: 'Preferred',
			preferredDiv: 'Preferred Dividends',
			product: 'Product',
			previousArticles: 'Update preceding results with previous page',
			previousClose: 'Previous close',
			previousCloseRange: 'Previous close range',
			previousCloseRangeDescription: 'The closing price range of the previous trading session.',
			previousMonthEnd: 'All other data as of previous month end.',
			premiumDiscount: 'Premium/discount',
			premiumDiscountDailyChart: 'Premium/discount (daily) chart',
			premiumDiscountDaily: 'Premium/discount (daily)',
			premiumDiscountToNav: 'Premium/discount to NAV',
			premDiscountChartDisclaimer: 'A pricing situation that occurs when an ETF is trading a <strong>premium</strong> or <strong>discount</strong> to its net asset value (NAV). An ETF trading at a price higher than its NAV is said to be trading at a premium. When an ETF trades at a lower price than its NAV, it is trading at a discount.',
			premiumAndDiscountDays: 'Premium and Discount days',
			premiumAndDiscountAmts: 'Premium and Discount amounts',
			preTaxMargin: 'Pre-tax margin',
			price: 'Price',
			pricePerformancePrix: 'Price',
			priceRatio: 'Price',
			priceChange: 'Price Change',
			sur: '',
			priceDollarGainers: 'Price $ gainers',
			priceDollarLosers: 'Price $ losers',
			pricePctGainers: 'Price % gainers',
			pricePctLosers: 'Price % losers',
			pricePerContract: 'Price Per Contract',
			pricePerformance: 'Price performance',
			pricePerShare: 'Price Per Share',
			pricePerUnit: 'Price Per Unit',
			profile: 'Profile',
			profitability: 'Profitability',
			profitMargin: 'Profit margin',
			proFormaAdj: 'Pro Forma Adjustment',
			proFormaBasicEPS: 'Pro Forma Basic EPS',
			proFormaDilutedEPS: 'Pro Forma Diluted EPS',
			proFormaNetIncome: 'Pro Forma Net Income',
			propertyTax: 'Property & Other Taxes',
			provisionIncomeTax: 'Provision for Income Taxes',
			purchAcqIntangibles: 'Purchase/Acq of Intangibles',
			purchaseDetails: 'Purchase details',
			purchasedPower: 'Purchased Power',
			purchasedRandDWrittenOff: 'Purchased R&D Written-Off',
			purchaseDetailsCurrency: 'Purchase Details Currency',
			purchaseFixedAssets: 'Purchase of Fixed Assets ',
			purchaseInvestment: 'Purchase of Investments',
			purchaseRD: 'Purchased R&D',
			puts: 'Puts',
			put: 'Put',
			pensionBeneOverfunded: 'Pension Bene. - Overfunded',
			pensionBenefitsUnderfunded: 'Pension Benefits - Underfunded',
			policyLiabilities: 'Policy Liabilities ',
			prefStockNonRdmbl: 'Pref. Stock - Non Rdmbl.',
			prefStockNonRdmblNet: 'Pref. Stock-Non Rdmbl, Net',
			propPlantEquipGross: 'Prop./Plant/Equip. - Gross',
			propPlantEquipNet: 'Prop./Plant/Equip. - Net',
			provDoubtAccts: 'Prov. for Doubtful Accts',
			quarterly: 'Quarterly',
			quarterlyOption: 'Quarterly',
			quarterlyIncomeStatementItem: 'Quarterly income statement item',
			quarterlyBalanceSheetItem: 'Quarterly balance sheet item',
			quarterlyCashFlowItem: 'Quarterly cash flow item',
			quickScreener: 'Quick screener',
			quintileForIndustry: '{quintile} for industry',
			quotedetail: 'Quote detail',
			quotesDelayedBy15: 'Quotes delayed by at least 15 minutes',
			'Quote lists': 'Quote lists',
			quickRatio: 'Quick ratio',
			quarterlyEps: 'Quarterly EPS',
			rAndD: 'Research & Development',
			rangeVariable: '{min} to {max}',
			rate: 'Rate',
			ratingSummary: 'Rating summary',
			ratingSummaryText1: 'The consensus recommendation is ',
			ratingSummaryText2: ' or ',
			ratingSummaryText3: ' as of ',
			ratingSummaryText4: ' with ',
			ratingSummaryText5: ' of ',
			ratingSummaryText6: ' total analysts reporting.',
			ratingSummaryText: 'The consensus recommendation is {rating} or  {ratingText} as of {ratingDate} with {opinions} of {analysts} total analysts reporting.',
			ratingPrevSummaryText: ' This is an {trend} trend from the previous rating of {ratingText} ({rating}). As of {prevDate} ',
			rating: 'Rating',
			ratio: 'Ratio',
			rename: 'Rename',
			readArticle: 'Read article',
			readCommentarySR: 'Read commentary about {headline}',
			readCommentary: 'Read commentary',
			readArticleSr: 'Read article about {headline}',
			readMoreReports: 'More reports',
			realestate: 'Real estate',
			MSDStockSectorRealEstate: 'Sector Real estate',
			'realestateinvestment&services': 'Real Estate Investment & Services',
			realestateinvestmenttrusts: 'Real Estate Investment Trusts',
			realEstateOpExpense: 'Real Estate Operat Expnse',
			realEstateOpGain: 'Real Estate Operation Gain',
			realizedGainLoss: 'Realized Gains (Losses)',
			recentEtfs: 'Recent ETFs',
			recentEtfsHidden: 'Select from recent ETFs',
			recentSymbolNews: 'Recent {symbol} news',
			recentWatchlistNews: 'Recent {watchlist} news',
			redemptionCharge: 'Redemption charge',
			refreshList: 'Refresh List',
			refinitivDisclaimer: 'Market data and news are supplied by Refinitiv. © Refinitiv. Republication or redistribution of Refinitiv content, including by framing or similar means, is prohibited without the prior written consent of Refinitiv. Refinitiv is not liable for any errors or delays in Refinitiv content, or for any actions taken in reliance on such content. Refinitiv’s logo is a trademark of Refinitiv and its affiliated companies.',
			reinsuranceExpense: 'Reinsurance - Expense',
			reinsuranceIncome: 'Reinsurance - Income',
			relativeStrengthIndex: 'Relative strength index (RSI)',
			relativeStrengthIndexDescription: 'The RSI compares the magnitude of a security\'s recent gains to the magnitude of its recent losses, and turns that information into a number that ranges from 0 to 100. The RSI indicates whether a security is overbought or oversold. RSI over 70 may indicate that a security is overbought, and RSI under 30 may indicate that it is oversold.',
			relativeVolume: 'Relative volume',
			removeSelected: 'Remove Selected',
			reports: 'Reports',
			reportNotAvailable: 'Report not available',
			reportsReview: 'Review reports',
			repurchRetirementCommon: 'Repurch/Retirement Common',
			repurchRetirementPreferd: 'Repurch/Retirement Preferd',
			repurchRetirmntCommonPreferr: 'Repurch/Retirmnt Common/Preferr',
			restructuringCharge: 'Restructuring Charge',
			reportTitle: 'Report title',
			researchHighlights: 'Research highlights (PDF files)',
			results: '{count} results',
			resultsNoCount: 'Results',
			return: 'Return',
			returnYear: '{year} return',
			returns: 'Returns',
			returnToNews: 'Return to news',
			returnToReports: 'Return to reports',
			returnToCommentary: 'Return to commentary',
			returnsNotAnnualized: ' Returns for time periods under 1 year are not annualized and represent actual returns.',
			returnOnAssets: 'Return on assets',
			returnOnAssets2: 'Return on assets',
			returnOnInvestments: 'Return on investments',
			returnOnEquity: 'Return on equity',
			returnOnEquity2: 'Return on equity',
			revenue: 'Revenue',
			review: 'Review',
			reviewCharts: 'Review charts',
			reviewChartsSymbolSr: 'Review {symbol} charts',
			reviewLipperLeaders: 'Review Lipper Leaders',
			reviewLipperLeadersSymbolSR: 'Review {symbol} Lipper Leaders',
			reviewScreen: 'Review',
			reviewSavedScreens: 'Review saved screens',
			reviewSavedSearches: 'Review saved searches',
			reviewSnapshotSR: 'Review {symbol} snapshot',
			reviewPerformanceSR: 'Review {symbol} performance',
			reviewPortfolioSR: 'Review {symbol} portfolio',
			reviewReportsSR: 'Review {symbol} reports',
			revenueGrowthNfy: 'Revenue growth NFY',
			revenueGrowthNfy2: 'Revenue growth NFY',
			revenuePerShare: 'Revenue per share',
			revenueGrowth: 'Revenue growth',
			reviewCommentary: 'Review commentary',
			reviewSpecificCommentary: 'Review {title} commentary',
			reviewFundamentals: 'Review fundamentals',
			reviewPerformance: 'Review performance',
			reviewPortfolio: 'Review portfolio',
			reviewMarketsAtAGlance: 'Review markets at a glance',

			reviewNumResults: 'Review {numResults} results',
			reviewNMatches: 'Review {count} matches',
			rho: 'Rho',
			rsi14d: '14-day RSI',
			rSquaredVsCategory: 'R-Squared vs. {category}',
			rSquaredVsCategoryTooltip: 'R-squared shows the strength of a linear relationship between a security and its benchmark over a stated period of time. R-squared values range from 0 to 1. A high r-squared indicates a strong linear relationship.',
			rdmblConvPrefStock: 'Rdmbl Conv. Pref. Stock',
			redeemablePrefStockSRPR: 'Redeemable Preferred Stock',
			redeemablePrefStockZPSK: 'Redeemable Preferred Stock',
			reinsuranceAsst: 'Reinsurance - Asset',
			reinsuranceLiability: 'Reinsurance - Liability',
			repurchaseAgreements: 'Repurchase Agreements',
			reserves: 'Reserves',
			restrictedCashCurrent: 'Restricted Cash - Current',
			restrictedCashLT: 'Restricted Cash - LT',
			retEarnAccumDeficit: 'Ret. Earn.(Accum. Deficit)',
			risk: 'Risk',
			rSquared: 'R-Squared',
			rSquaredYear: 'R-squared ({year} year)',
			MSDPrimaryIndexRSquaredY1Ptile: 'R-squared (1 year)',
			MSDPrimaryIndexRSquaredY3Ptile: 'R-squared (3 year)',
			MSDPrimaryIndexRSquaredY5Ptile: 'R-squared (5 year)',
			MSDPrimaryIndexRSquaredY10Ptile: 'R-squared (10 year)',
			MSDPrimaryIndexRSquaredY1: 'R-squared (1 year)',
			MSDPrimaryIndexRSquaredY3: 'R-squared (3 year)',
			MSDPrimaryIndexRSquaredY5: 'R-squared (5 year)',
			MSDPrimaryIndexRSquaredY10: 'R-squared ({10 year)',
			rSquaredDescription: 'One-, three-, five- or ten-year r-squared is an indication of how well the linear relationship between an ETF\'s performance and the performance of its benchmark exists over a specified period. R-squared values range from 0 to 1. A high r-squared indicates that the ETF\'s performance has been in line with the benchmark, while a lower r-squared indicates that the ETF does not act like the benchmark.',
			saleBusiness: 'Sale of Business',
			saleFixedAssets: 'Sale of Fixed Assets',
			saleIntangible: 'Sale of Intangible',
			saleIssuanceCommon: 'Sale/Issuance of Common',
			saleIssuancePreferred: 'Sale/Issuance of Preferred',
			saleIssueCommonPrefrd: 'Sale/Issue of Common/Prefrd',
			saleMaturityInvestments: 'Sale/Maturity of Investment',
			salesRatio: 'Sales Ratio',
			salesReturnAllow: 'Sales Returns & Allowances',
			save: 'Save',
			saveAs: 'Save as',
			saveScreenInNewDialog: 'Save screen in a new dialogue',
			saveAsNewScreenInANewDialog: 'Save as a new screen in a new dialogue',
			savedAdvancedSearches: 'Saved advanced searches',
			savedScreenLinkCaption: '{screenName} displayed in accompanying table',
			savedScreens: 'Saved screens',
			savedScreensCaption: 'Saved screens table',
			saveSr: 'Save and go to watch lists',
			saveAndCloseDialogue: 'Save and close dialogue',
			savedItems: 'Saved Items',
			savedSearches: 'Saved searches',
			saveWatchlist: 'Save and go to watchlist',
			screenIncludesCriteria: 'This Screen includes the following Criteria:',
			screenName: 'Screen name',
			Screener: 'Screener',
			ScreenerDistribution: 'Distribution',
			screenerFundType: 'Fund type',
			screenerFundTypeDescription: 'A broad categorization of ETFs based on asset class, strategy or country.',
			screenerPortfolio: 'Portfolio',
			screeners: 'Screeners',
			screenerLinkSr: '{type} screener',
			screenerPreview: 'Our new screeners page isn’t quite done, but will be soon. Once it’s ready, all your saved screener information will be accessible via our new and improved format. Stay tuned!',
			search: 'Search',
			searchByKeyword: 'Search by keyword',
			searchIn: 'Search in',
			searchOrSecurity: 'or security',
			searchSymbol: 'Search by symbol',
			searchSymbolOrSecurity: 'Search by symbol or security',
			searchSymbolOrCompany: 'Search by symbol or company',
			searchSymbolToAddToList: 'Search for symbols to add to your list.',
			secondaryEmail: 'Secondary email',
			sectorEquity: 'Sector equity',
			security: 'Security',
			selectOne: 'Select one',
			selectOneOrMore: 'Select one or more values',
			selectPeriod: 'Select period',
			selectaListToAdd: 'Select a list to add',
			selectWatchList: 'Select watch list',
			selectWatchlistSrOnly: 'Select {name}',
			selectYourCriteria: 'Select your criteria from the accompanying dropdown menus',
			selectAll: 'Select all',
			selectSector: 'Select a sector',
			selectSectorFirst: 'Select a sector first',
			selectSymbol: 'Select {symbol} from list',
			selectSymbolBasic: 'Select {symbol}',
			selectSymbolFromQuoteList: 'Select {symbol} from quote list',
			selectAllSecurities: 'Select all current watch list securities',
			selectAllOptions: 'Select all current quote list options',
			selectAllFunds: 'Select all current quote list funds',
			selectAllQLSecurities: 'Select all current quote list securities',
			selectAStrikePrice: 'Select a Strike Price',
			selectMaxCompare: 'Please select maximum {count} similar ETFs to compare.',
			selectYourCriteriaFromDropdown: 'Select your criteria from the accompanying dropdown menus',
			sellGenAdminExpenseTotal: 'Sell/General/Admin. Expenses, Total',
			sellingGenAdminExpense: 'Selling/Gen/Admin Expense',
			seriesBy: 'Series by',
			setAlerts: 'Set alerts',
			setStockAlert: 'Set alert',
			setWatchListAlerts: 'Set watch list alerts',
			setSymbolAlerts: 'Set {symbol} alerts',
			services: 'Services',
			shares: 'Shares',
			sharpeRatio: 'Sharpe ratio',
			sharpeRatioYear: 'Sharpe ratio ({year} year)',
			MSDSharpeRatioY1Ptile: 'Sharpe ratio (1 year)',
			MSDSharpeRatioY3Ptile: 'Sharpe ratio (3 year)',
			MSDSharpeRatioY5Ptile: 'Sharpe ratio (5 year)',
			MSDSharpeRatioY10Ptile: 'Sharpe ratio (10 year)',
			MSDSharpeRatioY1: 'Sharpe ratio (1 year)',
			MSDSharpeRatioY3: 'Sharpe ratio (3 year)',
			MSDSharpeRatioY5: 'Sharpe ratio (5 year)',
			MSDSharpeRatioY10: 'Sharpe ratio (10 year)',
			sharpeRatioDescription: 'The one-, three-, five- or ten-year Sharpe ratio indicates how well the excess return of an asset rewards the investor for the risk taken over a specified period. The higher Sharpe ratio, the better the risk-adjusted performance.',
			sharpRatioTooltip: 'Sharpe ratio indicates how well an asset’s excess return rewards the investor for the risk taken over a stated period of time. The higher the sharpe ratio, the better the risk-adjusted performance.',
			sharesOutstanding: 'Shares outstanding',
			sharesRelated: 'Share related items (millions) ',
			shortDurationHighQuality: 'Short Duration/High Quality',
			shortDurationMediumQuality: 'Short Duration/Medium Quality',
			shortDurationLowQuality: 'Short Duration/Low Quality',
			shortPosition: 'Short Position %',
			shortRestriction: 'Short restriction',
			shortStrategy: 'Short strategy',
			shortStrategyChart: 'Short strategy chart',
			shortTermDebtIssued: 'Short Term Debt Issued',
			shortTermDebtNet: 'Short Term Debt, Net',
			shortTermDebtReduce: 'Short Term Debt Reduction',
			show: 'Show',
			showAll: 'Show all',
			showChangeInDollars: 'Show change in dollars',
			showChangeInPercent: 'Show change in percent',
			shownIn: 'Shown In',
			sliceNumber: 'Slice Number',
			silver: 'Silver',
			silverSr: 'Silver chart',
			sixMonthTBill: '6 Month T-Bill',
			similarETFs: 'Similar ETFs',
			similarETFsNotFound: 'No similar ETFs found',
			simpleRange: 'Simple range',
			since: 'Since',
			size: 'Size',
			slowStochastics: 'Slow stochastics',
			sma15day: '15-day SMA',
			sma50day: '50-day SMA',
			sma100day: '100-day SMA',
			sma200day: '200-day SMA',
			smallCapBlend: 'Small Cap Blend',
			smallCapGrowth: 'Small Cap Growth',
			smallCapValue: 'Small Cap Value',
			snapshot: 'Snapshot',
			snapshotReview: 'Review snapshot',
			snapshotLower: 'snapshot',
			snapshotPortfolioDisclaimer: 'Premium/discount data updated daily. All other data as of previous month end.',
			'software&computerservices': 'Software & Computer Services',
			softwareDevCosts: 'Software Development Costs',
			sortBy: 'Sort by',
			sortable: 'sortable',
			sp500ChartHidden: 'S&P 500 chart',
			spTsx60ChartHidden: 'S&P/TSX 60 chart',
			spTsxCompChartHidden: 'S&P/TSX Composite chart',
			spTsxVentureChartHidden: 'S&P/TSX venture composite chart',
			standardDeviation: 'Standard deviation',
			standardDeviationYear: 'Standard deviation ({year} year)',
			MSDStandardDeviationY1Ptile: 'Standard deviation (1 year)',
			MSDStandardDeviationY3Ptile: 'Standard deviation (3 year)',
			MSDStandardDeviationY5Ptile: 'Standard deviation (5 year)',
			MSDStandardDeviationY10Ptile: 'Standard deviation (10 year)',
			MSDStandardDeviationY1: 'Standard deviation (1 year)',
			MSDStandardDeviationY3: 'Standard deviation (3 year)',
			MSDStandardDeviationY5: 'Standard deviation (5 year)',
			MSDStandardDeviationY10: 'Standard deviation (10 year)',
			standardDeviationDescription: 'Standard deviation measures the variation of a fund\'s return over a one-, three-, five- or ten-year period.',
			standardDeviationTooltip: 'Standard deviation measures the variation of a fund’s return over a stated period of time.',
			starsOfStarsRating: '{stars} of {max} star',
			starsOfStarsMaxRating: '{max} of {max} stars',
			startDay: 'Start day',
			startMonth: 'Start month',
			startYear: 'Start year',
			steamOps: 'Steam Operations',
			stochasticOscillator: 'Stochastic oscillator',
			stochasticOscillatorDescription: `The stochastic oscillator consists of 2 lines, a K% line and a D% line. The K% line demonstrates where a security's price closed relative to its price range over a given period of time. The D% line provides smoothing of the K% line by taking a 3-day moving average of the K%.
			\nA bullish signal occurs when K% crosses above D%. A bearish signal occurs when K% crosses below D%. `,
			stockBaseComp: 'Stock Based Compensation',
			stockScreener: 'Screeners',
			stockCentre: 'Stock Centre',
			stocksETFsIndices: 'Stocks, ETFs & Indices',
			strike: 'Strike',
			strikesShown: 'Strikes shown',
			strikePrice: 'Strike price',
			strikeType: 'Strike/Type',
			stockDetail: 'Stock detail',
			source: 'Source',
			stocks: 'Stocks',
			stocksAndIndicesCaption: '{count} stocks and/or indices',
			stockEarningsChartHeader: '{symbol} earnings per share (EPS)',
			stockEarningsLink: 'Click or tab through bars for more info',
			subjectToChangeDisclaimer1: 'All Rating or Ranking information is subject to change on a monthly basis.',
			subjectToChangeDisclaimer2: 'The rate of return or mathematical table shown is used only to illustrate the effects of the compound growth rate and is not intended to reflect future values of the fund or returns on investment in the fund.',
			suspendFrom: 'Suspend from',
			summary: 'Summary',
			supportservices: 'Support Services',
			symbol: 'Symbol',
			symbolLabel: 'Symbol',
			symbols: 'Symbols',
			symbolLastChangeBottom: 'last change',
			symbolLastChangeTop: 'Symbol',
			symbolCompany: 'Symbol/company',
			symbolCompanyName: 'Symbol or company name',
			symbolChart: '{symbol} chart ',
			symbolDetails: '{symbol} details',
			symbolDoesNotHaveAnyOptions: '{symbol} does not have options.',
			symbolInactive: 'This symbol is no longer active.',
			symbolsForWatchlist: 'Symbols for Watch List',
			symbolsInList: 'Symbols in list',
			symbolDoesNotExist: 'The symbol you are trying to view does not exist. Please use the Symbol Search to find another security.',
			symbolNotFound: 'Symbol Not Found',
			symbolNotFoundTryAgain: 'The symbol is invalid.',
			secDepotsits: 'Security Deposits',
			secIndbtdRltPrts: 'Sec./Indbtd. of Rlt\'d Prts',
			securitiesHeld: 'Securities Held ',
			securitiesSale: 'Securities for Sale',
			sepAccntLiability: 'Sep. Accounts - Liability',
			seperateAcctAssets: 'Separate Accounts - Assets',
			shortTermInvs: 'Short Term Investments',
			shrOutComSktIss2: 'Shr Outs.-Com. Stk. Iss. 2',
			shrOutComSktIss3: 'Shr Outs.-Com. Stk. Iss. 3',
			shrOutComSktIss4: 'Shr Outs.-Com. Stk. Iss. 4',
			shrOutsPrimaryLss: 'Shr Outs.-Com. Primary Iss.',
			shrsOutPrefPrimaryIss: 'Shrs Out-Pref Primary Iss.',
			shrsOutPrefStkIss2: 'Shrs Out-Pref Stk Iss. 2',
			shrsOutPrefStkIss3: 'Shrs Out-Pref Stk Iss. 3',
			shrsOutPrefStkIss4: 'Shrs Out-Pref Stk Iss. 4',
			shrsOutPrefStkIss5: 'Shrs Out-Pref Stk Iss. 5',
			shrsOutPrefStkIss6: 'Shrs Out-Pref Stk Iss. 6',
			startEtfScreener1: 'Start your ETF research with our ',
			startEtfScreener2: ', or complete your own search using our ',
			tobacco: 'Tobacco',
			taxableBond: 'Taxable bond',
			taxablebond: 'Taxable bond',
			taxOnExtraItem: 'Tax on Extraordinary Items',
			tCost: 'cost',
			'Technical analysis': 'Technical analysis',
			technicalAnalysis: 'Technical Analysis',
			Technical: 'Technical',
			technicals: 'Technicals',
			technology: 'Technology',
			MSDStockSectorTechnology: 'Sector Technology',
			'technologyhardware&equipment': 'Technology Hardware & Equipment',
			telecommunications: 'Telecommunications',
			telecommunicationServices: 'Telecommunication services',
			tenure: 'Tenure',
			tenYearBond: '10 Year Bond',
			test: 'test',
			text: 'Text',
			the: 'The',
			theLowerCase: 'the',
			thereAreCurrentlyNoGainers: 'There are currently no gainers today',
			thereAreCurrentlyNoLoser: 'There are currently no losers today',
			thereAreNoReports: 'There are no news reports available for this ETF.',
			thereAreNoNewsReports: 'There are no news reports available for this ETF.',
			theta: 'Theta',
			theWeekAhead: 'The week ahead',
			theWeekAheadWC: 'A look at the week ahead',
			theWeekAheadAnalystReports: 'The week ahead',
			theWeekAheadDescription: 'Stay ahead of the market with analyses of the latest economic developments affecting fixed income, equity and foreign exchange markets, along with previews of next week\'s key releases.',
			timeframe: 'Timeframe',
			thirtyYearBond: '30 Year Bond',
			thisOptionHasExpired: 'This option has expired.',
			thousand: 'thousand',
			threeMonth: 'Three Month',
			threeMonthTBill: '3 Month T-Bill',
			threeYearTBill: '3 Year T-Bill',
			to: 'to',
			tools: ' tools.',
			toUpperCase: 'To',
			toContextDe: 'to',
			toLowerCase: 'to',
			today: 'Today',
			todaysChange: 'Today\'s change',
			todaysChangeSr: '',
			todaysMovers: "Today's movers",
			todayFunds: "Today's funds",
			todayIndex: "Today's indices",
			todaysOpen: 'Today\'s open',
			todaysRange: 'Today\'s range',
			todaysRangeButtonSr: 'Expand for 52 week range details',
			topPerformingBelowAverageMEREquityETFs: 'Top Performing Below Average MER Equity ETFs',
			topPerformingBelowAverageMEREquityETFs_desc: 'ETFs that invest in Canadian, U.S. and international equity holdings, with a below-average MER and an above-average 1-year return.',
			topPerformingEmergingMarketsETFs: 'Top Performing Emerging Markets ETFs',
			topPerformingEmergingMarketsETFs_desc: 'ETFs that invest in the emerging markets, with an above-average 1-year return.',
			top10Holdings: 'Top 10 holdings',
			top10HoldingsDescription: 'The top 10 holdings within an ETF portfolio.',
			topGlobalHoldings: 'Top global holdings',
			topGlobalHoldingsDescription: 'The top country holdings within an ETF portfolio.',
			topGlobalHoldingsTooltip: 'Country breakdown is calculated using only the portfolio’s long stock and bond holdings, by country of domicile.',
			topHoldings: 'Top holdings',
			topNMostActive: 'Top {count} Most Active',
			topNMovers: 'Top {count} Movers',
			topPctMovers: 'Top % movers',
			topPerformers: 'Top performers',
			topPriceMovers: 'Top $ movers',
			topMovers: 'Top movers',
			topSectorHoldings: 'Top sector holdings',
			topSectorHoldingsDescription: 'The top sector holdings within an ETF portfolio.',
			topSectorsByPercent: 'Top sectors by percent',
			topSectorsHeld: 'Top sectors held',
			topSecuritiesHeld: 'Top securities held',
			total: 'Total',
			totalAdjToNetIncome: 'Total Adjust to Net Income',
			totalAssets: 'Total Assets',
			totalAssetsLowerA: 'Total assets',
			totalCashDividsPaid: 'Total Cash Dividends Paid',
			totalCashFinancing: 'Total Cash From Financing',
			totalCashInvest: 'Total Cash from Investing',
			totalCashOps: 'Total Cash from Operations',
			totalDebtEquity: 'Total debt/equity',
			totalDebtIssue: 'Total Debt Issued',
			totalDebtReduce: 'Total Debt Reduction',
			totalExtraItem: 'Total Extraordinary Items',
			totalGainLossOf: 'Total Gain/Loss of {issueType}',
			totalInterestExpense: 'Total Interest Expense',
			totalNetAssets: 'Total net assets',
			totalNetAssetsShort: 'TNA',
			totalNetAssetsDescription: 'Total net assets are calculated by subtracting an ETF\'s liabilities from its assets.',
			totalNetAssetsRange0to10M: '< 10M',
			totalNetAssetsRange10to50M: '10M-50M',
			totalNetAssetsRange50to500M: '50M-500M',
			totalNetAssetsRange500to1000M: '500M-1B',
			totalNetAssetsRangeabove1000M: '> 1B',
			totalOpExpense: 'Total Operating Expense',
			totalPremEarned: 'Total Premiums Earned',
			totalRev: 'Total Revenue',
			totalSpecialItems: 'Total Special Items',
			totalReturnPerformance: 'Total return performance',
			trade: 'Trade',
			tradeSr: 'Trade {symbol}',
			trailingTwelveMonths: 'Trailing twelve months (TTM)',
			type: 'Type',
			typeContextCategory: 'Type',
			trStockReports: 'TR StockReports+',
			tradePricesAndQuotes: 'Trade prices and quotes are not sourced from all markets.',
			tradingAcctInterest: 'Trading Account Interest',
			trailingReturnPeriod: 'Trailing return period',
			trailingRollingReturns: 'Trailing rolling returns',
			trailingRollingReturnTooltip: 'Trailing return is the total return of a fund over a specific time period. For example, the latest bar represents an ETF’s total return based on the selected time period (e.g., 3 months). The previous bar is also based on the selected time period, but is calculated at the end of the previous month rather than the latest month.',
			'travel&leisure': 'Travel & Leisure',
			treasuryStock: 'Treasury Stock',
			ttm: 'TTM',
			ttm1YrAgo: 'TTM vs. same 1 year ago',
			ttmLabel: 'TTM',
			totalCurrentAsst: 'Total Current Assets',
			totalCurrentLiabili: 'Total Current Liabilities',
			totalDebt: 'Total Debt',
			totalDept: 'Total Deposits',
			totalEquity: 'Total Equity',
			totalGrossLoan: 'Total Gross Loans',
			totalInvent: 'Total Inventory',
			totalInvestSec: 'Total Invest. Securities',
			totalLibailities: 'Total Liabilities',
			totalLongTermDebt: 'Total Long Term Debt',
			totalRcvbleNet: 'Total Receivables, Net',
			totalSTBorrow: 'Total ST Borrowings',
			totalShareholdersEquity: 'Total Liabilities & Shareholders&#39; Equity',
			totalUtilPlantNet: 'Total Utility Plant, Net',
			tradeAcctsRcvbleGross: 'Trade Accts Recvble, Gross',
			tradeAcctsRcvbleShortTerm: 'Trade Accts Recvble, Net',
			tradingAcctAsst: 'Trading Account Assets ',
			transportation: 'Transportation',
			treasuryStockCommon: 'Treasury Stock - Common',
			treasuryStockPref: 'Treasury Stock - Preferred',
			trsyShrCommIss2: 'Trsy. Shrs-Comm. Iss. 2',
			trsyShrCommIss3: 'Trsy. Shrs-Comm. Iss. 3',
			trsyShrCommIss4: 'Trsy. Shrs-Comm. Iss. 4',
			trsyShrCommPrimaryLss: 'Trsy. Shrs-Comm. Primary Iss.',
			trsyShrPrefIss1: 'Trsry Shrs-Pref. Iss. 1',
			trsyShrPrefIss2: 'Trsry Shrs-Pref. Iss. 2',
			trsyShrPrefIss3: 'Trsry Shrs-Pref. Iss. 3',
			trsyShrPrefIss4: 'Trsry Shrs-Pref. Iss. 4',
			trsyShrPrefIss5: 'Trsry Shrs-Pref. Iss. 5',
			trsyShrPrefIss6: 'Trsry Shrs-Pref. Iss. 6',
			ttlCommSharesOuts: 'Ttl Comm. Shares Outs.',
			unableToLoadPage: 'We were unable to load this page successfully',
			underlying: 'Underlying',
			underlyingprice: 'Underlying Price',
			underwritingCommissions: 'Underwriting & Commissions',
			units: 'Units',
			unrealizedGain: 'Unrealized Gains',
			unrealizedGains: 'Unrealized Gains (Losses)',
			unrealizedLoss: 'Unrealized Losses',
			unrealizedLossGains: 'Unrealized Losses (Gains)',
			unusualExpenseIn: 'Unusual Expense (Income)',
			up: 'Up',
			us: 'US',
			usGrowthAtReasonablePrice: 'U.S. - growth at a reasonable price',
			usGrowthAtReasonablePrice_desc: 'US stocks with reasonable price growth',
			usLowPEStocks: 'U.S. - low PE stocks',
			usValueStocks: 'U.S. - value stocks',
			usCommodityETFs: 'U.S. Commodity ETFs',
			usCommodityETFs_desc: 'ETFs that invest in Commodity securities.',
			usDollars: 'US dollars',
			usePreviousSearch: 'Use the previous search to add symbols.',
			usEquity: 'US equity',
			usEquityETFs: 'U.S. Equity ETFs',
			usEquityETFs_desc: 'ETFs that invest in U.S. equity holdings.',
			usFixedIncomeETFs: 'U.S. Fixed Income ETFs',
			usFixedIncomeETFs_desc: 'ETFs that invest in U.S. fixed income securities.',
			usGaapAdj: 'U.S. GAAP Adjustment',
			usPrime: 'US Prime',
			ususualItems: 'Unusual Items',
			usVsCanadianDollars: 'US vs Canadian Dollars',
			USvsCanadianSrOnly: 'U S vs Canadian Dollars',
			usNews: 'U S News',
			utilities: 'Utilities',
			MSDStockSectorUtilities: 'Sector Utilities',
			unbilledUtilRev: 'Unbilled Utility Revenues',
			unearnedIncome: 'Unearned Income',
			unearnedPremRev: 'Unearned Prem. Or Rev.',
			unrealizedGainLoss: 'Unrealized Gain (Loss)',
			utilPlant: 'Utility Plant',
			utilPlantAccumDeprc: 'Utlty. Plnt. Accum. Deprc.',
			utilPlantNet: 'Utility Plant, Net',
			vacationStop: 'Vacation stop',
			value: 'Value',
			valuation: 'Valuation',
			vega: 'Vega',
			viewAll: 'View All',
			viewBy: 'View By',
			viewMore: 'View more',
			viewTop5: 'View top 5',
			viewTop10: 'View top 10',
			viewMoreReports: 'View More Reports',
			viewMarketMovers: 'View Market Movers',
			viewMoreNewsHeadlines: 'More news',
			viewOption: 'List Option',
			volume: 'Volume',
			avgVolume: 'Average volume',
			threeMonths: '(3 mos)',
			threeMonthsSr: '3 months',
			volatility: 'Volatility',
			vsSnP500: 'vs S&P 500',
			vsSnP5004Week: 'vs S&P 500 1 month',
			vsSnP50052Week: 'vs S&P 500 1 year',
			warrantsConverted: 'Warrants Converted',
			watchlist: 'Watch List',
			watchlistDeliveryConfigured: 'Your watch list alerts are configured to be sent {period}.',
			watchlistAlertDescription: 'Receive an email summary of price performance of stocks in your watch lists. This daily, weekly or monthly scheduled alert includes stock closing prices, volumes and 52-week highs and lows.',
			watchlistAlerts: 'Watch list alerts',
			watchlistAlertsDescription: 'Receive an email summary of price performance of stocks in your watch lists. This daily, weekly or monthly scheduled alert includes stock closing prices, volumes and 52-week highs and lows.',
			'Watch list': 'Watch list',
			'Watch lists': 'Watch lists',
			warningIcon: 'Warning',
			watchlistDataUnavailable: '{section} data is not available for {type}',
			watchlistDataUnavailable2: '{section} data is not available for {type}',
			watchlistDataUnavailable3: '{type} {section} data is not available',
			watchlistInsufficientCapacity: 'There is insufficient space to add symbols to the list.',
			watchlistTotals: 'Watch List Totals',
			watchlistNameLenghtError: 'Watch list names cannot exceed 20 characters.',
			watchlistNews: '{watchlist} news',
			watchlistSymbolDetail: '{symbol} details',
			waterOps: 'Water Operations',
			web: 'Web: ',
			weekly: 'Weekly',
			weeksVariable: '{number} weeks',
			yearly: 'Yearly',
			yes: 'Yes',
			yield: 'Yield',
			yieldLabel: 'Yield',
			youMustSelect: 'Please select symbols to add.',
			yourSelectionAltersData: 'Your selection alters accompanying data',
			ytd: 'YTD',
			ytdEtfPerformance: 'YTD',
			ytdPct: 'YTD %',
			ytdReturn: 'YTD return',
			yourWatchlistEmpty: 'Your watch list is currently empty.',
			yourPdfDownloaded: 'Your PDF has been downloaded',

			// begin morningstar categories
			'allocation--15%to30%equity': 'Allocation--15% to 30% equity',
			'allocation--30%to50%equity': 'Allocation--30% to 50% equity',
			'allocation--50%to70%equity': 'Allocation--50% to 70% equity',
			'allocation--70%to85%equity': 'Allocation--70% to 85% equity',
			'allocation--85%+equity': 'Allocation--85%+ equity',
			alternativecreditfocused: 'Alternative credit focused',
			alternativeequityfocused: 'Alternative equity focused',
			alternativemarketneutral: 'Alternative market neutral',
			'alternativemulti-strategy': 'Alternative multi-strategy',
			alternativeother: 'Alternative other',
			asiapacificequity: 'Asia Pacific equity',
			bankloan: 'Bank loan',
			bearmarket: 'Bear market',
			canada: 'Canada',
			MSDRegionCanada: 'Region Canada',
			canadaAndUS: 'Canada and US',
			canadiancorporatefixedincome: 'Canadian corporate fixed income',
			'canadiandividend&incomeequity': 'Canadian dividend & income equity',
			canadianequity: 'Canadian equity',
			canadianequitybalanced: 'Canadian equity balanced',
			canadianFixedIncomeETFs: 'Canadian Fixed Income ETFs',
			canadianFixedIncomeETFs_desc: 'ETFs that invest in Canadian fixed income securities.',
			canadianfixedincome: 'Canadian fixed income',
			canadianfixedincomebalanced: 'Canadian fixed income balanced',
			'canadianfocusedsmall/midcapequity': 'Canadian focused small/mid cap equity',
			canadianinflationprotectedfixedincome: 'Canadian inflation protected fixed income',
			canadianlongtermfixedincome: 'Canadian long term fixed income',
			canadianmoneymarket: 'Canadian money market',
			canadianneutralbalanced: 'Canadian neutral balanced',
			canadianshorttermfixedincome: 'Canadian short term fixed income',
			'canadiansmall/midcapequity': 'Canadian small/mid cap equity',
			chinaregion: 'China region',
			commoditiesbroadbasket: 'Commodities broad basket',
			commoditiesenergy: 'Commodities energy',
			commoditiesfocused: 'Commodities focused',
			communications: 'Communications',
			convertibles: 'Convertibles',
			corporatebond: 'Corporate bond',
			daily: 'Daily',
			dailyVolume: 'Daily volume',
			diversifiedemergingmarkets: 'Diversified emerging markets',
			'diversifiedpacific/asia': 'Diversified Pacific/Asia',
			emergingmarketsbond: 'Emerging markets bond',
			emergingmarketsequity: 'Emerging markets equity',
			emergingmarketsfixedincome: 'Emerging markets fixed income',
			emergingmarketslocalcurrencybond: 'Emerging markets local currency bond',
			energyequity: 'Energy equity',
			energylimitedpartnership: 'Energy limited partnership',
			equityenergy: 'Equity energy',
			equitypreciousmetals: 'Equity precious metals',
			europestock: 'Europe stock',
			europeanequity: 'European equity',
			financial: 'Financial',
			financialservicesequity: 'Financial services equity',
			floatingrateloans: 'Floating rate loans',
			foreignlargeblend: 'Foreign large blend',
			foreignlargegrowth: 'Foreign large growth',
			foreignlargevalue: 'Foreign large value',
			'foreignsmall/midblend': 'Foreign small/mid blend',
			'foreignsmall/midgrowth': 'Foreign small/mid growth',
			'foreignsmall/midvalue': 'Foreign small/mid value',
			geographicequity: 'Geographic equity',
			globalcorporatefixedincome: 'Global corporate fixed income',
			globalequity: 'Global equity',
			globalequitybalanced: 'Global equity balanced',
			globalfixedincome: 'Global fixed income',
			globalfixedincomebalanced: 'Global fixed income balanced',
			globalinfrastructureequity: 'Global infrastructure equity',
			globalneutralbalanced: 'Global neutral balanced',
			globalrealestate: 'Global real estate',
			'globalsmall/midcapequity': 'Global small/mid cap equity',
			greaterchinaequity: 'Greater China equity',
			health: 'Health',
			highDistributionEquityETFs: 'High Distribution Equity ETFs',
			highDistributionEquityETFs_desc: 'ETFs that invest in Canadian equity holdings with an above-average distribution yield.',
			highyieldbond: 'High yield bond',
			highyieldfixedincome: 'High yield fixed income',
			highyieldmuni: 'High yield muni',
			highYieldFixedIncomeETFs: 'High Yield Fixed Income ETFs',
			highYieldFixedIncomeETFs_desc: 'ETFs that invest in Canadian high-yield fixed income securities.',
			incomeStatement: 'Income statement',
			indiaequity: 'India equity',
			'inflation-protectedbond': 'Inflation-protected bond',
			infrastructure: 'Infrastructure',
			intermediatecorebond: 'Intermediate core bond',
			'intermediatecore-plusbond': 'Intermediate core-plus bond',
			intermediategovernment: 'Intermediate government',
			internationalequity: 'International equity',
			japanstock: 'Japan stock',
			largeblend: 'Large blend',
			largegrowth: 'Large growth',
			largevalue: 'Large value',
			latinamericastock: 'Latin America stock',
			longgovernment: 'Long government',
			'long-shortcredit': 'Long-short credit',
			'long-shortequity': 'Long-short equity',
			'long-shortbond': 'Long-short bond',
			managedfutures: 'Managed futures',
			marketneutral: 'Market neutral',
			'mid-capblend': 'Mid-cap blend',
			'mid-capgrowth': 'Mid-cap growth',
			'mid-capvalue': 'Mid-cap value',
			'miscellaneous--incomeandrealproperty': 'Miscellaneous--income and real property',
			'miscellaneous--other': 'Miscellaneous--other',
			miscellaneousregion: 'Miscellaneous region',
			miscellaneoussector: 'Miscellaneous sector',
			multialternative: 'Multialternative',
			multicurrency: 'Multicurrency',
			multisectorbond: 'Multisector bond',
			municalifornialong: 'Muni California long',
			muninationalinterm: 'Muni national interm',
			muninationallong: 'Muni national long',
			muninationalshort: 'Muni national short',
			muninewyorkintermediate: 'Muni New York intermediate',
			muninewyorklong: 'Muni New York long',
			munitargetmaturity: 'Muni target maturity',
			naturalresources: 'Natural resources',
			naturalresourcesequity: 'Natural resources equity',
			nontraditionalbond: 'Nontraditional bond',
			northamericanequity: 'North American equity',
			'options-based': 'Options-based',
			'pacific/asiaex-japanstock': 'Pacific/Asia ex-Japan Stock',
			'passiveinverse/leveraged': 'Passive inverse/leveraged',
			preciousmetalsequity: 'Precious metals equity',
			preferredsharefixedincome: 'Preferred share fixed income',
			preferredstock: 'Preferred stock',
			realestateequity: 'Real estate equity',
			sectorequity: 'Sector equity',
			shortgovernment: 'Short government',
			'short-termbond': 'Short-term bond',
			singlecurrency: 'Single currency',
			smallblend: 'Small blend',
			smallgrowth: 'Small growth',
			smallvalue: 'Small value',
			tacticalallocation: 'Tactical allocation',
			tacticalbalanced: 'Tactical balanced',
			targetmaturity: 'Target maturity',
			'trading--inversecommodities': 'Trading--inverse commodities',
			'trading--inversedebt': 'Trading--inverse debt',
			'trading--inverseequity': 'Trading--inverse equity',
			'trading--leveragedcommodities': 'Trading--leveraged commodities',
			'trading--leverageddebt': 'Trading--leveraged debt',
			'trading--leveragedequity': 'Trading--leveraged equity',
			'trading--miscellaneous': 'Trading--miscellaneous',
			ultrashortbond: 'Ultrashort bond',
			'usdividend&incomeequity': 'U.S Dividend & Income Equity',
			usequity: 'US equity',
			usmoneymarket: 'US money market',
			'ussmall/midcapequity': 'US small/mid cap equity',
			worldallocation: 'World allocation',
			worldbond: 'World bond',
			'worldbond-usdhedged': 'World bond-USD hedged',
			worldlargestock: 'World large stock',
			'worldsmall/midstock': 'World small/mid stock',
			year: 'Year',
			yieldAndDate: 'yield & date',
			yourSelectionDidNotYieldResult: 'Your search yielded no results. Please try different search criteria.',
			// end morningstar categories

			// Analyst Recommendations Ratings
			strongBuy: 'Strong buy',
			'Strong Buy': 'Strong Buy',
			buy: 'Buy',
			Buy: 'Buy',
			hold: 'Hold',
			Hold: 'Hold',
			sell: 'Sell',
			Sell: 'Sell',
			strongSell: 'Strong sell',
			'Strong Sell': 'Strong Sell',
			Underperform: 'Underperform',
			upwardTrend: 'an upward trend',
			downwardTrend: 'a downward trend',
			unchangedTrend: 'unchanged',
			analystRatingText1: 'This is {trendText} from the previous rating of ',
			analystRatingText2: '. As of ',

			// Earnings Chart
			actualEarnings: 'Actual earnings of ',
			earningSurprise: ', a {surprise}  of ',
			priceEarnings1: '{symbol}\'s price {priceDirection} ',
			priceEarnings2: ' {daysAfter}',
			priceEarnings3: '{date} announcement.',
			earningEstimate1: 'The consensus long term growth estimate is ',
			earningEstimate2: ' with a high estimate of ',
			earningEstimate3: ' and a low estimate of ',
			earningEstimate4: ' with {numOfAnalysts} analysts reporting.',
			rose: 'rose',
			fell: 'fell',
			positiveSurprise: ' a positive surprise of ',
			negativeSurprise: ' a negative surprise of ',
			noAnaylystOpinions: 'There are no analyst opinions available for the selected time period.',
			earningsAnnouncement: 'The next earnings announcement from {symbol} has not been released.',
			exceededExp: 'exceeded expectations',
			missedExp: 'missed expectations',
			stockEarningsChartTooltip: 'Select a quarter or year for more information',
			estimatesLabel: 'Estimates',
			qtrEarningsChartCaption: '{quarter} FY {year} estimates',
			yrEarningsChartCaption: 'FY {year} estimates',
			earningsChartCaptionSr: '{quarter} FY {year} data based on previous chart selection',

			// Dividend Information (Distribution Information)
			latestXdDate: 'Latest XD date',
			dividendTaxationCode: 'Dividend taxation code',
			distributionInformationMF: 'Distribution Information',
			noDistributionInformation: 'No distribution information available',
			updatedAsOf: 'Updated as of',

			// Lipper Leader Ratings
			lipperLeaderRatings: 'Lipper leaders ratings',
			lipperLeaderTooltip: 'Scores for Total Return reflect fund historic total return performance relative to peers. Scores for Consistent Return reflect fund historical risk-adjusted returns relative to peers. Lipper scores for Preservation are relative, rather than absolute. Lipper Leader scores DO NOT take into account the effects of sales charges. Overall Scores are based on an equal-weighted average of percentile ranks for each measure over 3-, 5-, and 10-year periods (if applicable).',
			overallRating: 'Overall rating',
			overallRatingDescription: 'Morningstar\'s proprietary rating system ranks ETFs according to their risk-adjusted performance. The Overall rating is a weighted average of the ETF\'s three-, five- and ten-year rating. ETFs are rated on a star system: 5 stars is the highest ranking; 1 star is the lowest.',
			'3YearRating': '3 year rating',
			'5YearRating': '5 year rating',
			'10YearRating': '10 year rating',
			totalReturn: 'Total return',
			consistentReturn: 'Consistent rating',
			preservation: 'Preservation',
			lipperLeaderKey: 'Lipper Leaders Key',
			lipperLogoAlt: 'Refinitiv Lipper Leaders are rated on a scale of 5, the highest, to 1, the lowest',
			lipperLeaderContent1: 'The Lipper Leader Rating System is a tool kit that helps guide you in selecting funds that suit your investment styles and goals.',
			lipperLeaderContent2: 'The Lipper Leader Rating System uses your criteria to deliver a simple, clear description of a fund\'s success in meeting certain goals, such as preserving capital or building wealth through consistent, strong returns.',
			lipperLeaderCaption: 'Lipper Leaders are rated on a scale of 5, the highest, to 1, the lowest',

			// Fund Distribution Chart
			incomeDistributionbyDollar: 'Income distributions by dollar',
			sumofCalendarYear: 'Sum of calendar years {year1} to {year2}',
			fundUpdateDisclaimer: 'Fund and index performance data (unless otherwise noted) updated as of {updateDate}. Fund size (total net asset) data updated as of {updateDate}. All other data, including Lipper Leader information, updated as of {updateDate} unless otherwise noted.',

			// Compare ETF section
			compareEtf: 'Compare ETFs',
			findAndCompare: 'Find and compare up to 4 ETFs',
			'52-WeekRange': '52-Week Range',
			avg10Day: 'Avg. volume (10-day)',
			etfObjective: 'Objective',
			annualReturns: 'Annualized returns',
			calendarYearReturnsSr: 'Calendar year returns data to follow',
			annualReturnsSr: 'Annualized returns data to follow',
			etfSimilarTo: 'ETFs similar to ',
			'1yearRisk': '1 year',
			'1yearRiskSr': '1 year risk data to follow ',
			'3yrRisk': '3 year',
			'3yearRiskSr': '3 year risk data to follow ',
			'5yrRisk': '5 year',
			'5yearRiskSr': '5 year risk data to follow',
			'10yrRisk': '10 year',
			'10yearRiskSr': '10 year risk data to follow ',
			Alpha: 'Alpha',
			Beta: 'Beta',
			RSquared: 'R-squared',
			premiumDiscount3Month: 'Premium/discount (3 month)',
			numOfDaysPremium: 'No. of days at premium',
			numOfDaysNAV: 'No. of days at NAV',
			numOfDaysDiscount: 'No. of days at discount',
			numOfDays5Pct: 'No. of days within +/- 0.5% of NAV',
			overAll: 'Overall',
			sector: 'Sector',
			assetAllocSr: 'Asset allocation data to follow',
			globalExposureSr: 'Global exposure data to follow',
			sectorSr: 'Sector data to follow',
			holdingsSr: 'Holdings data to follow',
			findSimilarEtfs: 'Find similar ETFs',
			similarEtfTooltipIntro: 'To find similar ETFs, we begin by identifying ETFs from the same Morningstar Category.  We then create a total score for each by prioritizing (in order) those with:',
			similarEtfTooltipPoint1: '1) the closest 3 Year RSquared Ratio to the Primary Morningstar Index',
			similarEtfTooltipPoint2: '2) the highest Sharpe Ratio',
			similarEtfTooltipPoint3: '3) the lowest Expense Ratio',
			similarEtfTooltipConclusion: 'ETFs with the best total score are considered the most similar.',
			overallRatingSr: 'Overall Morningstar rating data to follow',
			'3yearRatingSr': '3 year Morningstar rating data to follow',
			'5yearRatingSr': '5 year Morningstar rating data to follow',
			'10yearRatingSr': '10 year Morningstar rating data to follow',
			noDataForETF: 'No data is available for this ETF.',
			topSectors: 'Top sectors',
			canadian: 'Canadian',
			unitedStates: 'United States',
			MSDRegionUnitedStates: 'Region United States',
			compareWarningText: 'Your Compare tab is currently populated with ETFs. “OK” will replace them with the ETFs you’ve selected here. “Cancel” will return you to the current page without making any changes.',

			// Alerts
			manageAlerts: 'Manage alerts',
			marketAlert: 'Market alert',
			marketAlerts: 'Market alerts',
			marketAlertsDescription: 'Market alerts provide user-defined updates sent at designated times or based on specific triggering events.',
			modifyMarketAlerts: 'Modify market alerts',
			exchange: 'Exchange',
			exchangeTradedFunds: 'Exchange traded funds (ETFs)',
			dailyListOfMostActiveStocks: 'Daily list of most active stocks',
			toBeSentAt: 'To be sent at ',
			percentage: 'Percentage',
			gainers: 'Gainers',
			losers: 'Losers',
			gainersAndLosers: 'Gainers and losers',
			timeframe1024: 'Market open 10-11 EST',
			timeframe4096: 'Midday 12-1 EST',
			timeframe65536: 'After market close 4-5 EST',
			dailyListOf52week: 'Daily list of new 52-week highs/lows',
			new52week: 'New 52-week',
			highs: 'Highs',
			lows: 'Lows',
			highsAndLows: 'Highs and lows',
			highAndLow: 'High and low',
			dailyListOfVolumeChanges: 'Daily list of volume changes',
			stocksWithTheLargest: 'Stocks with the largest ',
			increases: 'Increases',
			decreases: 'Decreases',
			increasesAndDecreases: 'Increases and decreases ',
			dailyListOfGaps: 'Daily list of gaps',
			stocksThatOpenedAtLeast: 'Stocks that opened at least 1%',
			higher: 'Higher',
			lower: 'Lower',
			higherAndLower: 'Higher and lower',
			dailyListOfBreakouts: 'Daily list of breakouts',
			stocksWhose: 'Stocks whose',
			lowPriceAboveYesterdaysHigh: 'Low price is above yesterday’s high',
			highPriceAboveYesterdaysLow: 'High price was above yesterday’s low',
			lowHighPriceAboveBelowYesterdaysHighLow: 'Low/high price is above/below yesterdays high/low',
			dailyListOfStockSplits: 'Daily list of stock splits',
			pleaseNoteAlert: 'Please note',
			noEmailProvidedModal: 'No email address has been provided to receive alerts. Any alerts created will not be sent out.',
			noEmailProvidedModalPrefs: 'You have removed all email addresses to receive alerts. Saved alerts will not be deleted but cannot be sent until at least one email address is provided.',
			setEmailAlerts: 'Set email alerts',
			tsx: 'TSX',
			tsxVenture: 'TSX Venture',
			nyse: 'NYSE',
			nasdaq: 'NASDAQ',
			amex: 'AMEX',
			addAlert: 'Add alert',
			stockAlerts: 'Stock alerts',
			stockAlertsFor: 'Stock alerts for {symbol}',
			priceAndVolumeAlerts: 'Price and volume alerts',
			priceDropsBelow: 'Price drops below',
			orRisesAbove: 'or rises above',
			priceDropPoint: 'Price drop point',
			priceRisePoint: 'Price rise point',
			priceGap: 'Price gap',
			atOpeningTrade: 'at opening trade',
			dropDownOptions: 'Drop down options:',
			gapUp: 'Up',
			gapDown: 'Down',
			lastPriceAlert: 'Last price:',
			peRatioAlert: 'P/E ratio',
			peDropPoint: 'P/E drop point',
			peRisePoint: 'P/E rise point',
			reachesANew52Weekp1: 'Reaches a new 52-week',
			reachesANew52Weekp2: '',
			symbolIsOfIts52WeekHighOfOn: '{symbol} is {percent} of its 52-week high of {high52Week} on {date}',
			'52WeekValue': '52-week value',
			priceChange1Week: 'Price chg 1 week',
			priceChange4Week: 'Price chg 1 month',
			priceChanges: 'Price changes',
			fromPreviousClose: 'from previous close',
			closeOnDate: '({previousClose} on {date})',
			priceChangeValue: 'Price change value',
			crossed: 'crossed',
			crossesIts: 'Crosses its',
			movingAverage: 'moving average',
			crossesItsMovingAverage: 'Crosses its {30 day} moving average',
			movingAverageValue: 'Moving average value',
			'15day': '15 day',
			'30day': '30 day',
			'60day': '60 day',
			changesAlert: 'Changes',
			fasterThanThe: 'faster than the',
			overTheLast: 'over the last',
			changeValue: 'Change value',
			marketAlertLabel: 'Market',
			spTsxComposite: 'S&P/TSX Composite',
			djia: 'DJIA',
			changePeriod: 'Change period',
			tradingVolumeExceeds10DayAverageBy: 'Trading volume exceeds 10 day average by',
			tradingVolumeExceeds10DayAverageBy2: '',
			rsiOverBoughtSold: 'RSI over bought/sold',
			rsiUnderBoughtSold: 'RSI under bought/sold',
			stockSplitAnnounced: 'Stock split announced',
			earningEstimates: 'Earning estimates',
			quarterlyEarningsEstimatesAnnounced: 'Quarterly earnings estimates announced',
			annualEarningsEstimatesAnnounced: 'Annual earnings estimates announced',
			mostRecentDate: '(Most recent: {date})',
			newsAlerts: 'News alerts',
			newsStoriesFromReuters: 'News stories from Reuters',
			inDepthStories: '(In-depth stories, breaking news and analysis by email)',
			analystReportsAlerts: 'Analyst reports alerts',
			analystReportsForAllProviders: 'Analyst reports for all providers',
			companyBasedAnalystSearch: '(Company-based analyst research)',
			pleaseSetTheLowPrice: 'Please set the low price less than the current quote price.',
			pleaseSetTheHighPrice: 'Please set the high price greater than the current quote price',
			pleaseSetTheLowPe: 'Please set the low P/E field less than the current P/E',
			pleaseSetTheHighPe: 'Please set the high P/E field greater than the current P/E',
			pleaseInputValues: 'Please input values for the selected alert.',
			editMarketAlert: 'Edit {name} from markets tab',
			editStockAlert: 'Edit {name} from stocks tab',
			deleteAlert: 'Delete {name}',
			peDropsBelow: 'P/E drops below',
			inputValueToContinue: 'Please input values for the selected alert.',
			// Market alert description
			alert_52: 'Daily List of Most Active Stock Gainers',
			alert_53: 'Daily List of Most Active Stock Losers',
			alert_56: 'Daily List of Most Active Stock Gainers and Losers',
			alert_57: 'Daily List of New 52-week Highs',
			alert_58: 'Daily List of New 52-week Lows',
			alert_65: 'Daily List of New 52-week Highs and Lows',
			alert_66: 'Daily List of Volume Increases',
			alert_86: 'Daily List of Volume Decreases',
			alert_87: 'Daily List of Volume Increases or Decreases',
			alert_88: 'Daily List of Gaps (Higher)',
			alert_89: 'Daily List of Gaps (Lower)',
			alert_90: 'Daily List of Gaps (Higher or Lower)',
			alert_91: 'Daily List of Breakouts (Low Price is Above Yesterday\'s High)',
			alert_92: 'Daily List of Breakouts (High Price was Below Yesterday\'s Low)',
			alert_93: 'Daily List of Breakouts (Low/High Price is Above/Below Yesterday\'s High/Low)',
			alert_289: 'Daily List of Stock Splits',

			// Stock alert descriptions
			alert_1: 'Price drops below {price}',
			alert_2: 'Price rises above {price}',
			alert_3: 'Reaches a new 52-week high',
			alert_4: 'Reaches a new 52-week low',
			alert_5: 'Reaches a new 52-week high or low',
			alert_6: 'Price changes from Prior Close +/- {pricePercentChange}',
			alert_49: 'P/E drops below {peLimit}',
			alert_50: 'P/E rises above {peLimit}',
			alert_168: 'News Stories from Reuters',
			alert_201: 'RSI over bought/sold',
			alert_202: 'RSI under bought/sold',
			alert_309: 'Earnings Estimates (Annual)',
			alert_310: 'Earnings Estimates (Quarterly)',
			alert_311: 'Earnings Estimates (Quarterly or Annual)',
			alert_376: 'Stock Split is announced',
			alert_381: 'Price crosses Moving Average',
			alert_430: 'Trading volume exceeds 10 day average by {percent}',
			alert_434: 'Price gap down at opening trade',
			alert_435: 'Price gap up at opening trade',
			alert_436: 'Price rises or falls {percentChange} faster than the {index} over the last {days} days',

			// Upgrades & Downgrades
			upgrades: 'Upgrades',
			upgradesSr: 'Show upgrades in accompanying table',
			upgradesAndDowngrades: 'Upgrades and Downgrades',
			downgrades: 'Downgrades',
			downgradesSr: 'Show downgrades in accompanying table',
			numOfUpgrades: '# of upgrades',
			numOfDowngrades: '# of downgrades',
			numOfTotalViews: '# of total views',
			pctUpgrades: '% upgraded',
			pctDowngrades: '% downgraded',
			consensusRating: 'consensus rating',
			analystsViews: 'Analyst views (in the last 4 weeks)',
			upgradesBtnSr: 'Show upgrades in accompanying table',
			downgradesBtnSr: 'Show downgrades in accompanying table ',

			// Screener
			selectAllSr: 'Select all to include all criteria in the accompanying list',
			selectCustomSr: 'Select custom to choose your criteria from the accompanying list',
			selectAllSrIndustry: 'Select all to include all criteria in the accompanying list. Industry criteria is based on the previous Sector criteria selection.',
			holdControlKeyToSelect: 'Hold the Ctrl key to select multiple options',
			MSDDomicileCountry: 'Country',
			MSDCIBCETFType: 'Fund type',
			includeStandardETFs: 'Include standard ETFs',
			includeLeveragedETFs: 'Include leveraged ETFs',
			includeInverseETFs: 'Include inverse ETFs',
			MSDMorningstarCategoryName: 'Fund category',
			custom: 'Custom',
			addCriteriaToStart: 'Add the criteria to start a custom ETF search.',
			criteria: 'Criteria',
			minimizeCriteria: 'Minimize criteria selection',
			expandCriteria: 'Expand criteria selection',
			criteriaMatches: 'Criteria matches',
			screenMatches: 'Screen matches',
			removeCriteriaCategory: 'Remove criteria category',
			resetCriteria: 'Reset criteria',
			resetAllCriteria: 'Reset All criteria',
			canadaEquity: 'Canada equity',
			addCriteriaToStartACustomSearch: 'Add the criteria to start a custom ETF search.',
			showCriteriaMatchesToolTip: '<p><strong>Show criteria matches</strong></p><p>When you select a dropdown option from a specific criteria choice, the <strong>Show criteria matches</strong> column will display the number of ETFs available for that selection only. The displayed number is not affected by any other criteria choices you make.</p><p>The <strong>Screen matches</strong> column displays the cumulative results of all criteria selected.</p>',
			bottom20: 'Bottom 20%',
			belowAvg20: 'Below avg 20%',
			avg20: 'Avg 20%',
			aboveAvg20: 'Above avg 20%',
			top20: 'Top 20%',
			from: 'From',
			eventDate: 'Event date',
			STXOverSMA15SMA50: 'Moving avg x-over (15-day SMA/50-day SMA)',
			movingAverageOver: 'Moving avg x-over ({over}-day {overType}/ {under}-day {underType})',
			movingAverageOverNone: 'Moving avg x-over',
			currentlyAbove52WeekHigh: 'Currently trading above its previous 52-week high',
			new52WeekLow: 'Currently trading below its previous 52-week low',
			greaterThanEqualTo: 'Greater than or equal to',
			greaterThanSymbolVariable: '> {number}',
			greaterThanEqualToSymbolVariable: '>= {number}',
			lessThanEqualTo: 'Less than or equal to',
			lessThanSymbolVariable: '< {number}',
			between: 'Between',
			and: ' and ',
			specificRange: 'Specific range: ',
			specificRangeSRPerformance: 'Select the basic ranges radio button to choose values, or the specific range radio button to enter a value or values.',
			nonNumericError: 'Please enter numeric characters only.',
			fundStructureTooltip: '<p><strong>Fund Structure</strong><br/>An ETF can be classified as a standard, leverage or inverse.</p><p><strong>Leveraged ETF</strong><br/>Leveraged ETFs use financial derivatives to amplify the returns of an underlying index or basket of securities on a daily basis. These ETFs should be traded with caution and are intended for advanced traders with an in-depth understanding of the associated risks.</p><p><strong>Inverse ETF</strong><br/>Inverse ETFs are designed to benefit from price movements opposite to that of the underlying index of basket of securities.</p>',
			exchangeCountryTooltip: 'All criteria dollar values are processed using the selected country’s native currency.',
			removeSymbol: 'Remove {symbol} from criteria',
			removeAllCriteria: 'Remove All Criteria',
			globalHoldingSR: 'Select a region, then choose either the basic ranges radio button to select values, or the specific range radio button to enter a value or values.',
			region: 'Region',
			asia: 'Asia',
			MSDRegionAsia: 'Region Asia',
			europe: 'Europe',
			MSDRegionEurope: 'Region Europe',
			sectors: 'Sector',
			yourResults: 'Your results',
			standard: 'Standard',
			leveraged: 'Leveraged',
			inverse: 'Inverse',
			bullish: 'Bullish',
			bearish: 'Bearish',
			asOfDatPreviousDayMonthEnd: 'Market data is of previous day\'s close. All other data as of previous month end',
			startACustomScreenWithTheseCriteria: 'Start a custom screen with these criteria',
			saveScreen: 'Save screen',
			saveAsNewScreen: 'Save as new screen',
			saveScreener: 'Save screener',
			enterScreenName: 'Enter screen name',
			nameMustNotbeMoreThan22: 'The screener name can’t be more than 22 characters. ',
			descriptionLongerThan100: 'The description can’t be more than 100 characters.',
			deleteScreen: 'Delete screen',
			doYouWantToDeleteScreen: 'Do you want to delete this screen?',
			nameFieldMustBe3Characters: 'The screener name field needs to be at least three characters.',
			thisNameAlreadyExists: 'This name already exists in your saved screens.',
			overYourSavedSCreenLimit: 'You are over your saved screen limit. Please delete a saved screen before attempting to save a new one.',
			compareSelectedEtfs: 'Compare the ETFs you have selected from the accompanying table.',
			error: 'Error',
			STRSIPeriod2: '2-day RSI',
			STRSIPeriod14: '14-day RSI',
			STRSIPeriod20: '20-day RSI',
			reviewFundSnapshot: 'Review {symbol} snapshot',
			pleaseSelectCompareSymbols: 'Please select symbol(s) to compare.',
			youCanOnlySelect4: 'You can only select up to 4 symbols to compare.',
			preScreeners: 'Prescreeners',
			savedScreeners: 'Saved screeners',
			saveSearch: 'Save search',
			saveSearchSrOnly: 'Save search in a new dialogue',
			stockScreenMatches: '{results} matches',
			sectorCurrency: 'Sector/currency',
			yearChange: '1 year change',
			stockCriteria: 'Stock criteria',
			expandStockCriteria: 'Expand stock criteria',
			minimizeStockCriteria: 'Minimize stock criteria ',
			canadaLowPEStocks_desc: 'Canadian stocks with low price-to-earnings ratios (PE ratio)',
			usLowPEStocks_desc: 'US stocks with low price-to-earnings ratios (PE ratio) ',
			canadaValueStocks_desc: 'Canadian large cap stocks with a higher return on equity',
			usValueStocks_desc: 'US large cap stocks with a higher return on equity',
			cumulativeMatches: 'Cumulative matches:',
			payoutRatioPercent: 'Payout ratio (%)',
			dividendYieldPercent: 'Dividend yield (%)',
			priceEarningRatio: 'Price/earning ratio',
			growthRate5yearEps: 'Growth-rate/5 year EPS',
			revenueMillions: 'Revenue ($ Mill)',
			roePercent: 'ROE (%)',
			roaPercent: 'ROA (%)',
			netProfitMargin: 'Net profit margin',
			greaterThan: 'Greater than',
			lessThan: 'Less than',
			minimizeFundamentals: 'Minimize fundamentals criteria',
			expandFundamentals: 'Expand fundamentals criteria',
			year1: '1 year',
			twoYears: '2 years',
			reutersConsensusEstimates: 'Refinitiv I/B/E/S consensus and estimates',
			minimizeReutersConsensusEstimates: 'Minimize Refinitiv I/B/E/S criteria',
			expandReutersConsensusEstimates: 'Expand Refinitiv I/B/E/S criteria',
			recommendations: 'Recommendations',
			annualRevenueInMillions: 'Annual revenue ($ Mill)',
			twoYearSingularEnglishOnly: '2 year',
			threeYearSingularEnglishOnly: '3 year',
			fiveYearSingularEnglishOnly: '5 year',
			tenYearSingularEnglishOnly: '10 year',
			annualEarningsPerShare: 'Annual earnings per share ($)',
			quarterlyRevenue: 'Quarterly revenue ($ Mill)',
			quarterlyEarningsPerShare: 'Quarterly earnings per share ($)',
			'1qtr': '1 qtr',
			'2qtr': '2 qtr',
			'3qtr': '3 qtr',
			'5qtr': '5 qtr',
			'10qtr': '10 qtr',

			// Keywords search tooltip content
			keywordSearchHeading: 'Enhanced Pre-Defined Keyword Searches',
			keywordSearchDescription: 'Using the Advanced Search page, users now can filter Keyword Searches by choosing a radio button to match "Exact Phrase", "All Keywords" or "Any Keywords."',
			keywordSearchP1: '<strong>"Exact Phrase"</strong> searches perform keyword searches for matches where all words are included in consecutive order. For example, a search for <i>New Economy</i> with the Exact Phrase button selected would only return results where the <strong>phrase</strong> <i>New Economy</i> is included.',
			keywordSearchP2: '<strong>"All Keywords"</strong> searches perform searches for documents where all words included in the keyword search are included in the document, but not necessarily in consecutive order. Thus, a search for New Economy with the All Keywords button selected would return any results where both of the words New and Economy are included anywhere in the document.',
			keywordSearchP3: '<strong>"Any Keywords"</strong> searches perform searches for documents where any words included in the keyword search are included in the document. Thus, a search for New Economy with the Any Keywords button selected would return any results where <strong>either</strong> the word <i>New or Economy</i> are included anywhere in the document',
			keywordSearchP4: 'By selecting the button labelled "Search Headlines Only", you can limit any one of these searches to the documents\' headlines only.',
			keywordSearchP5: 'How these pre-defined searches work:',
			keywordSearchTable1H1: 'Keyword Criterion',
			keywordSearchTable1H2: 'Searches for',
			keywordSearchTable1H3: 'Results',
			keywordSearchTable1R1C1: 'Exact phrase',
			keywordSearchTable1R1C2: 'New Economy',
			keywordSearchTable1R1C3: 'Only documents with the phrase <i>New Economy</i> included',
			keywordSearchTable1R2C1: 'All keywords',
			keywordSearchTable1R2C3: 'Only documents with <strong>both</strong> <i>New</i> <strong>and</strong> <i>Economy</i> included',
			keywordSearchTable1R3C1: 'Any keywords',
			keywordSearchTable1R3C3: 'Only documents with <strong>either</strong> <i>New<i/> <strong>or</strong> <i>Economy</i> included',
			additionalKeywordSearchHeading: 'Additional Keyword Search Examples',
			additionalKeywordSearchDescription: 'For greater specificity of keyword searches not provided with the standard interface, users can search for combinations of words or phrases using added Boolean logic. For example, if you want to find documents that contain the phrase "annual report" and "balance sheet", you can type',
			additionalKeywordSearchP1: '"annual report" & "balance sheet"',
			additionalKeywordSearchP2: 'in the keywords search field. Notice the quotation marks -- they indicate that the entire phrase is the search object, not the individual words. The searches for',
			additionalKeywordSearchP3: 'paper clip',
			additionalKeywordSearchP4: 'and',
			additionalKeywordSearchP5: '"paper clip"',
			additionalKeywordSearchP6: 'return different results. The first query returns documents that have either the word "paper" or the word "clip" in them. The second returns only documents that have the phrase "paper clip" in them.',
			additionalKeywordSearchP7: 'Examples of how to construct more complex searches:',
			keywordSearchTable2H1: 'Typing in the text...',
			keywordSearchTable2H2: 'Will retrieve documents containing...',
			keywordSearchTable2R1C1: 'DEC',
			keywordSearchTable2R1C2: '...DEC, dec, Dec, etc.',
			keywordSearchTable2R2C1_1: 'DEC & IBM',
			keywordSearchTable2R2C1_2: 'or',
			keywordSearchTable2R2C1_3: 'DEC and IBM',
			keywordSearchTable2R2C2: '...both DEC and IBM.',
			keywordSearchTable2R3C1_1: 'DEC | IBM',
			keywordSearchTable2R3C1_2: 'or',
			keywordSearchTable2R3C1_3: 'DEC or IBM',
			keywordSearchTable2R3C2: '...either DEC or IBM.',
			keywordSearchTable2R4C1: 'DEC IBM',
			keywordSearchTable2R4C2: '...either DEC or IBM.',
			keywordSearchTable2R5C1: 'DEC & IBM | GE',
			keywordSearchTable2R5C2: '...either both DEC and IBM, or GE.',
			keywordSearchTable2R6C1: 'DEC & ( IBM | GE )',
			keywordSearchTable2R6C2: '...both DEC and either IBM or GE.',
			keywordSearchTable2R7C1: '"annual report"',
			keywordSearchTable2R7C2: '...the phrase "annual report".',
			keywordSearchTable2R8C1: 'annual report',
			keywordSearchTable2R8C2: '...either "annual" or "report".',
			keywordSearchTable2R9C1: 'software & "annual reports"',
			keywordSearchTable2R9C2: '...both the word software and the phrase annual reports.',
			keywordSearchTable2R10C1: 'software & ^ spreadsheet',
			keywordSearchTable2R10C2: '...software but not spreadsheet.',
			keywordSearchTable2R11C1: 'Java &^ (coffee | Indonesia)',
			keywordSearchTable2R11C2: '...java but not coffee or Indonesia.',
			keywordSearchTable2R12C1: 'Coca-Cola near/5 PepsiCo',
			keywordSearchTable2R12C2: '...Coca-Cola no more than 5 characters away from PepsiCo.',
			keywordSearchTable2R13C1: 'Coca-Cola near PepsiCo. (Note: without a number of characters then it will default to 64 characters).',
			keywordSearchTable2R13C2: '...Coca-Cola near PepsiCo.',
			keywordSearchTable2R14C1: 'mer*',
			keywordSearchTable2R14C2: '...mer, Merrill, merry, etc. The "*" stands for any number of letters at the end of the word.',
			keywordSearchTable2R15C1: 'm?cro',
			keywordSearchTable2R15C2: '...micro, macro, m3cro, etc. The "?" stands for any one letter or digit.',
			keywordSearchTable2R16C1: 'Jane\'s >',
			keywordSearchTable2R16C2: '...Jane\'s. The apostrophe is not treated like a single quote.',
			keywordSearchTable2R17C1: '"news of the day"',
			keywordSearchTable2R17C2: '...the phrases "news of the day", "news of day", "news day". Words like "of", "the", "and", "in" are excluded from the search.',
			keywordSearchTable2R18C1: '"news & day"',
			keywordSearchTable2R18C2: '...the phrases "news & day", "news day", "news day". Special characters (not letters or numbers) are regarded as interchangeable.',

			// -- START Industries --
			Advertising: 'Advertising',
			Aerospace: 'Aerospace',
			'Aerospace & Defense': 'Aerospace & defense',
			'Agricultural Crop Production': 'Agricultural crop production',
			'Agricultural Livestock': 'Agricultural livestock',
			Agriculture: 'Agriculture',
			'Agricultural Chemicals': 'Agricultural chemicals',
			'Airlines/Air Freight': 'Airlines/air freight',
			'Apparel, Footwear & Accessories': 'Apparel, footwear & accessories',
			'Appliances & Electronics': 'Appliances & electronics',
			'Auto Parts': 'Auto parts',
			'Autos & Auto Parts': 'Autos & auto parts',
			'Autos- Manufacturing': 'Autos manufacturing',
			Banking: 'Banking',
			'Banking & Savings': 'Banking & savings',
			Beverages: 'Beverages',
			Biotechnology: 'Biotechnology',
			'Brokers & Intermediaries': 'Brokers & intermediaries',
			Builders: 'Builders',
			'Business Services': 'Business services',
			Chemicals: 'Chemicals',
			'Commercial Services & Supplies': 'Commercial services & supplies',
			Communications: 'Communications',
			'Computer Hardware & Equipment': 'Computer hardware & equipment',
			'Construction Materials': 'Construction materials',
			'Construction Services': 'Construction services',
			'Consumer Goods': 'Consumer goods',
			'Consumer Products': 'Consumer products',
			'Consumer Services': 'Consumer services',
			'Containers & Packaging': 'Containers & packaging',
			'Credit & Lending': 'Credit & lending',
			Defense: 'Defense',
			'Diagnostic & Health Related Services': 'Diagnostic & health related services',
			'Diversified Chemicals': 'Diversified chemicals',
			'Educational Services': 'Educational services',
			'Electric Utilities': 'Electric utilities',
			'Electrical Equipment': 'Electrical equipment',
			'Electronic Instruments & Related Products': 'Electronic instruments & related products',
			Energy: 'Energy',
			Entertainment: 'Entertainment',
			'Equipment & Services': 'Equipment & services',
			ETFs: 'ETFs',
			'Finance Intermediaries & Services': 'Finance intermediaries & services',
			Financials: 'Financials',
			'Fishing & Gaming': 'Fishing & gaming',
			Food: 'Food',
			'Food & Beverage': 'Food & beverage',
			Forestry: 'Forestry',
			Furniture: 'Furniture',
			'Gas Utilities': 'Gas utilities',
			'General Insurance': 'General insurance',
			'Health Care': 'Health care',
			'Health Care Equipment': 'Health care equipment',
			'Health Care Services': 'Health care services',
			'Holding and other Investment Offices': 'Holding and other Investment Offices',
			'Hospitals & Health Care Facilities': 'Hospitals & health care facilities',
			'Hotels, Restaurants & Travel': 'Hotels, restaurants & travel',
			'Household & Personal Products': 'Household & personal products',
			'Household Appliances, Electronics & Goods': 'Household appliances, electronics & goods',
			'Industrial Machinery & Equipment': 'Industrial machinery & equipment',
			Industrials: 'Industrials',
			Insurance: 'Insurance',
			'Internet & Software': 'Internet & software',
			'Investments & Brokers': 'Investments & brokers',
			'IT & Communications': 'IT & communications',
			'IT Hardware': 'IT hardware',
			'IT Services': 'IT services',
			'IT Services & Software': 'IT services & software',
			'Leisure Equipment': 'Leisure equipment',
			'Life & Health': 'Life & health',
			Machinery: 'Machinery',
			Manufacturing: 'Manufacturing',
			Materials: 'Materials',
			Media: 'Media',
			'Medical Instruments & Equipment': 'Medical instruments & equipment',
			'Metal Products': 'Metal products',
			'Metals & Mining': 'Metals & mining',
			Mining: 'Mining',
			Miscellaneous: 'Miscellaneous',
			'Miscellaneous Consumer Goods': 'Miscellaneous consumer goods',
			'Miscellaneous Consumer Services': 'Miscellaneous consumer services',
			'Miscellaneous Transportation Services': 'Miscellaneous transportation services',
			miscellaneouseducationalserviceproviders: 'Miscellaneous Educational Services Providers',
			'Non-Precious Metals': 'Non-precious metals',
			'Office Equipment & Furniture': 'Office equipment & furniture',
			'Oil & Gas': 'Oil & gas',
			'Oil Royalty Traders': 'Oil royalty traders',
			'Paper & Forest Products': 'Paper & forest products',
			'Patent Owners & Lessors': 'Patent owners & lessors',
			Peripherals: 'Peripherals',
			Pharmaceuticals: 'Pharmaceuticals',
			'Pharmaceuticals & Biotechnology': 'Pharmaceuticals & biotechnology',
			Plastics: 'Plastics',
			'Precious Metals': 'Precious metals',
			Printing: 'Printing',
			'Production & Extraction': 'Production & extraction',
			'Property, Real Estate & Development': 'Property, real estate & development',
			Publishing: 'Publishing',
			'Radio & Television': 'Radio & television',
			Rail: 'Rail',
			'Real Estate': 'Real estate',
			'Refining & Marketing': 'Refining & marketing',
			REITs: 'REITs',
			Retail: 'Retail',
			'Retail - Apparel and Accessories': 'Retail - apparel and accessories',
			'Retail - Appliances and Electronics': 'Retail - appliances and electronics',
			'Retail - Automotive': 'Retail - automotive',
			'Retail - Food & Beverage, Drug & Tobacco': 'Retail - food & beverage, drug & tobacco',
			'Retail - Furniture & Home Furnishings': 'Retail - furniture & home furnishings',
			'Retail - General Merchandise/Department Stores': 'Retail - general merchandise/department stores',
			'Retail - Hardware & Home Improvement': 'Retail - hardware & home improvement',
			'Retail - Specialty': 'Retail - specialty',
			'Rubber Products': 'Rubber products',
			'Sanitation Services': 'Sanitation services',
			Semiconductors: 'Semiconductors',
			Services: 'Services',
			Shipping: 'Shipping',
			'Specialty Chemicals': 'Specialty chemicals',
			'Sporting & Recreational': 'Sporting & recreational',
			'Synthetic Materials': 'Synthetic materials',
			Textiles: 'Textiles',
			'Textiles & Apparel': 'Textiles & apparel',
			Tobacco: 'Tobacco',
			'Tobacco Products': 'Tobacco products',
			Transport: 'Transport',
			Trucking: 'Trucking',
			Utilities: 'Utilities',
			'Venture Capital': 'Venture capital',
			'Water Utilities': 'Water utilities',
			'Wealth Management': 'Wealth management',
			// -- END Industries --
			screenDescription: 'Screener description',
			renameScreenerSr: 'Rename screener open dialogue',
			editScreenerSr: 'Edit screener open dialogue',
			deleteScreenerSr: 'Delete screener open dialogue',
			customSearchResults: 'Search results',
			editScreener: 'Edit Screener',
			cibcPreScreens: 'CIBC prescreens',
			reviewPrescreens: 'Review prescreens',

			// Fund Screener
			cibcSeriesD: 'CIBC Series D',
			cibcSeriesDDescription: 'A list of CIBC series D mutual funds',
			cibcMutualFunds: 'CIBC mutual funds',
			cibcMutualFundsDescription: 'A list of CIBC mutual funds',
			renaissance: 'Renaissance',
			renaissanceDescription: 'A list of Renaissance mutual funds',
			axiomFunds: 'Axiom',
			axiomFundsDescription: 'A list of Axiom mutual funds',

			technicalAnalysisAndRisk: 'Technical analysis and risk',
			'1mo': '1 mo',
			'3mo': '3 mo',
			'1yr': '1 yr',
			'3yr': '3 yr',
			PriceBookRatio: 'Price/book ratio',
			alpha3Yr: 'Alpha (3 yr)',
			beta3Yr: 'Beta (3 yr)',
			informationRatio: 'Information ratio',
			annulaizedStdDev: 'Annualized std dev',
			consistentReturnLiteral: 'Consistent return',
			customizeScreenerAction: 'Customize screener',
			fundTypes: 'Fund types',
			additionalFundAttributes: 'Additional fund attributes',
			screenNameLabel: 'Screen name',
			screenerList: 'Screener list',
			// begin stock screener industries
			// -- Begin Academic & Education services
			'academic&educationalservices': 'Academic & Educational Services',
			professionalbusinesseducation: 'Professional Business Education',
			schoolcollegeuniversity: 'School College University',
			// -- End Academic..
			// -- Begin Industries - BASIC Materials
			agriculturalchemicals: 'Agricultural chemicals',
			aluminum: 'Aluminum',
			commoditychemicals: 'Commodity Chemicals',
			constructionmaterials: 'Construction Materials',
			diversifiedchemicals: 'Diversified Chemicals',
			diversifiedmining: 'Diversified Mining',
			forestwoodproducts: 'Forest & Wood Products',
			miningsupportservicesequipment: 'Mining support services equipment',
			nongoldpreciousmetalsminerals: 'Non Gold Precious Metal Minerals',
			nonpapercontainerspackaging: 'Non paper containers packaging',
			paperpackaging: 'Paper packaging',
			paperproducts: 'Paper products',
			specialtychemicals: 'Specialty chemicals',
			chemicalmanufacturing: 'Chemical manufacturing',
			chemicalsplasticrubber: 'Chemicals – plastic & rubber',
			containerspackaging: 'Containers & packaging',
			fabricatedplasticrubber: 'Fabricated plastic & rubber',
			forestrywoodproducts: 'Forestry & wood products',
			goldsilver: 'Gold & silver',
			ironsteel: 'Iron & steel',
			metalmining: 'Metal mining',
			miscfabricatedproducts: 'Misc. fabricated products',
			nonmetallicmining: 'Non-metallic mining',
			paperpaperproducts: 'Paper & paper products',
			specialtyminingmetals: 'Specialty mining metals',
			// -- End Basic materials
			// -- Begin Capital Goods
			aerospacedefense: 'Aerospace & defense',
			constragricmachinery: 'Constr. – agric. machinery ',
			constrsuppliesfixtures: 'Constr. – supplies & fixtures',
			constructionrawmaterials: 'Construction – raw materials',
			constructionservices: 'Construction services',
			misccapitalgoods: 'Misc. capital goods',
			mobilehomesrvs: 'Mobile homes & RVs',
			// -- End Capital Goods
			// -- Begin Consumers cyclical
			apparelaccessories: 'Apparel/accessories',
			apparelaccessoriesretailers: 'Apparel accessories retailers',
			appliancetool: 'Appliance & tool',
			appliancestoolshousewares: 'Appliances tools housewares',
			audiovideoequipment: 'Audio & video equipment',
			autotruckmanufacturers: 'Auto & truck manufacturers',
			autotruckparts: 'Auto & truck parts',
			autotruckmotorcycleparts: 'Auto truck motorcyle parts',
			autovehiclespartsserviceretailers: 'Auto vehical parts service retailers',
			advertisingmarketing: 'Advertising marketing',
			computerelectronicsretailers: 'Computer & electronics retailers',
			constructionsuppliesfixtures: 'Construction supplies & fixtures',
			consumerpublishing: 'Consumer publishing',
			departmentstores: 'Department stores',
			discountstores: 'Discount stores',
			entertainmentproduction: 'Entertainment production',
			footwear: 'Footwear',
			furniturefixtures: 'Furniture & fixtures',
			homebuilding: 'Home building',
			homefurnishings: 'Home furnishings',
			homefurnishingsretailers: 'Home furnishings retailers',
			homeimprovementproductsservicesretailers: 'Home Improvement Products & Services Retailers',
			hotelsmotelscruiselines: 'Hotels, Motels & CruiseLines',
			jewelrysilverware: 'Jewelry & silverware',
			leisurerecreation: 'Leisure & Recreation',
			otherspecialtyretailers: 'Other Specialty Retailers',
			photography: 'Photography',
			recreationalproducts: 'Recreational products',
			restaurantsbars: 'Restaurants & bars',
			textilenonapparel: 'Textile – non apparel',
			textilesleathergoods: 'Textiles & Leather Goods',
			tires: 'Tires',
			tiresrubberproducts: 'Tires & Rubber products',
			toysjuvenileproducts: 'Toys & Juvenile Products',
			// -- End Conusmers cyclical
			// -- Begin Consumer non-cyclical
			beveragesalcoholic: 'Beverages (alcoholic)',
			beveragesnonalcoholic: 'Beverages (non-alcoholic)',
			brewers: 'Brewers',
			crops: 'Crops',
			consumergoodsconglomerates: 'Consumer Goods Conglomerates',
			distillerswineries: 'Distillers wineries',
			drugretailers: 'Drug retailers',
			fishlivestock: 'Fish/livestock',
			fishingfarming: 'Fishing & Farming',
			foodprocessing: 'Food processing',
			foodretaildistribution: 'Food Retail & Distribution',
			householdproducts: 'Household Products',
			nonalcoholicbeverages: 'Non-Alcoholic Beverages',
			officesupplies: 'Office supplies',
			personalproducts: 'Personal Products',
			personalhouseholdprods: 'Personal & household prods.',
			// -- End Consumer non-cyclical
			// -- Begin Energy
			coal: 'Coal',
			integratedoilgas: 'Integrated Oil & Gas',
			oilgasdrilling: 'Oil & gas drilling',
			oilgasexplorationandproduction: ' Oil & Gas Exploration and Production',
			oilgasintegrated: 'Oil & gas - integrated',
			oilgasoperations: 'Oil & gas operations',
			oilgasrefiningandmarketing: 'Oil & Gas Refining and Marketing',
			oilwellservicesequipment: 'Oil well services & equipment',
			oilgastransportationservices: 'Oil & Gas Transportation Services',
			oilrelatedservicesandequipment: 'Oil Related Services and Equipment',
			renewableenergyequipmentservices: 'Renewable Energy Equipment',
			renewablefuels: 'Renewable Fuels',
			// -- End Energy
			// -- Begin Financial
			consumerfinancialservices: 'Consumer financial services',
			insuranceaccidenthealth: 'Insurance (accident & health)',
			insurancelife: 'Insurance (life)',
			insurancemiscellaneous: 'Insurance (miscellaneous)',
			insurancepropcasualty: 'Insurance (prop. & casualty)',
			investmentservices: 'Investment services',
			miscfinancialservices: 'Misc. financial services',
			moneycentrebanks: 'Money centre banks',
			regionalbanks: 'Regional banks',
			slssavingsbanks: 'S&Ls/savings banks',
			closedendfunds: 'Closed End Funds',
			consumerlending: 'Consumer Lending',
			corporatefinancialservices: 'Corporate Financial Services',
			diversifiedinvestmentservices: 'Diversified Investment Services',
			financialcommoditymarketoperators: 'Financial & Commodity Market Operators',
			investmentbankingbrokerageservices: 'Investment Banking & Brokerage Services',
			investmentholdingcompanies: 'Investment Holding Companies',
			investmentmanagementfundoperators: 'Investment Management & Fund Operators',
			investmenttrusts: 'Investment Trusts',
			lifehealthinsurance: 'Life & Health Insurance',
			mutualfunds: 'Mutual funds',
			multilineinsurancebrokers: 'Multiline Insurance & Brokers',
			propertycasualtyinsurance: 'Property & Casualty Insurance',
			reinsurance: 'Reinsurance',
			// -- End Financials
			// -- Begin Healthcare
			biotechnologydrugs: 'Biotechnology & drugs',
			healthcarefacilities: 'Healthcare facilities',
			majordrugs: 'Major drugs',
			medicalequipmentsupplies: 'Medical equipment & supplies',
			advancedmedicalequipmenttechnology: 'Advanced Medical Equipment & Technology',
			biotechnologymedicalresearch: 'Biotechnology & Medical Research',
			healthcarefacilitiesservices: 'Healthcare Facilities & Services',
			managedhealthcare: 'Managed Health care',
			medicalequipmentsuppliesdistribution: 'Medical Equipment, Supplies & Distribution',
			pharmaceuticals: 'Pharmaceuticals',
			// -- End Healthcare
			// -- Begin Services
			advertising: 'Advertising',
			broadcastingcabletv: 'Broadcasting & cable TV',
			broadcasting: 'Broadcasting',
			businessservices: 'Business services',
			casinosgaming: 'Casinos & gaming',
			communicationsservices: 'Communications services',
			hotelsmotels: 'Hotels & motels',
			motionpictures: 'Motion pictures',
			personalservices: 'Personal services',
			printingpublishing: 'Printing & publishing',
			printingservices: 'Printing services',
			realestateoperations: 'Real estate operations',
			recreationalactivities: 'Recreational activities ',
			rentalleasing: 'Rental & leasing',
			restaurants: 'Restaurants',
			retailapparel: 'Retail (apparel)',
			retailcatalogmailorder: 'Retail (catalog & mail order)',
			retaildepartmentdiscount: 'Retail (department & discount)',
			retaildrugs: 'Retail (drugs)',
			retailgrocery: 'Retail (grocery)',
			retailhomeimprovement: 'Retail (home improvement)',
			retailspeciality: 'Retail (speciality)',
			retailtechnology: 'Retail (technology)',
			schools: 'Schools',
			securitysystemsservices: 'Security systems & services',
			wastemanagementservices: 'Waste management services',
			// -- End Services
			// -- Begin Industrials
			airfreightlogistics: 'Air Freight & Logistics',
			airlines: 'Airlines',
			airportservices: 'Airport Services',
			businesssupportservices: 'Business Support Services',
			businesssupportsupplies: 'Business Support Supplies',
			commercialprintingservices: 'Commercial Printing Services',
			constructionengineering: 'Construction & Engineering',
			diversifiedindustrialgoodswholesale: 'Diversified Industrial Goods Wholesale',
			electricalcomponentsequipment: 'Electrical Components & Equipment',
			employmentservices: 'Employment Services',
			environmentalservicesequipment: 'Environmental Services & Equipment',
			groundfreightlogistics: 'Ground Freight & Logistics',
			heavyelectricalequipment: 'Heavy Electrical Equipment',
			heavymachineryvehicles: 'Heavy Machinery & Vehicles',
			highwaysrailtracks: 'Highways & Rail Tracks',
			industrialmachineryequipment: 'Industrial Machinery & Equipment',
			marinefreightlogistics: 'Marine Freight & Logistics',
			marineportservices: 'MarinePortServices',
			passengertransportationgroundsea: 'Passenger Transportation, Ground & Sea',
			professionalinformationservices: 'Professional Information Services',
			shipbuilding: 'Ship building',
			// -- End Industrials
			// -- Begin Technology
			communicationsequipment: 'Communications equipment',
			computerhardware: 'Computer hardware',
			computernetworks: 'Computer networks',
			computerperipherals: 'Computer peripherals',
			computerservices: 'Computer services',
			computerstoragedevices: 'Computer storage devices',
			electronicinstrcontrols: 'Electronic instr. & controls',
			officeequipment: 'Office equipment',
			scientifictechnicalinstr: 'Scientific & technical instr.',
			semiconductors: 'Semiconductors ',
			softwareprogramming: 'Software & programming',
			blockchaincryptocurrency: 'Block chain & Cryptocurrency',
			communicationsnetworking: 'Communications & Networking',
			electronicequipmentparts: 'Electronic Equipment & Parts',
			financialtechnologyfintech: 'Financial Technology(Fintech)',
			householdelectronics: 'Household Electronics',
			integratedhardwaresoftware: 'Integrated Hardware & Software',
			integratedtelecommunicationsservices: 'Integrated Telecommunications Services',
			internetservices: 'Internet Services',
			itservicesconsulting: 'IT Services & Consulting',
			miscellaneousfintechinfrastructure: 'Miscellaneous Fintech Infrastructure',
			phoneshandhelddevices: 'Phones & Handheld Devices',
			semiconductorequipmenttesting: ' Semiconductor,Equipment & Testing',
			software: 'Software',
			wirelesstelecommunicationsservices: 'Wireless Telecommunications Services',
			// -- End Technology
			// -- Begin Real estate
			commercialreits: 'Commercial REITs',
			diversifiedreits: 'Diversified REITs',
			realestatedevelopmentoperations: 'Real Estate Development & Operations',
			realestateservices: 'Real Estate Services',
			residentialreits: 'Residential REITs',
			specializedreits: 'SpecializedREITs',
			// -- End Real estate
			// -- Begin Transportation
			aircourier: 'Air courier',
			airline: 'Airline',
			misctransportation: 'Misc. transportation',
			railroads: 'Railroads',
			trucking: 'Trucking',
			watertransportation: 'Water transportation',
			// -- End Transportation
			// -- Begin Utilities
			electricutilities: 'Electric utilities',
			naturalgasutilities: 'Natural gas utilities',
			waterutilities: 'Water utilities',
			independentpowerproducers: 'Independent Power Producers',
			multilineutilities: 'Multiline Utilities',
			// -- End Utilities
			searchByKeywords: 'Search by keywords',
			selectSources: 'Select sources',
			selectIndustries: 'Select industries',
			industrySection: 'Industry',
			// end stock screener industries
			true: 'True',
			false: 'False',
			editCriteria: 'Edit Criteria',
			renameScreen: 'Rename screen',
			allFundFamilies: 'All fund families',
			allEtfTypes: 'All ETF types',
			browseByEtf: 'Browse by ETF',
			reportPdf: 'Report (PDF)',
			sourceCriterion: 'Source criterion',
			industryCriterion: 'Industry criterion',
			customSearchResultsFor: 'Custom search results for {searchName}',
			thereAreNoSavedSearches: 'There are no saved searches.',
			newAdvancedSearch: 'New advanced search',
			savedAnalystReportsSearchButtonSR: '{searchName} reports displayed in accompanying table',
			deleteSearch: 'Delete search',
			editSearchInNewDialog: 'Edit {searchName} search in a new dialogue',
			renameSearchInNewDialog: 'Rename {searchName} search in a new dialogue',
			editSavedSearch: 'Edit {searchName} search',
			renameSearch: 'Rename search',
			deleteSavedSearch: 'Delete saved search',
			doYouWantToDelete: 'Do you want to delete this saved search?',
			editCustomSearchName: 'Edit custom search name',
			saveCustomSearch: 'Save custom search',
			customSearchNameOver32: 'Custom search names cannot exceed 32 characters.',
			setAlert: 'Set alert',
			setAlertSr: 'Set {searchName} alert',
			analystReportAlertsHeader: 'Analyst report alerts',
			recieveDailyEmail: 'Receive a daily email alert of the latest analyst reports based on specific search criteria.',
			setAnalystReportAlerts: 'Set analyst report alerts',
			savedSearch: 'Saved search',
			savedSearchResults: '{searchName} search results',
			alreadySearchingSymbol: 'This symbol is already in the list',
			noResultsFoundPleaseChooseDiff: 'No results found. Please choose different search criteria.',
			nameExistsAlready: 'There is already a saved search with that name. Please rename.',
			saveAsNewSearch: 'Save as new search',
			searchName: 'Search name',
			equityFunds: 'Equity funds',
			mixedEquityFunds: 'Mixed equity funds',
			fixedIncomeFunds: 'Fixed income funds',
			moneyMarketFunds: 'Money Market funds',
			bondFunds: 'Bond funds',
			realEstateFunds: 'Real estate funds',
			otherFunds: 'Other funds ',
			LFFundType: 'Fund types',
			LFAssetType: 'Asset type',
			LFFundPromotor: 'Fund promoter',
			LFLipperClassification: 'Fund category',
			LFTotalReturns1m: 'Performance amounts (%)',
			lessThanZero: '< 0%',
			zeroToFive: '0-5%',
			fiveToTen: '5-10%',
			tenToFifteen: '10-15%',
			greaterThanFifteen: '15%+',
			timePeriod: 'Time period',
			'<$1K': '<$1K',
			'$1K-2K': '$1K-2K',
			'$2K-10K': '$2K-10K',
			'>$10K': '>$10K',
			minimumInitialInvestmentScreener: 'Minimum initial investment',
			'<$100M': '< $100M',
			'$100M-500M': '$100M-500M',
			'$500M-1B': '$500M-1B',
			'$1B+': '$1B+',
			mgmtExpenseRatio: 'Management Expense Ratio',
			'0-1%': '0-1%',
			'1-2%': '1-2%',
			'2-3%': '2-3%',
			'3-4%': '3-4%',
			'4%+': '4%+',
			LFLoadType: 'Load types',
			noLoad: 'No Load',
			front: 'Front',
			back: 'Back',
			level: 'Level',
			originalAmount: 'Original amount',
			marketValueLabel: 'Market value',
			noSavedScreeners: 'You currently do not have any saved screeners.',
			createNewScreener: 'Create a new screener.',
			reviewScreener: 'Review screeners',
			minimizeFundTypeCriteria: 'Minimize fund type criteria',
			expandFundTypeCriteria: 'Expand fund type criteria',
			lipperInstructions: 'Choose the Lipper Leader rating from the accompanying categories that match your investment goals. You can make multiple selections.',
			PreservationCriteria: 'Preservation',
			LFNoLoad: 'No Load',
			LFFrontLoad: 'Front',
			LFBackLoad: 'Back',
			LFLevelLoad: 'Level',
			LFDeferredLoadOrigAmt: 'Original amount',
			LFDeferredLoadMktValue: 'Market value',
			equalTo: 'Equal to',
			anotherDifferentTranslationOfAll: 'All',
			totalNetAssetsScreener: 'Total net assets',
			minimizeTotalReturnPerformance: 'Minimize total return performance criteria',
			expandTotalReturnPerformance: 'Expand total return performance criteria ',
			screenerTotalReturnPerformance: 'Total return performance',
			minimizeAdditionalFund: 'Minimize additional fund attributes criteria',
			expandAdditionalFund: 'Expand additional fund attributes criteria',
			minimizeLipperCriteria: 'Minimize Lipper Leader ratings criteria',
			expandLipperCriteria: 'Expand Lipper Leader ratings criteria',
			lipperLeaderRatingsScreener: 'Lipper Leader ratings',
			canadianMFs: 'Canadian Mutual Funds',
			canadianETFs: 'Canadian ETFs',
			usETFs: 'US ETFs',
			pageIsLoading: 'Page is loading',
			// WebDrawer Top Holdings
			top5Holdings: 'Top 5 holdings',
			top5HoldingsTooltip: 'Top holdings are assets held within a portfolio with the greatest weighting.',
			entity: 'Entity',
			showSectorHoldings: 'Show sector holdings',
			showEntityHoldings: 'Show entity holdings',
			// Account Holdinfs
			accountHoldings: {
				buy: 'Buy',
				hold: 'Hold',
				sell: 'Sell'
			}
		}
	},
	fr: {
		locale: 'French',
		messages: {
			'': '',
			'1d': '1j',
			'1dHidden': '1 jour',
			'1week': '1 semaine',
			'1m': '1m',
			'1month': '1 mois',
			'1monthPct': '1 month %',
			'1monthReturn': 'Rendement sur 1 mois',
			'1monthReturnDescription': 'Rendement total d\'un FCB tenant compte de la variation du cours et des distributions réinvesties au cours d\'une période d\'un mois.',
			'1star': '1 étoile',
			'2days': '2 jours',
			'2months': '2 mois',
			'2stars': '2 étoiles',
			'3stars': '3 étoiles',
			'4stars': '4 étoiles',
			'5d': '5j',
			'5days': '5 jours',
			'5dHidden': '5 jours',
			'5stars': '5 étoiles',
			'3m': '3m',
			'3month': '3 mois',
			'3monthPct': '3 month %',
			'3monthReturn': 'Rendement sur 3 mois',
			'3monthReturnDescription': 'Rendement total d\'un FCB tenant compte de la variation du cours et des distributions réinvesties au cours d\'une période de trois mois.',
			'3months': '3 mois',
			'3mHidden': '3 mois',
			'3w': '3s',
			'3wHidden': '3 semaines',
			'1y': '1a',
			'1yHidden': '1 an',
			'1yPct': '1 year %',
			'3yPct': '3 year %',
			'5y': '5a',
			'5yHidden': '5 ans',
			'6m': '6m',
			'6month': '6 mois',
			'6monthReturn': 'Rendement sur 6 mois',
			'6monthReturnDescription': 'Rendement total d\'un FCB tenant compte de la variation du cours et des distributions réinvesties sur une période de six mois.',
			'6months': '6 mois',
			'3monthPremiumDiscount': 'Prime et escompte des 3 derniers mois',
			'1year': '1 an',
			'1yearReturn': 'Rendement sur 1 an',
			'1yearReturnDescription': 'Rendement total annualisé moyen d\'un FCB tenant compte de la variation du cours et des distributions réinvesties sur une période d\'un an.',
			'1yearLower': '1 an',
			'3year': '3 ans',
			'3yearRating': 'Classement 3 ans',
			'3yearRatingDescription': 'Cote de Morningstar fondée sur le rendement ajusté au risque du FCB sur une période de trois ans.',
			'3yearReturn': 'Rendement sur 3 ans',
			'3yearReturnDescription': 'Rendement total annualisé moyen d\'un FCB tenant compte de la variation du cours et des distributions réinvesties sur une période de trois ans.',
			'3yearReturnRatingDescription': 'Cote de rendement du FCB sur une période de trois ans.',
			'3yearRisk': 'Cote de risque 3 ans',
			'3yearRiskDescription': 'Cote de risque du FCB sur une période de trois ans.',
			'3yearLower': '3 ans',
			'3years': '3 ans',
			'5year': '5 ans',
			'5yearRating': 'Classement 5 ans',
			'5yearRatingDescription': 'Cote de Morningstar fondée sur le rendement ajusté au risque du FCB sur une période de cinq ans.',
			'5yearReturn': 'Rendement sur 5 ans',
			'5yearReturnDescription': 'Rendement total annualisé moyen d\'un FCB tenant compte de la variation du cours et des distributions réinvesties sur une période de cinq ans.',
			'5yearReturnRatingDescription': 'Cote de rendement du FCB sur une période de cinq ans.',
			'5yearRisk': 'Cote de risque 5 ans',
			'5yearRiskDescription': 'Cote de risque du FCB sur une période de cinq ans.',
			'5yearLower': '5 ans',
			'5years': '5 ans',
			'10year': '10 ans',
			'10yearRating': 'Classement 10 ans',
			'10yearRatingDescription': 'Cote de Morningstar fondée sur le rendement ajusté au risque du FCB sur une période de dix ans.',
			'10yearReturn': 'Rendement sur 10 ans',
			'10yearReturnDescription': 'Rendement total annualisé moyen d\'un FCB tenant compte de la variation du cours et des distributions réinvesties sur une période de dix ans.',
			'10yearReturnRatingDescription': 'Cote de rendement du FCB sur une période de dix ans.',
			'10yearRisk': 'Cote de risque 10 ans',
			'10yearRiskDescription': 'Cote de risque du FCB sur une période de dix ans.',
			'10yearLower': '10 ans',
			'10years': '10 ans',
			'52WeekRange': 'Période de 52 semaines ',
			'52WeekRangeLowerCase': 'Var. 52 sem.',
			'52WeekRangeSr': 'Fourchette des cours sur 52 semaines entre',
			'52WkRange': '52 Wk Range',
			'52WH': '52 S É',
			'52WL': '52 S F',
			'7days': '7 jours',
			'14days': '14 jours',
			'30days': '30 jours',
			'60days': '60 jours',
			'90days': '90 jours',
			'1WeekReturn': 'Rendement sur 1 semaine',
			'1MonthReturn': 'Rendement sur 1 mois',
			'3MonthReturn': 'Rendement sur 3 mois',
			'6MonthReturn': 'Rendement sur 6 mois',
			'1YearReturn': 'Rendement sur 1 an',
			'3YearReturnAnn': 'Rendement sur 3 ans (annualisé)',
			'3YearsReturn': 'Rendement sur 3 ans',
			'5YearReturnAnn': 'Rendement sur 5 ans (annualisé)',
			'5YearsReturn': 'Rendement sur 5 ans',
			'10YearReturnAnn': 'Rendement sur 10 ans (annualisé)',
			'--': '--',
			__RECENT__: 'Cotes récentes',
			'5StarEquityETFs': 'FNB d\'actions 5 étoiles',
			'5StarEquityETFs_desc': 'FNB cotés 5 étoiles Morningstar, qui investissent dans des actions canadiennes, américaines et internationales.',
			above: 'au-dessus de',
			aboveAverage: 'Au-dessus de la moyenne',
			above52WeekHigh: 'Au-dessus du sommet de 52 semaines',
			above52WeekHighDescription: 'FCB qui ont atteint un nouveau sommet de 52 semaines.',
			accountingChange: 'Accounting Change',
			accountsPayable: 'Accounts Payable',
			accountsReceivable: 'Accounts Receivable',
			accruedExpenses: 'Accrued Expenses',
			acqBusiness: 'Acquisition of Business',
			actionCantBeUndone: 'Cette action est irréversible',
			advertExpense: 'Advertising Expense',
			add: 'Ajouter',
			addCriteria: 'Ajouter des critères',
			addCriteriaSRDirections: 'Pour accéder aux catégories de critères, utilisez la touche de tabulation. Pour accéder à chaque catégorie, utilisez la flèche vers la droite. Utilisez les flèches vers le haut et le bas pour parcourir la liste de critères.',
			additionalPerShare: 'Autres données par action',
			addNewAlert: 'Add New Alert (Enter Symbol)',
			addSelectedToWatchList: 'Add Selected to Watch List',
			addToWatchListAndCloseDialogue: 'Ajouter à la liste de surveillance et fermer la fenêtre de dialogue',
			addToWatchlistSr: 'Ajouter les éléments sélectionnés à la liste de surveillance dans une nouvelle fenêtre de dialogue',
			addToWatchlistSymbolSr: 'jouter {symbol} à la liste de surveillance',
			addOption: 'Add Option',
			addOptionSymbol: 'Ajouter {symbol} à la liste des cotes',
			addOptionSymbolAndGoTo: 'Ajouter {symbol} et aller aux listes de cotes',
			address: 'Adresse',
			addSymbolsUsingTheSearchAbove: 'Add symbols using the search above.',
			addSymbolsUsingTheSearchAboveOrSelectOpton: 'Add symbols using the search above or select an option below.',
			addToQuoteList: 'Ajouter à la liste des cotes',
			addToWatchList: 'Ajouter à la liste de surveillance',
			Advanced: 'Avancé',
			advancedChart: 'Graphique avancé',
			advancedChartSr: '{symbol} graphique avancé dans une nouvelle boîte de dialogue',
			advancedChartSrOnly: '{symbol} : graphique avancé dans une nouvelle boîte de dialogue',
			'aerospace&defense': 'Aéronautique et défense',
			age: 'Âge',
			alertsAgreement: 'Entente relative aux alertes',
			alertsAgreementHidden: 'La version PDF de l’Entente relative aux alertes s’affiche dans un nouvel onglet du navigateur',
			alertsPref: 'Alertes préférences',
			alertsPrefDisclaimer1: 'Si vous vous abonnez aux alertes en fournissant votre adresse de courriel et en sélectionnant le bouton Enregistrer ci-dessous, vous acceptez d’être lié par les modalités de notre Entente relative aux alertes.',
			alertsPrefDisclaimer2: 'En raison de la taille limitée des messages texte, vous pourriez recevoir plusieurs messages texte pour chaque alerte. Les tarifs habituels de messagerie texte peuvent s’appliquer selon l’entente que vous avez avec votre fournisseur.',
			alertsPrefEmailNote: 'Les modifications d’adresse de courriel relatives aux alertes techniques ne peuvent être apportées qu’à l’adresse de courriel 1.',
			alertsPrefMsg0: 'Vous pouvez recevoir des alertes par courriel et par message texte.',
			alertsPrefMsg1: 'Pour configurer une alerte par courriel, vous devez fournir au moins une adresse de courriel. Vous pouvez choisir de recevoir des courriels en format HTML ou Texte.',
			alertsPrefMsg2: 'En fournissant votre adresse de courriel et en sélectionnant le bouton Enregistrer ci-dessous, vous acceptez d’être lié par les modalités de notre Entente relative aux alertes. Pour lire cette entente, consultez le lien au bas de la page.',
			alertsPrefMsg3: 'Si vous souhaitez recevoir des alertes par message texte, veuillez fournir un numéro de téléphone mobile en plus d’une adresse de courriel.',
			alertsPrefMsg4: 'Vous pouvez aussi utiliser l’option d’interruption vacances pour cesser de recevoir des alertes pendant une période donnée.',
			alertsPrefMsg5: 'Pour enregistrer vos préférences, sélectionnez le bouton Enregistrer au bas de la page.',
			all: 'Tous',
			allcanada: 'Tous',
			allMarketDisclaimer: 'Les prix des opérations et les cotes ne proviennent pas de tous les marchés.',
			allunitedstates: 'Tous',
			allocation: 'Répartition',
			allowFundsDurConst: 'Allow Funds Used Dur Const',
			allSectors: 'Tous les secteurs',
			allFundType: 'Tous les types de fonds',
			allFundPromoters: 'Tous les promoteurs de fonds',
			allKeywords: 'Tous les mots clés',
			alpha: 'Alpha',
			alphaYear: 'Alpha ({year} an)',
			analystConsensus: 'Consensus des analystes',
			analystConsensusTooltip: 'Le consensus des analystes est fondé sur l’évaluation des analystes côté vendeur pour les 3 derniers mois',
			MSDPrimaryIndexAlphaY1Ptile: 'Alpha (1 an)',
			MSDPrimaryIndexAlphaY3Ptile: 'Alpha (3 an)',
			MSDPrimaryIndexAlphaY5Ptile: 'Alpha (5 an)',
			MSDPrimaryIndexAlphaY10Ptile: 'Alpha (10 an)',
			MSDPrimaryIndexAlphaY1: 'Alpha (1 an)',
			MSDPrimaryIndexAlphaY3: 'Alpha (3 an)',
			MSDPrimaryIndexAlphaY5: 'Alpha (5 an)',
			MSDPrimaryIndexAlphaY10: 'Alpha (10 an)',
			alphaDescription: 'L’alpha sur une période d’un an, de trois ans, de cinq ans ou de dix ans représente le rendement excédentaire d’un FNB par rapport au rendement ajusté au risque de son indice de référence sur une période déterminée. L’alpha peut servir à mesurer la valeur ajoutée par le gestionnaire de portefeuille.',
			alphaVsCategory: 'Alpha vs. {category}',
			alphaVsCategoryTooltip: 'L\'alpha représente le rendement excédentaire d\'un FCB par rapport au rendement ajusté au risque de son indice de référence sur une période donnée. L\'alpha peut servir à mesurer la valeur ajoutée par le gestionnaire de portefeuille.',
			alreadyInWatchList: 'Le symbole figure déjà dans cette liste.',
			alreadyInWatchList2: '{symbols} are already in your watch list',
			alternative: 'Non traditionnels',
			alternativeenergy: 'Énergie de remplacement',
			analystReports: 'Rapports d’analystes',
			analystReportAlerts: 'La section des alertes liées aux rapports d’analystes n’est pas tout à fait terminée, mais elle le sera bientôt. Une fois que la section sera prête, notre nouveau format amélioré vous permettra de trouver tous les renseignements liés aux alertes que vous avez enregistrés. <br/><strong>Remarque :</strong> Les alertes actuelles seront suspendues jusqu’à ce que notre nouveau format soit opérationnel.',
			analystReportsDisclaimer1: 'Les renseignements sur la recherche de placements que renferme le présent site Web sont généraux. Vous ne devez pas les considérer comme des conseils précis en matière de recherche de placements ni vous y fier à ce sujet.',
			analystReportsDisclaimer2: 'Les exigences de divulgation en vigueur au Canada ne s’appliquent pas aux rapports de recherche produits par des entités autres que Marchés mondiaux CIBC inc.',
			analystReportsDisclaimer3: 'Des rapports de recherche imprimés ou électroniques, préparés par Marchés mondiaux CIBC et les divisions CIBC Wood Gundy de Marchés mondiaux CIBC inc., seront offerts simultanément à tous les clients ayant le droit de les recevoir. En règle générale, les documents n’ayant pas trait à des titres en particulier, comme les documents Études économiques et stratégie ou l’indice obligataire, sont offerts au grand public. La plupart des documents qui portent sur des secteurs ou des titres en particulier, comme Fixed Income Research, Equity Research, and Technical & Quantitative Analysis (en anglais seulement), sont considérés comme exclusifs et sont réservés aux destinataires suivants : clients institutionnels, courtiers de détail de Marchés mondiaux CIBC afin qu’ils les distribuent à leurs clients, clients de sociétés affiliées de la Banque CIBC qui ont reçu l’autorisation d’accéder à ces documents en ligne. Le document le plus récent sera diffusé dans le présent site Web, et tous les clients ayant l’autorisation de le recevoir pourront y accéder. Ces documents ne sont destinés qu’aux résidents canadiens.',
			analysts: 'Analystes',
			analystRating: 'Notes des analystes',
			analystReportsPreview: 'La section de recherche personnalisée liée aux rapports d’analystes n’est pas tout à fait terminée, mais elle le sera bientôt. Notre nouveau format amélioré vous permettra de trouver tous les renseignements de recherche personnalisée que vous avez enregistrés.',
			analystEstimate: 'Estimations d\'analyste',
			analystRatingText: 'This is {trendText} from the previous rating of {ratingText} ({rating}). As of {ratingDate}.',
			annualEps: 'RPA annuel',
			alertType: 'Type d’alerte',
			alertTypeSet: 'Alert type set',
			amortAcqCosts: 'Amortiz of Acquist Costs',
			amortDeferPolicyAcqCosts: 'Amort Defer Policy Acq Csts',
			amortIntangibles: 'Amortization of Intangibles',
			amortization: 'Amortization',
			amortOAcqCosts: 'Amortization of Acq Costs',
			amortOfAcqCosts: 'Amortization of Acq Costs',
			amortOfIntangibles: 'Amortization of Intangibles',
			amortPolicyAcquisitionCost: 'Amort. Of Policy Acquisition Costs',
			annual: 'Annuels',
			annualOption: 'Annuelle',
			semiannual: 'Semestrielle',
			annualIncomeStatementItem: 'Élément de l’état des résultats annuel',
			annualBalanceSheetItem: 'Élément du bilan annuel',
			annualCashFlowItem: 'Élément des flux de trésorerie annuels',
			annualDividend: 'Dividende annuel',
			annualizedReturnsTooltip: 'Performance de {symbol} au cours de multiples périodes',
			annualizedReturns: 'Rendements annualisés',
			annualPerformance: 'Performance annuelle',
			annualPerformanceChartDataFollows: 'Performance annuelle les données du tableau suivent',
			annualPerformanceData: 'Données de performance annuelle',
			annualized: 'annualisé',
			anyKeywords: 'N’importe quel mot clé',
			apply: 'Appliquer',
			annualizedPerformance: 'Rendement annualisé',
			applySrOnly: 'Appliquer des filtres de recherche',
			ascending: 'Ascending',
			articleByDate: 'Article de {article}, par date',
			ask: 'Cours vendeur',
			askLotsSrOnly: 'Lot cours vendeurs',
			asOfMarketClose: 'À la clôture du marché, le',
			asOfPreviousClose: 'À la clôture précédente',
			asOfPreviousClose2: 'À la fermeture des marchés précédente',
			asOfPreviousMonthEnd: 'Les données sont en date de la fin de mois précédente',
			assetAllocation: 'Répartition de l\'actif',
			assetAllocationInfo: ' Répartition de l\'actif fondée sur les plus récents renseignements communiqués par Morningstar.',
			asOf: 'En date du',
			dailyMarketAsOf: 'Les marchés le',
			askLots: 'Symbole/société',
			askLotsCapitalization: 'Cours vendeurs/lots',
			askLotsCapitalization2: 'Cours vendeurs/Lots',
			askSharesCapitalization: 'Cours vendeurs/actions',
			askSharesCapitalization2: 'Cours vendeurs/Actions',
			assetClasses: 'Catégories d\'actif',
			assetClass: 'Catégories d\'actif',
			announcementDate: 'Announcement Date',
			assets: 'de l’actif',
			'automobiles&parts': 'Automobiles et pièces',
			availableWatchlists: 'Available Watchlists',
			average: 'Moyenne',
			averageCost: 'Coût moyen',
			averageVolume10Day: 'Volume moyen (10 jours)',
			averageVolume10DayDescription: 'Nombre moyen d\'actions négociées au cours des 10 derniers jours de bourse.',
			accptOutstanding: 'Acceptances Outstanding',
			accruedInvestIncome: 'Accrued Investment Income',
			accumGoodwillAmort: 'Accum. Goodwill Amort.',
			accumIntangibleAmort: 'Accum. Intangible Amort.',
			accumulatedDepletion: 'Accumulated Depletion',
			accumulatedDepreciation: 'Accumulated Depreciation',
			additionalPaidInCap: 'Additional Paid-In Capital',
			balanced: 'équilibrés',
			balanceSheet: 'Bilan',
			banks: 'Banques',
			Basic: 'De base',
			basicRanges: 'Fourchette de base: ',
			basicRangesSR: 'Sélectionnez le bouton d’option Fourchette de base pour choisir des valeurs, ou le bouton d’option Fourchette précise pour entrer une ou plusieurs valeurs.',
			basicmaterials: 'Matériaux de base',
			MSDStockSectorBasicMaterials: 'Secteur Matériaux de base',
			basicNormalEPS: 'Basic Normalized EPS',
			basicPrimarEPSExclExtraItems: 'Basic/Primary EPS Excl. Extra. Items',
			basicPrimarEPSInclExtraItems: 'Basic/Primary EPS Incl. Extra. Items',
			basicPrimarWeightAvShares: 'Basic/Primary Weighted Average Shares',
			bearishMovingAverageCrossover: 'Croisement haussier des moyennes mobiles',
			bearishMovingAverageCrossover_desc: 'On est en présence d\'un croisement baissier des moyennes mobiles lorsqu\'une moyenne mobile sur une courte période (15 jours) passe au-dessous d\'une moyenne mobile sur une plus longue période (50 jours).',
			bearishRSI: 'Indice de force relative en baisse',
			bearishRSI_desc: 'On est en présence d\'un indice de force relative (IFR) en baisse lorsque l\'IFR (14 jours) passe sous 70.',
			bearishStochastics: 'Facteurs stochastiques en baisse',
			bearishStochastics_desc: 'On est en présence de facteurs stochastiques en baisse lorsque la ligne K% (14 jours) passe sous la ligne D% (3 jours).',
			beta: 'Bêta',
			betaYear: 'Bêta ({year} an)',
			MSDPrimaryIndexBetaY1Ptile: 'Bêta (1 an)',
			MSDPrimaryIndexBetaY3Ptile: 'Bêta (3 an)',
			MSDPrimaryIndexBetaY5Ptile: 'Bêta (5 an)',
			MSDPrimaryIndexBetaY10Ptile: 'Bêta (10 an)',
			MSDPrimaryIndexBetaY1: 'Bêta (1 an)',
			MSDPrimaryIndexBetaY3: 'Bêta (3 an)',
			MSDPrimaryIndexBetaY5: 'Bêta (5 an)',
			MSDPrimaryIndexBetaY10: 'Bêta (10 an)',
			betaDescription: 'Le bêta sur une période d’un an, de trois ans, de cinq ans ou de dix ans traduit la propension d’un FNB à réagir aux fluctuations de son indice de référence sur une période déterminée. Un bêta de 1 indique que le FNB pourrait enregistrer le même rendement que son indice de référence; un bêta de 1,5 signifie qu’il pourrait enregistrer 1,5 fois le rendement de l’indice.',
			betaVsCategory: 'Beta vs. {category}',
			betaVsCategoryTooltip: 'Le bêta traduit la propension du rendement d\'un FCB à réagir aux fluctuations de son indice de référence sur une période donnée. Un bêta de 1 indique que le FCB pourrait enregistrer le même rendement que son indice de référence; un bêta de 1,5 signifie qu\'il pourrait enregistrer 1,5 fois le rendement de l\'indice.',
			beta1Year: 'Bêta (1 Année)',
			beverages: 'Boissons',
			bid: 'Cours acheteur',
			bids: 'Cours acheteur',
			bidLots: 'Cours acheteurs/lots',
			bidLotsCapitalization: 'Cours acheteurs/lots',
			bidLotsCapitalization2: 'Cours acheteurs/Lots',
			bidSharesCapitalization: 'Cours acheteurs/actions',
			bidSharesCapitalization2: 'Cours acheteurs/Actions',
			bidLotsSrOnly: 'Lots cours acheteurs',
			billion: 'milliard(s)',
			both: 'Les deux',
			bottomNMovers: 'Bottom {count} Movers',
			bottomPriceMovers: 'Titres perdants ($)',
			bottomPerformers: 'Pires rendements',
			bottomPctMovers: 'Titres perdants (%)',
			below: 'en dessous de',
			belowAverage: 'Sous moyenne',
			belowB: 'Below B',
			below52WeekLow: 'Au-dessous du creux de 52 semaines',
			below52WeekLowDescription: 'FCB qui ont atteint un nouveau creux de 52 semaines.',
			bonds: 'Obligations',
			bookRatio: 'valeur comptable',
			bookValue: 'Valeur comptable',
			breakeven: 'Break Even',
			buildings: 'Buildings',
			bottomMovers: 'Titres les moins actifs',
			bullishMovingAverageCrossover: 'Croisement baissier des moyennes mobiles',
			bullishMovingAverageCrossover_desc: 'On est en présence d\'un croisement haussier des moyennes mobiles lorsqu\'une moyenne mobile sur une courte période (15 jours) passe au-dessus d\'une moyenne mobile sur une plus longue période (50 jours).',
			bullishRSI: 'Indice de force relative en hausse',
			bullishRSI_desc: 'On est en présence d\'un indice de force relative (IFR) en hausse lorsque l\'IFR (14 jours) dépasse 30.',
			bullishStochastics: 'Facteurs stochastiques en hausse',
			bullishStochastics_desc: 'On est en présence de facteurs stochastiques en hausse lorsque la ligne K% (14 jours) passe au-dessus de la ligne D% (3 jours).',
			buttonView: 'Afficher les boutons',
			byChain: 'Par chaîne',
			byCustom: 'Par option des recommandations personnalisées',
			byExpiry: 'By Expiry',
			byStrike: 'By Strike',
			'C-AM': 'Call',
			calls: 'Options d’achat',
			call: 'Option d’achat',
			callPut: 'Achat/Option de vente',
			canadaGrowthAtReasonablePrice: 'Canada – croissance à prix raisonnable',
			canadaGrowthAtReasonablePrice_desc: 'Actions canadiennes affichant une croissance raisonnable des cours',
			canadaLowPEStocks: 'Canada – actions à faible ratio C/B',
			canadaValueStocks: 'Canada – actions de valeur',
			calendarYearReturns: 'Rendements par année civile',
			canadianEquityETFs: 'FNB d\'actions canadiennes',
			canadianEquityETFs_desc: 'FNB qui investissent dans des actions canadiennes.',
			canadianEquity: 'Actions canadiennes',
			canadianPrime: 'Taux préférentiel canadien',
			canadianResearchReview: 'Canadian research review (en anglaise seulement)',
			canadianResearchReviewDescription: 'Un aperçu annuel de l’univers des actions canadiennes par les analystes de Marchés mondiaux CIBC. Celle-ci comprend une sélection des meilleurs titres et des commentaires sur l’économie.',
			cancel: 'Annuler',
			cancelSr: 'Annuler et fermer la fenêtre de dialogue',
			cancelWatchlist: 'Annuler et aller aux listes de surveillance',
			canadianDollars: 'Dollars canadiens',
			canadianNews: 'Nouvelles au Canada',
			capEx: 'Capital Expenditures',
			capLabel: 'boursière',
			cash: 'Liquidités',
			cashDividsPaidCommon: 'Cash Divids Paid - Common',
			cashDividsPaidPreferred: 'Cash Divs Paid - Preferred ',
			cashFlow: 'flux de trésorerie',
			cashFlowStandAlone: 'Flux de trésorerie',
			cashInterestPdSuppl: 'Cash Interest Pd, Suppl',
			cashPayments: 'Cash Payments',
			cashReceipts: 'Cash Receipts',
			cashTaxesPdSuppl: 'Cash Taxes Pd, Supplemental',
			change: 'Variation',
			changeContextChangement: 'Changement',
			changeContextVariation: 'Variation',
			changeDollars: 'Change $',
			categoryAverage: 'Moyenne de la catégorie',
			category: 'Catégorie',
			categoryRankTooltip: 'Le classement par catégorie représente le rang du FNB sur une échelle où 1 représente le centile le plus élevé et 100 le plus bas, en comparaison aux autres.<br/><br/>Par exemple, un FNB avec un classement de «<span style="font-weight:800;">15/10</span>» dans «Classement par catégorie/Total» signifie que ce FNB se classe dans les premiers <span style="font-weight:800;">15%</span> de la catégorie qui comporte <span style="font-weight:800;">10 FNB</span>.',
			categoryRank: 'Total et rang de la catégorie',
			changeOf: 'Variation de',
			changePercent: 'Change %',
			changesWorkingCapital: 'Changes in Working Capital',
			changeToETFDetails: 'Précisions sur les changements de {symbol}',
			chargesMayNotBeApplicable: 'Les frais peuvent ne pas s’appliquer à tous les types de frais d’acquisition ou de rachat',
			chart: 'Graphique',
			charts: 'Diagrammes',
			chartUnavailable: 'Aucun tableau n’est accessible',
			checkYourNotifications: 'Pour l’ouvrir, consultez vos notifications, vos téléchargements ou votre navigateur',
			chemicals: 'Produits chimiques',
			cibcFunds: 'Fonds de la CIBC',
			cibcWoodGundy: 'PCP CIBC Wood Gundy',
			cibcWorldMarkets: 'Marchés mondiaux CIBC',
			cibcWorldMarketsLower: 'Marchés mondiaux CIBC',
			clearList: 'Effacer la liste',
			closeMenu: 'Fermer le menu',
			close: 'Conclusion',
			closeWindow: 'Fermer la fenêtre',
			closeDialogueSrOnly: 'Fermer la fenêtre de dialogue',
			closeCopy: 'Fermer',
			Commentary: 'Ressources',
			commishFeeSecurityAct: 'Commish & Fees/Secur Act',
			commodities: 'Marchandises',
			commoditiesInUsDollars: 'Produits de base en dollars américains',
			commoditiesPriceChart: 'Graphique des prix de produits de base',
			commodity: 'Marchandises',
			commonStockNet: 'Common Stock, Net',
			communicationservices: 'Services de Communication',
			MSDStockSectorCommunicationServices: 'Secteur Services de Communication',
			company: 'société',
			companyOverview: "Vue d'ensemble de la société",
			Compare: 'Comparer',
			comparedToIndustry: '(en regard du sous-secteur)',
			compareLimitReached: 'Veuillez ne pas sélectionner plus de {count} FNB.',
			compareToSymbol: 'Comparer à {symbol}',
			comingSoon: 'À venir bientôt',
			conglomerates: 'Conglomérats',
			consensusEstimate: 'Estimations du consensus',
			consumercyclical: 'Biens de consommation cycliques',
			MSDStockSectorConsumerCyclical: 'Secteur Biens de consommation cycliques',
			consumercyclicals: 'Biens de consommation cycliques',
			consumerdefensive: 'Biens de consommation de nécessité',
			MSDStockSectorConsumerDefensive: 'Secteur Biens de consommation de nécessité',
			consumerDiscretionary: 'Produits non essentiels',
			consumergoods: 'Biens de consommation',
			'consumernon-cyclicals': 'Biens de consommation non cycliques',
			'consumernon-cyclical': 'Biens de consommation non cyclique',
			consumerservices: 'Services aux consommateurs',
			consumerStaples: 'Produits essentiels',
			contactInfo: 'Coordonnées',
			cost: 'Coût',
			cfra: 'CFRA',
			'construction&materials': 'Construction et matériaux',
			convertible: 'Titres convertibles',
			costOfRev: 'Cost of Revenue',
			costRevenueTotal: 'Cost of Revenue, Total',
			country: 'Pays',
			countryDescription: 'Pays dans lequel le FCB se négocie.',
			creditCardFee: 'Credit Card Fees',
			creditQuality: 'Qualité du crédit',
			creditDuration: 'Durée des titres de créance',
			creditordebit: 'Credit or Debt',
			creditQualityRating: 'Qualité du crédit',
			creditQualityRatingDescription: 'La cote de qualité du crédit sert à évaluer le risque de défaillance des placements à revenu fixe.',
			creditYears: '{years} ans',
			crosses: 'Croise',
			createNew: 'Create New',
			createNewScreen: 'Créer une nouvelle sélection',
			createNewWatchlist: 'Créer une liste de surveillance',
			currentlyAddedSymbols: 'Symboles actuellement ajoutés',
			currentRatio: 'Ratio de liquidité générale',
			'cyf/nfy': '(exercice en cours/prochain exercice)',
			capitalgoods: 'Biens d\'équipement',
			capitalLeastObligations: 'Capital Lease Obligations',
			capitalPreservation: 'Préservation du capital',
			cashDueBanks: 'Cash & Due from Banks',
			cashEquivalents: 'Cash & Equivalents',
			cashShortTermInv: 'Cash and Short Term Inv',
			commercialPaper: 'Commercial Paper',
			commonStockQCMS: 'Common Stock',
			commonStockSCMS: 'Common Stock',
			constructionProgress: 'Construction in Progress',
			convPrefStkNonRdmbl: 'Conv. Pref. Stk-Non Rdmbl.',
			cumTransAdjst: 'Cum. Trans. Adjustment',
			currentFundCurrency: 'Devise actuelle du fonds',
			currPortLTDebtCapLs: 'Curr. Port. LT Dbt/Cap Ls.',
			customScreener: 'Sélection personalisée',
			customSearch: 'Recherche personnalisée',
			cumulativePerformance: 'Rendement cumulatif',
			customerAcceptances: 'Customer Acceptances',
			customerAdvances: 'Customer Advances',
			customizeScreener: 'Personnalisation de la sélection',
			customizeScreenerResults: 'Résultats de la personnalisation de la sélection',
			customPeriodRange: 'Intervalle de la période personnalisée',
			data: 'Données',
			dataRetrieved: 'Données recueillies le',
			date: 'Date',
			dateCreated: 'Date de création',
			day: 'Cours maximum',
			dayD: 'de la ligne D % de X jours',
			dailyChart: 'Graphique quotidien',
			dayKCrosses: 'lorsque la ligne K % de X jours passe',
			days: 'jours',
			dealerTradingAcctProfit: 'Dealer Trading Acct Profit',
			dealerTradingAcctLoss: 'Dealer Trading Account Loss',
			deferredTaxes: 'Deferred Taxes',
			definitions: 'Définitions',
			definitionsSrOnly: 'Définitions dans une nouvelle fenêtre de dialogue',
			dayRange: 'Var. quotidienne',
			delete: 'Supprimer',
			deletePurchaseDetails: 'Delete Purchase Details',
			deleteSelected: 'Supprimer les éléments sélectionnés',
			deleteSelectedSrOnly: 'Supprimer les éléments sélectionnés de la liste de surveillance',
			deleteWatchlist: 'Supprimer la liste',
			deleteWatchlistSr: 'Supprimer la liste dans une nouvelle fenêtre de dialogue',
			deleteWatchlistHeader: 'Supprimer la liste de surveillance',
			delta: 'Delta',
			depletion: 'Depletion',
			deposits: 'Deposits',
			depreciation: 'Depreciation',
			depreciationAmortization: 'Depreciation/Amortization',
			depreciationDepletion: 'Depreciation/Depletion',
			depreciationExpense: 'Depreciation Expense',
			depreciationSupp: 'Depreciation, Supplemental',
			depreciationSuppl: 'Depreciation, Supplemental',
			descending: 'Descending',
			description: 'Description',
			detail: 'Détail',
			details: 'précisions sur',
			detailList: 'Detail list ',
			detailedList: 'Liste détaillée',
			detailValue: 'Valeur détaillée',
			dilutedEPSExclExtraItems: 'Diluted EPS Excl. Extra. Items',
			dilutedEPSInclExtraItems: 'Diluted EPS Incl. Extra. Items',
			dilutedNormalEPS: 'Diluted Normalized EPS',
			dilutedWeightedAveShares: 'Diluted Weighted Average Shares',
			dilutionAdj: 'Dilution Adjustment',
			discontinuedOps: 'Discontinued Operations',
			disclosures: '####',
			display: 'Afficher',
			displayBy: 'Afficher par',
			displayCurrency: 'Afficher la devise',
			distribution: 'Distribution',
			distributionChart: 'Distribution chart',
			distributionChartUnavailable: 'Les renseignements sur la distribution ne sont pas disponibles pour ce FNB.',
			noDistributionInformationMF: 'Il n’y a pas de données sur les distributions pour ce fonds.',
			distributionDetail: 'Précisions sur la distribution',
			distributionFrequency: 'Fréquence des distributions',
			distributionFrequencyDescription: 'Fréquence à laquelle un FCB verse des distributions. Les distributions peuvent être versées tous les mois, tous les trimestres, tous les semestres ou tous les ans.',
			distributionInformation: 'Information sur les distributions',
			distributionInformationAmounts: 'Montant d\'information sur les distributions',
			distributionsPerYear: 'Distributions par année',
			distributionYield: 'Rendement des distributions',
			distributionYieldDescription: 'Distributions annuelles déclarées par un FCB, divisées par le cours du FCB.',
			distributionsPerShare: 'Distribution par action',
			distributionLabel: 'Rendement',
			dividend: 'Distribution',
			dividendYield: 'Rendement en dividendes',
			dividendGrowthLabel: 'Croissance des dividendes sur 5 ans',
			dividendGrowthLabel2: 'Div croissance 5 ans',
			dividendInfo: 'Renseignements sur les dividendes',
			djiaChartHidden: 'Graphique de la moyenne Dow Jones des valeurs industrielles',
			domesticbond: 'Obligations canadiennes',
			down: 'Baisse de',
			dpsCommonStockPrimaryIssue: 'DPS - Common Stock Primary Issue',
			duplicateWatchlistNameError: 'Watch List name already in use',
			defIncTaxLTAsst: 'Def. Inc. Tax - LT Asset',
			defPolicyAcqCosts: 'Def. Policy Acq. Costs',
			deferredCharges: 'Deferred Charges',
			deferredGasCost: 'Deferred Gas Cost',
			deferredIncomeTax: 'Deferred Income Tax',
			deferredIncomeTaxLBDT: 'Deferred Income Tax',
			deferredIncomeTaxLDTC: 'Deferred Income Tax',
			deferredIncomeTaxSBDT: 'Deferred Income Tax',
			deferrentInvTaxCredit: 'Deferred Inv. Tax Credit',
			discontOpsCurrentAsst: 'Discont. Ops.-Curr. Assets',
			discontOpsLiabili: 'Discont. Ops. - Lblts',
			discontinuedOperations: 'Discontinued Operations',
			disctOpsLTAsst: 'Disct. Ops. - LT Asset',
			dividendsPayable: 'Dividends Payable',
			domicile: 'Pays de résidence',
			doNotSend: 'Ne pas envoyer',
			duringThePriorTradingSession: 'à la séance de négociation précédente.',
			earnings: 'Bénéfices',
			earningsPerShare: 'Bénéfices par action',
			economicInsights: 'Economic insights (en anglaise seulement)',
			economicInsightsDescription: 'Consulter les mises à jour de nos prévisions sur les marchés financiers canadiens et américains, ainsi que nos perspectives sur l’économie nord-américaine.',
			economics: 'Economies',
			edit: 'Modifier',
			editSearch: 'Modifier la recherche',
			editScreenerName: 'Modifier le nom de la sélection',
			editPurchaseDetails: 'Edit Purchase Details',
			editWatchlist: 'Modifier la liste de surveillance',
			editWatchlistSrOnly: 'Modifier la liste de surveillance sélectionnée',
			educationDisclaimer: "Les commentaires d'analystes, le contenu éducatif et les autres renseignements de recherche en placement disponible sur ce site web sont d'ordre général seulement, ceux-ci ne sont pas désignés comme étant des conseils de placement spécifiques et vous ne devriez pas vous y fier de cette façon. Les exigences de divulgation en vigueur au Canada ne s’appliquent pas aux rapports de recherche produits par des entités autres que Marchés mondiaux CIBC inc. ",
			earningsValue: 'Earnings Value',
			effectiveTaxRate: 'Taux d\'imposition réel',
			effectSpecialItemOnIncomeTax: 'Effect of Special Items on Income Taxes',
			electricity: 'Électricité',
			electricOps: 'Electric Operations',
			'electronic&electricalequipment': 'Matériel électronique et équipement électrique',
			emailAddress: 'Adresse de courriel',
			enterName: 'Entrer le nom de la liste',
			enterNumberToMax: 'Enter a number to a maximum of 6 digits',
			energy: 'Énergie',
			MSDStockSectorEnergy: 'Secteur Énergie',
			enterNameForWatchlist: 'Please enter a name for this watch list.',
			enterSpecificValues: 'Entrer des valeurs spécifiques',
			equalToNLessThanM: '= à {min} à < {max}',
			etfs: 'FNB',
			etfMenu: 'FNB menu',
			etfPortfolioDisclaimer: 'ETF Portfolio Disclaimer',
			etfHoldingSearch: 'Recherche de titres détenus dans les fonds',
			featuredScreens: 'Sélections vedettes',
			featuredScreens2: 'Présentation des écrans',
			fundClassesDisclaimer: "Un fonds commun de placement peut être offert en plusieurs catégories. Les différences de frais et de charges entre les catégories signifient que chaque catégorie a une valeur liquidative par part différente. Par conséquent, le rendement peut varier d'une catégorie à l'autre.",
			fundClassification: 'Classification de fonds',
			fundPromoter: 'Promoteur de fonds',
			fundCommissionsDisclaimer: 'Les placements dans les fonds communs de placement peuvent faire l’objet de commissions, de commissions de suivi, de frais de gestion et d’autres charges. Veuillez lire le prospectus simplifié avant d’investir. Chaque taux de rendement indiqué est un taux de rendement total composé annuel historique et tient compte des fluctuations de la valeur des parts et du réinvestissement de toutes les distributions, mais qui ne tient pas compte des commissions d’achat et de rachat, des frais de placement ni des frais optionnels ou de l’impôt sur le revenu payable par un porteur qui auraient eu pour effet de réduire le rendement. Pour les fonds marché monétaire, l’information sur le rendement passé qui est fournie suppose le réinvestissement des distributions uniquement, mais ne tient pas compte des commissions d’achat et de rachat, des frais de placement ni des frais optionnels ou de l’impôt sur le revenu payable par un porteur qui auraient pour effet de réduire le rendement. Les titres de fonds communs de placement ne sont pas couverts par la Société d’assurance-dépôts du Canada ni par un autre organisme public d’assurance-dépôts. Rien ne garantit que les fonds pourront maintenir une valeur liquidative fixe par part ou que le plein montant de votre placement pourra vous être remboursé. Les fonds communs de placement ne sont pas garantis, leur valeur varie fréquemment et leur rendement passé n’est pas indicatif de leur rendement futur.',
			ema15day: 'MME de 15 jours',
			ema50day: 'MME de 50 jours',
			ema100day: 'MME de 100 jours',
			ema200day: 'MME de 200 jours',
			endDay: 'Jour de fin',
			endMonth: 'Mois de fin',
			endYear: 'Année de fin',
			enterPurchaseDetails: 'Entrer les détails d’achat',
			enterPurchaseDetailsSr: 'Entrer les détails d’achat de {symbol} dans une nouvelle fenêtre de dialogue',
			epsGrowth: 'Croissance du RPA',
			epsGrowthCFY: 'Croissance EPS CFY',
			epsGrowthNFY: 'Croissance EPS NSY',
			equities: 'Actions',
			equity: 'Actions',
			equityFund: 'Mesures',
			equityInAffiliates: 'Equity In Affiliates',
			equityinvestmentinstruments: 'Instruments de placement en actions',
			equityNetEarnLoss: 'Equity in Net Earnings/Loss',
			equityStylebox: 'Grille de style sur les actions',
			equityStyleboxDescription: 'Outil exclusif de Morningstar qui fournit une représentation graphique du style de gestion d\'un FCB. La Matrice du style des actions Morningstar est une grille à neuf cases qui classe les actions par capitalisation boursière (axe vertical) et par style de gestion (axe horizontal).',
			errorDuringDownload: 'Error during download',
			ESPODebtGuarantee: 'ESOP Debt Guarantee',
			estimates: 'estimations',
			estimatesDisclaimer: 'CFY = Current Fiscal Year, NFY = Next Fiscal Year',
			estimatesStockScreener: 'Prévisions',
			etfCentre: 'Centre des FNB',
			etfsAtGlance: 'Les FNB en bref',
			etfsCaption: '{count} FNB',
			etfsOverviewTodaysMoversTooltip: "Les FNB à effet de levier utilisent des produits dérivés financiers et des titres de créance pour augmenter les rendements quotidiens d'un indice sous-jacent. Nous recommandons de faire preuve de prudence au moment de négocier ces FNB, qui sont conçus pour des investisseurs aguerris possédant une compréhension approfondie des risques qui y sont associés.",
			exactPhrase: 'Expression exacte',
			examplePhone: 'Exemple: 5555551212@domain.com',
			exciseTaxPayment: 'Excise Taxes Payments',
			excisTaxReceipts: 'Excise Taxes Receipts',
			exceededExpectations: 'Au-delà des attentes',
			eps: 'BPA',
			exDividendDate: 'Date ex-dividende',
			exDivDate: 'date ex-dividende',
			expirationDate: 'Date d’expiration',
			expiration: 'Expiration',
			expired: 'Expiré',
			expiry: 'Expiration',
			expiryLabel: 'date d\'exp',
			extraItem: 'Extraordinary Item',
			extraordinaryItemun: 'Extraordinary Item',
			explorationProduction: 'Exploration & Production',
			family: 'Famille',
			fax: 'Télécopieur : ',
			fedFundResale: 'Fed. Fund/Resale',
			fedFundSoldSecSoldUnderRepurch: 'Fed Funds Sold/Secs Sold under Repurch Agrmnt',
			fedFundsREPO: 'Fed. Funds/REPOs',
			feeCommishFromOp: 'Fees & Commish from Operat',
			feeOtherCustServ: 'Fees for Other Cust Serv',
			FHLB: 'FHLB',
			financeCashFlowItem: 'Financing Cash Flow Items',
			foodproducers: 'Producteurs de produits alimentaires',
			foreclosedRealEstate: 'Foreclosed Real Estate',
			foreignbond: 'Obligations étrangères',
			forexAdjustment: 'Foreign Currency Adjustment',
			forexAdjustmentNonOp: 'Foreign Currency Adjustment',
			forexEffects: 'Foreign Exchange Effects',
			ForexGains: 'Foreign Currency Gains',
			forexLoss: 'Foreign Currency Losses',
			format: 'Format',
			financials: 'Finances',
			financialservices: 'Services financiers',
			MSDStockSectorFinancialServices: 'Secteur Services financiers',
			financialStrength: 'Solidité financière',
			financialsType: 'Type de données financières',
			finishedAddingCriteria: 'Fin de l’ajout de critères',
			finishedAddingCriteriaError: 'Sélectionnez les critères pour continuer.',
			fundCentre: 'Centre des fonds',
			fundCodes: 'Code du fonds',
			fundCategory: 'Catégorie de fonds',
			fundCategoryDescription: 'Classement détaillé de FCB selon les normes du secteur, déterminé en fonction du style, de la stratégie, de la catégorie d\'actif ou d\'autres critères.',
			fundFamily: 'Famille de fonds',
			fundFamilyDescription: 'Groupe de FCB offerts par une société ou un promoteur de fonds.',
			fundName: 'Nom du fonds',
			fundNameLower: 'Nom du fonds',
			fundType: 'Type',
			fundTypeHeader: 'Type de fonds',
			fundDetails: 'Renseignements sur {symbol}',
			fundDetail: 'Renseignements sur le Fonds',
			fundamentals: 'Éléments fondamentaux',
			fundamentalsStockScreener: 'Données fondamentales',
			fundOverview: 'Aperçu du fond',
			fundValue: 'Valeur du Fonds',
			fundMovement: 'Fluctuation du fonds',
			fiftyTwoWeek: '52 Semaine',
			filterBy: 'Filtrer par',
			filterByClassification: 'Filtrer par classification',
			filterByCountry: 'Filtrer par pays',
			filterByDate: 'Filter by date',
			filterByEtfType: 'Filtrer par type de FNB ',
			filterByFundFamily: 'Filter by fund family',
			filterByFundPromoter: 'Filtrer par promoteur de fonds',
			filterByMovers: 'Filtrer par facteurs déterminants',
			filterByPerformance: 'Filtrer par rendement',
			filterByPeriod: 'Filtrer par période',
			filterByPublication: 'Filtrer par publication',
			filterByScreenType: 'Filtrer par type d’écran',
			filterBySector: 'Filtrer par secteur',
			filterByVolume: 'Filtrer par volume',
			filterMoversBy: 'Filtrer les facteurs déterminants par',
			filterDataTypeBy: 'Filtrer les données par',
			financialsFor: 'Données financières pour {symbol}',
			financialsPeriod: 'Période financières',
			fiveYrAvgPreTaxMargin: 'Marge avant impôts moyenne sur 5 ans',
			fiveYrAvgOperatingMargin: 'Marge d\'exploitation moyenne sur 5 ans',
			fiveYrNetProfitMargin: 'Marge bénéficiaire nette moyenne sur 5 ans',
			fiveYrAvgNetProfitMarginGrowth: 'Croissance de la marge bénéficiaire nette moyenne sur 5 ans',
			fiveYrAvgEffectiveTaxRate: 'Taux d\'imposition réel moyen sur 5 ans',
			fiveYrYieldAvg: 'Moyenne du rendement sur 5 ans',
			fixedincome: 'Titres à revenu fixe',
			fixedIncome: 'Titres à revenu fixe',
			fixedIncomeStylebox: 'Grille de style sur les revenus fixes',
			fixedIncomeStyleboxDescription: 'Mesure exclusive de Morningstar pour les fonds de titres à revenu fixe, reposant sur la sensibilité aux taux d\'intérêt et la qualité du crédit. La Matrice du style des titres à revenu fixe Morningstar est une grille à neuf cases qui mesure la qualité du crédit (axe vertical) et la sensibilité aux taux d\'intérêt (axe horizontal).',
			fixedIncomeStyleboxCol1: 'H',
			fixedIncomeStyleboxCol2: 'M',
			fixedIncomeStyleboxCol3: 'F',
			fixedIncomeStyleboxRow1: 'F',
			fixedIncomeStyleboxRow2: 'M',
			fixedIncomeStyleboxRow3: 'E',
			financialsTableDisclaimer: 'En millions de {currency} (excepté pour les postes par actions)',
			fiveYrYieldGrowthRate: 'Taux de croissance sur 5 ans',
			fiveYearBond: 'Obligations à 5 ans',
			fixedlinetelecommunications: 'Télécommunications à lignes fixes',
			float: 'Flottant',
			'food&drugretailers': 'Détaillants de produits alimentaires et de médicaments',
			'forestry&paper': 'Foresterie et papier',
			fsiD: 'Deficient: Issuer Failed to Meet NASDAQ Continued Listing Requirements',
			fsiE: 'Delinquent: Issuer Missed Regulatory Filing Deadline',
			fsiG: 'Deficient and Bankrupt',
			fsiH: 'Deficient and Delinquent',
			fsiJ: 'Delinquent and Bankrupt',
			fsiK: 'Deficient, Delinquent, and Bankrupt',
			fsiN: 'Normal (Default)" Issue is not currently deficient, delinquent, or bankrupt.',
			fsiQ: 'Bankrupt: Issuer Has Filed for Bankruptcy',
			fuelExpense: 'Fuel Expense',
			fuelPurchasedResale: 'Fuel Purchased for Resale',
			funds: 'Fonds',
			fundsFromOpREIT: 'Funds From Operats - REIT',
			fundSnapshot: '{name} aperçu',
			fundStructure: 'Structure du fonds',
			fundStructureDescription: `Un FCB peut être classique, à effet de levier ou inversé.
			\nLes FCB à effet de levier utilisent des produits dérivés financiers pour augmenter les rendements quotidiens d'un indice ou d'un titre sous-jacent. Nous recommandons de faire preuve de prudence au moment de négocier ces FCB, qui sont conçus pour des investisseurs aguerris possédant une compréhension approfondie des risques qui y sont associés.
			\nLes FCB inversés sont conçus pour profiter des fluctuations des cours allant dans le sens inverse de l'indice ou du titre sous-jacent.`,
			fundObjective: 'Objectif du fonds',
			fedFundsPurch: 'Fed Funds Purch.',
			FHLBAdvances: 'FHLB Advances',
			fhlbStock: 'FHLB Stock',
			fuelInvent: 'Fuel - Inventory',
			getAnalystRatings: 'Consulter les notes des analystes',
			generalindustrials: 'Produits industriels généraux',
			generalretailers: 'Détaillants généraux',
			globalExposure: 'Placements mondiaux',
			globalExposureDisclaimer: 'La répartition par pays est calculée en utilisant seulement les position longue d\'actions et d\'obligations du portfolio selon leur pays d\'émission.',
			globalExposureError: 'Les placements mondiaux ne sont pas disponibles',
			globalExposureErrorPortfolio: 'Les placements mondiaux ne sont pas disponibles pour ce FNB',
			greatestPremium: 'Prime la plus élevée',
			greatestDiscount: 'Escompte le plus élevé',
			gamma: 'Gamma',
			gain: 'Gain',
			gainSaleofAssets: 'Gain (Loss) on Sale of Assets',
			gainSr: 'Gain de',
			gainTotalSr: 'Gain de  {gain}',
			'gas,water&multiutilities': 'Gaz, eau et multi-services publics',
			gasOps: 'Gas Operations',
			genPartnerDistro: 'Gen Partner\'s Distributions',
			getLatestCommentary: 'Get the latest ETF commentary from Morningstar analysts. Learn more about ETFs with Morningstar’s educational content. ',
			gold: 'Or',
			goldSr: 'Graphique de l’or',
			grossDividendCommStock: 'Gross Dividend - Common Stock',
			grossPremWritten: 'Gross Premiums Written',
			grossRev: 'Gross Revenue',
			groupBy: 'Group By',
			growthNfy: 'Growth NFY',
			grossMargin: 'Marge brute',
			growth5Year: 'Growth 5 Year',
			growth10K: 'Croissance de 10 000 $',
			growth10KText1: 'Un placement de 10 000 $ effectué le ',
			growth10KText2: ' vaudrait maintenant ',
			gasStorageInvent: 'Gas in Storage - Inventory',
			generalPartner: 'General Partner',
			goodwillGross: 'Goodwill, Gross',
			goodwillNet: 'Goodwill, Net',
			headlinesOnly: 'Manchettes seulement',
			headlinesAndDocumentBody: 'Manchettes et articles',
			'healthcareequipment&services': 'Matériel et services de santé',
			high: 'Haut',
			highPriceContext: 'Élevée',
			highLow: 'maximum / minimum',
			highLowLabel: '/ minimum quotidien',
			healthCare: 'Santé',
			healthcare: 'Soins de santé',
			MSDStockSectorHealthcare: 'Secteur Santé',
			holdings: 'Titres',
			etfHoldingsContent: 'Repérer les FNB les plus exposés aux actions sélectionnées',
			etfHoldingDisclaimer: 'Les renseignements sont fondés sur la plus récente information fournie par le fonds à Morningstar.',
			holdingsMentioned: 'Placement mentionné',
			'householdgoods&homeconstruction': 'Articles ménagers et construction domiciliaire',
			hyphen: 'à',
			highEstimate: 'Estimations hautes',
			inANewWindow: ' PDF dans une nouvelle fenêtre',
			hide: 'Masquer',
			hideCallsSrOnly: 'Masquer les options d’achat pour {symbol}',
			hidePutsSrOnly: 'Masquer les options de vente pour  {symbol}',
			hideCriteriaMatches: 'Masquer les résultats correspondant aux critères',
			impAssetsHeldSale: 'Impair-Assets Held for Sale',
			impAssetsHeldUse: 'Impair-Assets Held for Use',
			inactive: 'Inactif',
			inANewDialogue: '{name} s’ouvre dans une nouvelle boîte de dialogue',
			inception: 'Création',
			inceptionDate: 'Date de création',
			inceptionDateDescription: 'Date à laquelle le FCB commence à offrir des actions.',
			incomeStatement: 'État des résultats',
			includeLeveraged: 'Inclure à effet de levier',
			incomeAvailCommonExclExtraItems: 'Income Available to Common Excl. Extra. Items',
			incomeAvailCommonInclExtraItems: 'Income Available to Common Incl. Extra. Items',
			incomeHandling: 'Traitement du revenu',
			incTaxExImpactofSpItem: 'Inc Tax Ex Impact of Sp Items',
			indicationRatesNotActualRates: 'Taux à titre indicatif, et non taux réels',
			industrialengineering: 'Génie industriel',
			industrials: 'Industriels',
			MSDStockSectorIndustrials: 'Secteur Industriels',
			'industrialmetals&mining': 'Industrie, métaux et exploitation minière ',
			industrialtransportation: 'Transport industriel',
			industry: 'Sous-secteur',
			industryDataNotAvailable: 'Données sectorielles non disponible',
			informationTechnology: 'Information Technologie',
			initialCharge: 'Frais initiaux',
			insights: 'Observations',
			insurCommishFeePrem: 'Insur Commish Fees& Premium',
			int: 'Int',
			intangibleNet: 'Intangible, Net',
			interAdjPrimaryEPS: 'Inter Adjust - Primary EPS',
			interCapitalOp: 'Inter Capitalzd, Operating',
			interCapNonOp: 'Inter Capital, Non-Operat',
			interDivsInvestSecurs: 'Inter & Divs/ Invest Securs',
			interest: 'Interest',
			interesonDeposits: 'Interest on Deposits',
			interestCapSuppl: 'Interest Capitalized, Suppl',
			interestCoverage: 'Ratio de couverture des intérêts',
			interestExpenseSuppl: 'Interest Expense, Suppl',
			interestFeeLoans: 'Interest & Fees on Loans',
			interestIncomeBank: 'Interest Income, Bank',
			interestIncomeNonBank: 'Interest Income, Non-Bank',
			interestIncomeNetNonOp: 'Interest Income (Exp), Net Non-Operating',
			interestIncomeNonOp: 'Interest Income, Non-Operating',
			interestIncomeOp: 'Interest Income, Operating',
			interestInvestIncomeNonOp: 'Interest/Investment Income, Non-Operating',
			interestOnDeposits: 'Interest on Deposit',
			interExpenseNetNonOp: 'Inter Expse,Net Non-Operat',
			interExpenseNetOp: 'Inter Expense, Net Operatng',
			interExpenseNonOp: 'Inter Expse, Non-Operating',
			interExpenseOp: 'Inter Expense, Operating',
			interInvestOp: 'Inter/Invest Inc, Operating',
			internationalEquity: 'Actions internationales',
			internationalEquityAbbrv: 'Actions internationales',
			interOnOtherBorrow: 'Inter on Other Borrowings',
			inTheMoney: 'Dans le cours',
			indexName: 'Nom de l’indice',
			indexMovement: 'Fluctuation des indices',
			indicatorInput: 'Saisie de l’indicateur',
			impliedVolatility: 'Volatilité implicite',
			invalidCharacterError: 'Les renseignements que vous avez entrés ne peuvent être traités, car ils contiennent des symboles ou des mots non acceptables. Veuillez entrez de nouveau vos renseign',
			inventories: 'Inventories',
			inverseDisclaimer: 'Les FCB inversés sont conçus pour profiter des fluctuations des cours allant dans le sens inverse de l\'indice ou du titre sous-jacent.',
			inverseEtf: 'FNB inversé',
			inverseLeveragedETFs: 'FNB à effet de levier et inversés',
			inverseLeveragedETFs_desc: 'FNB qui utilisent des produits dérivés financiers pour obtenir un rendement inverse à celui de l\'indice/titre sous-jacent (FNB inversé) ou pour amplifier les rendements d\'un indice/titre sous-jacent (FNB à effet de levier).',
			investIncomeOp: 'Invest Income, Operating',
			investIncomeNonOp: 'Investment Income, Non-Operating',
			investmentType: 'Type de placement',
			investmentGoal: 'Investment Goal: ',
			investmentNet: 'Investment, Net',
			investmentValuation: 'Valeur des placements',
			investmtSecurityLoss: 'Investmt Securities Losses',
			investSecGains: 'Invest Securities Gains',
			issRetirmntDebtNet: 'Iss (Retirmnt) of Debt, Net',
			issRetirmntStockNet: 'Iss (Retirmnt) of Stock,Net',
			incomeTaxPayable: 'Income Taxes Payable',
			insruanceReserve: 'Insurance Reserves',
			insuranceReceivables: 'Insurance Receivables',
			intangibleGross: 'Intangibles, Gross',
			interestBearingDept: 'Interest Bearing Deposits',
			interestEarnDeposits: 'Interest-Earning Deposits',
			interestRateSensitivity: 'Sensibilité aux taux d’intérêt ',
			interestRcvbl: 'Interest Receivable',
			inventFinishedGoods: 'Invent. - Finished Goods',
			inventOther: 'Inventories - Other',
			inventRawMaterial: 'Invent. - Raw Materials',
			inventWIP: 'Invent. - Work In Progress',
			keyCanadianRates: 'Taux directeurs au Canada',
			keyUsRates: 'Taux directeurs aux États-Unis',
			keyword: 'Mot-clé',
			keywords: 'Mot-clés',
			keywordResultsBy: 'Résultats des mots-clés :',
			laborRelatedExpense: 'Labor & Related Expenses',
			lastTrade: 'Dernière opération le',
			lastTradeHeader: 'Dernier cours',
			lastChange: 'dernier changement',
			lastUpdatedToday: 'Dernière mise à jour aujourd’hui',
			lastUpdated: 'Dernière mise à jour le',
			last: 'Dernier',
			lastLabel: 'Dernier',
			lastPrice: 'Dernier cours',
			lastClose: 'dernière clôture',
			lastBidLots: 'Last Bid / Lots',
			lastAskLots: 'Last Ask / Lots',
			lastWeek: 'La dernière semaine',
			last2Weeks: 'Dernières 2 semaines',
			last4Weeks: 'Dernières 4 semaines',
			latestEconomicReports: 'Derniers rapports du Service des études économiques CIBC',
			latestEquityReports: 'Derniers rapports sur les actions',
			latestEtfCommentary: 'Observations sur les FNB',
			latestPdfReports: 'Plus récents rapports en format PDF',
			latestDividend: 'Dividende le plus récent',
			latestPaymentDate: 'Date de dernier versement',
			latestExDistributionDate: 'Dernière date ex-distribution',
			latestDistributionAmount: 'Montant de la dernière distribution',
			legalStructure: 'Structure juridique',
			leisuregoods: 'Biens de loisir',
			less: 'Moins',
			loadLabelFr: 'Frais d’acquisition',
			load: 'ou de rachat',
			leveragedEtf: 'FNB à effet de levier',
			leveragedDisclaimer: 'Les FNB à effet de levier utilisent des produits dérivés financiers et des titres de créance pour augmenter les rendements quotidiens d\'un indice sous-jacent. Nous recommandons de faire preuve de prudence au moment de négocier ces FNB, qui sont conçus pour des investisseurs aguerris possédant une compréhension approfondie des risques qui y sont associés. Pour en savoir plus sur les FNB à effet de levier, lisez Investir dans les FNB à effet de levier sous l\'onglet Ressources. Remarque : Ce ne sont pas tous les types de FNB qui comprennent des fonds à effet de levier.',
			lifeinsurance: 'Assurance vie',
			listBy: 'Afficher la liste selon',
			litigation: 'Litigation',
			litigationExpense: 'Litigation Expense',
			loanLossProvision: 'Loan Loss Provision',
			loanOriginationInvest: 'Loans Origination - Invest',
			loans: 'Loans',
			longDurationHighQuality: 'Longue durée/haute qualité',
			longDurationMediumQuality: 'Longue durée/qualité moyenne',
			longDurationLowQuality: 'Longue durée/qualité basse',
			longTermDebtIssued: 'Long Term Debt Issued',
			longTermDebtNet: 'Long Term Debt, Net',
			longTermDebtReduce: 'Long Term Debt Reduction',
			loss: 'perte',
			lossBeniesAdjust: 'Losses, Benies & Adjusts',
			lossBeniesAdjustTotal: 'Losses, Benies & Adjmts, Total',
			lossSaleAsts: 'Loss(Gain)/Sale Asts (Oper)',
			lossSr: 'perte de',
			lossTotalSr: 'Perte de {loss}',
			lots: 'Lots',
			lowHigh: 'Creux / sommet',
			lowAndHigh: 'Creux et sommet',
			low: 'Bas',
			lowEstimate: 'Estimations basses',
			lowPriceContext: 'Faible',
			longStrategy: 'Stratégie acheteur',
			longStrategyChart: 'Graphique de la stratégie acheteur',
			longPosition: '% des positions acheteur',
			largeCapBlend: 'Mixte à forte capitalisation',
			largeCapGrowth: 'Croissance à forte capitalisation',
			largeCapValue: 'Valeur à forte capitalisation',
			longTermDebtEquity: 'Ratio capitaux empruntés/capitaux propres',
			lipperLeaders: 'Valeurs lipper',
			landImprovements: 'Land/Improvements',
			leases: 'Leases',
			lifoReserve: 'LIFO Reserve',
			limitedPartner: 'Limited Partner',
			loanLossAllowances: 'Loan Loss Allowances',
			loansHeldSale: 'Loans Held for Sale',
			longTermDebt: 'Long Term Debt',
			longTermInvestment: 'Long Term Investments',
			LTInvntOther: 'LT Investments - Other',
			LTInvtAffiliateComp: 'LT Invt. - Affiliate Comp.',
			materials: 'Matériel',
			mixedEquity: 'Actions mixtes',
			midCapBlend: 'Mixte à moyenne capitalisation',
			midCapGrowth: 'Croissance à moyenne capitalisation',
			midCapValue: 'Valeur à moyenne capitalisation',
			makeDefaultWatchlist: 'Définir comme liste par défaut',
			managementEffectiveness: 'Efficacité de la gestion',
			managerDeclared: 'Déclaration du gestionnaire',
			manager: 'Gestionnaire',
			market: 'marchande',
			markets: 'Marchés',
			marketValue: 'Valeur marchande',
			marketCentreDisclaimer: 'Certains renseignements sur notre site (p. ex., Cotes et recherches) sont fournis par nos fournisseurs et ne sont pas disponibles en français.',
			marketsOverview: 'Aperçu des marchés',
			marketOverview: 'Aperçu des marchés',
			'Markets at a glance': 'Coup d’œil sur les marchés',
			'Market movers': 'Reflets du jour',
			marketLabel: 'Capitalisation',
			marketClose: 'À la clôture du marché, le',
			marketCentre: 'Centre des marchés',
			marketMovers: 'Reflets du jour',
			marketCap: 'Cap. boursière',
			marketCapShort: 'Cap. boursière',
			marketCapShort2: 'Capitalisation boursière',
			marketMoversHeading: '{type} for the {set}',
			marketDataDelayedBy15: 'Données sur le marché décalées d’au moins 15 minutes',
			marketDataIsRealTime: 'Les données du marché s’affichent en temps réel, sauf pour les titres hors cote',
			marketsOverviewDelayedBy15: 'Retard d’au moins 15 minutes',
			matches: 'Correspondances',
			maxHoldingsReached: 'Vous avez atteint la limite du nombre de symboles pour cette liste de surveillance.',
			maxQLHoldingsReached: 'Cette liste a atteint sa limite de symboles.',
			media: 'Médias',
			MER: 'RFG',
			MERDescription: 'Le ratio des frais de gestion (RFG) désigne le ratio des frais engagés pour exploiter un fonds, divisé par l\'actif annuel moyen du fonds.',
			mediumDurationHighQuality: 'Durée moyenne/haute qualité',
			mediumDurationMediumQuality: 'Durée moyenne/qualité moyenne',
			mediumDurationLowQuality: 'Durée moyenne/qualité basse',
			million: 'million(s)',
			minimumAdditionalInvestment: 'Minimum additionnel placement',
			minimumInitialInvestment: 'Minimum initial placement',
			minimumInvestmentsTooltip: 'Les placements minimaux CIBC peuvent être plus élevés',
			mining: 'Exploitation minière',
			minorityInterest: 'Minority Interest',
			minPensionLiabAdj: 'Min Pension Liabil Adjust',
			minPensionLiabGain: 'Min Pension Liability Gain',
			minPensionLiabilLoss: 'Min Pension Liability Loss',
			miscEarnAdjs: 'Misc Earnings Adjustment',
			missedExpectations: 'En deçà des attentes',
			mobileNumber: 'Numéro de téléphone mobile',
			mobiletelecommunications: 'Télécommunications mobiles',
			month: 'Mois',
			monthly: 'Chaque mois',
			monthlyOption: 'Mensuelle',
			monthlyFxOutlook: 'Monthly FX outlook (en anglaise seulement)',
			monthlyFxOutlookDescription: 'Publication mensuelle qui présente les perspectives à court et à long terme de plusieurs devises importantes. Les prochains événements susceptibles d’influer sur les taux de change y sont aussi signalés.',
			monthlyWorldMarketsReport: 'Perspectives des marchés mondiaux (en anglaise seulement)',
			monthlyWorldMarketsReportDescription: 'Bulletin mensuel qui présente les idées en vigueur dans le domaine des placements pour les actions canadiennes et américaines et donne des suggestions pour les fonds communs de placement.',
			moreNews: 'Plus de nouvelles',
			morningstarAnalyst: 'Morningstar Analyst',
			morningstarEquityStylebox: 'Matrice de style des actions Morningstar',
			morningstarEquityStyleboxSr: 'Sélectionnez les critères relatifs aux actions de Morningstar dans les matrices de style correspondantes.',
			morningstarEquityStyleboxCol1: 'V',
			morningstarEquityStyleboxCol2: 'M',
			morningstarEquityStyleboxCol3: 'C',
			morningstarEquityStyleboxRow1: 'F',
			morningstarEquityStyleboxRow2: 'M',
			morningstarEquityStyleboxRow3: 'P',
			morningstarFixedIncomeStylebox: 'Matrice de style des titres à revenu fixe Morningstar',
			morningstarFixedIncomeStyleboxSr: 'Sélectionnez les critères relatifs aux titres à revenu fixe Morningstar dans les matrices de style correspondantes.',
			morningstarOverallRisk: 'Risque global Morningstar',
			morningstarOverallReturn: 'Rendement global Morningstar',
			morningstar3YearRisk: 'Risque Morningstar sur 3 ans ',
			morningstar3YearReturn: 'Rendement Morningstar sur 3 ans',
			morningstar3YearRating: 'Cote Morningstar sur 3 ans',
			morningstar5YearRisk: 'Risque Morningstar sur 5 ans ',
			morningstar5YearReturn: 'Rendement Morningstar sur 5 ans',
			morningstar5YearRating: 'Cote Morningstar sur 5 ans',
			morningstar10YearRisk: 'Risque Morningstar sur 10 ans ',
			morningstar10YearReturn: 'Rendement Morningstar sur 10 ans',
			morningstar10YearRating: 'Cote Morningstar sur 10 ans',
			morningstarQuant: 'Morningstar Quant',
			moreMarketMovers: 'Plus de facteurs déterminants des marchés',
			moreReports: 'Plus de rapports',
			moversMarketFilter: 'Filtres des facteurs déterminants des marchés',
			more: 'Plus',
			morningNewsCall: 'Bulletin Morning News Call du Canada',
			morningNewsCallWC: 'Bulletin morning news call du Canada',
			usMorningNewsCall: 'Bulletin Morning News Call des États-Unis',
			usMorningNewsCallWC: 'Bulletin morning news call des États-Unis',
			insightDescription: 'Tenez-vous au courant des dernières nouvelles et perspectives de Reuters et de Marchés des capitaux CIBC.',
			morningstarReturn: 'Rendement de Morningstar',
			morningstarOverallRating: 'Cote Morningstar Globale',
			morningstarReturnRating: 'Cote de rendement de Morningstar',
			morningstarRisk: 'Cote de risque de Morningstar',
			morningstarStylebox: 'Matrice de style Morningstar',
			morningstarBenchmark: 'Principal indice de référence de Morningstar',
			morningstarStylbox: 'Matrice de style Morningstar',
			morningstarRating: 'Cote Morningstar',
			morningstarRatingButtonSr: 'Aggrandir pour afficher les cotes Morningstar par année',
			morningstarRatings: 'Classement Morningstar',
			morningstarRiskRating: 'Risque de Morningstar',
			morningstarReports: 'Rapport de Morningstar',
			morningstarAnalystCommentary: 'Commentaires des analystes de Morningstar',
			morningstarAnalystReport: 'Rapport de l\'analyste Morningstar',
			morningstarTooltip: 'Le système de notation exclusif de Morningstar classifie les FCB en fonction de leur rendement ajusté au risque. La cote globale correspond à la moyenne pondérée des cotes du FCB sur trois, cinq et dix ans. Les FCB sont notés au moyen d\'un système d\'étoiles où un classement de 5 étoiles correspond à la cote maximale et un classement de 1 étoile, à la cote la plus faible.',
			morningstarStockTooltip: 'Le système de notation de Morningstar relatif aux actions repose sur la comparaison du cours actuel du marché d’un titre avec l’estimation de Morningtar de sa juste valeur. Plus le cours du marché est inférieur à sa juste valeur, plus la cote est élevée. Les actions qui se négocient à un cours largement inférieur à leur juste valeur estimative recevront des cotes plus élevées (4 ou 5 étoiles) et les actions qui se négocient à un prix largement supérieur à leur juste valeur estimative recevront des cotes moins élevées (1 ou 2 étoiles).',
			morningstarRiskTooltip: 'Évaluation par Morningstar de la variation du rendement mensuel du FCB par rapport aux fonds similaires. La cote de risque globale correspond à la moyenne pondérée des cotes du FCB sur trois, cinq et dix ans.',
			morningstarDisclaimerETF: "Données fournies par Morningstar. © Morningstar Research Inc. Tous droits réservés. Les renseignements contenus dans les présentes 1) sont la propriété de Morningstar ou de ses fournisseurs de contenu, 2) ne peuvent être ni copiés ni distribués et 3) leur exactitude, leur exhaustivité et leur fiabilité ne peuvent être garanties. Morningstar et ses fournisseurs de contenu ne peuvent être tenus responsables de toute perte ou de tout préjudice résultant de l'utilisation de ces renseignements. Les rendements passés ne sont pas garants des rendements futurs.",
			mostActive: 'FNB les plus actifs',
			mostRecentQtr: 'Trimestre le plus récent (TLPR)',
			movingAverageCrossover: 'Croisements des moyennes mobiles',
			movingAvgXOVer: 'Croisement MM',
			movingAverageCrossoverDescription: `On est en présence d'un croisement haussier des moyennes mobiles lorsqu'une moyenne mobile sur une courte période clôture au-dessus d'une moyenne mobile sur une plus longue période (ex. une moyenne mobile simple sur 15 jours clôture au-dessus d'une moyenne mobile simple sur 50 jours).
			\nOn est en présence d'un croisement baissier des moyennes mobiles lorsqu'une moyenne mobile sur une courte période clôture au-dessous d'une moyenne mobile sur une plus longue période (ex. une moyenne mobile simple sur 15 jours clôture au-dessous d'une moyenne mobile simple sur 50 jours). `,
			mrq: 'TLPR',
			moneyMarket: 'Marché monétaire',
			mrq1YrAgo: 'TLPR avec TLPR voilà 1 an',
			municipalbond: 'Obligations municipales ',
			mutualFundsLower: 'Fonds communs de placement',
			mutualFunds: 'Fonds communs de placement',
			refinitivLipperDisclaimerTitle: 'Refinitiv Valeurs Lipper',
			refinitivLipperDisclaimerText: 'La cote du rendement global d\'un fonds traduit son rendement global historique établi en fonction de fonds similaires. La cote de rendement constant d\'un fonds traduit son rendement historique selon le risque en fonction de fonds similaires. Pour la protection du capital, les cotes Lipper sont relatives et non absolues. La cote Lipper Leader ne tient pas compte des effets des frais de vente. Les cotes reposent sur une moyenne à pondération uniforme du rang-centile de chaque mesure sur des périodes de trois, cinq et dix ans (s\'il y a lieu).',
			RUTRBCIndustry: 'Industrie',
			ruMktCapUS: 'Capitalisation boursière (M$)',
			ruPriceLast: 'Cours ($)',
			ruPriceChange: 'Variation du cours (%)',
			ruVol10dAvg: 'Volume (M)',
			mutualFundsLabel: 'fonds communs de placement',
			mutualFundCaption: '{count} fonds communs de placement',
			myAlerts: 'Mes alertes',
			myWatchLists: 'Mes listes de surveillance',
			myWatchListsNewDialog: 'Mes listes de surveillance dans une nouvelle fenêtre de dialogue',
			myQuoteLists: 'Mes listes de cotes',
			myCriteria: 'Mes critères',
			mySearches: 'Mes recherches',
			mySearchesPlaceholder: 'La section de recherche personnalisée liée aux rapports d’analystes n’est pas tout à fait terminée, mais elle le sera bientôt. Notre nouveau format amélioré vous permettra de trouver tous les renseignements de recherche personnalisée que vous avez enregistrés.',
			machineryEquip: 'Machinery/Equipment',
			maCrossover15_50: 'Croisement MM (MMS 15 jours/MMS 50 jours)',
			minPensionLiabiliAdj: 'Min. Pension Lblty Adj.',
			nStars: '{stars} stars',
			NA: 'N\'est pas applicable',
			name: 'Nom',
			nav: 'VAN',
			NAVShare: 'Valeur liquidative/action',
			nearTheMoney: 'Près du cours',
			nasdaqChartHidden: 'Graphique de l’indice NASDAQ',
			netAssetValue: 'Valeur liquidative',
			netCashBeginBalRsrvFutUse: 'NetCash-BeginBal/RsvdforFutUse',
			netCashEndBalRsrvFutUse: 'NetCash-EndBal/RsrvforFutUse',
			netChangeCash: 'Net Change in Cash',
			netIncome: 'Net Income',
			netIncomeAfterTax: 'Net Income After Taxes',
			netIncomeBeforeExtraItems: 'Net Income Before Extra. Items',
			netIncomeBeforeTax: 'Net Income Before Taxes',
			netInterestInAfterLoanLoss: 'NetInterIn after Loan Loss Provision',
			netInterestIncome: 'Net Interest Income',
			netInvestmentIncome: 'Net Investment Income',
			netPremEarned: 'Net Premiums Earned',
			netSales: 'Net Sales',
			nextArticles: 'Mettre à jour les résultats précédents avec la page suivante',
			newWatchlist: 'Nouvelle liste de surveillance',
			new: 'Nouveau',
			news: 'Nouvelles',
			News: 'Nouvelles',
			newAboutThisEtf: 'Nouvelles à propos de ce FNB',
			newsHeadlines: 'Manchettes',
			newsTeaserHeadline: 'Sommaire-{headline}',
			newWatchlistName: 'Watch List {name}',
			netPercentAllocation: 'Répartition nette en pourcentage',
			netPercentOfHoldings: 'Pourcentage net des placements',
			no: 'Non',
			noDataAvailable: 'Aucune donnée disponible',
			noETFHoldingsNews: 'Des rapports de nouvelles sur les placements sont disponibles pour ce FNB.',
			noData: 'Pas de données',
			nonCashItems: 'Non-Cash Items',
			nonclassifiedequity: 'Actions non classées',
			nonequityinvestmentinstruments: 'Instruments de placement autres que des actions',
			noNewsAvailable: 'Aucune nouvelle n\'est disponible',
			noneDeclared: 'Aucune déclaration',
			nonlifeinsurance: 'Assurance autre que l’assurance vie',
			northAmericanMarkets: 'Marchés nord-américains',
			noMutualFundsAreInThisQuotelist: 'Aucun fonds commun de placement ne figure dans cette liste des cotes.',
			nonInterestExpenseBank: 'Non-Interest Expense, Bank',
			nonInterestIncomeBank: 'Non-Interest Income, Bank',
			noOptionsAreInThisQuotelist: 'Aucune option ne figure dans cette liste des cotes.',
			normalIIncAvailtoCommon: 'Normalized Inc Avail to Common',
			normalIncomeAfterTax: 'Normalized Income After Tax',
			normalizedIncomeBeforeTax: 'Normalized Income Before Taxes',
			noRecentSymbols: 'Aucun symbole récent',
			noSavedScreens: 'Vous n’avez aucun écran enregistré. ',
			noSecuritiesAreInThisQuoteList: 'Aucun titre ne figure dans cette liste des cotes.',
			noSymbolsAreInThisQuotelist: 'No symbols are in this Quote List.',
			noSymbolsAreInThisWatchlist: 'No symbols are in this Watch List.',
			noSymbolsInWatchlist: 'Vous n’avez aucun symbole dans {name}',
			noTopHoldings: 'Aucun titre principal pour ce fonds',
			noTopSectors: 'Aucun secteur principal pour ce fonds',
			noWatchLists: 'FRENCH No Watch Lists',
			noWatchListsLong: 'It looks like you have no watchlists created yet.',
			noWatchListsLongLink: 'Would you like to create a watchlist?',
			noReportsAvailable: 'Aucun rapport n’est disponible',
			noReportsAvailableForThisEtf: 'Aucun rapport d’analyste de Morningstar n’est disponible pour ce FNB.',
			noDistributionsInformation: 'Les renseignements sur la distribution ne sont pas disponibles pour ce FNB.',
			numberOf: 'Number of',
			numberOfResults: 'Nombre de résultats',
			numberOfUnits: 'Nombre de parts',
			numberOfShares: 'Nombre d\'actions',
			numberOfContracts: 'Nombre de contrats',
			numberOfDaysWithinNav: 'Nombre de jours dans une fourchette variant de 0,5 % par rapport à la valeur liquidative',
			numberOfDaysAtPremium: 'Nombre de jours avec prime',
			numberOfDaysAtNav: 'Nombre de jours à la valeur liquidative',
			numberOfDaysAtDiscount: 'Nombre de jours avec escompte',
			numberOfFundsInCategory: 'Nombre de fonds dans la catégorie',
			numberOfHoldings: 'Classement parmi les avoirs',
			numOfAnalysts: 'Nombre d\'analystes',
			natResources: 'Natural Resources',
			netLoans: 'Net Loans',
			nonInterestBearingDept: 'Non-Interest Bearing Dep.',
			noteRcvbleLongTerm: 'Note Rcvble - Long Term',
			notesPayableSTDebt: 'Notes Payable/ST Debt',
			notesRcvbleShortTerm: 'Notes Recvble - Short Term',
			off52WeekHigh: '{symbol} affiche un écart de {diff52Week} écart sur 52 semaines haut de {high} $ enregistré le {highDate}',
			off52WeekHighSr: '{symbol} est en {direction} de {diff52Week} par rapport à son sommet sur 52 semaines, qui était de {high} $ le {highDate}',
			officerNameTitle: 'Dirigeant nom/profil',
			ok: 'OK',
			okSr: 'Sélectionner « OK » pour supprimer les éléments et fermer la fenêtre de dialogue',
			okSr2: 'OK et fermer la fenêtre de dialogue',
			of: 'de',
			oil: 'Pétrole',
			'oilequipment,services&distribution': 'Équipement, services et distribution dans le secteur du pétrole',
			oilSr: 'Graphique du pétrole',
			'oil&gas': 'Pétrole et gaz',
			'oil&gasproducers': 'Production de pétrole et de gaz',
			onAssets: 'de l’actif',
			oneMonth: 'un mois',
			onEquity: 'des capitaux propres',
			oneWeek: 'une semaine',
			oneYear: 'un an',
			operatingMargin: 'Marge d\'exploitation',
			open: 'Ouverture',
			openAlert: 'Ouvrir le menu {name} dans une nouvelle boîte de dialogue',
			openMenu: 'Ouvrir le menu',
			openSymbol: 'Ouvrir le menu {symbol} dans une nouvelle boîte de dialogue',
			openHelpContent: 'Ouvrir le contenu d’aide',
			openInt: 'Intérêt en cours',
			openInterest: 'Intérêt en cours',
			openSymbolMenu: 'Ouvrir le menu {symbol} dans une nouvelle boîte de dialogue',
			opIncome: 'Operating Income',
			opsMaintenance: 'Operations & Maintenance',
			options: 'Options',
			optionChain: 'Option chain',
			optionsNotFound: '{symbol} n’a pas d’options.',
			openPdf: 'Ouvrir le PDF',
			openPdfInANewWindow: 'Ouvrir le PDF {title} dans une nouvelle fenêtre',
			openInANewWindow: 'Ouvrir le {title} dans une nouvelle fenêtre',
			openPdfSr: 'Ouvrir le PDF {name}',
			optionsCaption: '{count} Options',
			optionsExercised: 'Options Exercised',
			optionChainFor: 'Chaîne d’options pour {symbol}',
			optionDetails: 'Renseignements sur l’option',
			optionDetailsSrOnly: 'Renseignements sur {symbol}',
			outOfTheMoney: 'Hors du cours',
			other: 'Autres',
			MSDRegionOther: 'Région Autres',
			others: 'Autres',
			otherAssets: 'Other Assets',
			otherFinancingCashFlow: 'Other Financing Cash Flow',
			otherInvestCashFlow: 'Other Investing Cash Flow',
			otherInvestCashFlowTot: 'OtherInvestCashFlowItms,Tot',
			otherNonCashItems: 'Other Non-Cash Items',
			otherExpense: 'Other Expense',
			otherInsuranceRev: 'Other Insurance Revenue',
			otherInterestIncome: 'Other Interest Income',
			otherNet: 'Other, Net',
			otherNonbankIncome: 'Other Non-Bank Income',
			otherNonInsurRev: 'Other Non-Insur Revenue',
			otherNonOpIncomeExpense: 'Other Non-Operating Income (Expenses)',
			otherNonutilRev: 'Other Non-Utility Revenue',
			otherOpExpense: 'Other Operating Expense',
			otherOpExpenseTotal: 'Other Operat Expse, Total',
			otherRev: 'Other Revenue',
			otherRevenue: 'Other Revenue',
			otherRevTotal: 'Other Revenue, Total',
			otherUnusualExpense: 'Other Unusual Expense',
			otherUnusualIncome: 'Other Unusual Income',
			otherUtilRev: 'Other Utility Revenue',
			otherAssetsTotal: 'Other Assets, Total',
			otherBearingLiabili: 'Other Bearing Liabilities ',
			otherBearingLiabiliTotal: 'Other Bearing Lblts, Total',
			otherComprehensiveIncome: 'Other Comprehensive Income',
			otherCurrLiabiliTotal: 'Other Curr. Lblts, Total',
			otherCurrentAsst: 'Other Current Assets',
			otherCurrentAsstTotal: 'Other Curr. Assets, Total',
			otherCurrntLiabili: 'Other Current Liabilities',
			otherDept: 'Other Deposits',
			otherEarningAsst: 'Other Earning Assets',
			otherEquity: 'Other Equity',
			otherEquityTotal: 'Other Equity, Total',
			otherErngAsstTotal: 'Other Erng. Assets, Total',
			otherLTLiabilities: 'Other LT Liabilities',
			otherLibailities: 'Other Liabilities',
			otherLibailitiesTotal: 'Other Liabilities, Total',
			otherLongTermAsst: 'Other Long Term Assets',
			otherPayables: 'Other Payables',
			otherPolicyholdFunds: 'Other Policyholders&#39; Funds',
			otherPropPlantEquip: 'Other Prop./Plant/Equip.',
			otherRcvlble: 'Other Receivables',
			otherRealEstateOwned: 'Other Real Estate Owned',
			otherSTBorrow: 'Other ST Borrowings',
			otherShortTrmInvest: 'Other Short Trm Invest.',
			overall: 'Période globale',
			overallReturn: 'Rendement global',
			overallReturnDescription: 'Évaluation par Morningstar du rendement excédentaire du FCB par rapport à un taux sans risque, comparativement aux fonds similaires. La cote de rendement globale correspond à la moyenne pondérée des cotes du FCB sur trois, cinq et dix ans.',
			overallRisk: 'Cote de risque globale',
			overallRiskDescription: 'Évaluation par Morningstar de la variation du rendement mensuel du FCB par rapport aux fonds similaires. La cote de risque globale correspond à la moyenne pondérée des cotes du FCB sur trois, cinq et dix ans.',
			overallTimeframe: 'Délai global',
			overThe: 'pendant',
			overview: 'Aperçu',
			Overview: 'Aperçu',
			pleaseSelectAListToAddTo: 'Sélectionnez une liste de surveillance pour y ajouter des symboles.',
			page: 'Page',
			pageCount: 'Page {count}',
			payableAccrued: 'Payable/Accrued',
			policyLoans: 'Policy Loans',
			preferrredStockNet: 'Preferred Stock, Net',
			prepaidExpense: 'Prepaid Expenses',
			preferences: 'Préférences',
			principPaymntSecurit: 'PrincipPaymnts fromSecurits',
			payoutRatio: 'Ratio De Distribution',
			pctChange: 'Variation du prix sur',
			pE: 'C/B',
			peRatio: 'Ratio cours',
			peRatioTTM: 'Ratio de P/E (DDM)',
			percentChange: '% Change',
			performers: 'FNB performants',
			period: 'Période',
			personalgoods: 'Biens personnels',
			'pharmaceuticals&biotechnology': 'Secteurs des produits pharmaceutiques et de la biotechnologie',
			phone: 'Téléphone : ',
			pdfReports: 'Rapports en format PDF',
			pleaseTryAgain: 'Please try again',
			primary: 'Principal',
			primaryEmail: 'Adresse de courriel principale',
			primarySr: 'Principaux résultats du rapport seulement s’ils sont cochés',
			priceAtLaunch: 'Prix au lancement',
			priceEarnings: 'Ratio cours/benefice',
			priceSales: 'Ratio cours/ventes',
			priceSalesRatio: 'Ratio cours/ventes',
			priceCashFlow: 'Cours/flux de trésorerie',
			priceCashFlowRatio: 'Ratio cours/flux de trésorerie',
			priceTangibleBook: 'Price/tangible book',
			priceFreeCashFlow: 'Ratio cours/liquidités disponibles',
			pricing: 'Tarification',
			pricingAndVolume: 'Prix et volume',
			periodRangeError: 'Sélectionner une date de fin valide qui est postérieure à la date de début.',
			pricingVolume: 'Prix et volume',
			performance: 'Rendement',
			performanceReview: 'Évaluer le rendement',
			percentOfHolding: '% des placements',
			percentOfHoldings: 'Pourcent placements',
			percentOfDaysWithinNav: '% des jours dans une fourchette variant de 0,5 % par rapport à la valeur liquidative',
			percentOfNetAssets: "% d'actif net",
			pleaseNameYourWatchList: 'Veuillez nommer votre nouvelle liste de surveillance.',
			pleaseNote: 'Remarque:',
			pleaseSelectSymbols: 'Veuillez sélectionner les symboles à supprimer.',
			portfolio: 'Portefeuille',
			portfolioReview: 'Passer en revue le portefeuille',
			portfolioAllocation: 'Répartition du portefeuille',
			portfolioTurnover: 'Chiffre du portefeuille',
			portfolioTurnoverDescription: 'Le taux de rotation du portefeuille mesure la fréquence à laquelle les composantes de l\'actif d\'un FCB sont achetées et vendues.',
			preferred: 'Titres privilégiés',
			preferredDiv: 'Preferred Dividends',
			preTaxMargin: 'Marge avant impôts',
			product: 'Produit',
			previousArticles: 'Mettre à jour les résultats précédents avec la page précédente',
			previousClose: 'Clôture précédente',
			previousCloseRange: 'Fourchette de clôture précédente',
			previousCloseRangeDescription: 'Fourchette des cours de clôture de la séance de négociation précédente.',
			previousMonthEnd: 'Toutes les autres données en date de la fin du mois.',
			premiumDiscount: 'Prime/escompte',
			premiumDiscountDaily: 'À prime/ à escompte (Journalier)',
			premiumDiscountDailyChart: 'À prime/ à escompte (Journalier) Graphique',
			premiumDiscountToNav: 'Prime/escompte par rapport à la valeur liquidative',
			premDiscountChartDisclaimer: 'Situation liée au cours qui survient lorsque le FCB se négocie à prime ou à escompte par rapport à sa valeur liquidative. On dit d\'un FCB qui se négocie à un cours supérieur à sa valeur liquidative qu\'il se négocie « à prime ». Lorsque le FCB se négocie à un cours inférieur à sa valeur liquidative, il se négocie « à escompte ».',
			premiumAndDiscountDays: 'Jours de prime et escompte',
			premiumAndDiscountAmts: 'Montants de prime et escompte',
			price: 'cours',
			pricePerformancePrix: 'Prix',
			priceRatio: 'Ratio cours',
			priceChange: 'Variation du prix',
			sur: 'sur',
			priceDollarGainers: 'Titres performants ($)',
			priceDollarLosers: 'Titres perdants ($)',
			pricePctGainers: 'Titres performants (%)',
			pricePctLosers: 'Titres perdants (%)',
			pricePerContract: 'Prix par contrat',
			pricePerShare: 'Prix par action',
			pricePerUnit: 'Prix par part',
			profile: 'Profil',
			pricePerformance: 'Performance au niveau des prix',
			profitMargin: 'Marge bénéficiaire',
			profitability: 'Rentabilité',
			proFormaAdj: 'Pro Forma Adjustment',
			proFormaBasicEPS: 'Pro Forma Basic EPS',
			proFormaDilutedEPS: 'Pro Forma Diluted EPS',
			proFormaNetIncome: 'Pro Forma Net Income',
			propertyTax: 'Property & Other Taxes',
			provisionIncomeTax: 'Provision for Income Taxes',
			purchAcqIntangibles: 'Purchase/Acq of Intangibles',
			purchaseDetails: 'Détails de l\'achat',
			purchaseDetailsCurrency: 'Devise / détails d’achat',
			purchasedPower: 'Purchased Power',
			purchasedRandDWrittenOff: 'Purchased R&D Written-Off',
			purchaseFixedAssets: 'Purchase of Fixed Assets ',
			purchaseInvestment: 'Purchase of Investments',
			purchaseRD: 'Purchased R&D',
			put: 'Option de vente',
			puts: 'Options de vente',
			pensionBeneOverfunded: 'Pension Bene. - Overfunded',
			pensionBenefitsUnderfunded: 'Pension Benefits - Underfunded',
			policyLiabilities: 'Policy Liabilities ',
			prefStockNonRdmbl: 'Pref. Stock - Non Rdmbl.',
			prefStockNonRdmblNet: 'Pref. Stock-Non Rdmbl, Net',
			propPlantEquipGross: 'Prop./Plant/Equip. - Gross',
			propPlantEquipNet: 'Prop./Plant/Equip. - Net',
			provDoubtAccts: 'Prov. for Doubtful Accts',
			quarterly: 'Trimestriels',
			quarterlyOption: 'Trimestrielle',
			quarterlyIncomeStatementItem: 'Élément de l’état des résultats trimestriel',
			quarterlyBalanceSheetItem: 'Élément du bilan trimestriel',
			quarterlyCashFlowItem: 'Élément des flux de trésorerie trimestriels',
			quickScreener: 'Sélection rapide',
			quintileForIndustry: '{quintile} pour le secteur',
			quotedetail: 'Détails de la cote',
			quotesDelayedBy15: 'Cotes décalées d’au moins 15 minutes',
			quarterlyEps: 'RPA trimestriel',
			quickRatio: 'Ratio de liquidité relative',
			rAndD: 'Research & Development',
			rangeVariable: '{min} à {max}',
			rate: 'Taux',
			rating: 'Cote',
			ratingPrevSummaryText: ' This is an {trend} trend from the previous rating of {ratingText} ({rating}). As of {prevDate} ',
			ratingSummary: 'Sommaire des cotes',
			ratingSummaryText1: 'La recommandation de consensus est ',
			ratingSummaryText2: ' ou ',
			ratingSummaryText3: ' au ',
			ratingSummaryText4: ' avec ',
			ratingSummaryText5: ' de ',
			ratingSummaryText6: ' total de report d\'analystes.',
			ratingSummaryText: 'The consensus recommendation is {rating} or  {ratingText} as of {ratingDate} with {opinions} of {analysts} total analysts reporting.',
			ratio: 'Ratio',
			readFull: 'Afficher description complète',
			readArticle: 'Lire l’article',
			readCommentary: 'Lire les commentaires',
			readCommentarySR: 'Lire les commentaires sur {headline}',
			readArticleSr: 'Lire l’article sur {headline}',
			readMoreReports: 'Plus de rapports',
			realestate: 'Immobilier',
			MSDStockSectorRealEstate: 'Secteur Immobilier',
			'realestateinvestment&services': 'Placements et services immobiliers',
			realestateinvestmenttrusts: 'Fiducies de placement immobilier',
			realEstateOpExpense: 'Real Estate Operat Expnse',
			realEstateOpGain: 'Real Estate Operation Gain',
			realizedGainLoss: 'Realized Gains (Losses)',
			recentEtfs: 'Nouveaux FNB',
			recentEtfsHidden: 'Veuillez sélectionner parmi les nouveaux FNB',
			recentSymbolNews: 'Nouvelles {symbol} récentes',
			recentWatchlistNews: 'Nouvelles récentes de la {watchlist}',
			redemptionCharge: 'Frais de rachat',
			rename: 'Renommer',
			refinitivDisclaimer: 'Les données de marché et d’actualité sont fournies par Refinitiv. © Refinitiv. La réédition ou la distribution du contenu des données Refinitiv, notamment par cadrage (framing) ou tout autre acte similaire, est strictement interdite sans le consentement écrit et préalable de Refinitiv. Refinitiv ne peut être tenu pour responsable en cas d’erreur ou de retard dans le contenu des données Refinitiv, ou pour toute action ou décision prise sur la base de ces données. Le logo Refinitiv est une marque déposée de Refinitiv et de ses sociétés affiliées.',
			refreshList: 'Actualiser la liste',
			reinsuranceExpense: 'Reinsurance - Expense',
			reinsuranceIncome: 'Reinsurance - Income',
			relativeStrengthIndex: 'Indice de force relative',
			relativeStrengthIndexDescription: 'L\'indice de solidité relative compare l\'ampleur des gains récents d\'un titre à l\'ampleur de ses pertes récentes, et traduit cette information en un chiffre de 0 à 100. L\'indice de solidité relative indique si un titre est suracheté ou survendu. Un indice supérieur à 70 peut traduire une position survendue et un indice inférieur à 30, une position surachetée.',
			relativeVolume: 'Volume relatif',
			restructuringCharge: 'Restructuring Charge',
			'P-AM': 'Put',
			removeSelected: 'Remove Selected',
			reports: 'Rapports',
			reportNotAvailable: 'Rapport non disponible',
			reportsReview: 'Passer en revue les rapports',
			repurchRetirementCommon: 'Repurch/Retirement Common',
			repurchRetirementPreferd: 'Repurch/Retirement Preferd',
			repurchRetirmntCommonPreferr: 'Repurch/Retirmnt Common/Preferr',
			reportTitle: 'Titre du rapport',
			researchHighlights: 'Points saillants de la recherche (fichiers PDF)',
			results: '{count} résultats',
			resultsNoCount: 'Résultats',
			return: 'Rendement',
			returnYear: 'Rendement de {year}',
			returns: 'Rendements',
			returnToNews: 'Retour aux nouvelles',
			returnToReports: 'Revenir aux rapports',
			returnToCommentary: 'Retour au commentaires',
			returnsNotAnnualized: '',
			returnOnAssets: 'Retour sur les biens',
			returnOnAssets2: 'Rendement de l’actif',
			returnOnInvestments: 'Rendement du capital investi',
			returnOnEquity: 'Retour sur l\'action/l\'équité',
			returnOnEquity2: 'Rendement des capitaux propres',
			revenue: 'Revenus',
			revenueGrowthNfy: 'Croissance des revenus (prochain exercice)',
			revenueGrowthNfy2: 'Croissance des revenus',
			revenuePerShare: 'Revenus par action',
			revenueGrowth: 'Croissance des revenus',
			review: 'Passer en revue',
			reviewCharts: 'Passer en revue les graphiques',
			reviewChartsSymbolSr: 'Passer en revue les graphiques du {symbol}',
			reviewLipperLeaders: 'Passer en revue les valeurs Lipper',
			reviewLipperLeadersSymbolSR: 'Passer en revue les valeurs Lipper du {symbol}',
			reviewSavedScreens: 'Examiner les écrans enregistrés',
			reviewSavedSearches: 'Revoir les recherches sauvegardées',
			reviewSymbol: 'Review {symbol}',
			reviewSnapshotSR: 'Passer en revue l’instantané {symbol}',
			reviewPerformanceSR: 'Évaluer le rendement {symbol}',
			reviewPortfolioSR: 'Passer en revue le portefeuille {symbol}',
			reviewReportsSR: 'Passer en revue les rapports {symbol}',
			reviewCommentary: 'Passer en revue les commentaires',
			reviewSpecificCommentary: 'Passer en revue les commentaires ({title})',
			reviewFundamentals: 'Examen des éléments fondamentaux',
			reviewNumResults: 'Passer en revue {numResults} résultats',
			reviewNMatches: 'Examiner {count} Correspondances',
			reviewPerformance: 'Examiner le rendement',
			reviewPortfolio: 'Passer en revue le portefeuille',
			reviewMarketsAtAGlance: 'Jeter un coup d’œil sur les marchés',
			rho: 'Rho',
			rsi14d: 'Indice de force relative 14 jours',
			rSquaredVsCategory: 'R-Squared vs. {category}',
			rSquaredVsCategoryTooltip: 'Le coefficient de détermination montre la solidité d\'une relation linéaire entre un titre et son indice de référence sur une période donnée. Les valeurs du coefficient de détermination vont de 0 à 1. Un coefficient de détermination élevé indique une solide relation linéaire.',
			rdmblConvPrefStock: 'Rdmbl Conv. Pref. Stock',
			redeemablePrefStockSRPR: 'Redeemable Preferred Stock',
			redeemablePrefStockZPSK: 'Redeemable Preferred Stock',
			reinsuranceAsst: 'Reinsurance - Asset',
			reinsuranceLiability: 'Reinsurance - Liability',
			repurchaseAgreements: 'Repurchase Agreements',
			reserves: 'Reserves',
			restrictedCashCurrent: 'Restricted Cash - Current',
			restrictedCashLT: 'Restricted Cash - LT',
			retEarnAccumDeficit: 'Ret. Earn.(Accum. Deficit)',
			risk: 'Risque',
			rSquared: 'R au carré',
			rSquaredYear: 'R au carré ({year} an) ',
			MSDPrimaryIndexRSquaredY1Ptile: 'R au carré (1 an) ',
			MSDPrimaryIndexRSquaredY3Ptile: 'R au carré (3 an) ',
			MSDPrimaryIndexRSquaredY5Ptile: 'R au carré (5 an) ',
			MSDPrimaryIndexRSquaredY10Ptile: 'R au carré (10 an) ',
			MSDPrimaryIndexRSquaredY1: 'R au carré (1 an) ',
			MSDPrimaryIndexRSquaredY3: 'R au carré (3 an) ',
			MSDPrimaryIndexRSquaredY5: 'R au carré (5 an) ',
			MSDPrimaryIndexRSquaredY10: 'R au carré (10 an) ',
			rSquaredDescription: 'Le R au carré sur une période d’un an, de trois ans, de cinq ans ou de dix ans montre la solidité d’une relation linéaire entre le rendement d’un FNB et son indice de référence sur une période déterminée. Les valeurs du R au carré vont de 0 à 1. Un R au carré élevé traduit une solide relation linéaire, alors qu’un R au carré faible indique que le FNB n’agit pas comme son indice de référence.',
			saleBusiness: 'Sale of Business',
			saleFixedAssets: 'Sale of Fixed Assets',
			saleIntangible: 'Sale of Intangible',
			saleIssuanceCommon: 'Sale/Issuance of Common',
			saleIssuancePreferred: 'Sale/Issuance of Preferred',
			saleIssueCommonPrefrd: 'Sale/Issue of Common/Prefrd',
			saleMaturityInvestments: 'Sale/Maturity of Investment',
			salesRatio: 'ventes',
			salesReturnAllow: 'Sales Returns & Allowances',
			save: 'Enregistrer',
			saveAs: 'Enregistrer comme',
			saveScreenInNewDialog: 'La sélection enregistrée s’ouvre dans une nouvelle boîte de dialogue',
			saveAsNewScreenInANewDialog: 'Enregistrer comme nouvelle sélection dans une nouvelle boîte de dialogue',
			savedAdvancedSearches: 'Recherches avancées sauvegardées',
			savedScreensCaption: 'Tableau des sélections enregistrées',
			savedScreenLinkCaption: '{screenName} dans le tableau correspondant',
			savedScreens: 'Sélections enregistrées',
			saveSr: 'Enregistrer et aller aux listes de surveillance',
			saveAndCloseDialogue: 'Enregistrer et fermer la fenêtre de dialogue',
			savedItems: 'Saved Items',
			savedSearches: 'Recherches sauvegardées',
			saveWatchlist: 'Enregistrer et aller aux listes de surveillance',
			screenIncludesCriteria: 'Cette sélection prend en compte les critères suivants:',
			screenName: 'Nom de l’écran',
			Screener: 'Sélection',
			ScreenerDistribution: 'Distribution',
			screenerFundType: 'Type de fonds',
			screenerFundTypeDescription: 'Classification générale des FCB reposant sur la catégorie d\'actif, la stratégie ou le pays.',
			screenerPortfolio: 'Portefeuille',
			screeners: 'Sélection',
			screenerLinkSr: 'Sélection de {type}',
			screenerPreview: 'Notre nouvelle page d’outils de sélection n’est pas tout à fait terminée, mais elle le sera bientôt. Une fois que la page sera prête, tous les renseignements liés aux outils de sélection que vous avez enregistrés seront accessibles dans notre nouveau format amélioré. Restez à l’affût!',
			searchByKeyword: 'Recherche par mot-clé',
			searchIn: 'Rechercher dans',
			search: 'Recherche',
			searchOrSecurity: 'ou titre',
			searchSymbol: 'Recherche par symbole',
			searchSymbolOrSecurity: 'Recherche par symbole ou titre',
			searchSymbolOrCompany: 'Recherche par symbole ou par entreprise',
			searchSymbolToAddToList: 'Rechercher des symboles à ajouter à votre liste.',
			secondaryEmail: 'Adresse de courriel secondaire',
			sectorEquity: 'Actions sectorielles',
			setSymbolAlerts: 'Définir des alertes pour {symbol}',
			security: 'Titre',
			selectAll: 'Sélectionner tout',
			selectSector: 'Sélectionner un secteur',
			selectSectorFirst: 'Sélectionner d’abord un secteur',
			selectSymbol: 'Sélectionner {symbol} dans la liste de surveillance',
			selectSymbolBasic: 'Sélectionner {symbol}',
			selectSymbolFromQuoteList: 'Sélectionner {symbol} dans la liste des cotes',
			selectAllSecurities: 'Sélectionner tous les titres de la liste de surveillance actuelle',
			selectAllOptions: 'Sélectionner toutes les options de la liste des cotes actuelle',
			selectAllFunds: 'Sélectionner tous les fonds de la liste des cotes actuelle',
			selectAllQLSecurities: 'Sélectionner tous les titres de la liste des cotes actuelle',
			shares: 'Actions',
			sharpeRatioDescription: 'Le ratio Sharpe sur une période d’un an, de trois ans, de cinq ans ou de dix ans indique dans quelle mesure le rendement excédentaire d’un actif récompense l’investisseur pour le risque endossé sur une période déterminée. Plus le ratio Sharpe est élevé, plus le rendement ajusté au risque est élevé.',
			selectAStrikePrice: 'Select a Strike Price',
			selectMaxCompare: 'Veuillez sélectionner jusqu’à trois FNB similaires pour les comparer.',
			selectYourCriteriaFromDropdown: 'Sélectionnez vos critères dans les menus déroulants correspondants',
			selectOne: 'Sélectionner une option',
			selectOneOrMore: 'Sélectionnez au moins une valeur',
			selectPeriod: 'Choisir une période',
			selectWatchList: 'Sélectionner une liste de surveillance',
			selectWatchlistSrOnly: 'Sélectionner {name}',
			selectYourCriteria: 'Sélectionnez vos critères dans les menus déroulants correspondants',
			selectaListToAdd: 'Sélectionner une liste pour ajouter',
			sellGenAdminExpenseTotal: 'Sell/General/Admin. Expenses, Total',
			sellingGenAdminExpense: 'Selling/Gen/Admin Expense',
			seriesBy: 'Series par',
			setAlerts: 'Définir des alertes',
			setStockAlert: 'Établir une alerte',
			setWatchListAlerts: 'Configurer des alertes pour la liste de surveillance',
			services: 'Services',
			sharesOutstanding: 'Actions en circulation',
			sharesRelated: 'Postes liés aux actions Millions',
			shortDurationHighQuality: 'Courte durée/haute qualité',
			shortDurationMediumQuality: 'Courte durée/qualité moyenne',
			shortDurationLowQuality: 'Courte durée/qualité basse',
			shortPosition: '% des positions vendeur',
			shortRestriction: 'Restriction vente découvert',
			shortStrategy: 'Stratégie vendeur',
			shortStrategyChart: 'Graphique de la stratégie vendeur',
			shortTermDebtIssued: 'Short Term Debt Issued',
			shortTermDebtNet: 'Short Term Debt, Net',
			shortTermDebtReduce: 'Short Term Debt Reduction',
			sharpeRatio: 'Ratio de Sharpe',
			sharpeRatioYear: 'Ratio Sharpe ({year} an)',
			MSDSharpeRatioY1Ptile: 'Ratio Sharpe (1 an)',
			MSDSharpeRatioY3Ptile: 'Ratio Sharpe (3 an)',
			MSDSharpeRatioY5Ptile: 'Ratio Sharpe (5 an)',
			MSDSharpeRatioY10Ptile: 'Ratio Sharpe (10 an)',
			MSDSharpeRatioY1: 'Ratio Sharpe (1 an)',
			MSDSharpeRatioY3: 'Ratio Sharpe (3 an)',
			MSDSharpeRatioY5: 'Ratio Sharpe (5 an)',
			MSDSharpeRatioY10: 'Ratio Sharpe (10 an)',
			sharpRatioTooltip: 'Le ratio Sharpe indique dans quelle mesure le rendement excédentaire d\'un actif récompense l\'investisseur pour le risque couru sur une période donnée. Plus le ratio Sharpe est élevé, plus le rendement ajusté au risque est élevé.',
			show: 'Show',
			showAll: 'Afficher tout',
			showChangeInDollars: 'Show change in dollars',
			showChangeInPercent: 'Show change in percent',
			shownIn: 'Shown In',
			sliceNumber: 'Numéro de tranche',
			similarETFs: 'Les FNB semblables',
			similarETFsNotFound: 'Aucun FNB semblable trouvé',
			simpleRange: 'Intervalle simple',
			since: 'Depuis',
			size: 'Taille',
			slowStochastics: 'Stochastiques lents',
			sma15day: 'MMS de 15 jours',
			sma50day: 'MMS de 50 jours',
			sma100day: 'MMS de 100 jours',
			sma200day: 'MMS de 200 jours',
			smallCapBlend: 'Mixte à petite capitalisation',
			smallCapGrowth: 'Croissance à petite capitalisation',
			smallCapValue: 'Valeur à petite capitalisation',
			snapshot: 'Instantané',
			snapshotReview: 'Passer en revue l’instantané',
			'software&computerservices': 'Logiciels et services informatiques',
			softwareDevCosts: 'Software Development Costs',
			sortBy: 'Trier par',
			sortable: 'triable',
			snapshotLower: 'instantané',
			sp500ChartHidden: 'Graphique de l’indice S&P 500',
			spTsx60ChartHidden: 'Graphique de l’indice S&P/TSX 60',
			snapshotPortfolioDisclaimer: 'Les données de Prime/Escompte sont mises à jours quotidiennement. Toutes les autres données sont en date de la fin du mois.',
			spTsxCompChartHidden: 'Graphique de l’indice S&P TSX Composite',
			spTsxVentureChartHidden: 'Graphique de l’indice S&P/TSX Composite de croissance',
			standardDeviation: 'Écart type',
			standardDeviationYear: 'Écart type ({year} an)',
			MSDStandardDeviationY1Ptile: 'Écart type ({year} an)',
			MSDStandardDeviationY3Ptile: 'Écart type ({year} an)',
			MSDStandardDeviationY5Ptile: 'Écart type ({year} an)',
			MSDStandardDeviationY10Ptile: 'Écart type ({year} an)',
			MSDStandardDeviationY1: 'Écart type ({year} an)',
			MSDStandardDeviationY3: 'Écart type ({year} an)',
			MSDStandardDeviationY5: 'Écart type ({year} an)',
			MSDStandardDeviationY10: 'Écart type ({year} an)',
			standardDeviationDescription: 'L’écart type mesure la variation du rendement d’un fonds sur une période d’un an, de trois ans, de cinq ans ou de dix ans.',
			standardDeviationTooltip: 'L\'écart-type mesure la variation du rendement d\'un fonds sur une période donnée.',
			starsOfStarsRating: '{stars} sur {max} étoiles',
			starsOfStarsMaxRating: '{max} sur {max} étoiles',
			startDay: 'Jour de début',
			startMonth: 'Mois de début',
			startYear: 'Année de début',
			steamOps: 'Steam Operations',
			stochasticOscillator: 'Oscillateur stochastique',
			stochasticOscillatorDescription: `L'oscillateur stochastique est composé de deux lignes : K% et D%. La ligne K% indique où le cours d'un titre a clôturé par rapport à sa fourchette de cours sur une période donnée. La ligne D% fournit un lissage de la ligne K% en prenant la moyenne mobile sur trois jours de K%.
			\nUn signal haussier est émis lorsque K% clôture au-dessus de D%. Un signal baissier est émis lorsque K% clôture au-dessous de D%.`,
			stocks: 'Actions',
			stockBaseComp: 'Stock Based Compensation',
			stockScreener: 'Sélection',
			strike: 'Exercice',
			strikesShown: 'Prix d’exercice affichés',
			strikePrice: 'Prix d’exercice',
			strikeType: 'Strike/Type',
			stockDetail: 'Information sur l’action',
			source: 'Source',
			stockCentre: 'Centre des actions',
			stocksAndIndicesCaption: '{count} actions et indices',
			stocksETFsIndices: 'Actions, FNB et indices',
			stockEarningsChartHeader: '{symbol} résultat par action (RPA)',
			stockEarningsLink: 'Click or tab through bars for more info',
			supportservices: 'Services de soutien',
			subjectToChangeDisclaimer1: 'Toutes les informations de notation ou de classement sont sujettes à modification sur une base mensuelle.',
			subjectToChangeDisclaimer2: 'Le taux de rendement ou la table mathématique sert uniquement à illustrer les effets du taux de croissance composé et ne vise pas à refléter les valeurs futures de l\'OPC ou le rendement d\'un placement dans l\'OPC.',
			suspendFrom: 'Suspendre du',
			summary: 'Sommaire',
			symbol: 'symbole',
			symbolLabel: 'Symbole',
			symbols: 'Symbols',
			symbolLastChangeBottom: 'dernier changement',
			symbolLastChangeTop: 'Symbole',
			symbolCompany: 'Symbole/société',
			symbolCompanyName: 'Symbole ou nom d’entreprise',
			symbolChart: 'Graphique de l’indice {symbol} - ',
			symbolDetails: 'Renseignements sur {symbol}',
			symbolDoesNotHaveAnyOptions: '{symbol} n’a pas d’options.',
			symbolInactive: 'FRENCH This symbol is no longer active.',
			symbolsForWatchlist: 'Symbols for Watch List',
			symbolsInList: 'Symboles dans la liste',
			symbolDoesNotExist: 'Le symbole que vous essayez de consulter n’existe pas. Veuillez utiliser la fonction Recherche de symbole pour trouver un autre titre.',
			symbolNotFound: 'Symbole introuvable',
			symbolNotFoundTryAgain: 'Le symbole n’est pas valide',
			sixMonthTBill: 'Bons du Trésor à 6 mois',
			silver: 'Argent',
			silverSr: 'Graphique de l’argent',
			secDepotsits: 'Security Deposits',
			secIndbtdRltPrts: 'Sec./Indbtd. of Rlt\'d Prts',
			securitiesHeld: 'Securities Held ',
			securitiesSale: 'Securities for Sale',
			sepAccntLiability: 'Sep. Accounts - Liability',
			seperateAcctAssets: 'Separate Accounts - Assets',
			shortTermInvs: 'Short Term Investments',
			shrOutComSktIss2: 'Shr Outs.-Com. Stk. Iss. 2',
			shrOutComSktIss3: 'Shr Outs.-Com. Stk. Iss. 3',
			shrOutComSktIss4: 'Shr Outs.-Com. Stk. Iss. 4',
			shrOutsPrimaryLss: 'Shr Outs.-Com. Primary Iss.',
			shrsOutPrefPrimaryIss: 'Shrs Out-Pref Primary Iss.',
			shrsOutPrefStkIss2: 'Shrs Out-Pref Stk Iss. 2',
			shrsOutPrefStkIss3: 'Shrs Out-Pref Stk Iss. 3',
			shrsOutPrefStkIss4: 'Shrs Out-Pref Stk Iss. 4',
			shrsOutPrefStkIss5: 'Shrs Out-Pref Stk Iss. 5',
			shrsOutPrefStkIss6: 'Shrs Out-Pref Stk Iss. 6',
			startEtfScreener1: 'Commencez votre recherche de FNB à l’aide de nos ',
			startEtfScreener2: ', ou effectuez votre propre recherche à l’aide des outils ',
			tobacco: 'Tabac',
			taxablebond: 'Obligations imposables',
			taxOnExtraItem: 'Tax on Extraordinary Items',
			tCost: 'des coûts',
			test: 'test-fr',
			text: 'Texte',
			technicalAnalysis: 'Analyse technique',
			Technical: 'Technique ',
			technicals: 'Paramètres techniques',
			'Technical analysis': 'Analyse technique',
			technology: 'Technologie',
			MSDStockSectorTechnology: 'Secteur Technologie',
			'technologyhardware&equipment': 'Matériel et équipement technologique',
			telecommunications: 'Télécommunications',
			telecommunicationServices: 'Services Télécommunications',
			tenure: 'Tenure',
			tenYearBond: 'Obligations à 10 ans',
			the: 'La',
			theLowerCase: 'la',
			thereAreCurrentlyNoGainers: 'There are currently no gainers today',
			thereAreCurrentlyNoLoser: 'There are currently no losers today',
			thereAreNoReports: 'Aucune nouvelle n\'est disponible pour ce FNB.',
			thereAreNoNewsReports: 'There are no news reports available for this ETF.',
			theWeekAhead: 'L’actualité hebdomadaire',
			theWeekAheadWC: 'L’actualité hebdomadaire',
			theWeekAheadAnalystReports: 'The week ahead (en anglaise seulement)',
			theWeekAheadDescription: 'Soyez en avance sur le marché grâce à des analyses des tout derniers développements économiques qui touchent les marchés des titres à revenu fixe, des actions et des changes ainsi qu’à des aperçus des événements attendus pour la semaine suivante.',
			timeframe: 'Période',
			theta: 'Theta',
			thirtyYearBond: 'Obligations à 30 ans',
			thisOptionHasExpired: 'Cette option est expirée.',
			thousand: 'mille',
			threeMonth: 'trois mois',
			threeMonthTBill: 'Bons du Trésor à 3 mois',
			threeYearTBill: 'Bons du Trésor à 3 ans',
			to: 'À',
			tools: '',
			toUpperCase: 'Au',
			toContextDe: 'de',
			toLowerCase: 'à',
			today: 'Aujourd’hui',
			todayIndex: 'Indices du jour',
			todayFunds: 'Fonds du jour',
			todaysChange: 'Variation de la journée',
			todaysChangeSr: 'en date d’aujourd’hui',
			todaysMovers: 'Titres les plus actifs',
			todaysOpen: 'Ouverture - aujourd\'hui',
			todaysRange: 'Fourchette de la journé',
			todaysRangeButtonSr: 'Agrandir pour afficher les renseignements sur la fourchette des cours sur 52 semaines',
			topGlobalHoldings: 'Principaux titres mondiaux',
			topGlobalHoldingsDescription: 'Principaux placements mondiaux d\'un portefeuille de FCB.',
			topPerformingBelowAverageMEREquityETFs: 'FNB d\'actions les plus performants avec RFG inférieur à la moyenne',
			topPerformingBelowAverageMEREquityETFs_desc: 'FNB qui investissent dans des actions canadiennes, américaines et internationales et affichent un RFG inférieur à la moyenne et un rendement annuel supérieur à la moyenne.',
			topPerformingEmergingMarketsETFs: 'FNB des marchés émergents les plus performants',
			topPerformingEmergingMarketsETFs_desc: 'FNB qui investissent dans des marchés émergents et affichent un rendement annuel supérieur à la moyenne.',
			top10Holdings: '10 principaux titres',
			top10HoldingsDescription: 'Dix principaux placements d\'un portefeuille de FCB.',
			topGlobalHoldingsTooltip: 'La répartition par pays est calculée en utilisant seulement les position longue d\'actions et d\'obligations du portfolio selon leur pays d\'émission',
			topHoldings: 'Principaux titres',
			topNMostActive: 'Top {count} Most Active',
			topNMovers: 'Top {count} Movers',
			topPctMovers: 'Titres gagnants (%) ',
			topPerformers: 'Meilleurs rendements',
			topPriceMovers: 'Titres gagnants ($)',
			topMovers: 'Titres les plus actifs',
			topSectorHoldings: 'Principaux titres sectoriels',
			topSectorHoldingsDescription: 'Principaux placement par secteur d\'un portefeuille de FCB.',
			topSectorsByPercent: 'Principaux secteurs (en pourcentage)',
			topSectorsHeld: 'Principaux secteurs détenus',
			topSecuritiesHeld: 'Principaux titres détenus',
			total: 'Total',
			totalAdjToNetIncome: 'Total Adjust to Net Income',
			totalCashDividsPaid: 'Total Cash Dividends Paid',
			totalCashFinancing: 'Total Cash From Financing',
			totalCashInvest: 'Total Cash from Investing',
			totalCashOps: 'Total Cash from Operations',
			totalDebtIssue: 'Total Debt Issued',
			totalDebtReduce: 'Total Debt Reduction',
			totalExtraItem: 'Total Extraordinary Items',
			totalInterestExpense: 'Total Interest Expense',
			totalOpExpense: 'Total Operating Expense',
			totalPremEarned: 'Total Premiums Earned',
			totalRev: 'Total Revenue',
			totalReturnPerformance: 'Performance des rendements totaux',
			totalSpecialItems: 'Total Special Items',
			totalDebtEquity: 'Ratio total emprunts/capitaux propres',
			totalAssets: 'Total de l’actif',
			totalAssetsLowerA: 'Total de l’actif',
			totalNetAssets: 'Actif net total',
			totalNetAssetsShort: 'Actif net total',
			totalNetAssetsDescription: 'On obtient l\'actif net total en soustrayant le passif d\'un FCB de son actif.',
			totalNetAssetsRange0to10M: '< 10M',
			totalNetAssetsRange10to50M: '10M-50M',
			totalNetAssetsRange50to500M: '50M-500M',
			totalNetAssetsRange500to1000M: '500M-1B',
			totalNetAssetsRangeabove1000M: '> 1B',
			totalGainLossOf: 'Gain/perte total des {issueType}',
			trade: 'Négocier',
			tradeSr: 'Négocier {symbol}',
			trStockReports: 'TR StockReports+',
			trailingTwelveMonths: 'Douze derniers mois (DDM)',
			tradePricesAndQuotes: 'Les prix des opérations et les cotes ne proviennent pas de tous les marchés.',
			tradingAcctInterest: 'Trading Account Interest',
			trailingReturnPeriod: '',
			trailingRollingReturns: 'Rendements anualisés passés',
			trailingRollingReturnTooltip: 'Le rendement passé est le rendement total d\'un fonds pendant une période donnée. Par exemple, la dernière barre présente le rendement total d\'un FCB pendant la période sélectionnée (p. ex. trois mois). La barre précédente indique aussi le rendement pour la période sélectionnée, mais calculé jusqu\'à la fin du mois précédent plutôt jusqu\'au mois en cours.',
			treasuryStock: 'Treasury Stock',
			ttm: 'DDM',
			ttmLabel: 'bénéfice (période de 12 mois)',
			ttm1YrAgo: 'DDM avec DDM voilà 1 an',
			type: 'Type',
			typeContextCategory: 'Catégorie',
			totalCurrentAsst: 'Total Current Assets',
			totalCurrentLiabili: 'Total Current Liabilities',
			totalDebt: 'Total Debt',
			totalDept: 'Total Deposits',
			totalEquity: 'Total Equity',
			totalGrossLoan: 'Total Gross Loans',
			totalInvent: 'Total Inventory',
			totalInvestSec: 'Total Invest. Securities',
			totalLibailities: 'Total Liabilities',
			totalLongTermDebt: 'Total Long Term Debt',
			totalRcvbleNet: 'Total Receivables, Net',
			totalSTBorrow: 'Total ST Borrowings',
			totalShareholdersEquity: 'Total Liabilities & Shareholders&#39; Equity',
			totalUtilPlantNet: 'Total Utility Plant, Net',
			tradeAcctsRcvbleGross: 'Trade Accts Recvble, Gross',
			tradeAcctsRcvbleShortTerm: 'Trade Accts Recvble, Net',
			tradingAcctAsst: 'Trading Account Assets ',
			transportation: 'Transport',
			'travel&leisure': 'Voyage et loisirs',
			treasuryStockCommon: 'Treasury Stock - Common',
			treasuryStockPref: 'Treasury Stock - Preferred',
			trsyShrCommIss2: 'Trsy. Shrs-Comm. Iss. 2',
			trsyShrCommIss3: 'Trsy. Shrs-Comm. Iss. 3',
			trsyShrCommIss4: 'Trsy. Shrs-Comm. Iss. 4',
			trsyShrCommPrimaryLss: 'Trsy. Shrs-Comm. Primary Iss.',
			trsyShrPrefIss1: 'Trsry Shrs-Pref. Iss. 1',
			trsyShrPrefIss2: 'Trsry Shrs-Pref. Iss. 2',
			trsyShrPrefIss3: 'Trsry Shrs-Pref. Iss. 3',
			trsyShrPrefIss4: 'Trsry Shrs-Pref. Iss. 4',
			trsyShrPrefIss5: 'Trsry Shrs-Pref. Iss. 5',
			trsyShrPrefIss6: 'Trsry Shrs-Pref. Iss. 6',
			ttlCommSharesOuts: 'Ttl Comm. Shares Outs.',
			units: 'Parts',
			usPrime: 'Taux préférentiel américain',
			usVsCanadianDollars: 'Dollar américain c. dollar canadien',
			USvsCanadianSrOnly: 'Dollar américain c. dollar canadien',
			unableToLoadPage: 'Nous n\'avons pas pu charger cette page avec succès',
			underlying: 'Underlying',
			underlyingprice: 'Underlying Price',
			underwritingCommissions: 'Underwriting & Commissions',
			unrealizedGain: 'Unrealized Gains',
			unrealizedGains: 'Unrealized Gains (Losses)',
			unrealizedLoss: 'Unrealized Losses',
			unrealizedLossGains: 'Unrealized Losses (Gains)',
			unusualExpenseIn: 'Unusual Expense (Income)',
			up: 'Hausse de',
			us: 'États-Unis ',
			usGrowthAtReasonablePrice: 'États-Unis – croissance à prix raisonnable',
			usGrowthAtReasonablePrice_desc: 'Actions américaines affichant une croissance raisonnable des cours',
			usLowPEStocks: 'États-Unis – actions à faible ratio C/B',
			usValueStocks: 'États-Unis – actions de valeur',
			usCommodityETFs: 'FNB de marchandises américains',
			usCommodityETFs_desc: 'FNB qui investissent dans des titres liés aux marchandises.',
			usDollars: 'Dollars américains',
			usePreviousSearch: 'Utilisez la recherche précédente pour ajouter des symboles.',
			usequity: 'Actions américaines',
			usEquityETFs: 'FNB d\'actions américaines',
			usEquityETFs_desc: 'FNB qui investissent dans des actions américaines.',
			usFixedIncomeETFs: 'FNB de titres à revenu fixe américains',
			usFixedIncomeETFs_desc: 'FNB qui investissent dans des titres à revenu fixe américains.',
			usGaapAdj: 'U.S. GAAP Adjustment',
			usNews: 'Nouvelles aux États-Unis',
			ususualItems: 'Unusual Items',
			value: 'Valeur',
			utilities: 'Services publics',
			MSDStockSectorUtilities: 'Secteur Services aux collectivités',
			unbilledUtilRev: 'Unbilled Utility Revenues',
			unearnedIncome: 'Unearned Income',
			unearnedPremRev: 'Unearned Prem. Or Rev.',
			unrealizedGainLoss: 'Unrealized Gain (Loss)',
			utilPlant: 'Utility Plant',
			utilPlantAccumDeprc: 'Utlty. Plnt. Accum. Deprc.',
			utilPlantNet: 'Utility Plant, Net',
			vacationStop: 'Interruption vacances',
			valuation: 'Évaluation',
			vega: 'Vega',
			viewAll: 'View All',
			viewMore: 'View more',
			viewTop5: 'Afficher les 5 meilleurs',
			viewTop10: 'Afficher les 10 meilleurs',
			viewBy: 'View By',
			viewMoreReports: 'View More Reports',
			viewMarketMovers: 'View Market Movers',
			viewMoreNewsHeadlines: 'Plus de nouvelles',
			viewOption: 'Liste des options',
			volume: 'Volume',
			avgVolume: 'Volume moyen',
			threeMonths: '(3 mois)',
			threeMonthsSr: '(3 mois)',
			volatility: 'Volatilité',
			vsSnP500: 'Par rapport à l’indice S&P 500 sur',
			vsSnP5004Week: 'vs S&P 500 sur 1 mois',
			vsSnP50052Week: 'vs S&P 500 sur 1 an',
			warrantsConverted: 'Warrants Converted',
			watchlist: 'listes de surveillance',
			watchlistDeliveryConfigured: 'Vos alertes de la liste de surveillance sont configurées pour être envoyées {period}.',
			watchlistAlerts: 'Alertes de la liste de surveillance',
			watchlistAlertsDescription: 'Recevez par courriel un sommaire de la progression des cours des actions dans vos listes de surveillance. Cette alerte quotidienne, hebdomadaire ou mensuelle comprend les cours de clôture des actions, les volumes et les cours maximum et minimum sur 52 semaines.',
			'Watch list': 'Liste de surveillance',
			'Watch lists': 'Listes de surveillance',
			'Quote lists': 'Listes de cotes',
			warningIcon: 'Attention',
			watchlistDataUnavailable: 'Les données sur les {section} des {type} ne sont pas disponibles',
			watchlistDataUnavailable2: 'Les données sur le {section} des {type} ne sont pas disponibles',
			watchlistDataUnavailable3: 'Les données sur le {section} des {type} ne sont pas disponibles',
			watchlistInsufficientCapacity: 'Il n’y a pas assez d’espace pour ajouter des symboles à la liste.',
			watchlistTotals: 'Totaux des listes de surveillance',
			watchlistNameLenghtError: 'Le nom de la liste de surveillance ne doit pas dépasser 20 caractères.',
			watchlistNews: 'Nouvelles à propos des {watchlist}',
			watchlistSymbolDetail: 'Renseignements sur {symbol}',
			waterOps: 'Water Operations',
			web: 'Web : ',
			weeksVariable: '{number} semaines',
			weekly: 'Chaque semaine',
			yearly: 'Chaque mois',
			yes: 'Oui',
			yield: 'Rendement',
			yieldLabel: 'des distributions',
			youMustSelect: 'Sélectionner des symboles à ajouter.',
			yourSelectionAltersData: 'Votre sélection modifie les données connexes',
			ytd: 'en cumul annuel',
			ytdEtfPerformance: 'Cumul annuel',
			ytdPct: 'YTD %',
			ytdReturn: 'Rendement cumulatif annuel',
			yourWatchlistEmpty: 'Votre liste de surveillance est actuellement vide.',
			yourPdfDownloaded: 'Votre fichier en format PDF a été téléchargé',

			// begin morningstar categories
			'allocation--15%to30%equity': 'Répartition : 15 % à 30 % d’actions',
			'allocation--30%to50%equity': 'Répartition : 30 % à 50 % d’actions',
			'allocation--50%to70%equity': 'Répartition : de 50 % à 70 % d’actions',
			'allocation--70%to85%equity': 'Répartition : de 70 % à 85 % d’actions',
			'allocation--85%+equity': 'Répartition : 85 % ou plus d’actions',
			alternativecreditfocused: 'Accent sur les titres de créance non traditionnels',
			alternativeequityfocused: 'Accent sur les actions non traditionnelles',
			alternativemarketneutral: 'Neutre au marché non traditionnel',
			'alternativemulti-strategy': 'Stratégies multiples non traditionnelles',
			'alternativemulti–strategy': 'Stratégies multiples non traditionnelles',
			alternativeother: 'Autre non traditionnel',
			asiapacificequity: 'Actions d’Asie-Pacifique',
			bankloan: 'Prêts bancaires',
			bearmarket: 'Marché baissier',
			canada: 'Canada',
			MSDRegionCanada: 'Région Canada',
			canadaAndUS: 'Canada et États-Unis',
			canadiancorporatefixedincome: 'Titres à revenu fixe de sociétés canadiennes',
			'canadiandividend&incomeequity': 'Actions canadiennes à dividendes et à revenu',
			canadianequity: 'Actions canadiennes',
			canadianequitybalanced: 'Équilibré actions canadiennes',
			canadianFixedIncomeETFs: 'FNB de titres à revenu fixe canadiens',
			canadianFixedIncomeETFs_desc: 'FNB qui investissent dans des titres à revenu fixe canadiens.',
			canadianfixedincome: 'Titres à revenu fixe canadiens',
			canadianfixedincomebalanced: 'Équilibré titres à revenu fixe canadiens',
			'canadianfocusedsmall/midcapequity': 'Actions en majorité canadiennes à petite/moyenne capitalisation',
			canadianinflationprotectedfixedincome: 'Revenu fixe canadien indexé à l’inflation',
			canadianlongtermfixedincome: 'Titres à revenu fixe canadiens à long terme',
			canadianmoneymarket: 'Marché monétaire canadien',
			canadianneutralbalanced: 'Équilibré neutre canadien',
			canadianshorttermfixedincome: 'Titres à revenu fixe canadiens à court terme',
			'canadiansmall/midcapequity': 'Actions canadiennes à petite/moyenne capitalisation',
			chinaregion: 'Région de la Chine',
			commoditiesbroadbasket: 'Vaste panier de titres du secteur des marchandises',
			commoditiesenergy: 'Marchandises relatives à l’énergie',
			commoditiesfocused: 'Accent sur les marchandises',
			communications: 'Communications',
			convertibles: 'Titres convertibles',
			corporatebond: 'Obligations de sociétés',
			daily: 'Chaque jour',
			dailyVolume: 'Volume journalier',
			diversifiedemergingmarkets: 'Marchés émergents diversifiés',
			'diversifiedpacific/asia': 'Pacifique et Asie diversifiés',
			emergingmarketsbond: 'Obligations des marchés émergents',
			emergingmarketsequity: 'Actions des marchés émergents',
			emergingmarketsfixedincome: 'Titres à revenu fixe des marchés émergents',
			emergingmarketslocalcurrencybond: 'Obligations de pays émergents libellées en monnaie locale',
			energyequity: 'Actions de l’énergie',
			energylimitedpartnership: 'Société en commandite du secteur de l’énergie',
			equityenergy: 'Actions de l’énergie',
			equitypreciousmetals: 'Actions de métaux précieux',
			europestock: 'Actions de la zone Europe',
			europeanequity: 'Actions européennes',
			financial: 'Finances',
			financialservicesequity: 'Actions de services financiers',
			floatingrateloans: 'Prêts à taux variable',
			foreignlargeblend: 'Titres étrangers à grande capitalisation, mixte',
			foreignlargegrowth: 'Titres étrangers à grande capitalisation, croissance',
			foreignlargevalue: 'Titres étrangers à grande capitalisation, valeur',
			'foreignsmall/midblend': 'Titres étrangers à faible ou moyenne capitalisation, mixte',
			'foreignsmall/midgrowth': 'Titres étrangers à faible ou moyenne capitalisation, croissance',
			'foreignsmall/midvalue': 'Titres étrangers à faible ou moyenne capitalisation, valeur',
			geographicequity: 'Actions par régions',
			globalcorporatefixedincome: 'Titres à revenu fixe de sociétés mondiales',
			globalequity: 'Actions mondiales',
			globalequitybalanced: 'Équilibré actions mondiales',
			globalfixedincome: 'Titres à revenu fixe mondiaux',
			globalfixedincomebalanced: 'Équilibré titres à revenu fixe mondiaux',
			globalinfrastructureequity: 'Actions d’infrastructures mondiales',
			globalneutralbalanced: 'Équilibré neutre mondial',
			globalrealestate: 'Titres immobiliers mondiaux',
			'globalsmall/midcapequity': 'Actions mondiales à petite/moyenne capitalisation',
			greaterchinaequity: 'Actions de la Chine élargie',
			health: 'Santé',
			highDistributionEquityETFs: 'FNB d\'actions à distribution élevée',
			highDistributionEquityETFs_desc: 'FNB qui investissent dans des actions canadiennes produisant un rendement de distributions supérieur à la moyenne.',
			highyieldbond: 'Obligations à rendement élevé',
			highyieldfixedincome: 'Titres à revenu fixe à rendement élevé',
			highyieldmuni: 'Obligations municipales à rendement élevé',
			highYieldFixedIncomeETFs: 'FNB de titres à revenu fixe à rendement élevé',
			highYieldFixedIncomeETFs_desc: 'FNB qui investissent dans des titres à revenu fixe canadiens à rendement élevé.',
			indiaequity: 'Actions indiennes',
			'inflation-protectedbond': 'Obligations indexées sur l’inflation',
			infrastructure: 'Infrastructure',
			intermediatecorebond: 'Obligations de base à moyen terme',
			'intermediatecore-plusbond': 'Obligations de base plus à moyen terme',
			intermediategovernment: 'Obligations d’État à moyen terme',
			internationalequity: 'Actions internationales',
			internationalEquityETFs: 'FNB d\'actions internationales',
			internationalEquityETFs_desc: 'FNB qui investissent dans des placements en actions internationales.',
			japanstock: 'Actions japonaises',
			largeblend: 'Grande capitalisation, mixte',
			largegrowth: 'Grande capitalisation, croissance',
			largevalue: 'Grande capitalisation, valeur',
			latinamericastock: 'Actions latino-américaines',
			longgovernment: 'Obligations d’État à long terme',
			'long-shortcredit': 'Positions acheteur/vendeur sur titres de créance',
			'long-shortequity': 'Positions acheteur/vendeur sur actions',
			'long-shortbond': 'Positions acheteur/vendeur sur obligations',
			managedfutures: 'Contrats à terme gérés',
			marketneutral: 'Neutre au marché',
			'mid-capblend': 'Moyenne capitalisation, mixte',
			'mid-capgrowth': 'Moyenne capitalisation, croissance',
			'mid-capvalue': 'Moyenne capitalisation, valeur',
			'miscellaneous--incomeandrealproperty': 'Divers – revenu et biens immobiliers',
			'miscellaneous-incomeandrealproperty': 'Divers – revenu et biens immobiliers',
			'miscellaneous--other': 'Divers – autres',
			'miscellaneous-other': 'Divers – autres',
			miscellaneousregion: 'Régions diverses',
			miscellaneoussector: 'Secteurs divers',
			multialternative: 'À multiples placements non traditionnels',
			multicurrency: 'Multidevises',
			multisectorbond: 'Obligations multisectorielles',
			municalifornialong: 'Obligations municipales à long terme d’émetteurs californiens',
			muninationalinterm: 'Obligations municipales à moyen terme d’émetteurs nationaux',
			muninationallong: 'Obligations municipales à long terme d’émetteurs nationaux',
			muninationalshort: 'Obligations municipales à court terme d’émetteurs nationaux',
			muninewyorkintermediate: 'Obligations municipales à moyen terme d’émetteurs new-yorkais',
			muninewyorklong: 'Obligations municipales à long terme d’émetteurs new-yorkais',
			munitargetmaturity: 'Obligations municipales à échéance cible',
			naturalresources: 'Ressources naturelles',
			naturalresourcesequity: 'Actions liées aux ressources naturelles',
			nontraditionalbond: 'Obligations non traditionnelles',
			northamericanequity: 'Actions nord-américaines',
			'options-based': 'Fondées sur les options',
			'pacific/asiaex-japanstock': 'Actions du Pacifique et de l’Asie (hors Japon)',
			'pacific/asiaex-japanstk': 'Actions du Pacifique et de l’Asie (hors Japon)',
			'passiveinverse/leveraged': 'Gestion passive à rendement inverse ou à levier financier',
			preciousmetalsequity: 'Actions liées aux métaux précieux',
			preferredsharefixedincome: 'Actions privilégiées à revenu fixe',
			preferredstock: 'Actions privilégiées',
			realestateequity: 'Actions de sociétés immobilières',
			sectorequity: 'Actions sectorielles',
			shortgovernment: 'Obligations d’État à court terme',
			'short-termbond': 'Obligations à court terme',
			singlecurrency: 'Devise unique',
			smallblend: 'Faible capitalisation, mixte',
			smallgrowth: 'Faible capitalisation, croissance',
			smallvalue: 'Faible capitalisation, valeur',
			tacticalallocation: 'Répartition tactique de l’actif',
			tacticalbalanced: 'Équilibré tactique',
			targetmaturity: 'Échéance cible',
			'trading--inversecommodities': 'Marchandises négociées avec effet de levier inversé',
			'trading--inversedebt': 'Titres de créance négociés avec effet de levier inversé',
			'trading--inverseequity': 'Actions négociées avec effet de levier inversé',
			'trading--leveragedcommodities': 'Marchandises négociées avec effet de levier',
			'trading--leverageddebt': 'Titres de créance négociés avec effet de levier',
			'trading--leveragedequity': 'Actions négociées avec effet de levier',
			'trading--miscellaneous': 'Négociation – divers',
			ultrashortbond: 'Obligation à très court terme',
			'usdividend&incomeequity': 'Actions américaines à dividence et à revenu',
			usmoneymarket: 'Titres des marchés monétaires américains',
			'ussmall/midcapequity': 'Actions américaines à petite/moyenne capitalisation',
			worldallocation: 'Répartition mondiale',
			worldbond: 'Obligations mondiales',
			'worldbond-usdhedged': 'Obligations mondiales couvertes ($ US)',
			worldlargestock: 'Actions mondiales à forte capitalisation',
			'worldsmall/midstock': 'Actions mondiales à faible/moyenne capitalisation',
			year: 'an',
			yieldAndDate: 'yield & date',
			yourSelectionDidNotYieldResult: 'Votre recherche n’a donné aucun résultat. Veuillez réessayer avec un critère de recherche différent.',
			// end morningstar cateogires

			// Earnings Chart
			actualEarnings: 'Les bénéfices réels de   ',
			earningSurprise: 'a {surprise}  of ',
			priceEarnings1: 'Le prix {symbol}\'s est {priceDirection} ',
			priceEarnings2: ' to {finalPrice} two days after the',
			priceEarnings3: ' après l\'annonce du {date}.',
			earningEstimate1: 'The consensus long term growth estimate is ',
			earningEstimate2: ' with a high estimate of ',
			earningEstimate3: ' and a low estimate of ',
			earningEstimate4: ' with {numOfAnalysts} analysts reporting.',
			rose: 'est monté de',
			fell: 'est tombé de',
			positiveSurprise: ' correspondent à une hausse de ',
			negativeSurprise: ' correspondent à une baisse de ',
			earningsAnnouncement: 'La prochaine annonce de bénéfices de {symbol} n\'est pas encore publié(e).',
			exceededExp: 'supérieur aux prévisions',
			missedExp: 'inférieur aux prévisions',
			stockEarningsChartTooltip: 'Sélectionnez un trimestre ou une année pour en savoir plus',
			estimatesLabel: 'Prévisions',
			qtrEarningsChartCaption: 'Estimations  pour l’{quarter} exercice {year}',
			yrEarningsChartCaption: 'Estimations  pour l’exercice {year}',
			earningsChartCaptionSr: 'Données de l’{quarter} exercice {year} selon la sélection du tableau précédent',

			// Analyst Recommendations Ratings
			strongBuy: 'Achat solide',
			'Strong Buy': 'Achat solide',
			buy: 'Achat',
			Buy: 'Achat',
			hold: 'Retenue',
			Hold: 'Retenue',
			sell: 'Vente',
			Sell: 'Vente',
			strongSell: 'Vente forte',
			'Strong Sell': 'Vente forte',
			Underperform: 'Rendement inférieur',
			upwardTrend: 'tendance à la hausse',
			downwardTrend: 'tendance à la baisse',
			unchangedTrend: 'inchangé',
			analystRatingText1: 'C\'est une {trendText} depuis la dernière éstimation de ',
			analystRatingText2: '. Au ',
			noAnaylystOpinions: 'Il n\'y a pas d\'opinion d\'analyste disponible pour la période demandée.',

			// Dividend Information (Distribution Information)
			latestXdDate: 'Date ex-dividende la plus récente',
			dividendTaxationCode: 'Code d\'imposition des dividendes',
			distributionInformationMF: 'Renseignements sur les distributions',
			noDistributionInformation: 'Aucun renseignement disponible sur les distributions.',
			updatedAsOf: 'Mise à jour le',

			// Lipper Leader Ratings
			lipperLeaderRatings: 'Cotes Lipper Leaders',
			lipperLeaderTooltip: 'Les cotes de rendement total reflètent le rendement historique total du fonds par rapport aux pairs. Les cotes de rendement constant reflètent les rendements ajustés au risque antérieurs du fonds par rapport aux pairs. Les notes Lipper pour la préservation du capital sont relatives plutôt qu’absolues. Les cotes Lipper Leader NE TIENNENT PAS COMPTE des répercussions des frais d’acquisition. Les notes globales sont fondées sur une moyenne équipondérée des rangs centiles pour chaque mesure sur des périodes de 3, 5 et 10 ans (s’il y a lieu).',
			overallRating: 'Classement globale',
			overallRatingDescription: 'Le système de notation exclusif de Morningstar classifie les FCB en fonction de leur rendement ajusté au risque. La cote globale correspond à la moyenne pondérée des cotes du FCB sur trois, cinq et dix ans. Les FCB sont notés au moyen d\'un système d\'étoiles où un classement de 5 étoiles correspond à la cote maximale et un classement de 1 étoile, à la cote la plus faible.',
			'3YearRating': 'Classement 3 ans',
			'5YearRating': 'Classement 5 ans',
			'10YearRating': 'Classement 10 ans',
			totalReturn: 'Total rendement',
			consistentReturn: 'Constant rendement',
			preservation: 'Protection du capital',
			lipperLeaderKey: 'Clé de notation Lipper Leader',
			lipperLogoAlt: 'Les notes Leader Lipper de Refinitiv sont sur une échelle de 5, 5 étant la note la plus élevée, et 1, la plus faible.',
			lipperLeaderContent1: 'Le système d\'évaluation Lipper Leader peut vous aider à choisir les fonds qui correspondent à votre style et à vos objectifs de gestion.',
			lipperLeaderContent2: 'Ce système applique vos critères pour vous donner une description simple et claire de la réalisation de certains objectifs par un fonds, comme la protection du capital ou son accroissement au moyen de rendements solides et constants.',
			lipperLeaderCaption: 'Les notes Leader Lipper sont sur une échelle de 5, 5 étant la note la plus élevée, et 1, la plus faible.',

			// Fund Distribution Chart
			incomeDistributionbyDollar: 'Revenu de distributions par dollar',
			sumofCalendarYear: 'Total des années civiles {year1} to {year2}',
			fundUpdateDisclaimer: 'Les données sur le rendement du fonds et de l\'indice (à moins d\'avis contraire) ont été mises à jour le {updateDate}. Les données sur la valeur du fonds (actif net total) ont été mises à jour le {updateDate}. Toutes les autres données, y compris celles sur les valeurs Lipper, ont été mises à jour le {updateDate} sauf avis contraire.',

			// Compare ETF section
			compareEtf: 'Comparer les FNB',
			findAndCompare: 'Trouver et comparer jusqu’à 4 FNB',
			'52-WeekRange': 'Période de 52 semaines',
			avg10Day: 'Volume moyen (10 jours)',
			etfObjective: 'Objectif de placement ',
			annualReturns: 'Rendements annualisés',
			calendarYearReturnsSr: 'Calendar year returns data to follow',
			annualReturnsSr: 'Annualized returns data to follow',
			etfSimilarTo: 'ETFs similar to ',
			'1yearRisk': 'Cote de risque 1 an',
			'1yearRiskSr': 'Les données sur le risque sur un an suivront',
			'3yrRisk': 'Cote de risque 3 ans',
			'3yearRiskSr': 'Les données sur le risque sur trois ans suivront',
			'5yrRisk': 'Cote de risque 5 ans',
			'5yearRiskSr': 'Les données sur le risque sur cinq ans suivront',
			'10yrRisk': 'Cote de risque 10 ans',
			'10yearRiskSr': 'Les données sur le risque sur dix ans suivront',
			Alpha: 'Alpha',
			Beta: 'Bêta',
			RSquared: 'R au carré',
			premiumDiscount3Month: 'À prime / à escompte (3 mois)',
			numOfDaysPremium: 'No. de jours avec prime',
			numOfDaysNAV: 'No. de jours à la valeur liquidative',
			numOfDaysDiscount: 'No. de jours avec escompte',
			numOfDays5Pct: 'No. de jours dans une fourchette variant de 0,5 % par rapport à la valeur liquidative',
			overAll: 'Globale',
			sector: 'Secteur',
			sectorBtnSr: 'Sector data to follow',
			assetAllocSr: 'Asset allocation data to follow',
			globalExposureSr: 'Global exposure data to follow',
			sectorSr: 'Sector data to follow',
			holdingsSr: 'Holdings data to follow',
			findSimilarEtfs: 'Trouver des FNB semblables',
			similarEtfTooltipIntro: 'Pour trouver des FNB semblables, nous commençons par repérer les FNB de la même catégorie Morningstar.  Nous créons ensuite une note totale pour chacun d’eux en accordant la priorité (dans l’ordre) aux éléments suivants:',
			similarEtfTooltipPoint1: '1) Le R au carré sur 3 ans se rapprochant le plus du principal indice Morningstar',
			similarEtfTooltipPoint2: '2) Le ratio Sharpe le plus élevé',
			similarEtfTooltipPoint3: '3) Le ratio des frais le plus faible',
			similarEtfTooltipConclusion: 'Les FNB ayant la meilleure note totale sont considérés comme les plus semblables.',
			overallRatingSr: 'Les données globales sur la cote Morningstar suivront',
			'3yearRatingSr': 'Les données sur la cote Morningstar sur trois ans suivront',
			'5yearRatingSr': 'Les données sur la cote Morningstar sur cinq ans suivront',
			'10yearRatingSr': 'Les données sur la cote Morningstar sur dix ans suivront',
			noDataForETF: 'Pas de données disponibles pour ce FNB.',
			topSectors: 'Meilleurs secteurs',
			canadian: 'Canadien',
			unitedStates: 'États Unis',
			MSDRegionUnitedStates: 'Région États Unis',
			compareWarningText: 'Vous avez déjà sélectionné des FNB dans l’onglet Comparaison. Sélectionnez OK pour remplacer tous les FNB actuellement sélectionnés dans l’onglet Comparaison par les FNB que vous avez sélectionnés à la page actuelle. Sélectionnez Annuler pour retourner à la page actuelle.',

			// Alerts
			manageAlerts: 'Manage alerts',
			marketAlert: 'Alerte liée aux marchés',
			marketAlerts: 'Alertes liées aux marchés',
			marketAlertsDescription: 'Les alertes liées aux marchés fournissent des mises à jour définies par l’utilisateur qui sont envoyées à des moments établis ou en fonction d’événements déclencheurs précis.',
			modifyMarketAlerts: 'Modifier les alertes liées aux marchés',
			exchange: 'Bourse',
			exchangeTradedFunds: 'Fonds négociés en bourse (FNB)',
			dailyListOfMostActiveStocks: 'Liste quotidienne des actions les plus actives',
			toBeSentAt: 'À envoyer',
			percentage: 'Pourcentage',
			gainers: 'Plus performants',
			losers: 'Moins performants',
			gainersAndLosers: 'Plus performants et moins performants',
			timeframe1024: 'À l’ouverture des marchés, de 10 h à 11 h (HNE)',
			timeframe4096: 'En milieu de journée, de 12 h à 13 h (HNE)',
			timeframe65536: 'Après la clôture des marchés, de 16 h à 17 h (HNE)',
			dailyListOf52week: 'Liste quotidienne des nouveaux cours maximum et minimum sur 52 semaines',
			new52week: 'Nouveaux cours sur 52 semaines',
			highs: 'Maximum',
			lows: 'Minimum',
			highsAndLows: 'Maximum et minimum',
			highAndLow: 'Maximum et minimum',
			dailyListOfVolumeChanges: 'Liste quotidienne des variations de volume',
			stocksWithTheLargest: 'Actions ayant enregistré leur plus forte',
			increases: 'Augmentation',
			decreases: 'Diminution',
			increasesAndDecreases: 'Augmentation et diminution',
			dailyListOfGaps: 'Liste quotidienne des écarts',
			stocksThatOpenedAtLeast: 'Actions ayant ouvert d’au moins 1 %',
			higher: 'À la hausse',
			lower: 'À la baisse',
			higherAndLower: 'À la hausse et à la baisse ',
			dailyListOfBreakouts: 'Liste quotidienne des cassures',
			stocksWhose: 'Actions dont',
			lowPriceAboveYesterdaysHigh: 'Le faible cours est supérieur au cours maximum d’hier ',
			highPriceAboveYesterdaysLow: 'Le cours élevé est supérieur au cours minimum d’hier ',
			lowHighPriceAboveBelowYesterdaysHighLow: 'Le cours faible/élevé est supérieur/inférieur au cours maximum/minimum d’hier ',
			dailyListOfStockSplits: 'Liste quotidienne des fractionnements d’actions',
			pleaseNoteAlert: 'Veuillez noter ',
			noEmailProvidedModal: 'Aucune adresse de courriel n’a été fournie pour recevoir des alertes. Les alertes créées ne seront pas envoyées.',
			noEmailProvidedModalPrefs: 'Vous avez retiré toutes les adresses de courriel pour recevoir des alertes. Les alertes enregistrées ne seront pas supprimées, mais ne pourront pas être envoyées tant qu’au moins une adresse de courriel n’aura pas été fournie.',
			setEmailAlerts: 'Configurer des alertes par courriel',
			tsx: 'TSX',
			tsxVenture: 'Bourse de croissance TSX',
			nyse: 'NYSE',
			nasdaq: 'NASDAQ',
			amex: 'AMEX',
			addAlert: 'Ajouter une alerte',
			stockAlerts: 'Alertes liées aux actions',
			priceAndVolumeAlerts: 'Alertes liées aux cours et aux volumes',
			stockAlertsFor: 'Alertes liées aux actions pour {symbol}',
			priceDropsBelow: 'Le cours chute sous',
			orRisesAbove: 'ou augmente au-dessus de',
			priceDropPoint: 'Point de chute du cours',
			priceRisePoint: 'Point de hausse du cours',
			priceGap: 'Écart de cours',
			atOpeningTrade: 'à l’ouverture',
			dropDownOptions: 'Options déroulantes :',
			gapUp: 'En hausse',
			gapDown: 'En baisse',
			lastPriceAlert: 'Dernier cours :',
			peRatioAlert: 'Ratio C/B',
			peDropPoint: 'Point de chute du ratio C/B ',
			peRisePoint: 'Point de hausse du ratio C/B',
			reachesANew52Weekp1: 'Atteint un nouveau cours',
			reachesANew52Weekp2: 'sur 52 semaines.',
			symbolIsOfIts52WeekHighOfOn: '{symbol} est à {percent} de son cours maximum sur 52 semaines, qui était de {high52Week} le {date}',
			'52WeekValue': 'Cours sur 52 semaines ',
			priceChange1Week: 'Variation du cours sur 1 semaine',
			priceChange4Week: 'Variation du cours sur 1 mois',
			priceChanges: 'Variation du cours de',
			fromPreviousClose: 'depuis la clôture précédente',
			closeOnDate: '({previousClose} le {date}) ',
			priceChangeValue: 'Valeur de la variation du cours',
			crossed: 'est passé',
			crossesIts: 'S’intersecte avec sa moyenne mobile sur',
			movingAverage: '',
			crossesItsMovingAverage: 'Crosses its {30 day} moving average',
			movingAverageValue: 'Valeur de la moyenne mobile ',
			'15day': '15 jours',
			'30day': '30 jours',
			'60day': '60 jours',
			changesAlert: 'Variations de',
			fasterThanThe: 'plus rapides que celles de',
			overTheLast: 'au cours des derniers',
			changeValue: 'Change value',
			marketAlertLabel: 'Marché',
			spTsxComposite: 'Indice composé S&P/TSX',
			djia: 'DJIA',
			changePeriod: 'Période de variation',
			tradingVolumeExceeds10DayAverageBy: 'Le volume d’opérations excède de',
			tradingVolumeExceeds10DayAverageBy2: 'la moyenne sur 10 jours',
			rsiOverBoughtSold: 'L’IFR indique un surachat ou une survente',
			rsiUnderBoughtSold: 'L’IFR indique un sous-achat ou une sous-vente',
			stockSplitAnnounced: 'Annonce de fractionnement d’actions',
			earningEstimates: 'Estimations des bénéfices',
			quarterlyEarningsEstimatesAnnounced: 'Annonce des estimations des bénéfices trimestriels',
			annualEarningsEstimatesAnnounced: 'Annonce des estimations des bénéfices annuels',
			mostRecentDate: '(Plus récente : {date}) ',
			newsAlerts: 'Nouvelles alertes',
			newsStoriesFromReuters: 'Nouvelles de Reuters',
			inDepthStories: '(Récits détaillés, nouvelles de dernière heure et analyses par courriel)',
			analystReportsAlerts: 'Alertes liées aux rapports d’analystes',
			analystReportsForAllProviders: 'Rapports d’analystes de tous les fournisseurs',
			companyBasedAnalystSearch: '(Recherche analytique en entreprise)',
			pleaseSetTheLowPrice: 'Laissez le faible cours sous le cours actuel. ',
			pleaseSetTheHighPrice: 'Établissez le cours élevé au-dessus du cours actuel. ',
			pleaseSetTheLowPe: 'Établissez le champ du faible ratio C/B sous le ratio C/B actuel. ',
			pleaseSetTheHighPe: 'Établissez le champ du ratio C/B élevé au-dessus du ratio C/B actuel.',
			pleaseInputValues: 'Entrez les valeurs de l’alerte sélectionnée. ',
			editMarketAlert: 'Modifier {name} dans markets tab',
			editStockAlert: 'Modifier {name} dans stocks tab',
			deleteAlert: 'Supprimer {name}',
			peDropsBelow: 'Le ratio C/B chute sous',
			inputValueToContinue: 'Entrez les valeurs de l’alerte sélectionnée.',

			// Market alert descriptions
			alert_52: 'Liste quotidienne des actions plus performants les plus actives',
			alert_53: 'Liste quotidienne des actions moins performants les plus actives',
			alert_56: 'Liste quotidienne des actions plus performants et moins performants les plus actives',
			alert_57: 'Liste quotidienne des nouveaux cours maximum sur 52 semaines',
			alert_58: 'Liste quotidienne des nouveaux cours minimum sur 52 semaines',
			alert_65: 'Liste quotidienne des nouveaux cours maximum et minimum sur 52 semaines',
			alert_66: 'Liste quotidienne des augmentation de volume',
			alert_86: 'Liste quotidienne des diminution de volume',
			alert_87: 'Liste quotidienne des augmentation et diminution de volume',
			alert_88: 'Liste quotidienne des écarts (À la hausse)',
			alert_89: 'Liste quotidienne des écarts (À la baisse)',
			alert_90: 'Liste quotidienne des écarts (À la hausse et à la baisse)',
			alert_91: 'Liste quotidienne des cassures (Le faible cours est supérieur au cours maximum d’hier)',
			alert_92: 'Liste quotidienne des cassures (Le cours élevé est supérieur au cours minimum d’hier)',
			alert_93: 'Liste quotidienne des cassures (Le cours faible/élevé est supérieur/inférieur au cours maximum/minimum d’hier)',
			alert_289: 'Liste quotidienne des fractionnements d’actions',

			// Stock alert descriptions
			alert_1: 'Le cours descend au-dessous la {price} en dollars',
			alert_2: 'Le cours s\'élève au-dessus la {price} en dollars',
			alert_3: 'Atteint un nouveau cours haut sur 52 semaines.',
			alert_4: 'Atteint un nouveau cours bas sur 52 semaines.',
			alert_5: 'Atteint un nouveau cours maximum et minimum sur 52 semaines.',
			alert_6: 'Variation du cours à la clôture précédente +/- {pricePercentChange}',
			alert_49: 'Le ratio C/B chute sous {peLimit}',
			alert_50: 'Le ratio C/B de hausse {peLimit}',
			alert_168: 'Nouvelles de Reuters',
			alert_201: 'L’IFR indique un surachat ou une survente',
			alert_202: 'L’IFR indique un sous-achat ou une sous-vente',
			alert_309: 'Estimations des bénéfices (annuellement)',
			alert_310: 'Estimations des bénéfices (trimestriellement)',
			alert_311: 'Earnings Estimates (Quarterly or Annual)',
			alert_376: 'Annonce du fractionnement d’actions',
			alert_381: 'Cours supérieurs à la moyenne mobile',
			alert_430: 'Le volume d’opérations excède de {percent} % la moyenne sur 10 jours',
			alert_434: 'Écart de cours en baisse à l’ouverture',
			alert_435: 'Écart de cours en hausse à l’ouverture',

			alert_436: 'Le cours a augmenté ou baissé {percentChange} % plus rapidement que le {index} au cours des {days} derniers jours',

			// Upgrades & Downgrades
			upgrades: 'Surclassements',
			upgradesSr: 'Afficher les surclassements dans le tableau correspondant',
			upgradesAndDowngrades: 'Surclassements et déclassements',
			downgrades: 'Déclassements',
			downgradesSr: 'Afficher les déclassements dans le tableau correspondant',
			numOfUpgrades: '# de surclassements',
			numOfDowngrades: '# de déclassements',
			numOfTotalViews: '# total de points de vue',
			pctUpgrades: '% de surclassements',
			pctDowngrades: '% de déclassements',
			consensusRating: 'Cote consensuelle',
			analystsViews: 'Points de vue des analystes (dans les 4 dernières semaines)',
			upgradesBtnSr: 'Afficher les surclassements dans le tableau correspondant',
			downgradesBtnSr: 'Afficher les déclassements dans le tableau correspondant',

			// Screener
			selectAllSr: 'Sélectionner l’ensemble pour inclure tous les critères de la liste correspondante.',
			selectCustomSr: 'Sélectionner l’option de personnalisation pour choisir vos critères de la liste correspondante.',
			selectAllSrIndustry: 'Sélectionner l’ensemble pour inclure tous les critères de la liste correspondante. Les critères de l’industrie sont fondés sur la sélection précédente des critères du secteur',
			holdControlKeyToSelect: 'Maintenir la touche Ctrl enfoncée pour sélectionner plusieurs options.',
			MSDDomicileCountry: 'Pays',
			MSDCIBCETFType: 'Type de fonds',
			MSDMorningstarCategoryName: 'Catégorie de fonds',
			canadaEquity: 'Actions canadiennes',
			includeStandardETFs: 'Inclure les FNB standard',
			includeLeveragedETFs: 'Inclure les FNB à effet de levier ',
			includeInverseETFs: 'Inclure les FNB à effet de levier inverse',
			custom: 'Personnaliser',
			criteria: 'Critères',
			eventDate: 'Date de l’activité',
			STXOverSMA15SMA50: 'Croisement MM (15-day SMA/50-day SMA)',
			movingAverageOver: 'Croisement MM ({over}-day {overType}/ {under}-day {underType})',
			movingAverageOverNone: 'Croisement MM',
			addCriteriaToStart: 'Ajoutez les critères ci-dessus pour démarrer une recherche personnalisée sur les FNB.',
			minimizeCriteria: 'Réduire la sélection de critères',
			expandCriteria: 'Réduire la sélection de critères',
			criteriaMatches: 'Résultats correspondant aux critères',
			screenMatches: 'Sélections correspondantes',
			removeCriteriaCategory: 'Supprimer la catégorie de critères',
			resetCriteria: 'Réinitialiser le critère',
			resetAllCriteria: 'Réinitialiser tous les critères',
			addCriteriaToStartACustomSearch: 'Ajoutez les critères ci-dessus pour démarrer une recherche personnalisée sur les FNB.',
			showCriteriaMatchesToolTip: '<p><strong>Afficher les résultats correspondant aux critères</strong></p><p>Lorsque vous sélectionnez une option dans le menu déroulant pour un critère précis, la colonne<strong>Afficher les résultats correspondant aux critères</strong> présentera uniquement le nombre de FNB qui répondent aux critères de cette sélection. Les autres critères que vous sélectionnez n’auront aucune incidence sur le nombre affiché.</p><p>La colonne <strong>Sélections correspondantes</strong> affiche les résultats cumulatifs pour tous les critères sélectionnés.</p>',
			bottom20: '20 % les plus faibles',
			belowAvg20: '20 % inférieurs à la moyenne',
			avg20: '20 % conformes à la moyenne',
			aboveAvg20: '20 % supérieurs à la moyenne',
			top20: '20 % les plus élevés',
			from: 'Évaluation précédente',
			currentlyAbove52WeekHigh: 'Se négocie actuellement à un cours supérieur au dernier sommet de 52 semaines',
			new52WeekLow: 'Se négocie actuellement à un cours inférieur au dernier bas de 52 semaines',
			greaterThanEqualTo: 'Égaux ou supérieurs à',
			greaterThanSymbolVariable: '> {number}',
			greaterThanEqualToSymbolVariable: '>= {number}',
			lessThanEqualTo: 'Égaux ou inférieurs à',
			lessThanSymbolVariable: '< {number}',
			between: 'Se situent entre',
			and: ' et ',
			specificRange: 'Fourchette précise: ',
			specificRangeSRPerformance: 'Sélectionnez le bouton d’option Fourchette de base pour choisir des valeurs, ou le bouton d’option Fourchette précise pour entrer une ou plusieurs valeurs.',
			nonNumericError: 'Veuillez entrer des caractères numériques seulement.',
			fundStructureTooltip: '<p><strong>Structure du fonds</strong><br/>Un FNB peut être classé comme un FNB standard, à effet de levier ou à effet de levier inverse.</p><p><strong>FNB à effet de levier</strong><br/>Les FNB à effet de levier utilisent quotidiennement des instruments dérivés pour amplifier les rendements d’un indice sous-jacent ou d’un panier de titres. Ces FNB doivent être négociés avec prudence et sont destinés aux négociateurs avancés ayant une compréhension approfondie des risques qui y sont associés.</p><p><strong>FNB à effet de levier inverse</strong><br/>Les FNB à effet de levier inverse sont conçus pour tirer parti des fluctuations des cours qui s’opposent à celles de l’indice sous-jacent ou du panier de titres.</p>',
			exchangeCountryTooltip: 'Toutes les valeurs en dollars liées aux critères sont traitées à l’aide de la devise du pays sélectionné.',
			removeSymbol: 'Remove {symbol} from criteria',
			removeAllCriteria: 'Effacer les critères',
			globalHoldingSR: 'Sélectionnez une région, puis le bouton d’option Fourchette de base pour choisir des valeurs ou le bouton d’option Fourchette précise pour entrer une ou plusieurs valeurs.',
			region: 'Région',
			asia: 'Asie',
			MSDRegionAsia: 'Région Asie',
			europe: 'Europe',
			MSDRegionEurope: 'Région Europe',
			yourResults: 'Vos résultats',
			standard: 'Standard',
			leveraged: 'Leveraged',
			inverse: 'Inverse',
			bullish: 'Baissier',
			bearish: 'Haussier',
			asOfDatPreviousDayMonthEnd: 'Données du marché établies à la clôture du marché le jour ouvrable précédent. Toutes les autres données sont établies à la fin du mois précédent',
			startACustomScreenWithTheseCriteria: 'Créez une sélection personnalisée selon ce critère',
			saveScreen: 'Enregistrer la sélection',
			saveAsNewScreen: 'Enregistrer comme nouvelle sélection',
			saveScreener: 'Enregistrer la sélection',
			enterScreenName: 'Entrer le nom de la sélection',
			nameFieldMustBe3Characters: 'Le champ Nom de la sélection doit contenir au moins trois caractères.',
			nameMustNotbeMoreThan22: 'Le nom de la sélection ne doit pas contenir plus de 22 caractères.',
			descriptionLongerThan100: 'La description ne doit pas contenir plus de 100 caractères.',
			deleteScreen: 'Supprimer la sélection',
			doYouWantToDeleteScreen: 'Voulez-vous supprimer cette sélection?',
			thisNameAlreadyExists: 'Ce nom existe déjà dans vos sélections enregistrées.',
			overYourSavedSCreenLimit: 'Vous avez dépassé la limite d’enregistrement des sélections. Supprimer une sélection enregistrée avant d’effectuer un nouvel enregistrement.',
			compareSelectedEtfs: 'Comparez les FNB que vous avez sélectionnés dans le tableau correspondant.',
			error: 'Erreur',
			STRSIPeriod2: '2 jours IFR',
			STRSIPeriod14: '14 jours IFR',
			STRSIPeriod20: '20 jours IFR',
			reviewFundSnapshot: 'Passer en revue l’instantané ({symbol})',
			pleaseSelectCompareSymbols: 'Veuillez sélectionner les symboles à comparer.',
			youCanOnlySelect4: 'Vous ne pouvez sélectionner que 4 symboles à comparer.',
			preScreeners: 'Présélections',
			savedScreeners: 'Sélections enregistrées',
			reviewScreen: 'Examiner',
			saveSearch: 'Sauvegarder la recherche',
			saveSearchSrOnly: 'Enregistrer la recherche dans une nouvelle boîte de dialogue.',
			stockScreenMatches: '{results} correspondances',
			sectorCurrency: 'Secteur/devise',
			yearChange: 'Variation sur 1 an',
			stockCriteria: 'Critères relatifs aux actions',
			expandStockCriteria: 'Élargir les critères des types de fonds',
			minimizeStockCriteria: 'Réduire les critères des types de fonds',
			canadaLowPEStocks_desc: 'Actions canadiennes à faibles ratios cours/bénéfice (ratio C/B)',
			usLowPEStocks_desc: 'États-Unis – actions à faible ratio C/B',
			canadaValueStocks_desc: 'Actions canadiennes à grande capitalisation avec un rendement des capitaux propres élevé ',
			usValueStocks_desc: 'Actions américaines à grande capitalisation avec un rendement des capitaux propres élevé ',
			cumulativeMatches: 'Correspondances cumulatives:',
			payoutRatioPercent: 'Ratio dividendes/bénéfice (%)',
			dividendYieldPercent: 'Rendement en dividendes (%)',
			priceEarningRatio: 'Ratio cours/bénéfice',
			growthRate5yearEps: 'Taux de croissance/BPA sur 5 ans',
			revenueMillions: 'Revenus (M$)',
			roePercent: 'RCP (%)',
			roaPercent: 'RA (%)',
			netProfitMargin: 'Marge bénéficiaire nette',
			greaterThan: 'Plus de',
			lessThan: 'Moins de',
			minimizeFundamentals: 'Réduire au minimum les critères relatifs aux données fondamentales',
			expandFundamentals: 'Élargir les critères relatifs aux données fondamentales',
			reutersConsensusEstimates: 'Consensus et prévisions I/B/E/S de Refinitiv',
			minimizeReutersConsensusEstimates: 'Réduire au minimum les critères I/B/E/S de Refinitiv',
			expandReutersConsensusEstimates: 'Élargir les critères I/B/E/S de Refinitiv',
			recommendations: 'Recommandations',
			annualRevenueInMillions: 'Revenus annuels (M$)',
			twoYearSingularEnglishOnly: '2 ans',
			threeYearSingularEnglishOnly: '3 ans',
			fiveYearSingularEnglishOnly: '5 ans',
			tenYearSingularEnglishOnly: '10 ans',
			annualEarningsPerShare: 'Résultat annuel par action ($)',
			quarterlyRevenue: 'Revenus trimestriels (M$)',
			quarterlyEarningsPerShare: 'Résultat trimestriel par action ($)',
			'1qtr': '1 trim',
			'2qtr': '2 trim',
			'3qtr': '3 trim',
			'5qtr': '5 trim',
			'10qtr': '10 trim',

			// Keywords search tooltip content
			keywordSearchHeading: 'Recherche améliorée de mots clés prédéterminés',
			keywordSearchDescription: 'À la page Recherche avancée, les utilisateurs peuvent désormais filtrer la recherche de mots clés en sélectionnant un bouton d’option pour trouver l’ "Expression exacte", "Tous les mots clés" ou "N’importe quel mot clé".',
			keywordSearchP1: 'La recherche par <strong>"Expression exacte"</strong> exécute la recherche de tous les mots qui se trouvent en ordre consécutif. Par exemple, la recherche de <i>nouvelle économie</i>, l’aide du bouton <strong>Expression exacte</strong>, ne donnera que des résultats contenant <strong>l’expression</strong> <i>nouvelle économie</i>',
			keywordSearchP2: 'La recherche par <strong>"Tous les mots clés"</strong> exécute la recherche dans les documents qui contiennent tous les mots clés, mais pas nécessairement en ordre consécutif. Ainsi, une recherche de nouvelle économie l’aide du bouton Tous les mots clés donnera des résultats dans lesquels les deux mots nouveau et économie se trouvent n’importe où dans le document.',
			keywordSearchP3: 'La recherche l’aide du bouton <strong>"N’importe quel mot clé"</strong> exécute une recherche de documents qui contiennent n’importe quel mot clé inclus dans la recherche par mots clés. Ainsi, une recherche de <i>nouvelle économie</i> l’aide du bouton <strong>N’importe quel mot clé</strong> donnera des résultats dans lesquels le mot <i>nouvelle</i> ou le mot économie se trouve n’importe où dans le document.',
			keywordSearchP4: 'En sélectionnant le bouton "Manchettes seulement", vous pouvez limiter n’importe laquelle de ces recherches aux manchettes des documents seulement.',
			keywordSearchP5: 'Fonctionnement de ces recherches prédéfinies :',
			keywordSearchTable1H1: 'Critère des mot clés',
			keywordSearchTable1H2: 'Recherches',
			keywordSearchTable1H3: 'Résultats',
			keywordSearchTable1R1C1: 'Expression exacte',
			keywordSearchTable1R1C2: 'Nouvelle économie',
			keywordSearchTable1R1C3: 'Seulement les documents contenant l’expression nouvelle <i>écononomie</i>',
			keywordSearchTable1R2C1: 'Tous les mots clés',
			keywordSearchTable1R2C3: 'Seulement les documents contenant le terme <i>nouveau</i> <strong>et</strong> le terme <i>économie</i>',
			keywordSearchTable1R3C1: 'N’importe quel mot clé',
			keywordSearchTable1R3C3: 'Seulement les documents contenant <strong>soit</strong> <i>nouveau</i> <strong>soit</strong> <i>économie</i>',
			additionalKeywordSearchHeading: 'Autres exemples de recherche par mots clés',
			additionalKeywordSearchDescription: 'Pour mieux préciser la recherche de mots clés, qui n’est pas fournie avec l’interface standard, les utilisateurs peuvent rechercher des combinaisons de mots ou d’expressions en ajoutant des opérateurs logiques booléens. Par exemple, si vous recherchez des documents contenant l’expression "rapport annuel" et "bilan", vous pouvez taper:',
			additionalKeywordSearchP1: '"rapport annuel" & "bilan"',
			additionalKeywordSearchP2: 'dans le champ de la recherche par mots clés. Remarquez les guillemets. Ils indiquent que l’expression entière constitue l’objet de la recherche, non les mots séparément. Les recherches de',
			additionalKeywordSearchP3: 'pince papiers',
			additionalKeywordSearchP4: 'et',
			additionalKeywordSearchP5: '"pince papiers"',
			additionalKeywordSearchP6: 'donnent des résultats différents. La première demande renvoie des documents contenant soit le mot "papiers", soit le mot "pince". La seconde demande ne renvoie que les documents contenant l’expression "pince papiers".',
			additionalKeywordSearchP7: 'Exemples d’élaboration de recherches plus complexes :',
			keywordSearchTable2H1: 'En tapant...',
			keywordSearchTable2H2: 'On obtient les documents contenant...',
			keywordSearchTable2R1C1: 'DEC',
			keywordSearchTable2R1C2: '...DEC, dec, Dec, etc.',
			keywordSearchTable2R2C1_1: 'DEC & IBM',
			keywordSearchTable2R2C1_2: 'ou',
			keywordSearchTable2R2C1_3: 'DEC et IBM',
			keywordSearchTable2R2C2: '...la fois DEC et IBM',
			keywordSearchTable2R3C1_1: 'DEC | IBM',
			keywordSearchTable2R3C1_2: 'ou',
			keywordSearchTable2R3C1_3: 'DEC ou IBM',
			keywordSearchTable2R3C2: '...soit DEC soit IBM.',
			keywordSearchTable2R4C1: 'DEC IBM',
			keywordSearchTable2R4C2: '...soit DEC soit IBM.',
			keywordSearchTable2R5C1: 'DEC & IBM | GE',
			keywordSearchTable2R5C2: '...soit DEC et IBM, soit GE.',
			keywordSearchTable2R6C1: 'DEC & ( IBM | GE )',
			keywordSearchTable2R6C2: '... la fois DEC et soit IBM soit GE.',
			keywordSearchTable2R7C1: '"rapport annuel" ',
			keywordSearchTable2R7C2: '...l’expression "rapport annuel".',
			keywordSearchTable2R8C1: 'rapport annuel',
			keywordSearchTable2R8C2: '...soit "rapport", soit "annuel".',
			keywordSearchTable2R9C1: 'logiciel & "rapports annuels"',
			keywordSearchTable2R9C2: '...le mot logiciel et l’expression rapports annuels.',
			keywordSearchTable2R10C1: 'logiciel & ^ chiffrier',
			keywordSearchTable2R10C2: '...logiciel, mais non chiffrier.',
			keywordSearchTable2R11C1: 'Java &^ (cafe | Indonesie)',
			keywordSearchTable2R11C2: '...java, mais non café ni Indonésie.',
			keywordSearchTable2R12C1: 'Coca-Cola NEAR/5 PepsiCo',
			keywordSearchTable2R12C2: '...Coca-Cola moins de 5 caractères de PepsiCo.',
			keywordSearchTable2R13C1: 'Coca-Cola NEAR PepsiCo. (Remarque : sans un nombre de caractères, le nombre de caractères par défaut sera 64). ',
			keywordSearchTable2R13C2: '...Coca-Cola adjacent PepsiCo.',
			keywordSearchTable2R14C1: 'mer*',
			keywordSearchTable2R14C2: '...mer, Merrill, merry, etc. L’astérisque remplace n’importe quel nombre de lettres la fin du mot.',
			keywordSearchTable2R15C1: 'm?cro',
			keywordSearchTable2R15C2: '...micro, macro, m3cro, etc. Le "?" remplace n’importe quelle lettre ou n’importe quel chiffre.',
			keywordSearchTable2R16C1: 'Jane\'s >',
			keywordSearchTable2R16C2: '...Jane’s. L’apostrophe n’est pas considérée comme un guillemet simple.',
			keywordSearchTable2R17C1: '"nouvelle de la journée"',
			keywordSearchTable2R17C2: '...les expressions "nouvelle & journée", "nouvelle @ journée" "nouvelle journée". Les caractères spéciaux (pas les lettres et les chiffres) sont considérés comme étant interchangeables.',
			keywordSearchTable2R18C1: '"nouvelle & journée"',
			keywordSearchTable2R18C2: '...les expressions "nouvelle & journée", "nouvelle @ journée" "nouvelle journée". Les caractères spéciaux (pas les lettres et les chiffres) sont considérés comme étant interchangeables.',

			year1: '1 an',
			twoYears: '2 ans ',
			// -- START Industries --
			Advertising: 'La publicité',
			Aerospace: 'Aérospatial',
			'Aerospace & Defense': 'Aérospatiale et défense',
			'Agricultural Crop Production': 'Crop production agricole',
			'Agricultural Livestock': 'Élevage agricole',
			Agriculture: 'Agriculture',
			'Agricultural Chemicals': 'Produits chimiques d’usage agricole',
			'Airlines/Air Freight': 'Compagnies aériennes et fret aérien',
			aluminum: 'Aluminum',
			'Apparel, Footwear & Accessories': 'Vêtements, chaussures et accessoires',
			'Appliances & Electronics': 'Appareils et électronique',
			'Auto Parts': 'Pièces d’automobiles',
			'Autos & Auto Parts': 'Automobiles et pièces d’automobiles',
			'Autos- Manufacturing': 'Fabrication autos',
			Banking: 'Bancaire',
			'Banking & Savings': 'Services bancaires et d\'épargne',
			Beverages: 'Boissons',
			Biotechnology: 'Biotechnologie',
			'Brokers & Intermediaries': 'Courtiers & intermédiaires',
			Builders: 'Constructeurs',
			'Business Services': 'Les services aux entreprises',
			Chemicals: 'Produits chimiques',
			'Commercial Services & Supplies': 'Services et fournitures commerciaux',
			Communications: 'Communications',
			'Computer Hardware & Equipment': 'Ordinateurs, composants et équipement',
			'Construction Services': 'Services de construction',
			'Consumer Goods': 'Biens de consommation',
			'Consumer Products': 'Les produits de consommation',
			'Consumer Services': 'Services aux consommateurs',
			'Containers & Packaging': 'Contenants et emballages',
			'Credit & Lending': 'Crédit et prêt',
			Defense: 'La défense',
			'Diagnostic & Health Related Services': 'Diagnostic & Services de santé connexes',

			'Educational Services': 'Services éducatifs',
			'Electric Utilities': 'Electric utilities',
			'Electrical Equipment': 'Équipement électrique',
			'Electronic Instruments & Related Products': 'Instruments électroniques et produits connexes',
			Energy: 'Énergie',
			Entertainment: 'Divertissement',
			'Equipment & Services': 'Equipement et Services',
			ETFs: 'FNB',
			'Finance Intermediaries & Services': 'Finance Intermédiaires et Services',
			Financials: 'Services financiers',
			'Fishing & Gaming': 'Pêche et chasse',
			Food: 'Aliments',
			'Food & Beverage': 'Nourriture et boissons',
			Forestry: 'Sylviculture',
			Furniture: 'Meubles',
			'Gas Utilities': 'Services publics de gaz',
			'General Insurance': 'Assurances générales',
			'Health Care': 'Soins de santé',
			'Health Care Equipment': 'Équipement de soins de santé',
			'Health Care Services': 'Services de santé',
			'Holding and other Investment Offices': 'Sociétés de portefeuille et autres bureaux de placement',
			'Hospitals & Health Care Facilities': 'Installations hôpitaux et centres de soins',
			'Hotels, Restaurants & Travel': 'Hôtels, restaurants et agences de voyages',
			'Household & Personal Products': 'Produits ménagers et personnels',
			'Household Appliances, Electronics & Goods': 'Electroménager, electronique & goods',
			'Industrial Machinery & Equipment': 'Machines & équipement industriel',
			Industrials: 'Produits industriels',
			Insurance: 'Assurance',
			'Internet & Software': 'Internet et logiciels',
			'Investments & Brokers': 'Investissements & courtiers',
			'IT & Communications': 'Technologie de l\'information et communications',
			'IT Hardware': 'Matériel informatique',
			'IT Services': 'Services informatiques',
			'IT Services & Software': 'Services et logiciels',
			'Leisure Equipment': 'Equipement de loisirs',
			'Life & Health': 'Vie et santé',
			Machinery: 'Machinerie',
			Manufacturing: 'Fabrication',
			Materials: 'Matériaux',
			Media: 'Médias',
			'Medical Instruments & Equipment': 'Instruments et équipements médicaux',
			'Metal Products': 'Des produits métalliques',
			'Metals & Mining': 'Metals & mining',
			Mining: 'Exploitation minière',
			Miscellaneous: 'Divers',
			'Miscellaneous Consumer Goods': 'Biens de consommation divers',
			'Miscellaneous Consumer Services': 'Divers services aux consommateurs',
			'Miscellaneous Transportation Services': 'Divers transports services',
			miscellaneouseducationalserviceproviders: 'Fournisseurs de services d’éducation divers',
			'Non-Precious Metals': 'Métaux non-précieux',
			'Office Equipment & Furniture': 'Équipement et ameublement de bureau',
			'Oil & Gas': 'Gaz de pétrole',
			'Oil Royalty Traders': 'Huile libres traders',
			'Paper & Forest Products': 'Papier et produits forestiers',
			'Patent Owners & Lessors': 'Propriétaires et bailleurs brevets',
			Peripherals: 'Périphériques',
			Pharmaceuticals: 'Médicaments',
			'Pharmaceuticals & Biotechnology': 'Pharmacie et biotechnologie',
			Plastics: 'Matières plastiques',
			'Precious Metals': 'Métaux précieux',
			Printing: 'Impression',
			'Production & Extraction': 'Production et extraction',
			'Property, Real Estate & Development': 'Immobilier, immobilier & développement',
			Publishing: 'Édition',
			'Radio & Television': 'Radio et télévision',
			Rail: 'Transport ferroviaire',
			'Real Estate': 'Immobilier',
			'Refining & Marketing': 'Raffinage et commercialisation',
			REITs: 'REITs',
			Retail: 'Vente au détail',
			'Retail - Apparel and Accessories': 'Vente au détail - vêtements et accessoires',
			'Retail - Appliances and Electronics': 'Commerce de détail - appareils électroménagers et électroniques',
			'Retail - Automotive': 'Vente au détail - automobile',
			'Retail - Food & Beverage, Drug & Tobacco': 'Retail - food & beverage, drug & tobacco',
			'Retail - Furniture & Home Furnishings': 'Vente au détail - meubles et ameublement',
			'Retail - General Merchandise/Department Stores': 'Vente au détail - general merchandise / grands magasins',
			'Retail - Hardware & Home Improvement': 'Commerce de détail - articles de quincaillerie et de rénovations domiciliaires',
			'Retail - Specialty': 'Commerce de détail - articles spécialisés',
			'Rubber Products': 'Produits en caoutchouc',
			'Sanitation Services': 'Services d\'assainissement',
			Semiconductors: 'Semi-conducteurs',
			Services: 'Prestations de service',
			Shipping: 'Livraison',
			'Specialty Chemicals': 'Produits chimiques spécialisés',
			'Sporting & Recreational': 'Sports et loisirs',
			'Synthetic Materials': 'Matériaux synthétiques',
			Textiles: 'Textiles',
			'Textiles & Apparel': 'Textiles et vêtements',
			Tobacco: 'Le tabac',
			'Tobacco Products': 'Produits du tabac',
			Transport: 'Transport',
			Trucking: 'Camionnage',
			Utilities: 'Utilitaires',
			'Venture Capital': 'Capital de risque',
			'Water Utilities': 'Services publics d’eau',
			'Wealth Management': 'Gestion de patrimoine',
			// -- END Industries --
			screenDescription: 'Description de la sélection',
			renameScreenerSr: 'Renommez la fenêtre de dialogue ouverte de la sélection',
			editScreenerSr: 'Modifier la fenêtre de dialogue ouverte de la sélection',
			deleteScreenerSr: 'Supprimer la fenêtre de dialogue ouverte de la sélection',
			customSearchResults: 'Résultats de la recherche',
			editScreener: 'Modifier la sélection',
			cibcPreScreens: 'Présélections CIBC',
			reviewPrescreens: 'Examiner les vérifications préalables',

			// Fund Screener
			cibcSeriesD: 'Série D CIBC',
			cibcSeriesDDescription: 'Série D CIBC dans le tableau correspondant',
			cibcMutualFunds: 'Fonds mutuels CIBC',
			cibcMutualFundsDescription: 'Liste de Fonds mutuels CIBC',
			renaissance: 'Renaissance',
			renaissanceDescription: 'Liste de fonds communs de placement Renaissance',
			axiomFunds: 'Axiom',
			axiomFundsDescription: 'Liste de fonds communs de placement Axiom',
			technicalAnalysisAndRisk: 'Analyse technique et risque',
			'1mo': '1 mois',
			'3mo': '3 mois',
			'1yr': '1 an',
			'3yr': '3 an',
			PriceBookRatio: 'Ratio cours/valeur comptable',
			alpha3Yr: 'Alpha (3 ans)',
			beta3Yr: 'Bêta (3 ans)',
			informationRatio: 'Ratio d’information',
			annulaizedStdDev: 'Écart-type annualisé',
			consistentReturnLiteral: 'Constant rendement',
			customizeScreenerAction: 'Personnalisation de la sélection',
			fundTypes: 'Types de fonds',
			additionalFundAttributes: 'Caractéristiques supplémentaires des fonds',
			screenNameLabel: 'Nom de la sélection',
			screenerList: 'Liste des sélections',
			// begin stock screener industries
			// -- Begin Academic & Education services
			'academic&educationalservices': 'Services de formation et d’enseignement',
			professionalbusinesseducation: 'Formation professionnelle en administration des affaires',
			schoolcollegeuniversity: 'Écoles, collèges, universités',
			// -- End Academic..
			// -- Begin Industries - BASIC Materials
			agriculturalchemicals: 'Produits chimiques d’usage agricole',
			commoditychemicals: 'Produits chimiques de base',
			constructionmaterials: 'Matériaux de construction',
			diversifiedchemicals: 'Produits chimiques diversifiés',
			diversifiedmining: 'Exploitation minière diversifiée',
			forestwoodproducts: 'Foresterie et produits du bois',
			miningsupportservicesequipment: 'Équipements annexes de l’extraction',
			nongoldpreciousmetalsminerals: 'Minéraux de métaux précieux autres que l’or',
			nonpapercontainerspackaging: 'Contenants et emballages autres qu’en papier',
			paperpackaging: 'Emballages en papier',
			paperproducts: 'Produits de papier',
			specialtychemicals: 'Produits chimiques spécialisés',
			chemicalmanufacturing: 'Fabrication de produits chimiques',
			chemicalsplasticrubber: 'Produits chimiques – plastique et caoutchouc',
			containerspackaging: 'Contenants et emballages',
			fabricatedplasticrubber: 'Produits fabriqués en plastique et en caoutchouc',
			forestrywoodproducts: 'Foresterie et produits du bois',
			goldsilver: 'Or et argent',
			ironsteel: 'Fer et acier',
			metalmining: 'Mines de métaux',
			miscfabricatedproducts: 'Produits fabriqués divers',
			nonmetallicmining: 'Exploitation minière non métallique',
			paperpaperproducts: 'Papier et produits du papier',
			specialtyminingmetals: 'Mines et métaux spécialisés',
			// -- End Basic materials
			// -- Begin Capital goods
			aerospacedefense: 'Aéronautique et défense',
			constragricmachinery: 'Construction – machines agricoles',
			constrsuppliesfixtures: 'Construction – meubles et accessoires',
			constructionrawmaterials: 'Construction – matières premières',
			constructionservices: 'Services de construction',
			misccapitalgoods: 'Biens d’équipement divers',
			mobilehomesrvs: 'Maisons mobiles et VR',
			// -- End Capital goods
			// -- Begin  Consumer cyclical
			apparelaccessories: 'Vêtements et accessoires',
			apparelaccessoriesretailers: 'Détaillants de Vêtements et accessoires',
			appliancetool: 'Appareils et outils',
			appliancestoolshousewares: 'Appareils et outils',
			audiovideoequipment: 'Matériel audio et vidéo',
			autotruckmanufacturers: 'Constructeurs d’automobiles et de camions',
			autotruckparts: 'Pièces d’automobiles et de camions',
			autotruckmotorcycleparts: 'Pièces d’automobiles, de motocyclette et de camions',
			autovehiclespartsserviceretailers: 'Détaillants de véhicules et de pièces automobiles',
			advertisingmarketing: 'Marketing publicitaire',
			computerelectronicsretailers: 'Détaillants d’ordinateurs et d’appareils électroniques',
			constructionsuppliesfixtures: 'Meubles et accessoires de construction',
			consumerpublishing: 'Publication pour les consommateurs',
			departmentstores: 'Grands magasins',
			discountstores: 'Magasins de rabais',
			entertainmentproduction: 'Production de divertissement',
			footwear: 'Chaussures',
			furniturefixtures: 'Meubles et accessoires',
			homebuilding: 'Construction individuelle',
			homefurnishings: 'Ameublement',
			homefurnishingsretailers: 'Détaillants de meubles',
			homeimprovementproductsservicesretailers: 'Détaillants de produits et services de rénovation domiciliaire',
			hotelsmotelscruiselines: 'Hôtels, motels et croisiéristes',
			jewelrysilverware: 'Bijoux et argenterie',
			leisurerecreation: 'Loisirs',
			otherspecialtyretailers: 'Autres détaillants spécialisés',
			photography: 'Photographie',
			recreationalproducts: 'Produits récréatifs',
			restaurantsbars: 'Restaurants et bars',
			textilenonapparel: 'Textile – autre que vêtements',
			textilesleathergoods: 'Textiles et produits de cuir',
			tires: 'Pneus',
			tiresrubberproducts: 'Pneus et produits en caoutchouc',
			toysjuvenileproducts: 'Jouets et produits pour enfants en bas âge',
			// -- End Consumer cyclical
			// -- Begin Consumer non-cyclial
			beveragesalcoholic: 'Boissons (alcoolisées)',
			beveragesnonalcoholic: 'Boissons (non alcoolisées)',
			brewers: 'Brasseries',
			crops: 'Productions agricoles',
			consumergoodsconglomerates: 'Conglomérats de biens de consommation',
			distillerswineries: 'Distilleries et établissements vinicoles',
			drugretailers: 'Détaillants de médicaments',
			fishlivestock: 'Poisson et bétail',
			fishingfarming: 'Pêche et agriculture',
			foodprocessing: 'Transformation des aliments',
			foodretaildistribution: 'Commerce de détail et distribution alimentaire',
			householdproducts: 'Produits ménagers',
			nonalcoholicbeverages: 'Boissons non alcoolisées',
			officesupplies: 'Fournitures de bureau',
			personalproducts: 'Produits personnels',
			personalhouseholdprods: 'Produits personnels et domestiques',
			// -- End of Consumer non-cyclical
			// -- Begin Energy
			coal: 'Charbon',
			integratedoilgas: 'Pétrole et gaz – sociétés intégrées',
			oilgasdrilling: 'Pétrole et gaz Forage',
			oilgasexplorationandproduction: 'Pétrole et gaz – Sociétés d’exploration et de production',
			oilgasintegrated: 'Pétrole et gaz – sociétés intégrées',
			oilgasoperations: 'Pétrole et gaz – exploitation',
			oilgasrefiningandmarketing: 'Raffinage et commercialisation de pétrole et de gaz',
			oilwellservicesequipment: 'Entretien des puits de pétrole et équipement',
			oilgastransportationservices: 'Services de transport de pétrole et de gaz',
			oilrelatedservicesandequipment: 'Services et équipement liés au pétrole',
			renewableenergyequipmentservices: 'Équipements liés à l’énergie renouvelable',
			renewablefuels: 'Combustibles renouvelables',
			// -- End Energy
			// -- Begin Financial
			consumerfinancialservices: 'Services financiers aux consommateurs',
			insuranceaccidenthealth: 'Assurance (accidents et santé)',
			insurancelife: 'Assurance (vie)',
			insurancemiscellaneous: 'Assurance (divers)',
			insurancepropcasualty: 'Assurance (dommages)',
			investmentservices: 'Services de placement',
			miscfinancialservices: 'Services financiers divers',
			moneycentrebanks: 'Banques installées sur les principales places financières',
			regionalbanks: 'Banques régionales',
			slssavingsbanks: 'Banques d’épargne et de crédit',
			closedendfunds: 'Fonds de placement à capital fixe',
			consumerlending: 'Prêts à la consommation',
			corporatefinancialservices: 'Services financiers aux entreprises',
			diversifiedinvestmentservices: 'Services de placement diversifiés',
			financialcommoditymarketoperators: 'Opérateurs de marchés financiers et des marchandises',
			investmentbankingbrokerageservices: 'Services de banque d’investissement et de courtage',
			investmentholdingcompanies: 'Sociétés de portefeuille de placements',
			investmentmanagementfundoperators: 'Gestion de placements et opérateurs de fonds',
			investmenttrusts: 'Fiducies de placement',
			lifehealthinsurance: 'Assurance vie et assurance maladie',
			mutualfunds: 'Fonds communs de placement',
			multilineinsurancebrokers: 'Assurance multirisque et courtiers',
			propertycasualtyinsurance: 'Assurance de dommages',
			reinsurance: 'Réassurance',
			// -- End Financial
			// -- Begin Healthcare
			biotechnologydrugs: 'Biotechnologie et médicaments',
			healthcarefacilities: 'Établissements de soins de santé',
			majordrugs: 'Principaux médicaments',
			medicalequipmentsupplies: 'Équipement et fournitures médicaux',
			advancedmedicalequipmenttechnology: 'Technologies et équipements médicaux de pointe',
			biotechnologymedicalresearch: 'Biotechnologie et recherche médicale',
			healthcarefacilitiesservices: 'Établissements et services de soins de santé',
			managedhealthcare: 'Soins de santé gérés',
			medicalequipmentsuppliesdistribution: 'Fournitures et équipements médicaux, et distribution',
			pharmaceuticals: 'Produits pharmaceutiques',
			// -- End Healthcare
			// -- Begin Services
			advertising: 'Publicité',
			broadcastingcabletv: 'Services de diffusion et câblodistribution',
			broadcasting: 'Services de diffusion',
			businessservices: 'Services aux entreprises',
			casinosgaming: 'Casinos et jeux de hasard',
			communicationsservices: 'Services de communication',
			hotelsmotels: 'Industries de l’hébergement',
			motionpictures: 'Cinéma',
			personalservices: 'Services personnels',
			printingpublishing: 'Impression et édition',
			printingservices: 'Services d’impression',
			realestateoperations: 'Activités immobilières',
			recreationalactivities: 'Activités récréatives',
			rentalleasing: 'Location et crédit-bail',
			restaurants: 'Restaurants',
			retailapparel: 'Détail (vêtements)',
			retailcatalogmailorder: 'Détail (catalogue et commande postale)',
			retaildepartmentdiscount: 'Détail (service et rabais)',
			retaildrugs: 'Détail (médicaments)',
			retailgrocery: 'Détail (épicerie)',
			retailhomeimprovement: 'Détail (rénovations domiciliaires)',
			retailspeciality: 'Détail (spécialité)',
			retailtechnology: 'Détail (technologie)',
			schools: 'Écoles',
			securitysystemsservices: 'Systèmes et services de sécurité',
			wastemanagementservices: 'Services de gestion des déchets',
			// -- End Services
			// -- Begin Industrials
			airfreightlogistics: 'Fret aérien et services logistiques',
			airlines: 'Transporteurs aériens',
			airportservices: 'Services aéroportuaires',
			businesssupportservices: 'Services de soutien opérationnel',
			businesssupportsupplies: 'Fournitures de soutien opérationnel',
			commercialprintingservices: 'Services d’impression commerciale',
			constructionengineering: 'Construction et ingénierie',
			diversifiedindustrialgoodswholesale: 'Commerce de gros de biens industriels diversifiés',
			electricalcomponentsequipment: 'Composants et équipement électriques',
			employmentservices: 'Services d’emploi',
			environmentalservicesequipment: 'Services et équipement environnementaux',
			groundfreightlogistics: 'Fret terrestre et services logistiques',
			heavyelectricalequipment: 'Équipements électriques lourds',
			heavymachineryvehicles: 'Machineries et véhicules lourds',
			highwaysrailtracks: 'Autoroutes et voies ferrées',
			industrialmachineryequipment: 'Machines et matériel industriels',
			marinefreightlogistics: 'Fret maritime et services logistiques',
			marineportservices: 'Ports et services maritimes',
			passengertransportationgroundsea: 'Transport de passagers par voie terrestre et maritime',
			professionalinformationservices: 'Services d’information professionnelle',
			shipbuilding: 'Construction de navires',
			// -- End Industrials
			// -- Begin Technology
			communicationsequipment: 'Équipement de communication',
			computerhardware: 'Matériel informatique',
			computernetworks: 'Réseaux informatiques',
			computerperipherals: 'Périphériques d’ordinateur',
			computerservices: 'Services informatiques',
			computerstoragedevices: 'Dispositifs de stockage informatique',
			electronicinstrcontrols: 'Instruments et contrôles électroniques',
			officeequipment: 'Matériel de bureau',
			scientifictechnicalinstr: 'Instruments scientifiques et techniques',
			semiconductors: 'Semiconducteurs',
			softwareprogramming: 'Logiciels et programmes',
			blockchaincryptocurrency: 'Chaînes de blocs et cryptomonnaies',
			communicationsnetworking: 'Communications et réseautage',
			electronicequipmentparts: 'Matériel et pièces électroniques',
			financialtechnologyfintech: 'Technologie financière (Fintech)',
			householdelectronics: 'Appareils électroniques ménagers',
			integratedhardwaresoftware: 'Matériel et logiciels intégrés',
			integratedtelecommunicationsservices: 'Services de télécommunications intégrés',
			internetservices: 'Services Internet',
			itservicesconsulting: 'Services et services-conseils en TI',
			miscellaneousfintechinfrastructure: 'Infrastructure de technologies financières diverses',
			phoneshandhelddevices: 'Téléphones et appareils portatifs',
			semiconductorequipmenttesting: 'Semiconducteurs, équipement et testeurs',
			software: 'Logiciels',
			wirelesstelecommunicationsservices: 'Services de télécommunications sans fil',
			// -- End Technology
			// -- Begin Real estate
			commercialreits: 'FPI commerciales',
			diversifiedreits: 'FPI diversifiées',
			realestatedevelopmentoperations: 'Promotion et exploitation immobilières',
			realestateservices: 'Services immobiliers',
			residentialreits: 'FPI résidentielles',
			specializedreits: 'FPI spécialisées',
			// -- End Real estate
			// -- Begin Transportation
			aircourier: 'Messagerie aérienne',
			airline: 'Compagnies aériennes',
			misctransportation: 'Transport divers',
			railroads: 'Chemins de fer',
			trucking: 'Camionnage',
			watertransportation: 'Transport par eau',
			// -- End Transportation
			// -- Begin Utilities
			electricutilities: 'Services publics d’électricité',
			naturalgasutilities: 'Services publics de gaz naturel',
			waterutilities: 'Services publics d’eau',
			independentpowerproducers: 'Producteurs d’électricité indépendants',
			multilineutilities: 'Services publics diversifiés',
			// -- End Utilities
			searchByKeywords: 'Recherche par mots-clés',
			selectSources: 'Sélectionner les sources',
			selectIndustries: 'Sélectionner les secteurs',
			industrySection: 'Secteur',
			// end stock screener industries
			true: 'Vrai',
			false: 'Faux',
			editCriteria: 'Modifier les critères',
			renameScreen: 'Renommer la sélection',
			allFundFamilies: 'Toutes les familles de fonds',
			allEtfTypes: 'Tous les types de FNB',
			browseByEtf: 'Parcourir par FNB',
			reportPdf: 'Rapport (PDF)',
			sourceCriterion: 'Critère d’origine',
			industryCriterion: 'Critère du secteur',
			thereAreNoSavedSearches: 'Il n’y a aucune recherche sauvegardée.',
			newAdvancedSearch: 'Nouvelle recherche avancée',
			savedAnalystReportsSearchButtonSR: 'Affichage dans le tableau correspondant des rapports : {searchName}',
			deleteSearch: 'Supprimer la recherche',
			editSearchInNewDialog: 'Modifier dans une nouvelle boîte de dialogue la recherche sur : {searchName}',
			renameSearchInNewDialog: 'Ouvrir dans une nouvelle boîte de dialogue la recherche sur : {searchName}',
			editSavedSearch: 'Modifier la recherche sur : {searchName}',
			renameSearch: 'Renommer la recherche',
			deleteSavedSearch: 'Supprimer la recherche sauvegardée',
			doYouWantToDelete: 'Voulez-vous supprimer cette recherche sauvegardée?',
			editCustomSearchName: 'Modifier le nom de la recherche personnalisée',
			saveCustomSearch: 'Enregistrer la recherche personnalisée',
			customSearchNameOver32: 'Les noms de la recherche personnalisée ne doivent pas dépasser 32 caractères.',
			setAlert: 'Configurer une alerte',
			setAlertSr: 'Configurer une alerte liée au rapport {searchName}',
			analystReportAlertsHeader: 'Alertes liées aux rapports d’analystes',
			recieveDailyEmail: 'Recevez chaque jour une alerte par courriel des plus récents rapports d’analystes selon des critères de recherche précis.',
			setAnalystReportAlerts: 'Configurer des alertes liées aux rapports d’analystes',
			savedSearch: 'Recherche sauvegardée',
			savedSearchResults: 'Résultats de la recherche {searchName}',
			alreadySearchingSymbol: 'Ce symbole figure déjà dans la liste.',
			noResultsFoundPleaseChooseDiff: 'Aucun résultat trouvé. Sélectionner un critère de recherche différent.',
			nameExistsAlready: 'Il y a déjà une recherche sauvegardée pour ce nom. Renommer.',
			saveAsNewSearch: 'Enregistrer comme nouvelle recherche',
			searchName: 'Nom de la recherche',
			equityFunds: 'Fonds d’actions',
			mixedEquityFunds: 'Fonds mixtes d’actions',
			fixedIncomeFunds: 'Fonds à revenu fixe',
			moneyMarketFunds: 'Fonds du marché monétaire',
			bondFunds: 'Fonds d’obligations',
			realEstateFunds: 'Fonds immobiliers',
			otherFunds: 'Autres fonds',
			LFFundType: 'Types de fonds',
			LFAssetType: 'Type d’actifs',
			LFFundPromotor: 'Promoteur de fonds',
			LFLipperClassification: 'Catégorie de fonds',
			LFTotalReturns1m: 'Montants des rendements (%)',
			lessThanZero: '< 0%',
			zeroToFive: '0-5%',
			fiveToTen: '5-10%',
			tenToFifteen: '10-15%',
			greaterThanFifteen: '15%+',
			timePeriod: 'Période',
			'<$1K': '<$1K',
			'$1K-2K': '$1K-2K',
			'$2K-10K': '$2K-10K',
			'>$10K': '>$10K',
			minimumInitialInvestmentScreener: 'Placement initial minimal',
			'<$100M': '< $100M',
			'$100M-500M': '$100M-500M',
			'$500M-1B': '$500M-1B',
			'$1B+': '$1B+',
			mgmtExpenseRatio: 'Ratio des frais de gestion',
			'0-1%': '0-1%',
			'1-2%': '1-2%',
			'2-3%': '2-3%',
			'3-4%': '3-4%',
			'4%+': '4%+',
			LFLoadType: 'Types de chargement',
			noLoad: 'Aucun chargement',
			front: 'Avant',
			back: 'Arrière',
			level: 'Niveau',
			originalAmount: 'Montant initial',
			marketValueLabel: 'Valeur marchande',
			noSavedScreeners: 'Vous n’avez actuellement aucune sélection enregistrée.',
			createNewScreener: 'Créer un nouvel écran.',
			reviewScreener: 'Examiner les sélections',
			minimizeFundTypeCriteria: 'Réduire les critères des types de fonds',
			expandFundTypeCriteria: 'Élargir les critères des types de fonds',
			lipperInstructions: 'Choisir la note Lipper Leader parmi les catégories connexes qui correspondent à vos objectifs de placement. Vous pouvez faire plusieurs choix.',
			PreservationCriteria: 'Préservation',
			LFNoLoad: 'Aucun chargement',
			LFFrontLoad: 'Avant',
			LFBackLoad: 'Arrière',
			LFLevelLoad: 'Niveau',
			LFDeferredLoadOrigAmt: 'Montant initial',
			LFDeferredLoadMktValue: 'Valeur marchande',
			equalTo: 'Égal à',
			anotherDifferentTranslationOfAll: 'Ensemble',
			totalNetAssetsScreener: 'Valeur liquidative totale',
			minimizeTotalReturnPerformance: 'Réduire les critères de rendement total',
			expandTotalReturnPerformance: 'Élargir les critères de rendement total',
			screenerTotalReturnPerformance: 'Rendement total',
			minimizeAdditionalFund: 'Réduire les critères des caractéristiques supplémentaires des fonds',
			expandAdditionalFund: 'Élargir les critères des caractéristiques supplémentaires des fonds',
			minimizeLipperCriteria: 'Réduire les critères des notes Lipper Leader ',
			expandLipperCriteria: 'Élargir les critères des notes Lipper Leader',
			lipperLeaderRatingsScreener: 'Notes Lipper Leader',
			canadianMFs: 'Fonds commun de placement canadiens',
			canadianETFs: 'FNB canadiens',
			usETFs: 'FNB américains',
			pageIsLoading: 'Chargement de la page en cours',
			// WebDrawer Top Holdings
			top5Holdings: '5 principaux placements',
			top5HoldingsTooltip: 'Les principaux placements sont les actifs détenus dans un portefeuille dont la pondération est la plus élevée.',
			entity: 'Entité',
			showSectorHoldings: 'Show sector holdings',
			showEntityHoldings: 'Show entity holdings',
			// Account Holdinfs
			accountHoldings: {
				buy: 'Acheter',
				hold: 'Détenir',
				sell: 'Vendre'
			}
		}
	}
};

export { translations as default };
