/* eslint-disable max-len */
/* eslint-disable no-extra-boolean-cast */
/* global window */
import React from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch
} from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual';
import { requestPostProfile } from 'Actions/AlertsActions';
import getLanguage from 'services/languageService';
import { areas } from 'SiteConfigs/pages-config';
import cssDetect from 'Utils/cssHelper';
import accessTokenService from 'services/accessTokenService';
import asyncComponent from './asyncComponent';

const Routes = props => {
	const {
		user,
		fetchingUserInfo,
		setAlertsLang,
		setLocale
	} = props;

	const { tier, language } = user || {};
	const { iframeCommunication, location, format } = window;
	let content = null;
	const areasToRoute = [
		areas.marketsCentre,
		areas.stockCentre,
		areas.stocks,
		areas.quotelists,
		areas.etfCentre,
		areas.etfCentreIndividualHolding,
		areas.fundCentreIndividualHolding,
		areas.fundCentre,
		areas.alerts,
		areas.analystReports,
		areas.pdfDownload,
		areas.pageNotFound
	];

	// look in query string first
	let foundUserLang = getLanguage();
	// if the user has a lang and its not the default of en then switch to french
	if (language && language.toLowerCase().indexOf('f') > -1) {
		foundUserLang = 'fr';
	}

	if (foundUserLang) {
		setLocale(foundUserLang);
		setAlertsLang(foundUserLang.toUpperCase());
		format.myLocale(foundUserLang);
		format.setDocumentLocale(foundUserLang);
	}

	function removeTokenFlag() {
		const exists = window.localStorage.getItem('skip_token_request');
		if (exists) {
			setTimeout(() => { window.localStorage.removeItem('skip_token_request'); }, 3000);
		}
	}

	const lang = foundUserLang === 'fr' ? 'fr' : 'en';
	const hasToken = accessTokenService.getPreviousToken();
	const skipTokenRequest = window.localStorage.getItem('skip_token_request');
	if (!hasToken) {
		if (skipTokenRequest) {
			// session ended error tracking object
			removeTokenFlag();
			const SessionEnded = asyncComponent(() => { return import('../Views/SessionEnded/SessionEnded'); });
			iframeCommunication.tokenExpired(location.pathname);
			content = <SessionEnded />;
		} else {
			iframeCommunication.requestToken();
		}
	} else if (user && tier) {
		const {
			techAnalysis,
			fundCentreETFs,
			allowedAlerts,
			allowedFundCentre,
			code
		} = tier;

		const { userId } = user;
		removeTokenFlag();

		const routesCollection = [];

		areasToRoute.forEach(area => {
			area.forEach(page => {
				if ((page.checkTechAnalysis && !techAnalysis)
					|| (page.checkFundCentreETFs && !fundCentreETFs)
					|| (page.checkAlerts && !allowedAlerts)
					|| (page.checkFundCentre && !allowedFundCentre)) {
					routesCollection.push(
						<Redirect
							key={page.key}
							from={page.route}
							to={page.redirectPath} />
					);
				} else {
					// check for matching route
					if (!!matchPath(window.location.pathname, page.route)) {
						const {
							name, heirarchy, addParams, tabNameMapping
						} = page;
						const updatedHeirarchy = (heirarchy && [...heirarchy]) || [];
						let pageName = name;
						let url = window.location.href;
						if (addParams) {
							const { params } = matchPath(window.location.pathname, page.route);
							const { tab } = params;
							pageName = (tabNameMapping && tabNameMapping[tab]) || tab;
						}
						if (window.sessionStorage.getItem('currentPage') !== window.location.pathname) {
							if (fundCentreETFs && updatedHeirarchy.indexOf('etf-centre') > -1) {
								updatedHeirarchy.pop();
								updatedHeirarchy.push('fund-centre');
								url = url.replace('etfcentre', 'fundcentre');
							}
							window.sessionStorage.setItem('currentPage', window.location.pathname);
						}
					}
					routesCollection.push(
						<Route
							key={page.key}
							exact={page.isExact}
							path={page.route}
							strict={page.strict}
							component={asyncComponent(() => { return import(`Views/${page.componentPath}`); })} />
					);
				}
			});
		});

		content = (
			<Router>
				<div
					onMouseMove={event => iframeCommunication.handleKeepAlive(event)}
					className={`${cssDetect.supportsFlex() ? '' : 'safari-fix'} ${cssDetect.isIe() ? 'ie-fix' : ''}`}>
					<Switch>
						{routesCollection}
					</Switch>
				</div>
			</Router>
		);
	} else if ((!user || !tier) && !fetchingUserInfo) {
		removeTokenFlag();
		const SessionEnded = asyncComponent(() => { return import('../Views/SessionEnded/SessionEnded'); });
		iframeCommunication.tokenExpired(location.pathname);
		content = <SessionEnded />;
	}

	return (
		content
	);
};

const mapStateToProps = state => ({
	user: state.userData.user,
	fetchingUserInfo: state.userData.fetchingUserInfo,
	locale: state.Intl.locale
});

const mapDispatchToProps = dispatch => ({
	setLocale: lang => dispatch(IntlActions.setLocale(lang)),
	setAlertsLang: lang => dispatch(requestPostProfile({ languageCode: lang }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
