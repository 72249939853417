/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import lodash from 'lodash';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	quoteList: {},
	totalOptions: 0,
	showError: false
};

const addOptionToQuoteList = (state, action) => {
	const {
		optionObject
	} = action;

	const byUnderlyingXid = state.quoteList;

	let totalOptions = 0;
	Object.keys(byUnderlyingXid).forEach(key => {
		const list = byUnderlyingXid[key];
		if (list.length) {
			totalOptions += list.length;
		}
	});

	if (totalOptions === 20) {
		console.log('just returning the state');
		return Object.assign({}, state, { totalOptions, showError: true });
	}

	if (byUnderlyingXid[optionObject.underlyingXid]) {
		const existingIndex = lodash.findLastIndex(byUnderlyingXid[optionObject.underlyingXid],
			o => { return o.xid === optionObject.xid; });
		if (existingIndex === -1) {
			byUnderlyingXid[optionObject.underlyingXid].push(optionObject);
		}
	} else {
		byUnderlyingXid[optionObject.underlyingXid] = [optionObject];
	}

	return Object.assign({}, state, {
		quoteList: Object.assign({}, byUnderlyingXid),
		totalOptions,
		showError: false
	});
};

const removeOptionsFromQuoteList = (state, action) => {
	const { xids } = action;
	const newList = state.quoteList;

	xids.forEach(xid => {
		if (newList[xid]) {
			delete newList[xid];
		}

		Object.keys(newList).forEach(underlyingXid => {
			const options = newList[underlyingXid];
			const index = lodash.findLastIndex(options, o => o.xid === xid);

			if (index > -1) {
				options.splice(index, 1);
			}

			if (options.length === 0) {
				delete newList[underlyingXid];
			} else {
				newList[underlyingXid] = options;
			}
		});
	});

	return Object.assign({}, state, {
		quoteList: Object.assign({}, newList),
		showError: false
	});
};

const clearOptionsQuoteList = state => {
	return Object.assign({}, state, {
		quoteList: Object.assign({}, {}),
		showError: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.ADD_OPTION_TO_QUOTE_LIST]: addOptionToQuoteList,
	[Types.REMOVE_OPTIONS_FROM_QUOTE_LIST]: removeOptionsFromQuoteList,
	[Types.CLEAR_OPTIONS_QUOTELIST]: clearOptionsQuoteList
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
