/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingScreenerColumnDistinctList: false,
	fetchesActive: 0,
	distinctColumnValues: {
		LFFundPromotor: [],
		LFLipperClassification: []
	}
};

const requestScreenerColumnDistinctList = state => {
	const { fetchesActive } = state;

	return Object.assign({}, state, {
		fetchingScreenerColumnDistinctList: true,
		fetchesActive: fetchesActive + 1
	});
};

const receiveScreenerColumnDistinctList = (state, action) => {
	const { distinctColumnValues, fetchesActive } = state || {};

	const responseItems = action.response.data.items;

	if (responseItems && responseItems.length && (action.columnName === 'RUTRBCSector')) {
		const foundIndex = responseItems.indexOf('Institutions,Associations&Organizations');
		if (foundIndex !== -1) {
			responseItems.splice(foundIndex, 1);
		}
	}

	distinctColumnValues[action.columnName] = responseItems;

	return Object.assign({}, state, {
		fetchingScreenerColumnDistinctList: false,
		distinctColumnValues,
		fetchesActive: fetchesActive - 1
	});
};

const setSavedScreenCount = (state, { count }) => {
	return Object.assign({}, state, {
		savedScreenCount: count
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_SCREENER_COLUMNS_DISTINCT_LIST]: requestScreenerColumnDistinctList,
	[Types.API_CIBC_RECEIVE_SCREENER_COLUMN_DISTINCT_LIST]: receiveScreenerColumnDistinctList,
	[Types.SET_SAVED_SCREEN_COUNT]: setSavedScreenCount,
	[Types.API_HANDLE_SCREENER_UTILS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
