/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingMarketRankings: false,
	data: {}
};

const requestMarketRankings = state => {
	return Object.assign({}, state, {
		fetchingMarketRankings: true
	});
};

const receiveMarketRankings = (state, action) => {
	const { data } = state;

	if (action.response.data && action.response.data.marketRankings) {
		data[action.rankingType] = action.response.data.marketRankings;
	}

	return Object.assign({}, state, {
		fetchingMarketRankings: false,
		data: Object.assign({}, data)
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_MARKET_RANKINGS]: requestMarketRankings,
	[Types.API_RECEIVE_MARKET_RANKINGS]: receiveMarketRankings,
	[Types.API_HANDLE_MARKET_RANKINGS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
