import React from 'react';
import ErrorWarning from 'assets/images/ErrorWarning.svg';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return {
			hasError: true,
			error
		};
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
		console.error(error);
		console.error(errorInfo);
	}

	render() {
		const { children } = this.props;
		const { hasError } = this.state;

		if (hasError) {
			// You can render any custom fallback UI
			return (
				<>
					<div className="error-boundry-wrapper" lang="en">
						<span className="error-boundry-icon">
							<img src={ErrorWarning} alt="Warning" />
						</span>
						<span>
							<div className="error-boundry-header">Your requested information is unavailable. Please retry later.</div>
							<div className="error-boundry-body">
								{'Visit the '}
								<a href="/markets?language=en">
									Market Centre
								</a>
								.
							</div>
						</span>
					</div>
					<div className="error-boundry-wrapper" lang="fr">
						<span className="error-boundry-icon">
							<img src={ErrorWarning} alt="Attention" />
						</span>
						<span>
							<div className="error-boundry-header">Les renseignements que vous avez demandés ne sont pas accessibles. Veuillez réessayer plus tard.</div>
							<div className="error-boundry-body">
								{'Visitez le '}
								<a href="/markets?language=fr">
									Centre des marchés
								</a>
								.
							</div>
						</span>
					</div>
				</>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
