import Types from './Types';

const MarketHoursTradeType = {
	Normal: 'normal',
	Extended: 'extended',
	All: 'all'
};

const getRealtimeByTier = tier => {
	const { code } = tier || {};

	switch (code) {
		case 'IEE':
		case 'IISE':
		case 'PEE':
			return true;
		default:
			return false;
	}
};

const receiveQuotes = response => {
	return ({
		type: Types.API_RECEIVE_QUOTE,
		response
	});
};

const handleLoader = fetchName => {
	return ({
		type: Types.API_HANDLE_QUOTE_LOADER,
		fetchName
	});
};

const requestQuotes = (XidsArray, MarketHoursType = MarketHoursTradeType.Normal,
	tier = {}, realTimeOverride = undefined, responseHandler) => {
	const realtime = (realTimeOverride !== undefined ? realTimeOverride : getRealtimeByTier(tier));
	const XidsString = XidsArray.join(',');
	const result = {
		type: Types.API_REQUEST_QUOTE,
		endPoint: '/quote/v1/details',
		params: {
			venueXids: XidsString,
			marketHoursTradeType: MarketHoursType
		},
		headers: {
			realtime
		},
		ajaxType: 'GET',
		onSuccess: responseHandler || receiveQuotes,
		loader: handleLoader,
		fetchName: 'fetchingQuoteData'
	};

	return result;
};

const receiveLegacyQuotes = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_LEGACY_QUOTE,
		response
	});
};

const requestLegacyQuotes = (xid, tier = {}) => {
	const result = {
		type: Types.API_CIBC_REQUEST_LEGACY_QUOTE,
		endPoint: `/quote/legacy/${xid}`,
		params: {},
		headers: {
			realtime: getRealtimeByTier(tier)
		},
		ajaxType: 'GET',
		onSuccess: receiveLegacyQuotes,
		loader: handleLoader,
		fetchName: 'fetchingLegacyQuoteData'
	};

	return result;
};

export {
	requestQuotes,
	receiveQuotes,
	MarketHoursTradeType,
	getRealtimeByTier,
	requestLegacyQuotes
};
