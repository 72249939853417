import Types from './Types';

const receiveUserInfo = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_USER_INFO,
		response
	});
};

const receiveUserInfoFail = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_USER_INFO_FAIL,
		response
	});
};

const handleLoader = fetchName => {
	return ({
		type: Types.API_CIBC_HANDLE_USER_INFO,
		fetchName
	});
};

const requestUserInfo = () => {
	const result = {
		type: Types.API_CIBC_SINGLE_REQUEST_USER_INFO,
		endPoint: 'user',
		ajaxType: 'GET',
		onSuccess: receiveUserInfo,
		loader: handleLoader,
		fetchName: 'fetchingUserInfo'
	};

	return result;
};

export {
	requestUserInfo,
	receiveUserInfoFail,
	receiveUserInfo
};
