/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingIndexData: false,
	indexData: {}
};

const requestIndexQuotes = state => {
	return Object.assign({}, state, {
		fetchingIndexData: true
	});
};

const recieveIndexQuotes = (state, action) => {
	return Object.assign({}, state, {
		fetchingIndexData: false,
		indexData: Object.assign({}, action.response.data)
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_INDEX_QUOTES]: requestIndexQuotes,
	[Types.API_RECIEVE_INDEX_QUOTES]: recieveIndexQuotes,
	[Types.API_HANDLE_INDEX_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
