/* global window */
function getUrlParameterValueByName(name, caseSensitive = false) {
	const url = window.location;

	/* eslint-disable */
	if (!caseSensitive) url.toLowerCase;
	const cleanedName = name.replace(/[\[\]]/g, '\\$&');
	if (!caseSensitive) cleanedName.toLowerCase;
	/* eslint-enable */
	const regex = new RegExp(`[?&]${cleanedName}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);

	if (!results) {
		return null;
	}

	return results[2] ? decodeURIComponent(results[2].replace(/\+/g, ' ')) : '';
}

export const isDebugInfoOn = () => {
	return getUrlParameterValueByName('..showdebuginfo..') === 'on';
};

export const getTokenFromQueryString = () => {
	return getUrlParameterValueByName('token', true);
};

export const getRefFromQueryString = () => {
	return getUrlParameterValueByName('REF', true);
};

export const getLangFromQueryString = () => {
	return getUrlParameterValueByName('language', true);
};

export const getNewsFromQueryString = () => {
	return getUrlParameterValueByName('news', true);
};

export const getPrescreenName = () => {
	return getUrlParameterValueByName('prescreen', true);
};

export const getScrollTo = () => {
	return getUrlParameterValueByName('scrollTo', true);
};

export const getScreenName = () => {
	return getUrlParameterValueByName('screen', true);
};

export const getView = () => {
	return getUrlParameterValueByName('view', true);
};
