/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingOptionData: false,
	fetchingStrategyChart: false,
	strategyChart: {},
	expDateData: {},
	optionChainData: {},
	quoteList: [],
	strikeCount: 20,
	viewStrikeCounts: [
		20,
		40,
		80,
		160,
		'all'
	],
	selectedOptionsList: []
};

const requestSetStrikeCount = (state, action) => {
	return Object.assign({}, state, {
		strikeCount: action.strikeCount
	});
};

const receiveOptionChain = (state, action) => {
	return Object.assign({}, state, {
		fetchingOptionData: false,
		optionChainData: action.response.data,
		optionChainByStrikeData: {}
	});
};

const receiveOptionChainByStrike = (state, action) => {
	const optionsByStrike = {};
	const { underlyingQuote } = action.response.data;
	if (action.response.data && action.response.data.items) {
		const { items } = action.response.data;

		items.forEach(item => {
			const { expirationDate, options } = item;

			options.forEach(option => {
				const { strikePrice } = option;

				if (optionsByStrike[strikePrice]) {
					optionsByStrike[strikePrice].push({
						expirationDate, ...option
					});
				} else {
					optionsByStrike[strikePrice] = [{
						expirationDate, ...option
					}];
				}
			});
		});
	}

	const orderedByStrikePrice = optionsByStrike;

	return Object.assign({}, state, {
		fetchingOptionData: false,
		optionChainData: {},
		optionChainByStrikeData: {
			orderedByStrikePrice,
			underlyingQuote
		}
	});
};

const requestOptionChain = (state, action) => {
	return Object.assign({}, state, {
		fetchingOptionData: true,
		strikeCount: action.strikeCount
	});
};

const requestExpirationDates = state => {
	return Object.assign({}, state, {
		fetchingExpData: true,
		expDateData: Object.assign({}, {})
	});
};

const receiveExpirationDates = (state, action) => {
	return Object.assign({}, state, {
		fetchingExpData: false,
		expDateData: action.response.data
	});
};

const addOptionToQuoteList = (state, action) => {
	return Object.assign({}, state, {
		quoteList: Object.assign([], state.quoteList, [action.option])
	});
};

const requestStrategyChart = state => {
	return Object.assign({}, state, {
		fetchingStrategyChart: true,
		strategyChart: Object.assign({}, {})
	});
};

const receiveStrategyChart = (state, action) => {
	return Object.assign({}, state, {
		strategyChart: Object.assign({}, state.strategyChart, action.response.data)
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

const addToSelectedOptionsList = (state, action) => {
	const { selectedOptionsList } = state;
	const { data } = action;
	return Object.assign({}, state, {
		selectedOptionsList: [...selectedOptionsList, data]
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_OPTION_CHAIN]: requestOptionChain,
	[Types.API_REQUEST_OPTION_CHAIN_WITH_QUOTES]: requestOptionChain,
	[Types.API_REQUEST_OPTION_EXPIRATION_DATES]: requestExpirationDates,
	[Types.API_RECEIVE_OPTION_EXPIRATION_DATES]: receiveExpirationDates,
	[Types.API_RECEIVE_OPTION_CHAIN]: receiveOptionChain,
	[Types.API_RECEIVE_OPTION_CHAIN_BY_STRIKE]: receiveOptionChainByStrike,
	[Types.ADD_OPTION_TO_QUOTE_LIST]: addOptionToQuoteList,
	[Types.API_CIBC_REQUEST_STRATEGY_CHART]: requestStrategyChart,
	[Types.API_CIBC_RECEIVE_STRATEGY_CHART]: receiveStrategyChart,
	[Types.SET_OPTION_CHAIN_STRIKE_COUNT]: requestSetStrikeCount,
	[Types.API_HANDLE_OPTION_LOADER]: handleLoader,
	[Types.ADD_SELECTED_OPTION]: addToSelectedOptionsList
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
