import Types from './Types';

const receiveXrefByVenueXids = response => {
	return ({
		type: Types.API_RECEIVE_XREF_BY_VENUE_XIDS,
		response
	});
};

const handleLoader = fetchName => {
	return ({
		type: Types.API_HANDLE_XREF_BY_VENUE_XIDS_LOADER,
		fetchName
	});
};

const requestXrefByVenueXids = (xids, responseHandler) => {
	return {
		type: Types.API_REQUEST_XREF_BY_VENUE_XIDS,
		endPoint: '/xref/v1/venue-xids',
		params: {},
		ajaxType: 'GET',
		newXids: xids,
		onSuccess: responseHandler || receiveXrefByVenueXids,
		loader: handleLoader,
		fetchName: 'fetchingXrefByVenueXids'
	};
};

const receiveSymbolInContext = response => {
	return ({
		type: Types.API_RECEIVE_SYMBOL_IN_CONTEXT,
		response
	});
};

const requestSymbolInContext = (exchanges, country, symbol, responseHandler) => {
	const symbols = [];

	if (exchanges
		&& exchanges.data
		&& exchanges.data.exchange
		&& exchanges.data.exchange.length > 0 && country) {
		exchanges.data.exchange.forEach(exchange => {
			const { countryIso, id } = exchange;
			if (countryIso && countryIso.toLowerCase() === country.toLowerCase() && id) {
				symbols.push({
					symbol,
					exchangeId: id,
					isCaseSensitive: false
				});
			}
		});
	} else if (Array.isArray(symbol)) {
		symbol.forEach(s => {
			symbols.push({
				symbol: s
			});
		});
	} else if (typeof symbol === 'string') {
		symbols.push({
			symbol
		});
	}

	return {
		type: Types.API_REQUEST_SYMBOL_IN_CONTEXT,
		endPoint: '/xref/v1/symbols',
		params: {
			symbols
		},
		ajaxType: 'POST',
		onSuccess: responseHandler || receiveSymbolInContext,
		on404: receiveSymbolInContext,
		loader: handleLoader,
		fetchName: 'fetchingSymbolInContext'
	};
};

const requestExchanges = (country, symbol) => {
	return {
		type: Types.API_REQUEST_EXCHANGES,
		endPoint: '/xref/v1/exchanges',
		ajaxType: 'GET',
		onSuccess: response => { return requestSymbolInContext(response, country, symbol); },
		loader: handleLoader,
		fetchName: 'fetchingExchanges'
	};
};

const requestBySymbolCountry = (country, symbol) => {
	return requestExchanges(country, symbol);
};

const receiveXidsByWsodCompany = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_XIDS_BY_COMPANYID,
		response
	});
};

const requestXidsByWsodCompany = xid => {
	return {
		type: Types.API_CIBC_REQUEST_XIDS_BY_COMPANYID,
		endPoint: '/xref/company',
		ajaxType: 'GET',
		params: {
			companyid: xid
		},
		onSuccess: receiveXidsByWsodCompany,
		loader: handleLoader,
		fetchName: 'fetchingXidsByCompanyID'
	};
};

const receiveUnderlayingXrefByVenueXids = response => {
	const actions = [{
		type: Types.API_CIBC_RECEIVE_UNDERLAYING_BY_XIDS,
		response
	}];
	const { data } = response || {};
	if (data && data.length) {
		actions.push(requestXrefByVenueXids(data.map(xref => xref.underlyingXid)));
	}

	return actions;
};

const requestUnderlayingXrefByVenueXids = xids => {
	return {
		type: Types.API_CIBC_REQUEST_UNDERLAYING_BY_XIDS,
		endPoint: '/xref/legacyByXids',
		ajaxType: 'GET',
		params: {},
		newXids: xids,
		onSuccess: receiveUnderlayingXrefByVenueXids,
		loader: handleLoader,
		fetchName: 'fetchingUnderlayingXrefByVenueXids'
	};
};

const requestXidsByLegacySymbol = (symbol, country) => {
	return {
		type: Types.API_CIBC_REQUEST_XIDS_BY_LEGACYSYMBOL,
		endPoint: '/xref/legacy',
		ajaxType: 'GET',
		params: {
			symbol,
			country
		}
	};
};

const setSymbolInContext = xref => {
	return ({
		type: Types.SET_SYMBOL_IN_CONTEXT,
		xref
	});
};

const setSymbolByXid = xref => {
	return ({
		type: Types.SET_SYMBOL_BY_XID,
		xref
	});
};

export {
	requestXrefByVenueXids,
	receiveXrefByVenueXids,
	requestSymbolInContext,
	requestBySymbolCountry,
	requestXidsByWsodCompany,
	requestExchanges,
	setSymbolInContext,
	receiveXidsByWsodCompany,
	receiveSymbolInContext,
	requestXidsByLegacySymbol,
	setSymbolByXid,
	requestUnderlayingXrefByVenueXids
};
