/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import lodash from 'lodash';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingXrefByVenueXids: false,
	recentEtfs: [],
	data: {},
	byLegacyCompany: {},
	symbolInContext: {},
	underlayingData: {},
	fetchingSymbolInContext: false,
	fetchingXidsByCompanyID: false,
	companyXids: []
};

const requestXrefByVenueXids = (state, action) => {
	const currentXids = Object.keys(state.data);
	const filterXids = action.newXids.filter(xid => currentXids.indexOf(`${xid}`) === -1);

	if (filterXids.length === 0) {
		action.abort = true;
	} else {
		action.params.venueXids = filterXids.join(',');
	}

	return Object.assign({}, state, {
		fetchingXrefByVenueXids: true
	});
};

const receiveXrefByVenueXids = (state, action) => {
	const newXrefData = {};
	const byLegacyCompanyMap = {};
	if (action.response.data && action.response.data.items) {
		const { items } = action.response.data;

		items.forEach(xrefResult => {
			if (!xrefResult.error) {
				const { xids } = xrefResult || {};
				const { venue, legacyCompany } = xids || {};
				newXrefData[venue] = xrefResult;
				byLegacyCompanyMap[legacyCompany] = xrefResult;
			}
		});
	}

	return Object.assign({}, state, {
		fetchingXrefByVenueXids: false,
		data: { ...state.data, ...newXrefData },
		byLegacyCompany: { ...state.byLegacyCompany, ...byLegacyCompanyMap }
	});
};

const receiveSymbolInContext = (state, action) => {
	const { response } = action;
	const { data } = response || {};
	const { items } = data || {};
	const previousEtfsList = state.recentEtfs;
	const foundSymbols = [];
	let newEtfsList = [];

	if (items && items.length > 0) {
		items.forEach(symbol => {
			if (!symbol.error) {
				foundSymbols.push(symbol);
			}
		});
	}
	const addSymbol = {};
	const addCompany = {};
	const hasSymbols = foundSymbols.length > 0;

	if (hasSymbols) {
		const symbol = foundSymbols[0];
		const { classification, xids } = symbol || {};
		const { venue, legacyCompany } = xids || {};
		const { id } = classification || {};

		if (venue) {
			addSymbol[venue] = symbol;
		}

		if (legacyCompany) {
			addCompany[legacyCompany] = symbol;
		}

		const prevousIndex = lodash.findLastIndex(previousEtfsList, x => {
			return x.xids.venue === symbol.xids.venue;
		});

		if (id === 18) {
			// adding the etf type
			newEtfsList.push(symbol);
		}

		if (prevousIndex > -1) {
			// was in the list already so remove it
			previousEtfsList.splice(prevousIndex, 1);
		}

		newEtfsList = newEtfsList.concat(previousEtfsList);
		// limit the list to 5 but the first(most recent) is always hidden
		if (newEtfsList.length >= 6) {
			newEtfsList = newEtfsList.slice(0, 6);
		}
	}

	return Object.assign({}, state, {
		fetchingSymbolInContext: false,
		recentEtfs: Object.assign([], newEtfsList),
		symbolInContext: hasSymbols ? foundSymbols[0] : false,
		data: { ...state.data, ...addSymbol },
		byLegacyCompany: { ...state.byLegacyCompany, ...addCompany }
	});
};

const requestExchanges = state => {
	return Object.assign({}, state, {
		fetchingSymbolInContext: true
	});
};

const requestSymbolInContext = state => {
	return Object.assign({}, state, {
		fetchingSymbolInContext: true
	});
};

const requestXidsByWsodCompany = state => {
	return Object.assign({}, state, {
		fetchingXidsByCompanyID: true,
		companyXids: []
	});
};

const receiveXidsByWsodCompany = (state, action) => {
	return Object.assign({}, state, {
		fetchingXidsByCompanyID: false,
		companyXids: action.response.data
	});
};

const requestUnderlayingXrefByVenueXids = (state, action) => {
	const currentXids = Object.keys(state.underlayingData);
	const filterXids = action.newXids.filter(xid => currentXids.indexOf(`${xid}`) === -1);

	if (filterXids.length === 0) {
		action.abort = true;
	} else {
		action.params.xids = filterXids.join(',');
	}

	return Object.assign({}, state, {
		fetchingUnderlayingXrefByVenueXids: true
	});
};

const receiveUnderlayingXrefByVenueXids = (state, action) => {
	const underlayingData = {};
	const { response } = action || {};
	const { data } = response || {};

	if (data && data.length) {
		data.forEach(xref => {
			underlayingData[xref.xid] = xref;
		});
	}

	return Object.assign({}, state, {
		fetchingUnderlayingXrefByVenueXids: false,
		underlayingData: { ...state.underlayingData, ...underlayingData }
	});
};

const setSymbolInContext = (state, action) => {
	const { xref } = action;
	const { xids } = xref || {};
	const { venue, legacyCompany } = xids || {};
	const addCompany = {};

	if (legacyCompany) {
		addCompany[legacyCompany] = xref;
	}

	return {
		...state,
		symbolInContext: xref,
		data: { ...state.data, ...{ [venue]: xref } },
		byLegacyCompany: { ...state.byLegacyCompany, ...addCompany }
	};
};

const setSymbolByXid = (state, action) => {
	const { xref } = action;
	const { xids } = xref || {};
	const { venue, legacyCompany } = xids || {};
	const newXrefData = {
		[venue]: xref
	};
	const addCompany = {};

	if (legacyCompany) {
		addCompany[legacyCompany] = xref;
	}

	return Object.assign({}, state, {
		fetchingXrefByVenueXids: false,
		data: { ...state.data, ...newXrefData },
		byLegacyCompany: { ...state.byLegacyCompany, ...addCompany }
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_XREF_BY_VENUE_XIDS]: requestXrefByVenueXids,
	[Types.API_RECEIVE_XREF_BY_VENUE_XIDS]: receiveXrefByVenueXids,
	[Types.API_RECEIVE_SYMBOL_IN_CONTEXT]: receiveSymbolInContext,
	[Types.API_REQUEST_SYMBOL_IN_CONTEXT]: requestSymbolInContext,
	[Types.API_REQUEST_EXCHANGES]: requestExchanges,
	[Types.API_HANDLE_XREF_BY_VENUE_XIDS_LOADER]: handleLoader,
	[Types.API_CIBC_RECEIVE_XIDS_BY_COMPANYID]: receiveXidsByWsodCompany,
	[Types.API_CIBC_REQUEST_XIDS_BY_COMPANYID]: requestXidsByWsodCompany,
	[Types.SET_SYMBOL_IN_CONTEXT]: setSymbolInContext,
	[Types.API_CIBC_REQUEST_UNDERLAYING_BY_XIDS]: requestUnderlayingXrefByVenueXids,
	[Types.API_CIBC_RECEIVE_UNDERLAYING_BY_XIDS]: receiveUnderlayingXrefByVenueXids,
	[Types.SET_SYMBOL_BY_XID]: setSymbolByXid,
	[Types.API_CIBC_HANDLE_XIDS_BY_COMPANYID_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
