/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingPriceChartData: false,
	data: {}
};

const requestPriceChart = state => {
	return Object.assign({}, state, {
		fetchingPriceChartData: true
	});
};

const receivePriceChart = (state, action) => {
	return Object.assign({}, state, {
		fetchingPriceChartData: false,
		data: Object.assign({}, action.response.data)

	});
};

const receiveMultiplePriceChart = (state, action) => {
	return Object.assign({}, state, {
		fetchingPriceChartData: false,
		data: Object.assign({}, state.data, { [action.response.data.venueXid]: action.response.data })

	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_SINGLE_REQUEST_PRICECHART]: requestPriceChart,
	[Types.API_CIBC_REQUEST_PRICECHART]: requestPriceChart,
	[Types.API_CIBC_RECEIVE_PRICECHART]: receivePriceChart,
	[Types.API_CIBC_RECEIVE_MULTIPLE_PRICECHART]: receiveMultiplePriceChart,
	[Types.API_CIBC_HANDLE_PRICECHART_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
