/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import chartColorHelper from 'Utils/chartColorHelper';
import lodash from 'lodash';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingMorningstarRatings: false,
	fetchingShareClass: false,
	fetchingPremiumDiscountDaily: false,
	fetchingPortfolioStatistics: false,
	fetchingManagedFundsStrategy: false,
	fetchingManagedFundsAttributes: false,
	fetchingManagedFundsAnnualReturns: false,
	fetchingManagedFundsGrowth10k: false,
	fetchingAssetAllocationData: false,
	fetchingMorningstarAnnualReturn: false,
	fetchingMorningstarTopHoldings: false,
	morningstarRatings: {},
	portfolioStatisticsData: {},
	shareClassData: {},
	fundStrategyData: {},
	benchmarkData: {},
	benchmarkDataCategories: {},
	attributeData: {},
	morningstarPeers: {},
	annualPerformance: {},
	performance: {},
	growth10KData: {
		investmentData: {}
	},
	assetAllocationData: {},
	companyInformation: {},
	annualReturnData: {},
	beta: null,
	volitility: {},
	fundDetails: [],
	prospectusFees: {},
	topHoldings: {}
};

const requestMorningstarRatings = state => {
	return Object.assign({}, state, {
		morningstarRatings: Object.assign({}),
		fetchingMorningstarRatings: true
	});
};

const receiveMorningstarRatings = (state, action) => {
	return Object.assign({}, state, {
		fetchingMorningstarRatings: false,
		morningstarRatings: Object.assign({}, action.response.data)
	});
};

const requestPremiumDiscountDaily = state => {
	return Object.assign({}, state, {
		fetchingPremiumDiscountDaily: true,
		premiumDiscountDailyData: Object.assign({})
	});
};

const receivePremiumDiscountDaily = (state, action) => {
	let premData = {};
	if (action.response.data && action.response.data.premiumDiscountDaily) {
		premData = action.response.data.premiumDiscountDaily;
	}

	return Object.assign({}, state, {
		fetchingPremiumDiscountDaily: false,
		premiumDiscountDailyData: Object.assign({}, premData)
	});
};

const requestShareClass = state => {
	return Object.assign({}, state, {
		fetchingShareClass: true,
		shareClassData: Object.assign({})
	});
};

const receiveShareClass = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingShareClass: false,
		shareClassData: Object.assign({}, premData)
	});
};

const requestPortfolioStatistics = state => {
	return Object.assign({}, state, {
		fetchingPortfolioStatistics: true,
		portfolioStatisticsData: Object.assign({})
	});
};

const receivePortfolioStatistics = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingPortfolioStatistics: false,
		portfolioStatisticsData: Object.assign({}, premData)
	});
};

const requestManagedFundsGrowth10k = state => {
	return Object.assign({}, state, {
		fetchingManagedFundsGrowth10k: true,
		growth10KData: Object.assign({})
	});
};

const receiveManagedFundsGrowth10k = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingManagedFundsGrowth10k: false,
		growth10KData: Object.assign({}, premData)
	});
};

const requestRiskMeasures = state => {
	return Object.assign({}, state, {
		fetchingRiskMeasures: true,
		beta: null,
		volitility: Object.assign({})
	});
};

const receiveRiskMeasures = (state, action) => {
	let premData = {};
	const volitility = {};
	let beta = null;
	if (action.response.data) {
		premData = action.response.data;
		const {
			fund,
			category
		} = premData || {};
		const {
			nav: fundNav,
			asOfDate: asOfDateFund
		} = fund || {};
		const { nav: categoryNav, asOfDate: asOfDateCategory } = category || {};
		const betaValue = fundNav
			&& fundNav.beta
			&& fundNav.beta.primaryIndex
			&& fundNav.beta.primaryIndex[0].value;

		if (betaValue) {
			beta = betaValue;
		}

		volitility.asOfDate = asOfDateFund || asOfDateCategory;

		const {
			rSquared: fundRsquared,
			alpha: fundAlpha,
			beta: fundBeta
		} = fundNav || {};
		const {
			rSquared: categoryRsquared,
			alpha: categoryAlpha,
			beta: categoryBeta
		} = categoryNav || {};

		const mapDataForName = (data, name, saveName, type) => {
			if (data && data[name] && data[name].length) {
				data[name].forEach(item => {
					if (!volitility[saveName]) {
						volitility[saveName] = {};
					}

					if (!volitility[saveName][item.temporalPeriod]) {
						volitility[saveName][item.temporalPeriod] = {};
					}

					volitility[saveName][item.temporalPeriod][type] = item.value;
				});
			}
		};

		mapDataForName(fundNav, 'standardDeviation', 'standardDeviation', 'fund');
		mapDataForName(fundNav, 'sharpeRatio', 'sharpeRatio', 'fund');
		mapDataForName(fundRsquared, 'primaryIndex', 'rSquared', 'fund');
		mapDataForName(fundAlpha, 'primaryIndex', 'alpha', 'fund');
		mapDataForName(fundBeta, 'primaryIndex', 'beta', 'fund');

		mapDataForName(categoryNav, 'standardDeviation', 'standardDeviation', 'category');
		mapDataForName(categoryNav, 'sharpeRatio', 'sharpeRatio', 'category');
		mapDataForName(categoryRsquared, 'primaryIndex', 'rSquared', 'category');
		mapDataForName(categoryAlpha, 'primaryIndex', 'alpha', 'category');
		mapDataForName(categoryBeta, 'primaryIndex', 'beta', 'category');
	}

	return Object.assign({}, state, {
		fetchingRiskMeasures: false,
		volitility: Object.assign({}, volitility),
		beta
	});
};

const requestManagedFundsStrategy = state => {
	return Object.assign({}, state, {
		fetchingManagedFundsStrategy: true,
		fundStrategyData: Object.assign({})
	});
};

const receiveMorningstarManagedFundsStrategy = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingManagedFundsStrategy: false,
		fundStrategyData: Object.assign({}, premData)
	});
};

const requestBenchmarkInfo = state => {
	return Object.assign({}, state, {
		fetchingBenchmarkInfo: true,
		benchmarkData: Object.assign({})
	});
};

const receiveBenchmarkInfo = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}
	const returnInfo = {};
	if (premData && premData.items && premData.items.length) {
		premData.items.forEach(item => {
			if (item.type === 'MorningstarPrimaryBenchmark') {
				returnInfo.primaryBenchmark = item.name;
			}

			if (item.type === 'GlobalCategoryAssetClass') {
				returnInfo.fundType = item.name;
			}

			if (item.type === 'MorningstarCategory') {
				returnInfo.fundCategory = item.name;
			}

			if (item.type === 'ProspectusPrimaryBenchmark') {
				returnInfo.prospectusBenchmark = item.name;
			}
		});
	}

	return Object.assign({}, state, {
		fetchingBenchmarkInfo: false,
		benchmarkData: Object.assign({}, returnInfo)
	});
};

const requestBenchmarkInfoCategory = state => {
	return Object.assign({}, state, {
		fetchingBenchmarkInfo: true,
		benchmarkDataCategories: Object.assign({})
	});
};

const receiveBenchmarkInfoCategory = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}
	const returnInfo = {};
	if (premData && premData.items && premData.items.length) {
		premData.items.forEach(item => {
			if (item.type === 'ProspectusPrimaryBenchmark') {
				returnInfo.primaryBenchmark = item.name;
			}

			if (item.type === 'GlobalCategoryAssetClass') {
				returnInfo.fundType = item.name;
			}

			if (item.type === 'MorningstarCategory') {
				returnInfo.fundCategory = item.name;
			}
		});
	}

	return Object.assign({}, state, {
		fetchingManagedFundsStrategy: false,
		benchmarkDataCategories: Object.assign({}, returnInfo)
	});
};

const requestAttributeData = state => {
	return Object.assign({}, state, {
		fetchingManagedFundsAttributes: true,
		attributeData: Object.assign({})
	});
};

const receiveAttributeData = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingManagedFundsAttributes: false,
		attributeData: Object.assign({}, premData)
	});
};

const requestMorningstarPeers = state => {
	return Object.assign({}, state, {
		fetchingMorningstarPeers: true,
		morningstarPeers: Object.assign({})
	});
};

const receiveMorningstarPeers = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingMorningstarPeers: false,
		morningstarPeers: Object.assign({}, premData)
	});
};

const requestAnnualPerformance = state => {
	return Object.assign({}, state, {
		fetchingAnnualPerformance: true
	});
};

const receiveAnnualPerformance = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	const data = (premData.items ? premData.items[0] : {});
	const venueXid = data.venueXid.toString();
	const newState = Object.assign({}, state);
	const newAnnualPerformanceData = newState.annualPerformance || {};
	newAnnualPerformanceData[venueXid] = data;

	return Object.assign({}, state, newState, {
		fetchingAnnualPerformance: false
	});
};

const requestPerformance = state => {
	return Object.assign({}, state, {
		fetchingPerformance: true
	});
};

const receivePerformance = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	let venueXid = null;
	if (action.xid) {
		venueXid = action.xid;
	}

	return {
		...state,
		performance: {
			...state.performance,
			[venueXid]: premData
		},
		fetchingPerformance: false
	};
};

const requestSectorAllocation = state => {
	return Object.assign({}, state, {
		fetchingSectorAllocationData: true,
		sectorAllocationData: Object.assign({})
	});
};

const receiveSectorAllocation = (state, action) => {
	let premData = {};
	let formatted = [];
	const sectors = {};
	let asOfDate = '';
	if (action.response.data) {
		premData = action.response.data;
		const { fundAllocation, categoryAllocation } = premData || {};
		const { datePortfolio } = fundAllocation || {};

		if (datePortfolio && asOfDate === '') {
			asOfDate = datePortfolio;
		}

		if (fundAllocation.equitySectors && fundAllocation.equitySectors.length > 0) {
			const fundSectors = fundAllocation.equitySectors;
			fundSectors.forEach(sector => {
				const { type, value } = sector || {};
				const { net } = value || {};

				if (net) {
					sectors[type] = {
						name: type,
						value: net
					};
				}
			});
		}

		if (categoryAllocation.equitySectors && categoryAllocation.equitySectors.length) {
			const catSectors = categoryAllocation.equitySectors;
			catSectors.forEach(sector => {
				const { type, value } = sector || {};
				const { net } = value || {};

				if (net && sectors[type]) {
					sectors[type].categoryValue = net;
				}
			});
		}
	}

	Object.keys(sectors).forEach(sector => {
		formatted.push(sectors[sector]);
	});

	formatted = lodash.orderBy(formatted, ['value'], ['desc']);

	formatted = formatted.map((sector, index) => {
		sector.color = chartColorHelper.getColorByIndex(index);
		return sector;
	});

	formatted = formatted.map(sector => {
		sector.isShort = sector.value < 0;
		sector.isLeveraged = sector.value > 100;
		return sector;
	});

	return Object.assign({}, state, {
		fetchingSectorAllocationData: false,
		sectorAllocationData: Object.assign({}, {
			asOfDate,
			sectors: formatted
		})
	});
};

const requestAssetAllocationData = state => {
	return Object.assign({}, state, {
		fetchingAssetAllocationData: true,
		assetAllocationData: Object.assign({}),
		assetsFormatted: Object.assign([])
	});
};

const receiveAssetAllocationData = (state, action) => {
	let premData = {};
	let formatted = [];

	if (action.response.data) {
		premData = action.response.data;
		const { items, categoryAllocation } = premData || {};
		const assets = {
			equity: {
				name: 'equity',
				value: 0,
				categoryValue: null
			},
			bonds: {
				name: 'bonds',
				value: 0,
				categoryValue: null
			}
		};

		if (items && items.length > 0) {
			items.forEach(item => {
				if (item.value.net) {
					if (item.type === 'usstock' || item.type === 'nonusstock') {
						assets.equity.value += item.value.net;
					} else if (item.type === 'usbond' || item.type === 'nonusbond') {
						assets.bonds.value += item.value.net;
					} else {
						if (assets[item.type]) {
							assets[item.type].value += item.value.net;
						} else {
							assets[item.type] = {
								name: item.type,
								value: item.value.net
							};
						}
					}
				}
			});
		}

		if (categoryAllocation && categoryAllocation.items && categoryAllocation.items.length > 0) {
			const catItems = categoryAllocation.items;
			catItems.forEach(catItem => {
				if (catItem.value.net) {
					if (catItem.type === 'usstock' || catItem.type === 'nonusstock') {
						assets.equity.categoryValue += catItem.value.net;
					} else if (catItem.type === 'usbond' || catItem.type === 'nonusbond') {
						assets.bonds.categoryValue += catItem.value.net;
					} else {
						if (assets[catItem.type] && assets[catItem.type].categoryValue) {
							assets[catItem.type].categoryValue += catItem.value.net;
						} else if (assets[catItem.type] && !assets[catItem.type].categoryValue) {
							const newData = {
								name: assets[catItem.type].name,
								value: assets[catItem.type].value,
								categoryValue: catItem.value.net
							};
							assets[catItem.type] = newData;
						}
					}
				}
			});
		}

		Object.keys(assets).forEach(key => {
			const item = assets[key];
			if (item.value !== 0) {
				formatted.push(item);
			}
		});

		if (formatted.length > 0) {
			formatted = lodash.orderBy(formatted, ['value'], ['desc']);
			formatted = formatted.map((asset, index) => {
				asset.color = chartColorHelper.getColorByIndex(index);
				return asset;
			});

			formatted = formatted.map(asset => {
				asset.isShort = asset.value < 0;
				asset.isLeveraged = asset.value > 100;
				return asset;
			});
		}
	}

	return Object.assign({}, state, {
		fetchingAssetAllocationData: false,
		assetAllocationData: Object.assign({}, premData),
		assetsFormatted: Object.assign([], formatted)
	});
};

const receiveTopHoldings = (state, action) => {
	let premData = {};
	const holdings = [];

	if (action.response.data) {
		premData = action.response.data;
		const { items } = premData || {};

		if (items && items.length > 0) {
			items.forEach((symbol, index) => {
				const {
					securityIdentifiers,
					holdingDetailDate,
					portfolioDate,
					shareDetails,
					marketValue
				} = symbol || {};
				const { name, venue } = securityIdentifiers || {};
				const { weighting } = shareDetails || {};

				holdings.push({
					name,
					value: weighting,
					asOfDate: portfolioDate || holdingDetailDate,
					xid: venue,
					color: chartColorHelper.getColorByIndex(index),
					isShort: Math.sign(marketValue) === -1 || false
				});
			});
		}
	}

	return Object.assign({}, state, {
		fetchingTopHoldings: true,
		topHoldingsData: Object.assign([], holdings)
	});
};

const requestTopHoldings = state => {
	return Object.assign({}, state, {
		fetchingTopHoldings: true,
		topHoldingsData: Object.assign([])
	});
};

const requestMorningstarCompanyInformation = state => {
	return Object.assign({}, state, {
		fetchingMorningstarCompanyInformation: true,
		companyInformation: Object.assign({})
	});
};

const receiveMorningstarCompanyInformation = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingMorningstarCompanyInformation: false,
		companyInformation: Object.assign({}, premData)
	});
};

const requestMorningstarFundDetails = state => {
	return Object.assign({}, state, {
		fetchingMorningstarFundDetails: true,
		fundDetails: []
	});
};

const receiveMorningstarFundDetails = (state, action) => {
	let premData = [];
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingMorningstarFundDetails: false,
		fundDetails: premData
	});
};

const requestMorningstarAnnualReturnData = state => {
	return Object.assign({}, state, {
		fetchingMorningstarAnnualReturn: true,
		annualReturnData: Object.assign({})
	});
};

const receivetMorningstarAnnualReturnData = (state, action) => {
	let premData = {};
	const { response } = action || {};
	const { data } = response || {};
	const obj = {
		marketReturnYTD: null,
		NAVYTDReturn: null,
		catAverageNAV: null,
		rank: null,
		catSize: null
	};

	if (data && data.items && data.items.length && data.items.length === 4) {
		obj.marketReturnYTD = data.items[0].values[data.items[0].values.length - 1] || null;
		obj.NAVYTDReturn = data.items[1].values[data.items[1].values.length - 1] || null;
		obj.rank = data.items[2].values[data.items[2].values.length - 1] || null;
		obj.catSize = data.items[3].values[data.items[3].values.length - 1] || null;
		if (
			data.category
			&& data.category.items
			&& data.category.items.length
			&& data.category.items.length >= 2
		) {
			obj.catAverageNAV = data.category.items[1].values[data.category.items[1].values.length - 1]
			|| null;
		}
	}

	if (data) {
		data.YTDValues = obj;
		premData = data;
	}

	return Object.assign({}, state, {
		fetchingMorningstarAnnualReturn: false,
		annualReturnData: Object.assign({}, premData)
	});
};

const requestMorningstarProspectusFees = state => {
	return Object.assign({}, state, {
		fetchingMorningstarProspectusFees: true,
		fundDetails: []
	});
};

const receiveMorningstarProspectusFees = (state, action) => {
	let premData = [];
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingMorningstarProspectusFees: false,
		prospectusFees: premData
	});
};

const requestMorningstarTopHoldings = state => {
	return {
		...state,
		fetchingMorningstarTopHoldings: true,
		topHoldings: []
	};
};

const receiveMorningstarTopHoldings = (state, action) => {
	let premData = [];
	if (action.response.data) {
		premData = action.response.data;
	}

	return {
		...state,
		fetchingMorningstarTopHoldings: false,
		topHoldings: premData
	};
};

const clearMorningstarHoldings = state => {
	return {
		...state,
		fetchingMorningstarTopHoldings: false,
		topHoldings: []
	};
};

const requestMorningstarAnnualFees = state => {
	return {
		...state,
		fetchingMorningstarAnnualFees: true,
		annualFees: {}
	};
};

const receiveMorningstarAnnualFees = (state, action) => {
	let tempFees = {};
	if (action && action.response && action.response.data) {
		tempFees = action.response.data;
	}
	return {
		...state,
		fetchingMorningstarAnnualFees: false,
		annualFees: tempFees
	};
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_MORNINGSTAR_RATINGS]: requestMorningstarRatings,
	[Types.API_RECEIVE_MORNINGSTAR_RATINGS]: receiveMorningstarRatings,
	[Types.API_REQUEST_MORNINGSTAR_PREMIUM_DISCOUNT_DAILY]: requestPremiumDiscountDaily,
	[Types.API_RECEIVE_MORNINGSTAR_PREMIUM_DISCOUNT_DAILY]: receivePremiumDiscountDaily,
	[Types.API_REQUEST_MORNINGSTAR_SHARE_CLASS]: requestShareClass,
	[Types.API_RECEIVE_MORNINGSTAR_SHARE_CLASS]: receiveShareClass,
	[Types.API_RECEIVE_MORNINGSTAR_PORTFOLIO_STATISTICS]: receivePortfolioStatistics,
	[Types.API_REQUEST_MORNINGSTAR_PORTFOLIO_STATISTICS]: requestPortfolioStatistics,
	[Types.API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_STRATEGY]: requestManagedFundsStrategy,
	[Types.API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_STRATEGY]: receiveMorningstarManagedFundsStrategy,
	[Types.API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK]: receiveBenchmarkInfo,
	[Types.API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK]: requestBenchmarkInfo,
	[Types.API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK_CATEGORY]: receiveBenchmarkInfoCategory,
	[Types.API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_BENCHMARK_CATEGORY]: requestBenchmarkInfoCategory,
	[Types.API_REQUEST_MORNINGSTAR_MANAGED_FUNDS_ATTRIBUTES]: requestAttributeData,
	[Types.API_RECEIVE_MORNINGSTAR_MANAGED_FUNDS_ATTRIBUTES]: receiveAttributeData,
	[Types.API_REQUEST_MORNINGSTAR_RISK_MEASURES]: requestRiskMeasures,
	[Types.API_RECEIVE_MORNINGSTAR_RISK_MEASURES]: receiveRiskMeasures,
	[Types.API_REQUEST_MORNINGSTAR_PEERS]: requestMorningstarPeers,
	[Types.API_RECEIVE_MORNINGSTAR_PEERS]: receiveMorningstarPeers,
	[Types.API_REQUEST_MORNINGSTAR_ANNUAL_PERFORMANCE]: requestAnnualPerformance,
	[Types.API_RECEIVE_MORNINGSTAR_ANNUAL_PERFORMANCE]: receiveAnnualPerformance,
	[Types.API_REQUEST_MORNINGSTAR_PERFORMANCE]: requestPerformance,
	[Types.API_RECEIVE_MORNINGSTAR_PERFORMANCE]: receivePerformance,
	[Types.API_REQUEST_MORNINGSTAR_GROWTH_10K]: requestManagedFundsGrowth10k,
	[Types.API_RECEIVE_MORNINGSTAR_GROWTH_10K]: receiveManagedFundsGrowth10k,
	[Types.API_REQUEST_MORNINGSTAR_ASSET_ALLOCATION]: requestAssetAllocationData,
	[Types.API_RECEIVE_MORNINGSTAR_ASSET_ALLOCATION]: receiveAssetAllocationData,
	[Types.API_RECEIVE_MORNINGSTAR_SECTOR_ALLOCATION]: receiveSectorAllocation,
	[Types.API_REQUEST_MORNINGSTAR_SECTOR_ALLOCATION]: requestSectorAllocation,
	[Types.API_RECEIVE_MORNINGSTAR_TOP_HOLDINGS]: receiveTopHoldings,
	[Types.API_REQUEST_MORNINGSTAR_COMPANY_INFORMATION]: requestMorningstarCompanyInformation,
	[Types.API_RECEIVE_MORNINGSTAR_COMPANY_INFORMATION]: receiveMorningstarCompanyInformation,
	[Types.API_CIBC_REQUEST_MORNINGSTAR_FUND_DETAILS]: requestMorningstarFundDetails,
	[Types.API_CIBC_RECEIVE_MORNINGSTAR_FUND_DETAILS]: receiveMorningstarFundDetails,
	[Types.API_CIBC_REQUEST_MORNINGSTAR_TOP_HOLDINGS]: requestTopHoldings,
	[Types.API_RECIEVE_MORNINGSTAR_ANNUAL_RETURN_DATA]: receivetMorningstarAnnualReturnData,
	[Types.API_REQUEST_MORNINGSTAR_ANNUAL_RETURN_DATA]: requestMorningstarAnnualReturnData,
	[Types.API_RECEIVE_MORNINGSTAR_PROSPECTUS_FEES]: receiveMorningstarProspectusFees,
	[Types.API_REQUEST_MORNINGSTAR_PROSPECTUS_FEES]: requestMorningstarProspectusFees,
	[Types.API_CIBC_RECEIVE_TOP_HOLDINGS]: receiveMorningstarTopHoldings,
	[Types.API_CIBC_REQUEST_TOP_HOLDINGS]: requestMorningstarTopHoldings,
	[Types.API_CLEAR_TOP_HOLDINGS]: clearMorningstarHoldings,
	[Types.API_REQUEST_MORNINGSTAR_ANNUAL_FEES]: requestMorningstarAnnualFees,
	[Types.API_RECEIVE_MORNINGSTAR_ANNUAL_FEES]: receiveMorningstarAnnualFees,
	[Types.API_HANDLE_MORNINGSTAR_RATINGS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
