/* eslint-disable no-console */
/* eslint-disable max-len */
import {
	call,
	put,
	takeEvery,
	takeLatest
} from 'redux-saga/effects';
import {
	getCibcApiRequestTypes,
	getCibcApiSingleRequestTypes,
	getAjaxHandlerType
} from './sagaHelpers';
import { apiFailure } from '../Actions/UtilityActions';
import { receiveUserInfoFail } from '../Actions/UserActions';

export default api => {
	function* worker(action) {
		const {
			ajaxType,
			params,
			endPoint,
			options
		} = action;
		// Get the handler for the verb type GET, POST, PUT, DELETE, ect
		const apiType = getAjaxHandlerType(ajaxType, api);

		try {
			let response;

			if (action.abort) {
				yield call(action.loader(action.fetchName));
			} else {
				// call the API
				response = yield call(apiType, endPoint, params, options);
			}

			switch (response.status) {
				case 304:
				case 200:
					// call our success method to hand the data off
					yield put(action.onSuccess(response.data, action.responseDetails));
					break;
					// Unauthorized request - attempt a logout
				case 400:
					if (action.on400) {
						yield put(action.on400(response.data));
					} else {
						yield put(apiFailure(`400 data response from action: ${action.type}`));
					}
					break;
				case 401:
				case 403:
					yield put(receiveUserInfoFail(response));
					yield put(apiFailure('bad auth'));
					break;
				default:
					yield put(apiFailure(`Bad data response from action: ${action.type}`));
					break;
			}
		} catch (error) {
			// Did we throw an error anywhere?
			yield put(apiFailure(`bad call cibc data ${error}`));
		} finally {
			// Catchall to turn off the loader
			if (action.loader && action.fetchName) {
				yield put(action.loader(action.fetchName));
			}
		}
	}

	function* watcher() {
		yield takeEvery(getCibcApiRequestTypes(), worker);
		yield takeLatest(getCibcApiSingleRequestTypes(), worker);
	}

	return {
		watcher,
		worker
	};
};
