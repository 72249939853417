/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from 'Actions/Types';

export const INITIAL_STATE = {
	fetchingBuzzNews: false,
	data: {}
};

const requestBuzzNews = state => {
	return Object.assign({}, state, {
		fetchingBuzzNews: true
	});
};

const receiveBuzzNews = (state, action) => {
	return Object.assign({}, state, {
		fetchingBuzzNews: false,
		data: Object.assign({}, action.response)
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_BUZZNEWS]: requestBuzzNews,
	[Types.API_CIBC_RECEIVE_BUZZNEWS]: receiveBuzzNews,
	[Types.API_CIBC_HANDLE_BUZZNEWS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
