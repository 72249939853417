/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';

import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingFinancials: false,
	financialsData: []
};

const requestFinancials = state => {
	return Object.assign({}, state, {
		fetchingFinancials: true,
		financialsData: []
	});
};

const orderKeys = data => {
	if (data) {
		const keys = Object.keys(data);
		const orderedKeys = keys.map(key => {
			return { key, order: data[key].order };
		}).sort((a, b) => {
			return a.order - b.order;
		}).map(key => {
			return key.key;
		});

		return orderedKeys;
	}
	return [];
};

const receiveFinancials = (state, action) => {
	let data = [];
	if (action.response.data) {
		data = action.response.data;

		// Takes out the date field, then filters and orders all the data
		// So that it's ordered in a logical, and easy to read order
		const tempData = { ...data };
		delete tempData.date;
		const tempDataNoDate = {};
		Object.keys(tempData).forEach(key => {
			Object.assign(tempDataNoDate, tempData[key]);
		});
		data.orderedKeys = orderKeys(tempDataNoDate);
	}

	return Object.assign({}, state, {
		fetchingFinancials: false,
		financialsData: data
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_FINANCIALS]: requestFinancials,
	[Types.API_CIBC_RECEIVE_FINANCIALS]: receiveFinancials,
	[Types.API_HANDLE_FINANCIALS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
