import Types from './Types';
import { requestXrefByVenueXids } from './xrefActions';

const watchlistTabs = {
	quoteDetail: 'quotedetail',
	portfolio: 'portfolio',
	performance: 'performance',
	fundamentals: 'fundamentals',
	estimates: 'estimates'
};

const holdingsGroups = {
	ETFS: 'etfs',
	MUTUALFUNDS: 'mutualFunds',
	OPTIONS: 'options',
	STOCKS: 'stocks'
};

const getPathByTab = (portfolioId, tab) => {
	switch (tab) {
		case watchlistTabs.quoteDetail:
			return `portfolio/${portfolioId}/quotedetail`;
		case watchlistTabs.performance:
			return `portfolio/${portfolioId}/performance`;
		case watchlistTabs.fundamentals:
			return `portfolio/${portfolioId}/fundamentals`;
		case watchlistTabs.estimates:
			return `portfolio/${portfolioId}/estimates`;
		case watchlistTabs.portfolio:
		default:
			return `portfolio/${portfolioId}/`;
	}
};

const getRealtimeByTier = tier => {
	const { code } = tier || {};

	switch (code) {
		case 'IEE':
		case 'IISE':
		case 'PEE':
			return true;
		default:
			return false;
	}
};

const handleLoader = fetchName => {
	return ({
		type: Types.API_HANDLE_WATCHLIST_LOADER,
		fetchName
	});
};

const receiveWatchlistNames = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_NAMES,
		response
	});
};

const requestWatchlistNames = (includeCounts = false, successCallback) => {
	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_NAMES,
		endPoint: 'portfolios',
		params: {
			includeCounts
		},
		ajaxType: 'GET',
		onSuccess: successCallback || receiveWatchlistNames,
		loader: handleLoader,
		fetchName: 'fetchingWatchlistNames'
	};

	return result;
};

const receiveWatchlistQuotes = response => {
	return ({
		type: Types.API_RECEIVE_WATCHLIST_QUOTE,
		response
	});
};

const requestWatchlistQuotes = (xids, tier) => {
	const xidsString = xids.join(',');
	const result = {
		type: Types.API_REQUEST_WATCHLIST_QUOTE,
		endPoint: '/quote/v1/details',
		params: {
			venueXids: xidsString,
			marketHoursTradeType: 'normal'
		},
		ajaxType: 'GET',
		headers: {
			realtime: getRealtimeByTier(tier)
		},
		onSuccess: receiveWatchlistQuotes,
		loader: handleLoader,
		fetchName: 'fetchingWatchlistQuoteData'
	};

	return result;
};

const receiveWatchlist = (response, tab, tier) => {
	const actions = [{
		type: Types.API_CIBC_RECEIVE_WATCHLIST,
		response
	}];

	const xids = [];
	const optionXids = [];

	if (response && response.data && response.data.holdings && response.data.holdings.length > 0) {
		response.data.holdings.forEach(item => {
			if (item.active) {
				xids.push(item.xid);
				if (item.issueType && item.issueType.toLowerCase() === 'op') {
					optionXids.push(item.xid);
					if (item.underlyingXid && xids.indexOf(item.underlyingXid) === -1) {
						xids.push(item.underlyingXid);
					}
				}
			}
		});
	}

	// Make xref and qoute calls for holdings if we have them
	if (xids.length > 0) {
		actions.push(requestXrefByVenueXids(xids));

		if (tab === watchlistTabs.quoteDetail) {
			actions.push(requestWatchlistQuotes(xids, tier));
		} else if (optionXids.length > 0) {
			const useTier = tab === watchlistTabs.quoteDetail ? tier : null;
			actions.push(requestWatchlistQuotes(optionXids, useTier));
		}
	}

	return actions;
};

const setActiveWatchilistId = activeWatchlistId => {
	return ({
		type: Types.SET_ACTIVE_WATCHLIST_ID,
		activeWatchlistId
	});
};

const requestWatchlist = (portfolioId, tab, currency = 'CAD', tier = {}, isRecentQuotes = false) => {
	const params = {
		currency
	};

	if (isRecentQuotes) {
		params.sortBy = 'lastUpdateTime';
		params.sortDir = 'DESC';
	}

	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST,
		endPoint: getPathByTab(portfolioId, tab),
		params,
		ajaxType: 'GET',
		onSuccess: response => { return receiveWatchlist(response, tab, tier); },
		activeWatchlistId: portfolioId,
		activeWatchlistTab: tab,
		loader: handleLoader,
		fetchName: 'fetchingWatchlist'
	};
	return result;
};

const sortHoldingsGroup = (holdingGroup, field, direction) => {
	return ({
		type: Types.SORT_WATCHLIST,
		field,
		direction,
		holdingGroup
	});
};

const setWatchlistTableSortIndicators = (holdingGroup, field, direction) => {
	return ({
		type: Types.SORT_WATCHLIST_SETINDICATORS,
		field,
		direction,
		holdingGroup
	});
};

const receiveUpdateTransaction = (response, portfolioId, currency) => {
	const actions = [];
	actions.push(requestWatchlist(portfolioId, watchlistTabs.portfolio, currency));
	actions.push({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_UPDATE_TRANSACTION,
		response
	});
	return actions;
};

const requestUpdateTransaction = (portfolioId, transactionId, holdingId, shares, price, currency = 'CAD') => {
	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_UPDATE_TRANSACTION,
		endPoint: `portfolio/${portfolioId}/editpurchasedetails`,
		params: [{
			transactionId,
			holdingId,
			shares: Number(shares),
			price: Number(price)
		}],
		ajaxType: 'PUT',
		onSuccess: response => receiveUpdateTransaction(response, portfolioId, currency),
		loader: handleLoader,
		fetchName: 'fetchingWatchlistQuoteData'
	};
	return result;
};

const receiveAddHoldings = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_ADD_HOLDINGS,
		response
	});
};

const requestAddHoldings = (portfolioId, xids) => {
	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_ADD_HOLDINGS,
		endPoint: `portfolio/${portfolioId}/addholdings`,
		params: xids,
		ajaxType: 'PUT',
		onSuccess: receiveAddHoldings,
		loader: handleLoader,
		fetchName: 'fetchingAddHoldings'
	};
	return result;
};

const receiveAddToRecentQuotes = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_ADD_TO_RECENT_QUOTES,
		response
	});
};

const requestAddToRecentQuotes = xid => {
	const result = {
		type: Types.API_CIBC_REQUEST_ADD_TO_RECENT_QUOTES,
		endPoint: `portfolio/addToRecentQuotes/${xid}`,
		ajaxType: 'PUT',
		onSuccess: receiveAddToRecentQuotes,
		loader: handleLoader,
		fetchName: 'fetchingAddToRecentQuotes'
	};
	return result;
};

const receiveDeleteHoldings = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_DELETE_HOLDINGS,
		response
	});
};

const requestDeleteHoldings = (portfolioId, holdingIds) => {
	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_DELETE_HOLDINGS,
		endPoint: `portfolio/${portfolioId}/deleteholdings`,
		params: holdingIds,
		ajaxType: 'DELETE',
		onSuccess: receiveDeleteHoldings,
		loader: handleLoader,
		fetchName: 'fetchingDeleteHoldings'
	};
	return result;
};

const receiveCreateNewWatchlist = (response, name, xids) => {
	const actions = [];
	let portfolioId = null;

	if (response && response.data && response.data.length) {
		const { data } = response;
		const portfolio = data.filter(watchlist => {
			return watchlist.name === name;
		})[0];
		portfolioId = portfolio.portfolioId;
		actions.push(requestAddHoldings(portfolioId, xids));
	}

	actions.push({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_CREATE_WATCHLIST,
		response,
		portfolioId
	});

	return actions;
};

const setRenameWatchlistError = value => {
	return ({
		type: Types.API_CIBC_SET_WATCHLIST_RENAME_ERROR,
		value
	});
};

const requestCreateNewWatchlist = (name, defaultPortfolio, xids = []) => {
	const params = {
		name
	};

	if (defaultPortfolio !== undefined) {
		params.defaultPortfolio = defaultPortfolio;
	}

	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_CREATE_WATCHLIST,
		endPoint: 'portfolios/create',
		params,
		ajaxType: 'GET',
		onSuccess: response => receiveCreateNewWatchlist(response, name, xids),
		on400: () => setRenameWatchlistError(true),
		loader: handleLoader,
		fetchName: 'fetchingCreateNewWatchlist'
	};
	return result;
};

const receiveRenameWatchlist = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_RENAME,
		response
	});
};

const requestRenameWatchlist = (portfolioId, oldName, name, defaultPortfolio) => {
	const params = {
		oldName,
		name
	};

	if (defaultPortfolio !== undefined) {
		params.defaultPortfolio = defaultPortfolio;
	}

	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_RENAME,
		endPoint: `portfolio/${portfolioId}/rename`,
		params,
		ajaxType: 'GET',
		onSuccess: receiveRenameWatchlist,
		on400: () => setRenameWatchlistError(true),
		loader: handleLoader,
		fetchName: 'fetchingRenameWatchlist'
	};
	return result;
};

const receiveDeleteWatchlist = response => {
	return ({
		type: Types.API_CIBC_RECEIVE_WATCHLIST_DELETE,
		response
	});
};

const requestDeleteWatchlist = portfolioId => {
	const result = {
		type: Types.API_CIBC_REQUEST_WATCHLIST_DELETE,
		endPoint: `portfolio/${portfolioId}/delete`,
		ajaxType: 'DELETE',
		onSuccess: receiveDeleteWatchlist,
		loader: handleLoader,
		fetchName: 'fetchingDeleteWatchlist'
	};
	return result;
};

export {
	requestWatchlistNames,
	receiveWatchlistNames,
	requestWatchlist,
	requestUpdateTransaction,
	requestCreateNewWatchlist,
	requestAddHoldings,
	requestDeleteHoldings,
	requestRenameWatchlist,
	requestDeleteWatchlist,
	watchlistTabs,
	holdingsGroups,
	sortHoldingsGroup,
	requestAddToRecentQuotes,
	setWatchlistTableSortIndicators,
	setActiveWatchilistId,
	setRenameWatchlistError
};
