/* eslint-disable no-restricted-globals */
/* eslint-disable no-mixed-spaces-and-tabs */
// https://format.dev.services.mdgapp.net/ formatting docs.
import MarkitFormat from '@markit/format';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'moment/locale/fr';
import NoData from 'components/NoData/NoData';
import React from 'react';
import { get, forEach, set } from 'lodash';

const formatOptions = {
	dateFormat: 'L',
	errorString: '--',
	inputTimezone: 'America/New_York',
	locale: null, // MIGHTDO pull this from query string if CIBC decides to pass language there
	percentModifier: 0.01,
	precision: {
		number: 0,
		percent: 2,
		price: 2
	},
	showNegAsParentheses: false,
	showSign: false,
	timeFormat: 'LT'
};

const frenchLocale = {
	delimiters: {
		thousands: ' ',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinal: number => {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: ' $'
	}
};

MarkitFormat.extend({
	myLocale(lang) {
		MarkitFormat.setLocale(lang);
		if (lang === 'fr') {
			this.numeral.locales.fr = frenchLocale;
		}
	},

	setDocumentLocale(lang) {
		// eslint-disable-next-line no-undef
		const htmlDoc = document.getElementsByTagName('html')[0];
		htmlDoc.setAttribute('lang', lang);
	},
	unformat(formattedInput) {
		if (formattedInput && typeof formattedInput === 'string') {
			return this.numeral(formattedInput).value();
		}
		return formattedInput;
	},
	isValidNumber(input) {
		if (input !== undefined && input != null && input !== '' && !isNaN(input) && input !== -32768 && input !== '-32768') {
			return true;
		}
		return false;
	},
	isValidString(input) {
		if (input && input != null && input.replace(/^\s+/, '').replace(/\s+$/, '') !== '' && input !== '-32768') {
			return true;
		}
		return false;
	},
	isValidDateString(input) {
		if (input && typeof (input) === 'string' && input.length) {
			const year = this.moment(input, 'YYYY').year();
			if (year >= 1900) {
				return true;
			}
		}
		return false;
	},
	isValidMsDate(input) {
		return this.isValidNumber(input) && input > 2000;
	},
	/**
	 * Checks string for null or emtpy values and returns error string or input as a string.
	 * @param {string || number} input
	 */
	string(input) {
		if (input === undefined || input === null || input === '' || input === -32768 || input === '-32768') {
			return this.errorValue();
		}
		return `${input}`;
	},
	/**
	 * Just returns the currently configured error value
	 */
	errorValue() {
		return <NoData errorString={this.options.errorString} />;
	},
	/**
	 * Formats number as price and variable decimal places, min 2 max 4 based on data
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	priceVariablePrecision(input, decimals) {
		if (input === 0 && !decimals) {
			return this.numeral(input).format('0.00');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}

		let decimalString = '00[00]';
		if (decimals) {
			decimalString = '[';
			for (let index = 0; index < decimals; index += 1) {
				decimalString = `${decimalString}0`;
			}
			decimalString = `${decimalString}]`;
		}

		if (this.moment.locale() === 'fr') {
			return this.numeral(input).format(`0,000.${decimalString}$`);
		}
		return this.numeral(input).format(`$0,000.${decimalString}`);
	},
	/**
	 * Formats number as price and variable decimal places, min 2 max 4 based on data
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	priceWithSignVariablePrecision(input, decimals) {
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}

		let decimalString = '00[00]';
		if (decimals) {
			decimalString = '[';
			for (let index = 0; index < decimals; index += 1) {
				decimalString = `${decimalString}0`;
			}
			decimalString = `${decimalString}]`;
		}

		if (input > 0) {
			if (this.moment.locale() === 'fr') {
				return this.numeral(input).format(`+0,000.${decimalString}$`);
			}
			return this.numeral(input).format(`+$0,000.${decimalString}`);
		}

		if (this.moment.locale() === 'fr') {
			return this.numeral(input).format(`0,000.${decimalString}$`);
		}
		return this.numeral(input).format(`$0,000.${decimalString}`);
	},
	/**
	 * Formats number with variable decimal places, min 2 max 4 based on data
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	numberVariablePrecision(input, decimals) {
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		let decimalString = '00[00]';
		if (decimals) {
			decimalString = '[';
			for (let index = 0; index < decimals; index += 1) {
				decimalString = `${decimalString}0`;
			}
			decimalString = `${decimalString}]`;
		}
		return this.numeral(input).format(`0,000.${decimalString}`);
	},
	/**
	 * Formats number with variable decimal places, min 2 max 4 based on data with sign
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	numberWithSignVariablePrecision(input, decimals) {
		if (input === 0 && !decimals) {
			return this.numeral(input).format('0.00');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		let decimalString = '00[00]';
		if (decimals) {
			decimalString = '[';
			for (let index = 0; index < decimals; index += 1) {
				decimalString = `${decimalString}0`;
			}
			decimalString = `${decimalString}]`;
		}
		return this.numeral(input).format(`+0,000.${decimalString}`);
	},
	/**
	 * Formats number as percent with variable decimal places, min 2 max 4 based on data with sign
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	percentWithSignVariablePrecision(input) {
		if (input === 0) {
			return this.numeral(input).format('0.00%');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.numeral(input / 100).format('+0.00[00]%');
	},
	/**
	 * Formats number as percent with variable decimal places, min 2 max 4 based on data
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	percentVariablePrecision(input) {
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.numeral(input / 100).format('0.00[00]%');
	},
	/**
	 * Formats number as price with no sign
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	priceNoSign(input, precision = null, options = null) {
		if (input === 0) {
			return this.numeral(input).format('0.00');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		if (precision != null) {
			if (this.moment.locale() === 'fr') {
				const formatted = this.price(input, precision, { showSign: false, ...options });
				return `${formatted.replace('$', '')} $`;
			}
			return this.price(input, precision, { showSign: false, ...options });
		}
		return this.priceVariablePrecision(input);
	},
	/**
	 * Formats number as price with sign
	 * @param {number} input
	 */
	priceWithSign(input, precision = null, options = null) {
		if (input === 0) {
			return this.numeral(input).format('0.00');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		if (precision != null) {
			if (this.moment.locale() === 'fr') {
				const formatted = this.price(input, precision, { showSign: true, ...options });
				return `${formatted.replace('$', '')} $`;
			}
			return this.price(input, precision, { showSign: true, ...options });
		}
		return this.priceWithSignVariablePrecision(input);
	},
	/**
	 * Formats number as percent multiplied by 100
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	percentMultiplied(input, precision = 2, options = null) {
		if (input === 0) {
			return this.numeral(input).format('0.00%');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.percent(input, precision, { percentModifier: 1, ...options });
	},
	/**
	 * Formats number as percent with sign
	 * @param {number} input
	 */
	percentWithSign(input, precision = 2, options = null) {
		if (input === 0) {
			return this.numeral(input).format('0.00%');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.percent(input, precision, { showSign: true, ...options });
	},
	/**
	 * Formats number as percent with no sign
	 * @param {number} input
	 */
	percentNoSign(input, precision = 2, options = null) {
		if (input === 0) {
			return this.numeral(input).format('0.00%');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.percent(input, precision, { showSign: false, ...options });
	},
	/**
	 * Formats number with sign
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	numberWithSign(input, precision = null, options = null) {
		if (input === 0) {
			return this.numeral(input).format('0.00');
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		if (precision != null) {
			return this.number(input, precision, { showSign: true, ...options });
		}
		return this.numberWithSignVariablePrecision(input);
	},
	numberInput(input) {
		return this.numeral(input).format('0,000.[00]');
	},
	/**
	 * Formats number with no sign
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	numberNoSign(input, precision = null, options = null) {
		if (input === 0) {
			return precision !== null
				? this.number(input, precision, { showSign: false, ...options })
				: this.numeral(input).format('0.00');
		}

		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		if (precision != null) {
			return this.number(input, precision, { showSign: false, ...options });
		}
		return this.numberVariablePrecision(input);
	},
	numberupTo5Decimal(input) {
		return this.numeral(input).format('0,000.[00000]');
	},
	/**
	 * Adds x to the from of the bid or ask size "x3"
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	bidSize(input, precision = 0, options = null) {
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return `${input ? 'x' : ''}${this.number(input, precision, { ...options })}`;
	},
	/**
	 * Adds x to the back of the bid or ask size "13.21x"
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	numberX(input, precision = 2, options = null) {
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return `${this.number(input, precision, { ...options })}x`;
	},
	/**
	 * Formats number with magnitude indicator
	 * 2 m, 1.4 k, if the value is < 1000 the precision is ignored;
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	priceVariableMagnitude(input, precision = 1, options = null) {
		if (precision !== null && input < 1000) {
			// eslint-disable-next-line no-param-reassign
			precision = null;
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.price(input, precision, { showMagnitude: true, ...options }).replace(' ', '').toUpperCase();
	},
	/**
	 * Formats number with magnitude indicator
	 * 2 m, 1.4 k, if the value is < 1000 the precision is ignored;
	 * @param {number} input
	 * @param {number} precision
	 * @param {object} options
	 */
	numberVariableMagnitude(input, precision = null, options = null) {
		if (precision !== null && input < 1000) {
			// eslint-disable-next-line no-param-reassign
			precision = null;
		}
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.number(input, precision, { showMagnitude: true, ...options }).replace(' ', '').toUpperCase();
	},
	/**
	 * Formats number with magnitude indicator 2 m, 1.4 k
	 * @param {*} input
	 * @param {*} precision
	 * @param {*} options
	 */
	numberMagnitude(input, precision = null, options = null) {
		if (!this.isValidNumber(input)) {
			return this.errorValue();
		}
		return this.number(input, precision, { showMagnitude: true, ...options }).replace(' ', '').toUpperCase();
	},
	/**
	 * Formats date object as Wednesday, October 21, 2015 10:29 AM
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	dateTimeLong(input, format = 'LLLL', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, options);
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 10/12/20
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearShort(input, format = 'M/DD/YY', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 10/12/2020
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayLongYear(input, format = 'M/DD/YYYY', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as October 21
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayShort(input, format = 'MMMM DD', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as Oct 21, 2020
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYear(input, format = 'MMM DD, YYYY', options = null) {
		if (this.isValidDateString(input)
		|| (options && options.isMSDate && this.isValidMsDate(input))) {
			return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
		}
		return this.errorValue();
	},
	/**
	 * Formats date object as Oct 21, 2020 - no conversion to ET
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearUtc(input, format = 'MMM DD, YYYY', options = null) {
		if (this.isValidDateString(input)
		|| (options && options.isMSDate && this.isValidMsDate(input))) {
			return this.time(input, format, { outputTimezone: 'UTC', ...options });
		}
		return this.errorValue();
	},
	/**
	 * Formats date object as Oct 21, 2020 3:15 ET
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDay2DigitYearTime(input, format = 'MMM DD, YY h:mm', options = null) {
		if (this.isValidDateString(input)) {
			return `${this.time(input, format, { outputTimezone: 'America/New_York', ...options })} ET`;
		}
		return this.errorValue();
	},
	/**
	 * Formats date object as Oct
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	month(input, format = 'MMM', options = null) {
		return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
	},
	/**
	 * Formats date object as 21
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	day(input, format = 'DD', options = null) {
		return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
	},
	/**
	 * Formats date object as 21
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	year(input, format = 'YY', options = null) {
		return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
	},
	/**
	 * Formats date object as October 21, 2020
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearLong(input, format = 'MMMM DD, YYYY', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as Wednesday, October 21, 2015 10:29 AM
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearTime(input, format = 'MMM DD, YYYY h:mmA', options = null) {
		if (this.isValidDateString(input)) {
			return `${this.time(input, format, { outputTimezone: 'America/New_York', ...options })} ET`;
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 2020-05-29 12:07AM ET
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearTimeNoZone(input, format = 'MMM DD, YYYY h:mmA z', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, { ...options });
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 05/28/20 10:07PM ET
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearShortTimeNoZone(input, format = 'M/DD/YY h:mmA z', options = null) {
		if (this.isValidDateString(input)) {
			return this.time(input, format, { ...options });
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 05/28/2020 10:07PM ET
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	monthDayYearShortTimeUTCtoET(input, format = 'M/DD/YYYY h:mm A', options = {
		inputTimezone: 'UTC',
		outputTimezone: 'America/New_York'
	}) {
		if (this.isValidDateString(input)) {
			return `${this.time(input, format, { ...options })} ET`;
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 05/28/2020 10:07PM
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	 monthDayYearShortTime(input,
		format = this.moment.locale() === 'en' ? 'MM/DD/YYYY h:mm A' : 'DD/MM/YYYY \\à H \\h mm',
	    options = null) {
		if (this.isValidDateString(input)) {
			return `${this.time(input, format, { ...options })}`;
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 10:29 AM ET
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	timeWithTimezone(input, format = 'h:mmA', options = null) {
		if (this.isValidDateString(input)) {
			return `${this.time(input, format, { outputTimezone: 'America/New_York', ...options })} ET`;
		}

		return this.errorValue();
	},
	/**
	 * Formats date object as 2020-10-21 10:29AM
	 * @param {object} input
	 * @param {string} format
	 * @param {object} options
	 */
	yearMonthDayTimeNoZone(input, format = 'YYYY-MM-DD h:mmA', options = null) {
		return this.time(input, format, { outputTimezone: 'America/New_York', ...options });
	},
	/**
	 * Returns class 'red' for negative or 'green' for positive
	 * @param {*} input
	 */
	getColorClass(input) {
		if (this.isValidNumber(input) && input !== 0) {
			return input > 0 ? 'green' : 'red';
		}
		return '';
	},
	ratio(input, precision, options) {
		return `${this.number(input, precision, { ...options })}${this.isValidNumber(input) ? 'x' : ''}`;
	},
	peRatio(input, precision, options) {
		if (this.isValidNumber(input) && input > 0) {
			return this.ratio(input, precision, options);
		}

		return this.errorValue();
	},
	fileSize(input) {
		if (this.isValidNumber(input)) {
			const value = Math.floor(input / 1024);
			return `${value} KB`;
		}

		return this.errorValue();
	},
	upDown(input) {
		let result = '';
		if (input && this.isValidNumber(input) && (input !== 0)) {
			result = (input > 0 ? 'up' : 'down');
		}

		return result;
	},
	/**
	 * converts number value to AAA credit string
	 */
	creditQualityConverter(credit, translate) {
		let creditText = this.errorValue();

		if (credit < 3) {
			creditText = 'AAA';
		} else if (credit < 6) {
			creditText = 'AA';
		} else if (credit < 9) {
			creditText = 'A';
		} else if (credit < 12) {
			creditText = 'BBB';
		} else if (credit < 15) {
			creditText = 'BB';
		} else if (credit < 18) {
			creditText = 'B';
		} else {
			creditText = translate('belowB');
		}

		return creditText;
	},
	/**
	 * Removes the utc timezone signifier from a date object/string property on an object
	 * and replaces it in-place with the specified date format
	 * @param {object} object
	 * @param {string} propertyName
	 * @param {string} dateFormat
	 */
	removeUtcTimezoneFromProperty(object, propertyName, dateFormat = 'YYYY-MM-DDTHH:mm:ss') {
		const propValue = get(object, propertyName);
		if (propValue) {
			set(object, propertyName, this.moment(propValue).utc().format(dateFormat));
		}
	},
	/**
	 * Removes the utc timezone signifier from date objects/strings properties on objects
	 * in a collection and replaces it in-place with the specified date format
	 * @param {object} collection
	 * @param {string} propertyName
	 * @param {string} dateFormat
	 */
	removeUtcTimezoneFromPropertyOnCollection(collection, propertyName, dateFormat = 'YYYY-MM-DDTHH:mm:ss') {
		forEach(collection, sector => this.removeUtcTimezoneFromProperty(
			sector, propertyName, dateFormat
		));
	},

	/**
	 * Converts a boolean value either 0/1 or false/true to a No/Yes string
	 * translated for the current locale.
	 * @param {*} value
	 * @param {object} translate
	 */
	yesNo(value, translate) {
		let result = this.errorValue();
		if ((value !== null) && (value !== undefined)) {
			result = (((value === 0) || (value === false)) ? translate('no') : translate('yes'));
		}

		return result;
	}
});

/**
 * Add any extention methods or customization here
 */

export default MarkitFormat(formatOptions);
