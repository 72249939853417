/* global window */
import { fork } from 'redux-saga/effects';
import markitDataSaga from './MarkitDataSaga';
import cibcDataSaga from './CibcDataSaga';
import markitApi from '../services/MarkitApi';

const rootSaga = token => {
	window.MD = window.MD || {};
	let platformUrl = window.MD.apiBaseUrl;
	let cibcApiUrl = window.MD.apiBaseCibcUrl;

	if (!platformUrl) {
		// console.error('No global API path found, falling back on default');
		platformUrl = 'https://qa-api.markitdigital.com';
	}

	if (!cibcApiUrl) {
		// console.error('No global API path found, falling back on default');
		cibcApiUrl = 'https://cibc.api.markitdigital.com/cibc-obr2-api/v1/';
	}

	const platformApi = markitApi.create(platformUrl, token);
	const cibcApi = markitApi.create(cibcApiUrl, token);

	function* root() {
		yield fork(cibcDataSaga(cibcApi).watcher);
		yield fork(markitDataSaga(platformApi).watcher);
	}
	return root;
};

export default rootSaga;
