/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	similarEtfs: {}
};

const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

const requestCompareSimilarEtfsPeers = state => {
	return Object.assign({}, state, {
		fetchingCompareSimilarEtfs: true,
		similarEtfs: Object.assign({})
	});
};

const receiveCompareSimilarEtfsPeers = (state, action) => {
	let premData = {};
	if (action.response.data) {
		premData = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingCompareSimilarEtfs: false,
		similarEtfs: Object.assign({}, premData)
	});
};

const ACTION_HANDLERS = {
	[Types.API_REQUEST_SIMILAR_ETFS]: requestCompareSimilarEtfsPeers,
	[Types.API_RECEIVE_SIMILAR_ETFS]: receiveCompareSimilarEtfsPeers,
	[Types.API_HANDLE_COMPARELIST_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
