/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* global window */
import types from '../Actions/Types';

function getApiRequestTypes() {
	const apiRequestTypes = [];
	const apiReg = /^API_REQUEST/;

	// MIGHTDO refactor to use filter or foreach
	for (const t in types) {
		if (apiReg.test(t)) {
			apiRequestTypes.push(types[t]);
		}
	}

	return apiRequestTypes;
}

function getApiSingleRequestTypes() {
	const apiRequestTypes = [];
	const apiReg = /^API_SINGLE_REQUEST/;

	// MIGHTDO refactor to use filter or foreach
	for (const t in types) {
		if (apiReg.test(t)) {
			apiRequestTypes.push(types[t]);
		}
	}

	return apiRequestTypes;
}

function getCibcApiSingleRequestTypes() {
	const apiRequestTypes = [];
	const apiReg = /^API_CIBC_SINGLE_REQUEST/;

	// MIGHTDO refactor to use filter or foreach
	for (const t in types) {
		if (apiReg.test(t)) {
			apiRequestTypes.push(types[t]);
		}
	}

	return apiRequestTypes;
}

function getCibcApiRequestTypes() {
	const apiRequestTypes = [];
	const apiReg = /^API_CIBC_REQUEST/;

	// MIGHTDO refactor to use filter or foreach
	for (const t in types) {
		if (apiReg.test(t)) {
			apiRequestTypes.push(types[t]);
		}
	}

	return apiRequestTypes;
}

function getAjaxHandlerType(verb, api) {
	switch (verb) {
		case 'POST':
			return api.postData;
		case 'PUT':
			return api.putData;
		case 'DELETE':
			return api.deleteData;
		case 'GETPDF':
			return api.getPdfData;
		default:
			return api.getData;
	}
}

function handleBlobResponse(response, action) {
	// eslint-disable-next-line no-undef
	const fileName = `${encodeURI(action.documentName)}.pdf`;
	const isIe = typeof window.navigator.msSaveOrOpenBlob !== 'undefined';
	if (isIe) {
		// if is IE or mobile - ask user for action - save or open
		window.navigator.msSaveOrOpenBlob(
			new Blob([response.data], { type: response.headers.contentType }),
			fileName
		);
	} else {
		const blobData = new File([Buffer.from(response.data)], fileName, { type: 'application/pdf' });
		const objectUrl = window.URL.createObjectURL(blobData);
		window.open(objectUrl, '_blank');
	}
}

export {
	getApiRequestTypes,
	getCibcApiRequestTypes,
	getCibcApiSingleRequestTypes,
	getAjaxHandlerType,
	getApiSingleRequestTypes,
	handleBlobResponse
};
