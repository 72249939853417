/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';

export default function asyncComponent(importComponent) {
	class AsyncComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				component: null
			};

			this._isMounted = false;
		}

		componentDidMount() {
			this._isMounted = true;
			this.getAsyncComponent();
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		async getAsyncComponent() {
			const { default: component } = await importComponent();
			if (this._isMounted) {
				this.setState({
					component
				});
			}
		}

		render() {
			// eslint-disable-next-line react/destructuring-assignment
			const C = this.state.component;

			// eslint-disable-next-line react/jsx-props-no-spreading
			return C && this._isMounted ? <C {...this.props} /> : null;
		}
	}

	return AsyncComponent;
}
