/* eslint-disable no-console */
/* eslint-disable max-len */
import {
	call,
	put,
	takeEvery,
	takeLatest
} from 'redux-saga/effects';
import {
	getApiRequestTypes,
	getAjaxHandlerType,
	getApiSingleRequestTypes,
	handleBlobResponse
} from './sagaHelpers';
import { apiFailure } from '../Actions/UtilityActions';

export default api => {
	function* worker(action) {
		const {
			ajaxType,
			params,
			endPoint,
			options
		} = action;
		// Get the handler for the verb type GET, POST, PUT, DELETE, ect
		const apiType = getAjaxHandlerType(ajaxType, api);

		// opportunity to override headers, specifically for news article API call or real time
		if (action.headers) {
			api.api.setHeaders(action.headers);
		} else {
			// Set the default headers
			// MIGHTDO find a better way to reset defaults
			api.api.setHeaders({
				Accept: '*/*',
				realtime: false
			});
		}

		try {
			let response;

			if (action.abort) {
				yield call(action.loader(action.fetchName));
			} else {
				// call the API
				response = yield call(apiType, endPoint, params, options);
			}
			// determine the right response handler based on the action type

			switch (action.type) {
				// determine if this is a pattern we want to use for document downloads
				case 'API_REQUEST_PDF':
				case 'API_REQUEST_RESEARCH_PDF':
					// FILE DOWNLOADS ARE HANDLED UNIQUELY -- WAY NEED TO DOWNLOAD THE BLOB AND THEN OPEN IN A NEW TAB
					handleBlobResponse(response, action);
					break;

				default:

					switch (response.status) {
						case 304:
						case 200:
						case 201:
						case 204:
						case 206:
							// call our success method to hand the data off
							yield put(action.onSuccess(response.data, action.responseDetails));
							break;
							// Unauthorized request - attempt a logout
						case 401:
							yield put(apiFailure('bad auth'));
							break;
						case 404:
							if (action.on404) {
								yield put(action.on404(response.data));
							} else {
								yield put(apiFailure(`404 data response from action: ${action.type}`));
							}
							break;
						default:
							yield put(apiFailure(`Bad data response from action: ${action.type}`));
							break;
					}
					break;
			}
		} catch (error) {
			// Did we throw an error anywhere?
			yield put(apiFailure(`bad call markit data ${error}`));
		} finally {
			// Catchall to turn off the loader
			if (action.loader && action.fetchName) {
				yield put(action.loader(action.fetchName));
			}
		}
	}

	function* watcher() {
		yield takeEvery(getApiRequestTypes(), worker);
		yield takeLatest(getApiSingleRequestTypes(), worker);
	}

	return {
		watcher,
		worker
	};
};
