/* eslint-disable prefer-object-spread */
/* eslint-disable no-mixed-spaces-and-tabs */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';


export const INITIAL_STATE = {
	marketsOverviewChartData: {}
};

const requestMarketsOverviewChart = state => {
	return Object.assign({}, state, {
		marketsOverviewChartData: {}
	});
};

const receiveMarketsOverviewChart = (state, action) => {
	const dataByXid = {};

	if (action.response && action.response.data) {
		action.response.data.forEach(datapoint => {
			if (datapoint) {
				dataByXid[datapoint.venueXid] = datapoint;
			}
		});
	}

	return Object.assign({}, state, {
		marketsOverviewChartData: dataByXid
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};


// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_MARKETS_OVERVIEW_CHART]: requestMarketsOverviewChart,
	[Types.API_RECEIVE_MARKETS_OVERVIEW_CHART]: receiveMarketsOverviewChart,
	[Types.API_HANDLE_MARKETS_OVERVIEW_CHART_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
