import React from 'react';
import { withTranslate } from 'react-redux-multilingual';

const NoData = props => {
	const { translate, errorString } = props;

	return (
		<>
			<span aria-hidden>{errorString}</span>
			<span className="sr-only">{translate && translate('noData')}</span>
		</>
	);
};

export default withTranslate(NoData);
