/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {

};

const apiRequest = (state, action) => {
	return Object.assign({}, state, {
		fetching: action.isFetching,
		name: action.name
	});
};

const failure = (state, action) => {
	return Object.assign({}, state, {
		error: true,
		message: action
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST]: apiRequest,
	[Types.API_FAILURE]: failure
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
