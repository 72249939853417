/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingFundData: false,
	snapshotData: {},
	performanceData: {},
	portfolioData: {},
	prospectusData: {}
};

const requestFundSnapshotData = state => {
	return Object.assign({}, state, {
		fetchingFundData: true,
		snapshotData: {}
	});
};

const clearFundSnapshotData = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		snapshotData: {}
	});
};

const receiveFundSnapshotData = (state, action) => {
	const { data } = action.response;
	return Object.assign({}, state, {
		fetchingFundData: false,
		snapshotData: Object.assign({}, data)
	});
};

const receiveFundSnapshotDataFail = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		snapshotData: {}
	});
};

const requestFundPerformanceData = state => {
	return Object.assign({}, state, {
		fetchingFundData: true,
		performanceData: {}
	});
};

const receiveFundPerformanceData = (state, action) => {
	const { data } = action.response;
	return Object.assign({}, state, {
		fetchingFundData: false,
		performanceData: Object.assign({}, data)
	});
};

const receiveFundPerformanceDataFail = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		performanceData: {}
	});
};

const clearFundPerformanceData = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		performanceData: {}
	});
};

const reuqestFundProspectusData = state => {
	return Object.assign({}, state, {
		fetchingFundProspectusData: true,
		prospectusData: {}
	});
};

const receiveFundProspectusData = (state, action) => {
	const { data } = action.response;

	return Object.assign({}, state, {
		fetchingFundProspectusData: false,
		prospectusData: Object.assign({}, data)
	});
};

const requestFundPortfolioData = state => {
	return Object.assign({}, state, {
		fetchingFundData: true,
		portfolioData: {}
	});
};

const clearFundPortfolioData = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		portfolioData: {}
	});
};

const receiveFundPortfolioData = (state, action) => {
	const { data } = action.response;
	return Object.assign({}, state, {
		fetchingFundData: false,
		portfolioData: Object.assign({}, data)
	});
};

const receiveFundPortfolioDataFail = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		portfolioData: {}
	});
};

const requestFundLipperLeaderData = state => {
	return Object.assign({}, state, {
		fetchingFundData: true,
		lipperLeaderData: {}
	});
};

const clearFundLipperLeaderData = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		lipperLeaderData: {}
	});
};

const receiveFundLipperLeaderData = (state, action) => {
	const { data } = action.response;
	return Object.assign({}, state, {
		fetchingFundData: false,
		lipperLeaderData: Object.assign({}, data)
	});
};

const receiveFundLipperLeaderDataFail = state => {
	return Object.assign({}, state, {
		fetchingFundData: false,
		lipperLeaderData: {}
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_HANDLE_FUND_DATA_LOADER]: handleLoader,
	[Types.API_CIBC_REQUEST_FUND_SNAPSHOT_DATA]: requestFundSnapshotData,
	[Types.CLEAR_FUND_SNAPSHOT_DATA]: clearFundSnapshotData,
	[Types.API_CIBC_RECEIVE_FUND_SNAPSHOT_DATA]: receiveFundSnapshotData,
	[Types.API_CIBC_RECEIVE_FUND_SNAPSHOT_DATA_FAIL]: receiveFundSnapshotDataFail,
	[Types.API_CIBC_REQUEST_FUND_PERFORMANCE_DATA]: requestFundPerformanceData,
	[Types.API_CIBC_RECEIVE_FUND_PERFORMANCE_DATA]: receiveFundPerformanceData,
	[Types.API_CIBC_RECEIVE_FUND_PERFORMANCE_DATA_FAIL]: receiveFundPerformanceDataFail,
	[Types.CLEAR_FUND_PERFORMANCE_DATA]: clearFundPerformanceData,
	[Types.API_CIBC_REQUEST_FUND_PORTFOLIO_DATA]: requestFundPortfolioData,
	[Types.CLEAR_FUND_PORTFOLIO_DATA]: clearFundPortfolioData,
	[Types.API_CIBC_RECEIVE_FUND_PORTFOLIO_DATA]: receiveFundPortfolioData,
	[Types.API_CIBC_RECEIVE_FUND_PORTFOLIO_DATA_FAIL]: receiveFundPortfolioDataFail,
	[Types.API_CIBC_REQUEST_FUND_LIPPER_LEADER_DATA]: requestFundLipperLeaderData,
	[Types.CLEAR_FUND_LIPPER_LEADER_DATA]: clearFundLipperLeaderData,
	[Types.API_CIBC_RECEIVE_FUND_LIPPER_LEADER_DATA]: receiveFundLipperLeaderData,
	[Types.API_CIBC_RECEIVE_FUND_LIPPER_LEADER_DATA_FAIL]: receiveFundLipperLeaderDataFail,
	[Types.API_CIBC_REQUEST_FUND_PROSPECTUS_DATA]: reuqestFundProspectusData,
	[Types.API_CIBC_RECEIVE_FUND_PROSPECTUS_DATA]: receiveFundProspectusData
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
