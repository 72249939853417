/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingQuoteData: false,
	fetchingLegacyQuoteData: false,
	legacyQuote: {},
	quotesByXid: {},
	data: {}
};

const requestQuotes = state => {
	return Object.assign({}, state, {
		fetchingQuoteData: true
	});
};

const receiveQuotes = (state, action) => {
	const byXid = {};

	if (action.response
		&& action.response.data
		&& action.response.data.quotes
		&& action.response.data.quotes.length > 0) {
		action.response.data.quotes.forEach(quote => {
			const { data, error } = quote || {};
			if (data && data.lastTrade && data.lastTrade.open === 0) {
				data.lastTrade.open = null;
			}

			if (!error) {
				byXid[data.venueXid] = data;
			}
		});
	}

	return Object.assign({}, state, {
		fetchingQuoteData: false,
		data: Object.assign({}, action.response),
		quotesByXid: Object.assign({}, state.quotesByXid, byXid)
	});
};

const requestLegacyQuotes = state => {
	return Object.assign({}, state, {
		fetchingLegacyQuoteData: true,
		legacyQuote: {}
	});
};

const receiveLegacyQuotes = (state, action) => {
	let data = {};

	if (action.response && action.response.data) {
		data = action.response.data;
	}

	return Object.assign({}, state, {
		fetchingLegacyQuoteData: false,
		legacyQuote: data
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_REQUEST_QUOTE]: requestQuotes,
	[Types.API_RECEIVE_QUOTE]: receiveQuotes,
	[Types.API_CIBC_REQUEST_LEGACY_QUOTE]: requestLegacyQuotes,
	[Types.API_CIBC_RECEIVE_LEGACY_QUOTE]: receiveLegacyQuotes,
	[Types.API_HANDLE_QUOTE_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
