/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from 'Actions/Types';

export const INITIAL_STATE = {
	fetchingTodaysTopSectors: false,
	data: []
};

const requestTechnicalAnalysis = state => {
	return Object.assign({}, state, {
		fetchingTodaysTopSectors: true
	});
};

const receiveTechnicalAnalysis = (state, action) => {
	if (action.response && action.response.data) {
		return Object.assign({}, state, {
			fetchingTodaysTopSectors: false,
			data: action.response.data
		});
	}

	return Object.assign({}, state, {
		fetchingTodaysTopSectors: false
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_TODAYS_TOP_SECTORS]: requestTechnicalAnalysis,
	[Types.API_CIBC_RECEIVE_TODAYS_TOP_SECTORS]: receiveTechnicalAnalysis,
	[Types.API_CIBC_HANDLE_TODAYS_TOP_SECTORS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
