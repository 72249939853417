import { createReducer } from 'reduxsauce';

import Types from '../Actions/Types';

export const INITIAL_STATE = {
	fetchingFundIndexOverviewFund: false,
	fetchingFundIndexOverviewIndex: false,
	indexData: [],
	fundData: []
};

const receiveFundIndexOverviewFund = (state, action) => {
	let premData = [];
	if (action.response.data) {
		premData = action.response.data;
	}

	return {
		...state,
		fetchingFundIndexOverviewFund: false,
		fundData: premData
	};
};

const requestFundIndexOverviewFund = state => {
	return {
		...state,
		fetchingFundIndexOverviewFund: true,
		fundData: []
	};
};

const receiveFundIndexOverviewIndex = (state, action) => {
	let premData = [];
	if (action.response.data) {
		premData = action.response.data;
	}

	return {
		...state,
		fetchingFundIndexOverviewIndex: false,
		indexData: premData
	};
};

const requestFundIndexOverviewIndex = state => {
	return {
		...state,
		fetchingFundIndexOverviewIndex: true,
		indexData: []
	};
};

const handleLoader = (state, action) => {
	return {
		...state,
		[action.fetchName]: false
	};
};

const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_FUND_INDEX_OVERVIEW_FUND]: requestFundIndexOverviewFund,
	[Types.API_CIBC_RECEIVE_FUND_INDEX_OVERVIEW_FUND]: receiveFundIndexOverviewFund,
	[Types.API_CIBC_REQUEST_FUND_INDEX_OVERVIEW_INDEX]: requestFundIndexOverviewIndex,
	[Types.API_CIBC_RECEIVE_FUND_INDEX_OVERVIEW_INDEX]: receiveFundIndexOverviewIndex,
	[Types.API_CIBC_HANDLE_FUND_INDEX_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
