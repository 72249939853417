/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { findLastIndex } from 'lodash';
import { requestXrefByVenueXids } from './xrefActions';
import Types from './Types';

const deliveryConfigDefaults = {
	noDelivery: {
		coverCode: 'CB048',
		delivery: 'NONE',
		destinationId: 'noDelivery',
		address: '',
		format: 'TEXT',
		intraDaySuspend: {
			isOn: false
		},
		isOnDeliveryHold: false,
		isOnVacation: false,
		isSuspended: true,
		isUndeliverable: false,
		suspensions: [{
			description: 'Global Suspend',
			typeId: 1
		}]
	}
};

const buildUpdateMarketAlert = (item, exchangeCode, timeframe, destinations) => {
	return ({
		criteria: {
			exchangeCode,
			timeframe
		},
		destinations: destinations.map(d => d.destinationId),
		item
	});
};

const buildStockAlert = (item, criteria, destinations) => {
	return ({
		criteria,
		destinations: destinations.map(d => d.destinationId),
		item
	});
};

const buildAnalystReportAlert = (alert, subscribed, destinations) => {
	const { attributesMap, criteria } = alert || {};
	const attributes = Object.keys(attributesMap).map(key => {
		return ({
			name: key,
			value: JSON.stringify(attributesMap[key])
		});
	});
	const input = {
		attributes,
		item: subscribed ? 634 : 628,
		criteria,
		destinations: destinations.map(d => d.destinationId)
	};

	return (input);
};

const handleLoader = fetchName => {
	return ({
		type: Types.API_HANDLE_ALERTS_LOADER,
		fetchName
	});
};

const receiveAlertsProfile = response => {
	return ({
		type: Types.API_RECEIVE_ALERTS_PROFILE,
		response
	});
};

const requestAlertsProfile = (includeSuspendInfo = true) => {
	return ({
		type: Types.API_REQUEST_ALERTS_PROFILE,
		endPoint: 'alerts/v1/profile',
		params: {
			includeSuspendInfo
		},
		ajaxType: 'GET',
		onSuccess: receiveAlertsProfile,
		loader: handleLoader,
		fetchName: 'fetchingAlertsProfile'
	});
};

const receiveAlertsDestinations = response => {
	const actions = [{
		type: Types.API_RECEIVE_ALERTS_PROFILE_DESTINATIONS,
		response
	}];

	// Adding this destination so there is always at least one destination
	// so that alerts can be set before there has been an email set
	if (response && response.data && response.data.items) {
		const { items } = response.data;
		const noDeliveryIndex = findLastIndex(items, { destinationId: 'noDelivery' });

		// Adding the noDelivery destination if it does not exist
		if (noDeliveryIndex === -1) {
			const { noDelivery } = deliveryConfigDefaults;
			const { destinationId } = noDelivery;
			actions.push(requestPutDestination(destinationId, noDelivery));
		}
	}

	return actions;
};

const requestAlertsDestinations = (includeSuspendInfo = true) => {
	return ({
		type: Types.API_REQUEST_ALERTS_PROFILE_DESTINATIONS,
		endPoint: 'alerts/v1/profile/destinations',
		params: {
			includeSuspendInfo
		},
		ajaxType: 'GET',
		onSuccess: receiveAlertsDestinations,
		loader: handleLoader,
		fetchName: 'fetchingAlertsDestination'
	});
};

const receiveAlertsSubscriptions = response => {
	const actions = [{
		type: Types.API_RECEIVE_ALERTS_SUBSCRIPTIONS,
		response
	}];
	const { data } = response;
	const { items } = data || {};

	if (items && items.length) {
		const xids = [];

		items.forEach(item => {
			if (item.criteria && item.criteria.venueXid && xids.indexOf(item.criteria.venueXid) === -1) {
				xids.push(item.criteria.venueXid);
			}
		});

		if (xids.length > 0) {
			const chunk = 40;
			for (let i = 0; i < xids.length; i += chunk) {
				const tempXids = xids.slice(i, i + chunk);
				// breaking the xref calls into chunks of 40
				actions.push(requestXrefByVenueXids(tempXids));
			}
		}
	}

	return actions;
};

const requestAlertsSubscriptions = (
	item,
	venueXid,
	alertCategory,
	destinationId,
	offset,
	limit = 1000
) => {
	return ({
		type: Types.API_REQUEST_ALERTS_SUBSCRIPTIONS,
		endPoint: 'alerts/v1/subscriptions',
		params: {
			item,
			venueXid,
			alertCategory,
			destinationId,
			limit,
			offset
		},
		ajaxType: 'GET',
		onSuccess: receiveAlertsSubscriptions,
		loader: handleLoader,
		fetchName: 'fetchingAlertsSubscriptions'
	});
};

const requestAlertById = itemId => {
	return ({
		type: Types.API_REQUEST_ALERTS_SUBSCRIPTIONS,
		endPoint: `alerts/v1/subscriptions/${itemId}`,
		params: {
			destinationId: 'noDelivery'
		},
		ajaxType: 'GET',
		onSuccess: receiveAlertsSubscriptions,
		loader: handleLoader,
		fetchName: 'fetchingAlertsSubscriptions'
	});
};

const requestAlertByCategory = (alertCategory, limit) => {
	return ({
		type: Types.API_REQUEST_ALERTS_SUBSCRIPTIONS,
		endPoint: 'alerts/v1/subscriptions/',
		params: {
			alertCategory,
			limit
		},
		ajaxType: 'GET',
		onSuccess: receiveAlertsSubscriptions,
		loader: handleLoader,
		fetchName: 'fetchingAlertsSubscriptions'
	});
};

const receiveDeleteDestination = response => {
	return ({
		type: Types.API_RECEIVE_ALERTS_DELETE_DESTINATION,
		response
	}, requestAlertsDestinations());
};

const requestDeleteDestination = destinationId => {
	return ({
		type: Types.API_REQUEST_ALERTS_DELETE_DESTINATION,
		endPoint: `alerts/v1/profile/destinations/${destinationId}`,
		ajaxType: 'DELETE',
		onSuccess: receiveDeleteDestination,
		loader: handleLoader,
		fetchName: 'fetchingDeleteDestination'
	});
};

const receivePutDestination = response => {
	return ({
		type: Types.API_RECEIVE_ALERTS_PUT_DESTINATION,
		response
	}, requestAlertsDestinations());
};

const requestPutDestination = (
	destinationId,
	destination
) => {
	return ({
		type: Types.API_REQUEST_ALERTS_PUT_DESTINATION,
		endPoint: `alerts/v1/profile/destinations/${destinationId}`,
		params: destination,
		ajaxType: 'PUT',
		onSuccess: receivePutDestination,
		loader: handleLoader,
		fetchName: 'fetchingPutDestination'
	});
};

const receivePostProfile = response => {
	return ({
		type: Types.API_RECEIVE_ALERTS_POST_PROFILE,
		response
	});
};

const requestPostProfile = profileObj => {
	return ({
		type: Types.API_REQUEST_ALERTS_POST_PROFILE,
		endPoint: 'alerts/v1/profile',
		params: profileObj,
		ajaxType: 'POST',
		onSuccess: receivePostProfile,
		loader: handleLoader,
		fetchName: 'fetchingPostProfile'
	});
};

const receiveDeleteAlerts = response => {
	return ({
		type: Types.API_RECEIVE_ALERTS_DELETE,
		response
	});
};

const requestDeleteAlerts = itemIds => {
	return ({
		type: Types.API_REQUEST_ALERTS_DELETE,
		endPoint: `alerts/v1/subscriptions/${itemIds.join(',')}`,
		ajaxType: 'DELETE',
		onSuccess: receiveDeleteAlerts,
		loader: handleLoader,
		fetchName: 'fetchingDeleteAlerts'
	});
};

const receiveUpdateAlert = response => {
	return ({
		type: Types.API_RECEIVE_ALERT_UPDATE,
		response
	});
};

const requestUpdateAlert = (itemId, alert) => {
	return ({
		type: Types.API_REQUEST_ALERT_UPDATE,
		endPoint: `alerts/v1/subscriptions/${itemId}`,
		ajaxType: 'PUT',
		params: alert,
		onSuccess: receiveUpdateAlert,
		loader: handleLoader,
		fetchName: 'fetchingUpdateAlert'
	});
};

const receiveUpdateAnalystResearchAlert = response => {
	const actions = [
		receiveUpdateAlert(response),
		requestAlertsSubscriptions()
	];

	return actions;
};

const requestUpdateAnalystResearchAlert = (itemId, alert) => {
	return ({
		type: Types.API_CIBC_REQUEST_ALERT_UPDATE,
		endPoint: `/analystresearch/alerts/${itemId}`,
		ajaxType: 'PUT',
		params: alert,
		onSuccess: receiveUpdateAnalystResearchAlert,
		loader: handleLoader,
		fetchName: 'fetchingUpdateAlert'
	});
};

const requestSetAnalystResearchAlert = alert => {
	return ({
		type: Types.API_CIBC_REQUEST_ALERT_UPDATE,
		endPoint: '/analystresearch/alerts',
		ajaxType: 'POST',
		params: alert,
		onSuccess: receiveUpdateAnalystResearchAlert,
		loader: handleLoader,
		fetchName: 'fetchingUpdateAlert'
	});
};

const receiveAddAlerts = response => {
	return ({
		type: Types.API_RECEIVE_ALERTS_ADD,
		response
	});
};

const requestAddAlerts = alerts => {
	return ({
		type: Types.API_REQUEST_ALERTS_ADD,
		endPoint: 'alerts/v1/subscriptions',
		ajaxType: 'POST',
		params: alerts,
		onSuccess: receiveAddAlerts,
		loader: handleLoader,
		fetchName: 'fetchingAddAlerts'
	});
};

export {
	requestAlertsProfile,
	requestAlertsDestinations,
	requestAlertsSubscriptions,
	requestAlertById,
	requestAlertByCategory,
	requestDeleteDestination,
	requestPutDestination,
	requestPostProfile,
	requestUpdateAlert,
	requestUpdateAnalystResearchAlert,
	requestSetAnalystResearchAlert,
	requestDeleteAlerts,
	requestAddAlerts,
	buildUpdateMarketAlert,
	buildAnalystReportAlert,
	buildStockAlert
};
