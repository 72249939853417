/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import Types from 'Actions/Types';

export const INITIAL_STATE = {
	fetchingTechnicalAnalysis: false,
	data: {}
};

const requestTechnicalAnalysis = state => {
	return Object.assign({}, state, {
		fetchingTechnicalAnalysis: true
	});
};

const receiveTechnicalAnalysis = (state, action) => {
	return Object.assign({}, state, {
		fetchingTechnicalAnalysis: false,
		data: Object.assign({}, action.response)
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_TECHNICAL_ANALYSIS]: requestTechnicalAnalysis,
	[Types.API_CIBC_RECEIVE_TECHNICAL_ANALYSIS]: receiveTechnicalAnalysis,
	[Types.API_CIBC_HANDLE_TECHNICAL_ANALYSIS_LOADER]: handleLoader
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
